<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
          <header class="modal-header" id="modalTitle">
              <slot name="header">
              {{$t('meterHistory')}}
              </slot>
              <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
              >
              <ods-icon name = "close" size = "18" />
              </button>
          </header>
          <ods-module v-loading="loading">
            <section class="modal-body">
                <slot name="body">
                    <ods-row v-if="data.length < 1" type="flex" justify="center" style="margin-top: 2rem">
                      <ods-icon name="info" size="100" class="custom-placeholder"/>
                    </ods-row>
                    <ods-timeline
                        class="mt-1"
                        :reverse="true">
                      <ods-timeline-item
                          v-for="(meter, index) in data"
                          :key="index"
                          :icon="meter.status === 'installed' ? 'ods-icon-pushpin' : 'ods-icon-close'"
                          placement="right"
                          size="large"
                          :color="meter.status === 'installed' ? '#36B348' : '#2E6C99'"
                      >
                       <div>
                          <p>{{meter.fromDate | date}}
                            <ods-badge
                                style="margin-left: 10px; margin-right: 10px"
                                v-if="meter.status"
                                :value="$t(meter.status).toUpperCase()"
                                :type="meter.status === 'installed' ? 'primary' : 'secondary'"
                            />
                          </p>
                          <p>
                            <span class="font-weight-bold">{{$t('serialNumber')}}:</span> {{meter.serialNumber}}
                          </p>

                       </div>
                       <!--
                       <span class="font-weight-bold">{{ activity.version == 0 ? $t('initialFields'): $t('fieldsUpdated')}}:</span>
                       <p v-if="activity.fields.networkNode">{{$t('networkElement')}}: {{activity.fields.networkNode.unitName}}</p>
                       <p v-if="activity.fields.action">{{$t('action')}}: {{activity.fields.action}}</p>
                       <p v-if="activity.fields.expectedCost">{{$t('expectedRealCost')}}: {{activity.fields.expectedCost | formatNumber}}</p>
                       <p v-if="activity.fields.expectedExecutionDate">{{$t('expectedExecutionDate')}}: {{activity.fields.expectedExecutionDate | date}}</p>
                       <p v-if="activity.fields.expectedComment">{{$t('comment')}}: {{activity.fields.expectedComment | max20}}</p>
                       <p v-if="activity.fields.result">{{$t('result')}}: {{activity.fields.result}}</p>
                       <p v-if="activity.fields.realCost">{{$t('realCost')}}: {{activity.fields.realCost | formatNumber}}</p>
                       <p v-if="activity.fields.executionDate">{{$t('executionDate')}}: {{activity.fields.executionDate | date}}</p>
                       <p v-if="activity.fields.comment">{{$t('otherComment')}}: {{activity.fields.comment | max20}}</p>-->
                      </ods-timeline-item>
                    </ods-timeline>
                </slot>
            </section>
          </ods-module>
        </div>
    </div>
  </transition>
</template>

<script>
import handlePromise from '@/utils/promise'
import MeterService from '@/services/Meter'

export default {
  name: 'HistoryMeterModal',
  props: {
    networkUnitId: String
  },
  mounted: function () {
    this.getData()
  },
  data () {
    return {
      loading: false,
      data: []
    }
  },
  methods: {
    async getData () {
      this.loading = true
      const [error, response, data] = await handlePromise(MeterService.historyMeter(this.networkUnitId))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.data = data.data
      this.loading = false
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style scoped>
  ::-webkit-scrollbar {
     width: 6px;
   }

   ::-webkit-scrollbar-thumb {
      background: rgb(224, 223, 223);
      border-radius: 10px;
   }

  ::-webkit-scrollbar-thumb:hover {
      background: rgb(197, 197, 197);
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 30%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 40vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
