<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
            {{$t('customers')}}:{{areaName}} - {{$t('totalConsumption')}}:{{output | formatNumberNoDecimals}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>

        <section class="modal-body">
            <slot name="body">
                 <ods-table
                    :data="tableDataCustomer"
                    v-loading="loading"
                    size="mini"
                    height="90%"
                >
                    <ods-table-column prop="networkUnit.meter.name" :label="$t('name')"/>
                    <ods-table-column :label="$t('consumption')" >
                    <template slot-scope="scope">
                        {{ scope.row.consumption | formatNumberNoDecimals }}
                    </template>
                    </ods-table-column>
                    <ods-table-column :label="$t('serviceType')" >
                    <template slot-scope="scope">
                        {{ scope.row.networkUnit.meter.serviceType }}
                    </template>
                    </ods-table-column>
                    <ods-table-column :label="$t('tariff')" >
                    <template slot-scope="scope">
                        {{ scope.row.networkUnit.meter.tariff }}
                    </template>
                    </ods-table-column>
                    <ods-table-column :label="$t('largePower')" >
                    <template slot-scope="scope">
                      <div :style="{'background-color': scope.row.networkUnit.meter.largePower == true ? 'green' : 'red'}" style="width: 28px; height: 28px;border-radius: 14px"/>
                    </template>
                    </ods-table-column>
                    <ods-table-column :label="$t('address')" >
                    <template slot-scope="scope">
                        {{ scope.row.networkUnit.meter.address }}
                    </template>
                    </ods-table-column>
                    <ods-table-column :label="$t('phone')" >
                    <template slot-scope="scope">
                        {{ scope.row.networkUnit.meter.phone }}
                    </template>
                    </ods-table-column>
                    <ods-table-column :label="$t('email')">
                    <template slot-scope="scope">
                        {{ scope.row.networkUnit.meter.email }}
                    </template>
                    </ods-table-column>
                </ods-table>
                <div class="block">
                  <ods-pagination
                       :total="total"
                       :page-size="pageSize"
                       @size-change="handleSizeChange"
                       @current-change="handlePageChange"
                       layout="prev, pager, next, jumper, sizes, ->, total"
                  >
                  </ods-pagination>
                </div>
            </slot>
        </section>
        </div>
    </div>
  </transition>
</template>

<script>
import map from 'lodash/map'
import BalanceService from '@/services/Balance'
import handlePromise from '@/utils/promise'

export default {
  name: 'Modal',
  props: {
    areaId: String,
    periodId: String,
    areaName: String,
    tableDataCustomer: Array,
    output: Number,
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      pageSize: 10,
      pageIndex: 0,
      sort: null,
      filters: {}
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async getData () {
      // console.log(this.areaId, this.periodId, 'LOL')
      const params = {
        offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize),
        limit: this.pageSize,
        ...this.filters
      }
      if (this.sort && this.sort.prop) params[`sortby[${this.sort.prop}]`] = this.sort.order === 'ascending' ? 'ASC' : 'DESC'
      this.toggleLoading()
      const [error, response, data] = await handlePromise(BalanceService.getBalanceCustomersByAreaWater(this.areaId, this.periodId, params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.$emit('customers', data)
    },
    handleSizeChange (val) {
      // console.log(val, 'handleSizeChange')
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })

      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 80%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
     height: 80vh;
    overflow-y: auto;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #292929;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

    .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
