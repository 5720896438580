<template>
  <section>
    <ods-module>
      <template slot="header">
        <list-header
            title="users"
            btn-label="newUser"
            url-key="UserNew"
            :hasPermission=hasPermission
        />
      </template>
      <ods-col :md="24" v-if="!hasPermission" style="min-height: 100%;">
        <ods-module style="min-height: 100%">
          <ods-row type="flex" justify="center" style="margin-top: 2rem; font-size:120%">
            {{$t('userDontHaveAcces')}}
          </ods-row>
          <ods-row type="flex" justify="center" style="margin-top: 2rem">
            <ods-icon name="info" size="100" class="custom-placeholder"/>
          </ods-row>
        </ods-module>
      </ods-col>
      <async-table
          v-if="hasPermission"
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <ods-table-column prop="email" :label="$t('email')">
          <template slot-scope="scope">
            <router-link v-if="scope.row.id" :to="{ name: 'UserEdit', params: { id: scope.row.id }}">
              <ods-button type="text" class="p-0">{{scope.row.email}}</ods-button>
            </router-link>
          </template>
        </ods-table-column>

        <ods-table-column
            prop="userProfile"
            :label="$t('name')"
            :formatter="(row) => row.userProfile.name"
        />
        <ods-table-column
            prop="roles"
            :label="$t('roles')"
        >
            <template slot-scope="scope">
              <div v-for="item in scope.row.roles" :key="item" >
                <span v-if="item !== 'ROLE_API'">
                  {{$t(item)}}
                </span>
              </div>
            </template>
        </ods-table-column>

        <ods-table-column prop="enabled" :label="$t('enabled')">
          <template slot-scope="scope">
            <ods-icon name="checkmark-2" v-if="scope.row.enabled"></ods-icon>
            <ods-icon name="close" v-if="!scope.row.enabled"></ods-icon>
          </template>
        </ods-table-column>

        <ods-table-column prop="externalAuthentication" :label="$t('externalAuthentication')">
          <template slot-scope="scope">
            <ods-icon name="checkmark-2" v-if="scope.row.externalAuthentication"></ods-icon>
            <ods-icon name="close" v-if="!scope.row.externalAuthentication"></ods-icon>
          </template>
        </ods-table-column>

        <ods-table-column align="right">
          <template slot-scope="scope">
            <div>
              <router-link :to="{ name: 'UserChangePassword', params: { id: scope.row.id }}">
                <ods-button
                    size="mini"
                    type="secondary"
                >
                  {{$t('changePassword')}}
                </ods-button>
              </router-link>
            </div>
            <div style="margin-top: 5px">
              <table-delete @click="toggleDialog(scope.row.id)"></table-delete>
            </div>
          </template>
        </ods-table-column>
      </async-table>

      <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @cancel="toggleDialog"
          @confirm="deleteItem"
      />
    </ods-module>
  </section>
</template>

<script>
import UserService from '@/services/User'
import handlePromise from '@/utils/promise'
import AsyncTable from '@/custom-components/AsyncTable'
import ListHeader from '@/custom-components/ListHeader'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import asyncTableCommon from '@/mixins/async-table'
import { mapState } from 'vuex'

export default {
  name: 'UsersDashboard',
  components: {
    AsyncTable,
    TableDelete,
    DialogConfirmation,
    ListHeader
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.handlePermission()
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      hasPermission: true,
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      promise: (params) => UserService.getUsers(params)
    }
  },
  methods: {
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(UserService.deleteUser(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
