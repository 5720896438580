<template>
  <li class="ods-menu-item"
    role="menuitem"
    tabindex="-1"
    :style="[paddingStyle, itemStyle, { backgroundColor }]"
    :class="{
      'is-active': active,
      'is-disabled': disabled
    }"
    @click="handleClick"
    @mouseenter="onMouseEnter"
    @focus="onMouseEnter"
    @blur="onMouseLeave"
    @mouseleave="onMouseLeave"
  >
    <ods-tooltip
      v-if="parentMenu.$options.componentName === 'OdsMenu' && rootMenu.collapse && $slots.title"
      effect="menu"
      open-delay="800"
      placement="right">
      <div slot="content"><slot name="title"></slot></div>
      <div style="position: absolute;left: 0;top: 0;height: 100%;width: 100%;display: inline-block;box-sizing: border-box;padding: 0 26px;">
        <slot></slot>
        <span>{{ $slots.title[0].children[0].text }}</span>
      </div>
    </ods-tooltip>
    <template v-else>
      <div>
        <slot></slot>
        <slot name="title"></slot>
      </div>
    </template>
  </li>
</template>
<script>
import Menu from '@/components/menu/menu-mixin'
import OdsTooltip from '@/components/tooltip/Tooltip'
import Emitter from '@/addons/mixins/emitter'

export default {
  name: 'OdsMenuItem',

  componentName: 'OdsMenuItem',

  mixins: [Menu, Emitter],

  components: { OdsTooltip },

  props: {
    index: {
      type: [String, Object],
      required: true
    },
    route: [String, Object],
    disabled: Boolean
  },
  computed: {
    active () {
      return this.index === this.rootMenu.activeIndex
    },
    hoverBackground () {
      return this.rootMenu.hoverBackground
    },
    backgroundColor () {
      return this.rootMenu.backgroundColor || ''
    },
    activeTextColor () {
      return this.rootMenu.activeTextColor || ''
    },
    textColor () {
      return this.rootMenu.textColor || ''
    },
    mode () {
      return this.rootMenu.mode
    },
    itemStyle () {
      const style = {
        color: this.active ? this.activeTextColor : this.textColor
      }
      if (this.mode === 'horizontal' && !this.isNested) {
        style.borderBottomColor = this.active
          ? (this.rootMenu.activeTextColor ? this.activeTextColor : '')
          : 'transparent'
      }
      return style
    },
    isNested () {
      return this.parentMenu !== this.rootMenu
    }
  },
  methods: {
    onMouseEnter () {
      if (this.mode === 'horizontal' && !this.rootMenu.backgroundColor) return
      this.$el.style.backgroundColor = this.hoverBackground
    },
    onMouseLeave () {
      if (this.mode === 'horizontal' && !this.rootMenu.backgroundColor) return
      this.$el.style.backgroundColor = this.backgroundColor
    },
    handleClick () {
      if (!this.disabled) {
        this.dispatch('OdsMenu', 'item-click', this)
        this.$emit('click', this)
      }
    }
  },
  mounted () {
    this.parentMenu.addItem(this)
    this.rootMenu.addItem(this)
  },
  beforeDestroy () {
    this.parentMenu.removeItem(this)
    this.rootMenu.removeItem(this)
  }
}
</script>
