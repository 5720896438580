<template>
  <ods-module
    :class="'tree__module ' + containerClass"
    :shadow="containerShadow"
    :body-style="containerBodyStyle"
  >
    <ods-row style="display: flex">
      <ods-input
        style="width: 55%"
        :placeholder="$t('filterKeyword')"
        v-model="form.name"
        @keyEnter="getTree"
      >
      </ods-input>

      <div style="margin-left: auto">
        <ods-button
          size="small"
          icon="ods-icon-plus"
          circle
          @click="dialogVisible = true"
        >
        </ods-button>

        <ods-popover
          ref="popover4"
          placement="left"
          width="250"
          trigger="hover"
        >
          <ods-row>
            <p style="text-align: center; font-weight: 500">
              {{ $t("activeFilters") }}
            </p>
            <hr />
            <div v-if="form.clientType">
              <ods-badge class="mt-1" :value="$t('clientType')">
                <ods-button
                  size="small"
                  class="hide-pointer"
                  type="secundary"
                  >{{ form.clientType.description | max20 }}</ods-button
                >
              </ods-badge>
            </div>

            <div v-if="form.generationType">
              <ods-badge
                class="mt-1"
                :value="$t('generationType')"
              >
                <ods-button
                  size="small"
                  class="hide-pointer"
                  type="secundary"
                  >{{ form.generationType.description | max20 }}</ods-button
                >
              </ods-badge>
            </div>

            <div v-if="form.asc">
              <ods-badge value="ASC" class="mt-1">
                <ods-button
                  size="small"
                  class="hide-pointer"
                  type="secundary"
                  >{{ form.asc.description | max20 }}</ods-button
                >
              </ods-badge>
            </div>

            <div v-if="form.region">
              <ods-badge :value="$t('region')" class="mt-1">
                <ods-button
                  size="small"
                  class="hide-pointer"
                  type="secundary"
                  >{{ form.region.description | max20 }}</ods-button
                >
              </ods-badge>
            </div>

            <div v-if="form.transportDivision">
              <ods-badge
                :value="$t('transportationDivision')"
                class="mt-1"
              >
                <ods-button
                  size="small"
                  class="hide-pointer"
                  type="secundary"
                  >{{ form.transportDivision.description | max20 }}</ods-button
                >
              </ods-badge>
            </div>

            <div v-if="form.voltageType">
              <ods-badge :value="$t('voltageType')" class="mt-1">
                <ods-button
                  size="small"
                  class="hide-pointer"
                  type="secundary"
                  >{{ form.voltageType.description | max20 }}</ods-button
                >
              </ods-badge>
            </div>

            <div v-if="form.networkElement">
              <ods-badge :value="$t('networkElement')" class="mt-1">
                <ods-button
                  size="small"
                  class="hide-pointer"
                  type="secundary"
                  >{{ form.networkElement.name | max20 }}</ods-button
                >
              </ods-badge>
            </div>
          </ods-row>
        </ods-popover>

        <ods-button
          size="small"
          icon="ods-icon-search"
          circle
          v-popover:popover4
          @click="getTree"
        >
        </ods-button>
      </div>
    </ods-row>

    <div class="tree__container">
      <ods-tree
        ref="tree"
        highlightCurrent
        :props="props"
        :data="tree"
        node-key="id"
        :expand-on-click-node="false"
        :default-expanded-keys="[]"
        :contentClass="getNodeClass"
        @node-click="(data) => $emit('node-click', data)"
        :render-content="renderContent"
      >
      </ods-tree>
    </div>

    <dialog-form
      v-if="dialogVisible"
      :title="$t('Network Filters')"
      :visible="dialogVisible"
      @submit="submitForm"
      @cancel="toggleDialog()"
    >
      <ods-form :model="form" ref="form" v-loading="formLoading">
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('voltageType')" prop="voltageType">
              <ods-select v-model="form.voltageType" clearable>
                <ods-option
                  v-for="item in voltages"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item
              :label="$t('transportationDivision')"
              prop="transportDivision"
            >
              <ods-select v-model="form.transportDivision" clearable>
                <ods-option
                  v-for="item in transportes"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('region')" prop="region">
              <ods-select v-model="form.region" clearable>
                <ods-option
                  v-for="item in regiones"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item label="ASC" prop="asc">
              <ods-select v-model="form.asc" clearable>
                <ods-option
                  v-for="item in ascs"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('generationType')" prop="generationType">
              <ods-select v-model="form.generationType" clearable>
                <ods-option
                  v-for="item in generations"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item :label="$t('clientType')" prop="clientType">
              <ods-select v-model="form.clientType" clearable>
                <ods-option
                  v-for="item in clients"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('networkElement')" prop="networkElement">
              <ods-select v-model="form.networkElement" clearable>
                <ods-option
                  v-for="item in networkElements"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
      </ods-form>
    </dialog-form>
  </ods-module>
</template>

<script>
import DialogForm from "@/custom-components/DialogForm";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import { mapState } from "vuex";
import NetworkService from "@/services/NetworkUnit";
import NetworkElementService from "@/services/NetworkElement";
import LovService from "@/services/Lov";
import handlePromise from "@/utils/promise";
import errors from "@/config/InputErrors";

export default {
  name: "NetworkMozambique",
  props: {
    containerClass: String,
    containerBodyStyle: {},
    containerShadow: String,
    selectedNodes: Array,
    filters: {},
    hideLastNode: {
      type: Boolean,
      default: false,
    },
  },
  components: { DialogForm },
  data() {
    return {
      props: {
        children: "children",
        label: "name",
        isLeaf: "leaf",
        penultimo: false,
      },
      tree: [],
      filterText: "",
      voltages: [],
      transportes: [],
      regiones: [],
      ascs: [],
      generations: [],
      clients: [],
      networkElements: [],
      form: {},
      dialogVisible: false,
      formLoading: false,
      errors,
    };
  },
  async mounted() {
    this.getVoltages();
    this.getTransports();
    this.getRegions();
    this.getASCs();
    this.getGenerations();
    this.getClients();
    this.getTree();
    this.getNetworkElements();
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.data,
    }),
  },
  methods: {
    async getVoltages() {
      this.$emit("toggle-loading");
      const [error, response, data] = await handlePromise(
        LovService.getLov("TV")
      );
      this.$emit("toggle-loading");
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.voltages = data.lovValues;
    },
    async getTransports() {
      this.$emit("toggle-loading");
      const [error, response, data] = await handlePromise(
        LovService.getLov("ETD")
      );
      this.$emit("toggle-loading");
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.transportes = data.lovValues;
    },
    async getRegions() {
      this.$emit("toggle-loading");
      const [error, response, data] = await handlePromise(
        LovService.getLov("R")
      );
      this.$emit("toggle-loading");
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.regiones = data.lovValues;
    },
    async getASCs() {
      this.$emit("toggle-loading");
      const [error, response, data] = await handlePromise(
        LovService.getLov("NCO")
      );
      this.$emit("toggle-loading");
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.ascs = data.lovValues;
    },
    async getGenerations() {
      this.$emit("toggle-loading");
      const [error, response, data] = await handlePromise(
        LovService.getLov("GT")
      );
      this.$emit("toggle-loading");
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.generations = data.lovValues;
    },
    async getClients() {
      this.$emit("toggle-loading");
      const [error, response, data] = await handlePromise(
        LovService.getLov("CT")
      );
      this.$emit("toggle-loading");
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.clients = data.lovValues;
    },
    async getNetworkElements() {
      const [, response, data] = await handlePromise(
        NetworkElementService.getElements({ limit: 0 })
      );
      if (response.ok) {
        this.networkElements = data.data;
      }
    },
    async getTree() {
      let auxParams = {};

      if (this.form.name) {
        auxParams = {
          "filters[name]": this.form.name,
          ...auxParams,
        };
      }

      if (this.form.voltageType) {
        auxParams = {
          "filters[tensionType]": this.form.voltageType.id,
          ...auxParams,
        };
      }

      if (this.form.transportDivision) {
        auxParams = {
          "filters[energyTransportDivision]": this.form.transportDivision.id,
          ...auxParams,
        };
      }

      if (this.form.region) {
        auxParams = {
          "filters[region]": this.form.region.id,
          ...auxParams,
        };
      }

      if (this.form.asc) {
        auxParams = {
          "filters[commercialOffice]": this.form.asc.id,
          ...auxParams,
        };
      }

      if (this.form.generationType) {
        auxParams = {
          "filters[generationType]": this.form.generationType.id,
          ...auxParams,
        };
      }

      if (this.form.clientType) {
        auxParams = {
          "filters[customerType]": this.form.clientType.id,
          ...auxParams,
        };
      }

      if (this.form.networkElement) {
        auxParams = {
          "filters[networkElement]": this.form.networkElement.id,
          ...auxParams,
        };
      }
      this.$emit("toggle-loading");
      const [error, response, data] = await handlePromise(
        NetworkService.getNetwork(auxParams)
      );

      this.$emit("toggle-loading");
      if (response.ok) {
        this.tree = data.data.map((item) => ({
          id: item.id,
          name: item.name,
          isLeaf: true,
          data: item,
          childNodes: [],
        }));
      } else {
        this.$store.commit("settings/toggleAlert", this.$t(error));
      }
    },
    getNodeClass(node) {
      const blocked =
        get(node, "data.data.networkTree.blocked") ||
        get(node, "data.data.parentNetworkTree.blocked")
          ? "tree__node--blocked"
          : "";
      const created =
        get(node, "data.data.status") === "created"
          ? "tree__node--created"
          : "";
      const deleted =
        get(node, "data.data.status") === "deleted"
          ? "tree__node--deleted"
          : "";
      const modified =
        get(node, "data.data.status") === "modified"
          ? "tree__node--modified"
          : "";
      const blockedByUser =
        (get(node, "data.data.parentNetworkTree.blockedBy") &&
          get(node, "data.data.parentNetworkTree.blockedBy") !==
            get(this.userData, "user.id")) ||
        (get(node, "data.data.networkTree.blockedBy") &&
          get(node, "data.data.networkTree.blockedBy") !==
            get(this.userData, "user.id"))
          ? "tree__node--blocked-user"
          : "";

      return `tree__node__content ${blocked} ${created} ${deleted} ${modified} ${blockedByUser}`;
    },
    renderContent(h, { node, data }) {
      const blocked = false;
      const selectedIndex = this.selectedNodes
        ? findIndex(this.selectedNodes, { id: node.data.id })
        : -1;
      return (
        <span class={`${selectedIndex !== -1 ? "font-weight-bold" : ""}`}>
          <span> {node.data.name} </span>(
          <span>{data.data.network_element.element_type}</span>)
          {blocked && node.level === 1 ? (
            <ods-icon
              name="lock"
              size="15"
              style="marginLeft: 5px; color: #2E6C99"
            />
          ) : null}
        </span>
      );
    },
    toggleDialog() {
      this.dialogVisible = !this.dialogVisible;
    },
    async submitForm() {
      this.getTree();
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.hide-pointer {
  cursor: default;
}
</style>
