<template>
  <section>
    <ods-module v-loading="loading">
       <template slot="header">
          <ods-row :gutter="20" type="flex">
            <ods-col :md="12">
              {{ $route.params.id && !isDetail ? $t('editPlan') : !$route.params.id && isDetail ? $t('createPlan') : $t('strategicPlanDetail') }}
            </ods-col>
            <ods-col class="text-right" :md="12">
              <ods-button size="medium" @click="toogleEdit" v-if="$route.params.id" :disabled="!hasPermission || form.status === 'CLOSED'">
              {{ !isDetail ? $t('detailPlan') : $t('editPlan')  }}
              </ods-button>
            </ods-col>
          </ods-row>
        </template>

      <ods-form
          v-if="!isDetail || !$route.params.id"
          :model="form"
          ref="form"
          v-loading="loading"
       >
          <ods-row :gutter="20">
              <ods-col :md="12">
                  <ods-form-item :label="$t('name')" prop="name"
                              :rules="[errors.required]">
                      <ods-input v-model="form.name"></ods-input>
                  </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                  <ods-form-item :label="$t('description')" prop="description"
                              :rules="[errors.required]">
                      <ods-input v-model="form.description" v-bind:autosize="true" type="textarea"></ods-input>
                  </ods-form-item>
              </ods-col>
        </ods-row>
        <ods-row :gutter="20">
           <ods-col :md="12">
                  <ods-form-item :label="$t('status')" prop="status"
                              :rules="[errors.required]">
                      <ods-select v-model="form.status" placeholder="" :disabled="$route.params.id ? false : true" >
                          <ods-option
                              v-for="item in statuses"
                              :key="item.id"
                              :label="$t(item.name)"
                              :value="item.id"
                          >
                          </ods-option>
                      </ods-select>
                  </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                  <ods-form-item :label="$t('area')" prop="balanceArea"
                  :rules="[errors.required]">
                      <ods-select v-model="form.balanceArea" @input="onChangeArea">
                          <ods-option
                              v-for="value in areas"
                              :key="value.id"
                              :label="value.name"
                              :value="value.id"
                          >
                          </ods-option>
                      </ods-select>
                  </ods-form-item>
              </ods-col>
        </ods-row>
        <ods-row :gutter="20" v-if="form.balanceArea">
              <ods-col :md="12">
                  <ods-form-item :label="$t('initialLossesPercent')" prop="initialLossesPercent">
                      <ods-input v-model="form.lossesInitial" disabled></ods-input>
                  </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                    <ods-form-item :label="$t('lossReductionTargetPercent')" prop="lossesObjective"
                              :rules="[errors.required,validations.lessOrEqualsThan100,validations.greatherThan0,validations.lessThanInitialLosses]">
                      <ods-input @input="handleLossReductionTargetValidation()" type="number" min="0" max="100" v-model="form.lossesObjective"></ods-input>
                    </ods-form-item>
              </ods-col>
        </ods-row>
        <ods-row :gutter="20">
              <ods-col :md="12">
                  <ods-form-item :label="$t('estimatedCostDollar')" prop="costEstimated"
                              :rules="[errors.required]">
                      <ods-input v-model="form.costEstimated"></ods-input>
                  </ods-form-item>
              </ods-col>

              <ods-col :md="12">
                  <ods-form-item :label="$t('startPeriod')" prop="from"
                              :rules="[errors.required]">
                      <ods-select v-model="form.from" @input="onChangePeriod">
                          <ods-option
                              v-for="value in periods"
                              :key="value.id"
                              :label="value.name"
                              :value="value.id"
                          >
                          </ods-option>
                      </ods-select>
                  </ods-form-item>
              </ods-col>
        </ods-row>
        <ods-row :gutter="20">
              <ods-col :md="12">
                  <ods-form-item :label="$t('timePeriod')" prop="timePeriod"
                              :rules="[errors.required,validations.lessOrEqualsThan100,validations.greatherThan0]">
                      <ods-input v-model="form.timePeriod" min="0" max="99" type="number"></ods-input>
                  </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                  <ods-form-item :label="$t('interventionsPlanned')" prop="interventionsPlanned"
                              :rules="[errors.required]">
                      <ods-input v-model="form.interventionsPlanned" v-bind:autosize="true" type="textarea"></ods-input>
                  </ods-form-item>
              </ods-col>
        </ods-row>
        <form-buttons
            @submit="submitForm"
            cancel-to="BalanceStrategicPlan"
            cancelText = "back"
        ></form-buttons>
      </ods-form>
      <ods-row v-loading="loading" v-if="isDetail && $route.params.id" :gutter="20" style="font-size:120%">
              <ods-col :md="12">
                  {{$t('name')}}: {{form.name ? form.name : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('description')}}: {{form.description ? form.description : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('status')}}: {{form.status ? form.status : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('area')}}: {{form.balanceArea ? this.areaFormName : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('initialLossesPercent')}}: {{form.lossesInitial ? form.lossesInitial || formatNumber : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('lossReductionTargetPercent')}}: {{form.lossesObjective ? form.lossesObjective || formatNumber : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('estimatedCostDollar')}}: {{form.costEstimated ? form.costEstimated || formatNumber : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('startPeriod')}}: {{form.from ? this. periodFormName : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('timePeriod')}}: {{form.timePeriod ? form.timePeriod : '---'}}
              </ods-col>
              <ods-col :md="12">
                  {{$t('interventionsPlanned')}}: {{form.interventionsPlanned ? form.interventionsPlanned : '---'}}
              </ods-col>
      </ods-row>
      <br>
      <br>
      <template v-if="isDetail && $route.params.id">
        <ods-row type="flex" justify="center">
          <router-link :to="{ name: 'BalanceStrategicPlan'}">
            <ods-button type="secondary">{{$t('back')}}</ods-button>
          </router-link>
        </ods-row>
      </template>
    </ods-module>

    <plan-activities
        v-if="$route.params.id"
        :plan="$route.params.id"
        :area= form.balanceArea
        :closed= form.status
        >
    </plan-activities>
  </section>
</template>

<script>
import PeriodService from '@/services/Period'
import StrategicService from '@/services/Strategic'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import PlanActivities from './Activity'
import BalanceAreaService from '@/services/BalanceArea'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
  name: 'StrategicEdit',
  components: {
    PlanActivities,
    FormButtons
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
    this.fetchPeriods()
    this.fetchBalanceAreas()
    this.handlePermission()
  },
  data () {
    return {
      hasPermission: true,
      periodFormName: null,
      areaFormName: null,
      isDetail: true,
      loading: false,
      form: {},
      errors,
      areas: [],
      periods: [],
      submitErrors: {},
      formLoading: false,
      userInfo: {},
      validations: {
        lessThan100: {
          validator (rule, value, callback) {
            return value < 100
          },
          message: 'lessThan100'
        },
        lessOrEqualsThan100: {
          validator (rule, value, callback) {
            return value <= 100
          },
          message: 'lessOrEqualsThan100'
        },
        greatherThan0: {
          validator (rule, value, callback) {
            return value > 0
          },
          message: 'greatherThan0'
        },
        lessThanInitialLosses: { required: true, message: 'errors.required', trigger: 'blur' }
      },
      statuses: [
        { id: 'OPEN', name: 'Open' },
        { id: 'CLOSED', name: 'Closed' }
      ]
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.data,
      userRole: state => state.userRoles.data
    })
  },
  methods: {
    handleLossReductionTargetValidation () {
      this.validations.lessThanInitialLosses = null
      if (this.form.lossesInitial == null || this.form.lossesInitial === 0) {
        this.validations.lessThanInitialLosses = {}
      } else {
        let losses = this.form.lossesInitial
        this.validations.lessThanInitialLosses = {
          validator (rule, value, callback) {
            return value < losses
          },
          message: 'lossReductionTargetLessThanInitialLosses'
        }
      }
    },
    async fetchBalanceAreas () {
      if (!this.$route.params.id) {
        this.form.status = 'OPEN'
      }
      this.toggleLoading()
      const [, response, responseData] = await handlePromise(BalanceAreaService.getAreas({ limit: 0 }))
      this.toggleLoading()
      if (response.ok) this.areas = responseData.data
    },
    async fetchPeriods () {
      this.toggleLoading()
      const [, response, responseData] = await handlePromise(PeriodService.getPeriods({ limit: 0 }))
      this.toggleLoading()
      if (response.ok) this.periods = responseData.data
    },
    async getData () {
      this.toggleLoading()

      const [error, response, data] = await handlePromise(StrategicService.getStrategicPlan(this.$route.params.id))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.areaFormName = data.balanceArea.name
      this.periodFormName = data.from.name
      this.form = {
        name: data.name,
        description: data.description,
        lossesInitial: data.lossesInitial,
        lossesObjective: data.lossesObjective,
        from: data.from.id,
        timePeriod: data.timePeriod,
        interventionsPlanned: data.interventionsPlanned,
        costEstimated: Vue.filter('formatNumberForm')(data.costEstimated),
        status: data.status,
        balanceArea: data.balanceArea.id
      }
      this.toggleLoading()
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          let toSend = {
            name: this.form.name,
            description: this.form.description,
            lossesInitial: this.form.lossesInitial,
            lossesObjective: this.form.lossesObjective,
            from: this.form.from,
            timePeriod: this.form.timePeriod,
            interventionsPlanned: this.form.interventionsPlanned,
            costEstimated: this.checkString(this.form.costEstimated) ? this.form.costEstimated.replace(/,/g, '') : this.form.costEstimated,
            status: this.form.status,
            balanceArea: this.form.balanceArea
          }

          if (this.$route.params.id) {
            toSend = {
              name: this.form.name,
              description: this.form.description,
              lossesObjective: this.form.lossesObjective,
              timePeriod: this.form.timePeriod,
              from: this.form.from,
              interventionsPlanned: this.form.interventionsPlanned,
              costEstimated: this.checkString(this.form.costEstimated) ? this.form.costEstimated.replace(/,/g, '') : this.form.costEstimated,
              status: this.form.status,
              balanceArea: this.form.balanceArea
            }
          }
          const [error, response, responseData] = await handlePromise(
            this.$route.params.id
              ? StrategicService.updateStrategicPlan(this.$route.params.id, toSend)
              : StrategicService.saveStrategicPlan(toSend)
          )
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
          return this.$router.push(this.$route.params.id
            ? { name: 'BalanceStrategicPlan' }
            : { name: 'PlanEdit', params: { id: responseData.id } })
        }
      })
    },
    async onChangeArea (event) {
      this.toggleLoading()
      const [error, response, responseData] = await handlePromise(StrategicService.getInitialLosseByArea(event))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      if (responseData == null) {
        this.form.lossesInitial = 0
      } else {
        this.form.lossesInitial = responseData
      }

      this.areas.map(item => {
        if (item.id === this.form.balanceArea) {
          this.areaFormName = item.name
        }
      })
    },
    async onChangePeriod () {
      this.periods.map(item => {
        if (item.id === this.form.from) {
          this.periodFormName = item.name
        }
      })
    },
    checkString (val) {
      if (typeof val === 'string') {
        return true
      }
      return false
    },
    toogleEdit () {
      this.isDetail = !this.isDetail
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  },
  watch: {
    'form.costEstimated': function (val) {
      this.form.costEstimated = this.checkString(val) ? Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, '')) : this.form.costEstimated
    }
  }
}
</script>
