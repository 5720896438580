const icons = {
  error: `<svg width="60px" height="60px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <path d="M24.2426407,20 L31.1213203,13.1213203 C32.2928932,11.9497475 32.2928932,10.0502525 31.1213203,8.87867966 C29.9497475,7.70710678 28.0502525,7.70710678 26.8786797,8.87867966 L20,15.7573593 L13.1213203,8.87867966 C11.9497475,7.70710678 10.0502525,7.70710678 8.87867966,8.87867966 C7.70710678,10.0502525 7.70710678,11.9497475 8.87867966,13.1213203 L15.7573593,20 L8.87867966,26.8786797 C7.70710678,28.0502525 7.70710678,29.9497475 8.87867966,31.1213203 C10.0502525,32.2928932 11.9497475,32.2928932 13.1213203,31.1213203 L20,24.2426407 L26.8786797,31.1213203 C28.0502525,32.2928932 29.9497475,32.2928932 31.1213203,31.1213203 C32.2928932,29.9497475 32.2928932,28.0502525 31.1213203,26.8786797 L24.2426407,20 Z" id="path-1"></path>
            </defs>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <use fill="#DE2F2F" fill-rule="nonzero" xlink:href="#path-1"></use>
            </g>
          </svg>`,
  success: `<svg width="60px" height="60px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                  <path d="M10.8,20.6 C9.4745166,19.6058875 7.59411255,19.8745166 6.6,21.2 C5.60588745,22.5254834 5.8745166,24.4058875 7.2,25.4 L15.2,31.4 C16.5563075,32.4172306 18.4854567,32.1093 19.4576958,30.720387 L33.4576958,10.720387 C34.4078393,9.36303914 34.0777349,7.49244776 32.720387,6.54230424 C31.3630391,5.59216072 29.4924478,5.92226508 28.5423042,7.27961297 L16.3200218,24.7400164 L10.8,20.6 Z" id="path-2"></path>
              </defs>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <mask fill="white">
                      <use xlink:href="#path-1"></use>
                  </mask>
                  <use fill="#36B348" fill-rule="nonzero" xlink:href="#path-2"></use>
              </g>
            </svg>`
}

export default icons