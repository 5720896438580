<template>
  <ods-module :class="'tree__module ' + containerClass"
              :shadow="containerShadow" :body-style="containerBodyStyle">
    <ods-row>
      <ods-col :md="17">
        <ods-select v-model="filterArea" :placeholder="$t('balanceArea')" @change="filterTree">
          <ods-option
              v-for="item in options.areas"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </ods-option>
        </ods-select>
      </ods-col>
      <ods-col :md="5">
        <ods-button style="margin-left:10px" size="mini" type="primary" @click="$emit('toggleShowDetail')">
            <ods-icon :name ="showDetail ? 'double-right' : 'double-left'"  style="margin-left:-5px;display:inherit;float:left"  size="18"/>{{$t('details')}}
        </ods-button>
      </ods-col>
    </ods-row>

    <div class="tree__container">
      <ods-tree
          ref="tree"
          highlightCurrent
          :props="props"
          :load="loadTreeNode"
          node-key="id"
          :expand-on-click-node="false"
          :default-expanded-keys="[]"
          lazy
          :contentClass="getNodeClass"
          @node-click="(data) => $emit('node-click', data)"
          :render-content="renderContent"
      >
      </ods-tree>
    </div>
  </ods-module>
</template>

<script>
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'
import { mapState } from 'vuex'
import NetworkNodeService from '@/services/NetworkNode'
import BalanceAreaService from '@/services/BalanceArea'
import handlePromise from '@/utils/promise'

export default {
  name: 'EnergyInputTree',
  props: {
    containerClass: String,
    containerBodyStyle: {},
    containerShadow: String,
    selectedNodes: Array,
    options: Object,
    detailed: {
      type: Boolean,
      default: false
    },
    showDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      props: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      },
      filterText: '',
      filterArea: null,
      filterType: null
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.data
    })
  },
  methods: {
    async loadTreeNode (node, resolve) {
      if (!this.filterArea) return resolve([])
      const params = {}
      if (node.level === 0) {
        this.$emit('toggle-loading')
        if (this.filterText) params['filters[criteria]'] = this.filterText
        // if (this.filterArea) params['filters[balanceArea]'] = this.filterArea
      }
      if (node.level === 1) {
        return resolve([])
      }
      const id = node.level === 0 ? null : node.data.id
      const [error, response, data] = await handlePromise(!id ? BalanceAreaService.getTreeByAreaWater(this.filterArea) : NetworkNodeService.getNodeTreeWater(id, params))

      if (node.level === 0) this.$emit('toggle-loading')
      if (response.ok) {
        resolve(data.map(item => ({
          id: item.id,
          name: item.networkUnit.name,
          isLeaf: false,
          data: item,
          children: [{}]
        })))
      } else {
        this.$store.commit('settings/toggleAlert', error)
        resolve([])
      }
    },
    async filterTree () {
      this.$refs.tree.collapse()
      this.$refs.tree.reload()
    },
    getNodeClass (node) {
      const blocked = get(node, 'data.data.networkTree.blocked') || get(node, 'data.data.parentNetworkTree.blocked') ? 'tree__node--blocked' : ''
      const created = get(node, 'data.data.status') === 'created' ? 'tree__node--created' : ''
      const deleted = get(node, 'data.data.status') === 'deleted' ? 'tree__node--deleted' : ''
      const modified = get(node, 'data.data.status') === 'modified' ? 'tree__node--modified' : ''
      const blockedByUser = (get(node, 'data.data.parentNetworkTree.blockedBy') && get(node, 'data.data.parentNetworkTree.blockedBy') !== get(this.userData, 'user.id')) ||
        (get(node, 'data.data.networkTree.blockedBy') && get(node, 'data.data.networkTree.blockedBy') !== get(this.userData, 'user.id'))
        ? 'tree__node--blocked-user' : ''

      return `tree__node__content ${blocked} ${created} ${deleted} ${modified} ${blockedByUser}`
    },
    renderContent (h, { node, data }) {
      const blocked = get(node, 'data.data.networkTree.blocked') || get(node, 'data.data.parentNetworkTree.blocked')
      const selectedIndex = this.selectedNodes ? findIndex(this.selectedNodes, { id: node.data.id }) : -1

      return (
        <span class={`${selectedIndex !== -1 ? 'font-weight-bold' : ''}`}>
          <span> {node.label} </span>
          (
          <span>{data.data.networkUnit.networkElement.elementType}</span>
          {node.level === 1 && get(data, 'data.networkTree')
            ? ` - ${data.data.networkTree.masterName ? (data.data.networkTree.masterName + ' ') : ''}${data.data.networkTree.code || ''}` : ''}
            )
          {blocked && node.level === 1
            ? <ods-icon name="lock" size="15" style="marginLeft: 5px; color: #2E6C99"/> : null}
        </span>
      )
    }
  }
}
</script>
