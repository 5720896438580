<template>
  <section v-loading="loading">
    <ods-row :gutter="20" type="flex">
      <ods-col  :md="6">
        <ods-module :header="$route.params.id ? $t('editArea') : $t('newArea')">
          <ods-form :model="form" ref="form">
            <ods-form-item :label="$t('name')" prop="name" :rules="[errors.required]">
              <ods-input v-model="form.name"></ods-input>
            </ods-form-item>

            <ods-form-item label="" prop="active">
              <ods-checkbox
                  v-model="form.active"
                  :label="$t('active')">
              </ods-checkbox>
            </ods-form-item>
          </ods-form>
        </ods-module>
        <div class="mt-1">
          <form-buttons
              @submit="submitForm"
              cancel-to="AreaDefinitionDashboard"
          ></form-buttons>
        </div>
      </ods-col>

      <ods-col :md="6" style="min-height: 100%;">
        <ods-module style="min-height: 100%;">
          <template slot="header">
            {{$t('selectedNodes')}}
            <div style="font-size: 12px; padding-top: 8px" class="custom-note font-weight-normal">
              {{$t('selectNodesInfo')}}
            </div>
          </template>

          <div class="area-definition--tree__selected">
            <div
                v-for="item in selectedNodes"
                :key="item.id"
            >
              <ods-row type="flex">
                <span v-if="selectedNodes[0] === item" @click="deleteNode(item.id)" class="cursor-pointer" style="margin-top: 2px; margin-right: 5px">
                  <ods-icon name="close" size="16" class="custom-note"/>
                </span>
                <span v-if="item.name">{{item.name}}</span>
                <div v-if="item.category" style="margin-left: 2px">
                  (<span>{{item.category === 0 ? 'WP' : item.category === 1 ? 'MP' : item.category === 2 ? 'WT' : item.category === 3 ? 'WR' : item.category === 4 ? 'WS' : 'MC'}}</span>)
                </div>
                <div v-if="item.elementType" style="margin-left: 2px">
                  (<span>{{item.elementType}}</span>)
                </div>
              </ods-row>
            </div>
          </div>
        </ods-module>
      </ods-col>

      <ods-col :md="12">
          <network-tree
              @node-click="addNode"
              @toggle-loading="toggleLoading"
              ref="treeComp"
          >
          </network-tree>
          <network-graph v-if="chartData.categories"
              :chartData="chartData"
              @node-click="addNode"
              ref="graph"
          >
          </network-graph>

      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import BalanceAreaService from '@/services/BalanceArea'
import NetworkNodeService from '@/services/NetworkNode'
import handlePromise from '@/utils/promise'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import errors from '@/config/InputErrors'
import _ from 'lodash'
import NetworkTree from '@/custom-components/Network/TreeWater'
import NetworkGraph from '@/custom-components/Network/NetworkGraph'

export default {
  name: 'MeterManagement',
  components: {
    // EnergyInputTree,
    NetworkTree,
    NetworkGraph,
    FormButtons
  },
  mounted () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      errors,
      selectedNodes: [],
      nodesToSend: [],
      chartData: {},
      childrens: [],
      level: null,
      tableDeleted: false
    }
  },
  methods: {
    async getData () {
      //  this.loading = true
      const [error, response, data] = await handlePromise(BalanceAreaService.getAreaWater(this.$route.params.id))
      //   this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        name: data.name,
        active: data.active
      }
      this.tableDeleted = true
      data.networkNodes.map(item => {
        this.selectedNodes.push({ id: item.id, name: item.networkUnit.name, elementType: item.networkUnit.networkElement.elementType })
      })

      this.toggleLoading()
      const [error2, response2] = await handlePromise(
        NetworkNodeService.getNetworkByNodeWater(this.selectedNodes[0].id)
      )
      this.toggleLoading()
      if (!response2.ok) return this.$store.commit('settings/toggleAlert', this.$t(error2))
      this.childrens = response2.data
      this.level = this.selectedNodes[this.selectedNodes.length - 1].elementType === 'WP' ? 0 : this.selectedNodes[this.selectedNodes.length - 1].elementType === 'MP' ? 1 : this.selectedNodes[this.selectedNodes.length - 1].elementType === 'WT' ? 2 : this.selectedNodes[this.selectedNodes.length - 1].elementType === 'WR' ? 3 : this.selectedNodes[this.selectedNodes.length - 1].elementType === 'WS' ? 4 : 5
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const data = {
            name: this.form.name,
            active: this.form.active,
            networkNodes: this.selectedNodes.map(item => item.id)
          }
          this.toggleLoading()
          const [error, response] = await handlePromise(
            this.$route.params.id
              ? BalanceAreaService.updateAreaWater(this.$route.params.id, data)
              : BalanceAreaService.saveAreaWater(data)
          )
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          return this.$router.push({ name: 'AreaDefinitionDashboard' })
        }
      })
    },
    async addNode ({ data }) {
      if (data.symbolSize && !_.find(this.childrens, { 'id': data.id }) && this.selectedNodes.length > 0) {
        return this.$store.commit('settings/toggleAlert', this.$t('ERROR_MESSAGE_INVALID_ORDER_BETWEEN_NODE_AND_PARENT'))
      } else if (this.selectedNodes.length > 0 && data.category && data.category < this.level) {
        return this.$store.commit('settings/toggleAlert', this.$t('ERROR_MESSAGE_INVALID_ORDER_BETWEEN_NODE_AND_PARENT'))
      }
      if (data.category) {
        this.level = data.category
      }

      if (data.firstNode) {
        this.tableDeleted = false
      }

      if (this.selectedNodes.length === 0 && !this.tableDeleted && data.firstNode) {
        this.toggleLoading()
        const [error, response] = await handlePromise(
          NetworkNodeService.getNetworkByNodeWater(data.firstNode ? data.firstNode.id : data.id)
        )
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.childrens = response.data
      }
      if (data.firstNode) {
        this.tree = data.id
        this.toggleLoading()
        const [error2, response2, responseData2] = await handlePromise(NetworkNodeService.getGrapgWater(data.id))
        this.toggleLoading()
        if (!response2.ok) return this.$store.commit('settings/toggleAlert', this.$t(error2))
        this.chartData = responseData2
      } else if (_.find(this.selectedNodes, { 'id': data.id })) {
        return this.$store.commit('settings/toggleAlert', this.$t('nodeAlreadySelected'))
      } else if (data.symbolSize) {
        this.selectedNodes.push(data)
      }
    },
    async deleteNode (id) {
      this.tableDeleted = true
      this.selectedNodes = []
      // const index = findIndex(this.selectedNodes, { id })
      // if (index !== -1) this.selectedNodes.splice(index, 1)
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    toggleShowDetail () {
      this.showDetail = !this.showDetail
    }
  }
}
</script>
