<template>
  <section>
        <ods-module :header="$t('brandModels')">
          <template slot="header">
            <list-header
                title="brands"
                btn-label="createBrand"
                url-key="BrandNew"
                :hasPermission=hasPermission
            />
          </template>
          <ods-col :md="24" v-if="!hasPermission" style="min-height: 100%;">
            <ods-module style="min-height: 100%">
              <ods-row type="flex" justify="center" style="margin-top: 2rem; font-size:120%">
                {{$t('userDontHaveAcces')}}
              </ods-row>
              <ods-row type="flex" justify="center" style="margin-top: 2rem">
                <ods-icon name="info" size="100" class="custom-placeholder"/>
              </ods-row>
            </ods-module>
          </ods-col>
          <async-table
            v-if="hasPermission"
            :data="data"
            :total="total"
            :loading="loading"
            @fetchData="getData"
          >
            <ods-table-column :label="$t('name')" >
              <template slot-scope="scope">
                <router-link v-if="scope.row.id" :to="{ name: 'BrandEdit', params: { id: scope.row.id }}">
                  <ods-button type="text" class="p-0">{{scope.row.name}}</ods-button>
                </router-link>
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('code')" >
              <template slot-scope="scope">
                {{ scope.row.code }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('description')" >
              <template slot-scope="scope">
                {{ scope.row.description | max20 }}
              </template>
            </ods-table-column>
            <ods-table-column align="right">
              <template slot-scope="scope">
                <table-delete @click="toggleDialog(scope.row.id)"></table-delete>
              </template>
            </ods-table-column>
          </async-table>
        </ods-module>
        <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDialog()"
        />
  </section>
</template>

<script>
import BrandModelService from '@/services/BrandModel'
import handlePromise from '@/utils/promise'
import AsyncTable from '@/custom-components/AsyncTable'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import asyncTableCommon from '@/mixins/async-table'
import ListHeader from '@/custom-components/ListHeader'
import { mapState } from 'vuex'

export default {
  name: 'BrandModel',
  components: {
    ListHeader,
    AsyncTable,
    TableDelete,
    DialogConfirmation
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.handlePermission()
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      hasPermission: true,
      loading: false,
      createDialogVisible: false,
      selectedNodes: [],
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      promise: (params) => BrandModelService.getBrands(params)
    }
  },
  methods: {
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(BrandModelService.deleteBrand(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
