<template>
  <section>
    <ods-module class="mt-1" :header="$t('consumptions')">
      <ods-table :data="data" size="mini">
        <ods-table-column prop="meter.serialNumber" :label="$t('meter')"/>
        <ods-table-column prop="date" :label="$t('date')">
          <template slot-scope="scope">
            {{scope.row.date | date}}
          </template>
        </ods-table-column>
        <ods-table-column prop="type" :label="$t('type')">
          <template slot-scope="scope">
            {{$t(scope.row.type)}}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('readingForecast')" >
              <template slot-scope="scope">
                {{ scope.row.readingForecast | formatNumber }}
              </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumption')" >
              <template slot-scope="scope">
                {{ scope.row.consumptionForecast | formatNumber }}
              </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumptionForecast')" >
              <template slot-scope="scope" v-if="scope.row.readingForecast">
                {{ scope.row.consumptionForecastToDay | formatNumber }}
              </template>
        </ods-table-column>
        <!--<ods-table-column prop="status" :label="$t('anomalies')">
          <template slot-scope="scope">
            <ods-tooltip class="item" effect="light" placement="top-start"
                         v-if="scope.row.highEstimationStatus === 'pending' || scope.row.highDeviationStatus === 'pending'
                         || scope.row.lowEstimationStatus === 'pending' || scope.row.noReadingStatus === 'pending'
                         || scope.row.potentialTurnoverStatus === 'pending' || scope.row.readingBoundsStatus === 'pending'
                          || scope.row.consumptionBoundsStatus === 'pending'">
              <div slot="content">
                <div v-if="scope.row.highEstimationStatus === 'pending'">- {{$t('highEstimation')}}</div>
                <div v-if="scope.row.highDeviationStatus === 'pending'">- {{$t('highDeviation')}}</div>
                <div v-if="scope.row.lowEstimationStatus === 'pending'">- {{$t('lowEstimation')}}</div>
                <div v-if="scope.row.noReadingStatus === 'pending'">- {{$t('noReading')}}</div>
                <div v-if="scope.row.potentialTurnoverStatus === 'pending'">- {{$t('potentialTurnover')}}</div>
                <div v-if="scope.row.readingBoundsStatus === 'pending'">- {{$t('readingBounds')}}</div>
                <div v-if="scope.row.consumptionBoundsStatus === 'pending'">- {{$t('consumptionBounds')}}</div>
              </div>
              <ods-icon name="info-alert" color="#DE2F2F" size="20" style="line-height: 1"/>
            </ods-tooltip>
          </template>
        </ods-table-column>-->
      </ods-table>
    </ods-module>
  </section>
</template>

<script>

export default {
  name: 'AnalysisReadings',
  props: {
    data: Array
  }
}
</script>
