<template>
  <section>
    <ods-module>
      <template slot="header">
        <list-header title="periodsMaintenance">
          <template slot="right"  v-if="hasPermission">
            <ods-form
                :model="form"
                ref="form"
                v-loading="loading"
            >
                  <ods-button style="margin-right:10px;margin-top:20px" size="medium" type="primary" @click="generatePeriods()">
                  <ods-icon name = "multiple"  style="margin-left:-10px; display:inherit; float:left; padding-right:10px"  size="18"/>{{ $t('generatePeriods') }}
                  </ods-button>
                  <ods-form-item :label="$t('year')" prop="year"
                                  :rules="[errors.required]"
                                  style="margin-left:0;width:150px; float:right"
                                  >
                    <ods-select v-model="form.year" placeholder="">
                      <ods-option
                          v-for="item in years"
                          :key="item.id"
                          :label="item.value"
                          :value="item.value"
                      >
                      </ods-option>
                    </ods-select>
                  </ods-form-item>
            </ods-form>
          </template>
        </list-header>
      </template>
      <ods-form :model="form2" ref="form2">
          <ods-row :gutter="24" type="flex" align="center" style="margin-top:-24px">
              <ods-col :md="5" :sm="6">
                <ods-form-item :label="$t('year')" prop="year">
                  <ods-select v-model="form2.year" placeholder="" remote clearable>
                    <ods-option
                          v-for="item in years"
                          :key="item.id"
                          :label="item.value"
                          :value="item.value"
                      >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>

              <ods-col :md="4" :sm="4">
                <ods-button type="primary" @click="searchByYear">
                  {{$t('search')}}
                </ods-button>
              </ods-col>
          </ods-row>
      </ods-form>
      <ods-table
          :data="data"
          v-loading="loading"
          size="mini"
          style="width: 100%">
      >
        <ods-table-column prop="name" :label="$t('name')" v-if="hasPermission">
          <template slot-scope="scope">
            <router-link v-if="scope.row.id && scope.row.status != 'closed'" :to="{ name: 'PeriodEdit', params: { id: scope.row.id }}">
              <ods-button type="text" class="p-0">{{scope.row.name}}</ods-button>
            </router-link>
            <ods-button v-if="scope.row.id && scope.row.status == 'closed'" type="text" class="p-0">{{scope.row.name}}</ods-button>
          </template>
        </ods-table-column>
        <ods-table-column prop="name" :label="$t('name')" v-if="!hasPermission">
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </ods-table-column>

        <ods-table-column prop="initialDate" :label="$t('initialDate')">
          <template slot-scope="scope">
            {{scope.row.initialDate | date}}
          </template>
        </ods-table-column>

        <ods-table-column prop="lastDayReadingsCapture" :label="$t('lastDayReadingsCapture')">
          <template slot-scope="scope">
            {{scope.row.lastDayReadingsCapture | date}}
          </template>
        </ods-table-column>

        <ods-table-column prop="closureDate" :label="$t('closureDate')">
          <template slot-scope="scope">
            {{scope.row.closureDate | date}}
          </template>
        </ods-table-column>

        <ods-table-column prop="status" :label="$t('status')">
          <template slot-scope="scope">
            <ods-badge
                v-if="scope.row.status"
                :value="$t(scope.row.status).toUpperCase()"
                :type="getStatusBadgeType(scope.row.status)"
            />
          </template>
        </ods-table-column>

        <ods-table-column align="right" v-if="hasPermission">
          <template slot-scope="scope">
            <table-delete @click="toggleDialog(scope.row.id)"></table-delete>
          </template>
        </ods-table-column>
     </ods-table>
     <div class="block">
      <ods-pagination
          :total="total"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          layout="prev, pager, next, jumper, sizes, ->, total"
      >
      </ods-pagination>
    </div>

      <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDialog()"
      />
    </ods-module>
  </section>
</template>

<script>
import PeriodService from '@/services/Period'
import handlePromise from '@/utils/promise'
import ListHeader from '@/custom-components/ListHeader'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import { mapState } from 'vuex'
import LovService from '@/services/Lov'
import errors from '@/config/InputErrors'
import map from 'lodash/map'

export default {
  name: 'PeriodDashboard',
  components: {
    TableDelete,
    DialogConfirmation,
    ListHeader
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.handlePermission()
    this.getYears()
    this.getData()
  },
  data () {
    return {
      years: [],
      form: {},
      form2: {},
      pageSize: 10,
      pageIndex: 0,
      total: 0,
      sort: null,
      filters: {},
      errors,
      hasPermission: true,
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      data: [],
      loading: false
    }
  },
  methods: {
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async getYears () {
      const [, response, years] = await handlePromise(LovService.getLov('years'))
      if (response.ok) this.years = years.lovValues
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(PeriodService.deletePeriodWater(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    async generatePeriods () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const data = {
            year: this.form.year
          }
          const [error, response] = await handlePromise(PeriodService.savePeriodWater(data))
          this.loading = false
          if (!response.ok) {
            return this.$store.commit('settings/toggleAlert', this.$t(error))
          }
          this.getData(this.tableParams)
        }
      })
    },
    async searchByYear () {
      this.$refs['form2'].validate(async (valid) => {
        if (valid) {
          this.year = this.form2.year
          this.getData(this.tableParams)
        }
      })
    },
    getStatusBadgeType (status) {
      switch (status) {
        case 'pending':
          return 'secondary'
        case 'open':
          return 'primary'
        case 'processing':
          return 'warning'
        case 'closed':
          return 'danger'
        default:
          return 'primary'
      }
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    async getData () {
      let params = {
        offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize) + 1,
        limit: this.pageSize,
        ...this.filters
      }

      if (this.form2.year) {
        params = {
          offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize) + 1,
          limit: this.pageSize,
          year: this.form2.year,
          ...this.filters
        }
      }

      if (this.sort && this.sort.prop) params[`sortby[${this.sort.prop}]`] = this.sort.order === 'ascending' ? 'ASC' : 'DESC'
      this.toggleLoading()
      const [error, response, data] = await handlePromise(PeriodService.getPeriodsWater(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.data = data.data
      this.total = data.total
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })

      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
