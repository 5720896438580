<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="7">
        <energy-input-tree
            @node-click="fetchMeter"
            @toggle-loading="toggleLoading"
            :selected-nodes="selectedNodes"
            :options="{areas, energyTypes}"
        >
        </energy-input-tree>
      </ods-col>

      <ods-col :md="17">
        <ods-module>
          <template slot="header">
            <list-header title="energyInput">
              <template slot="right">
                <ods-button size="medium" type="primary" @click="downloadTable()" :disabled="selectedNodes.length < 1 ? true : false">
                    <ods-icon name = "download"  style="margin-left:-10px; display:inherit; float:left; padding-right:10px"  size="18"/>{{ $t('downloadTable') }}
                </ods-button>
              </template>
            </list-header>
          </template>
          <ods-table
              :data="selectedNodes"
              size="mini"
          >
            <ods-table-column prop="networkUnit.name" :label="$t('element')"/>
            <ods-table-column prop="average" :label="$t('average')"/>
            <ods-table-column :label="$t('monthValue1')" >
              <template slot-scope="scope" v-if="scope.row.summary[0]">
                {{ scope.row.summary[0].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue1Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[0]">
                {{ scope.row.summary[0].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
           <!-- <ods-table-column prop="summary[0].totalEnergyInput" :label="$t('monthValue1')"/>-->
            <!--<ods-table-column prop="summary[0].totalEnergyInputPercent" :label="$t('monthValue1Percent')"/>-->
            <ods-table-column :label="$t('monthValue2')" >
              <template slot-scope="scope" v-if="scope.row.summary[1]">
                {{ scope.row.summary[1].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue2Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[1]">
                {{ scope.row.summary[1].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[1].totalEnergyInput" :label="$t('monthValue2')"/>-->
            <!--<ods-table-column prop="summary[1].totalEnergyInputPercent" :label="$t('monthValue2Percent')"/>-->
            <ods-table-column :label="$t('monthValue3')" >
              <template slot-scope="scope" v-if="scope.row.summary[2]">
                {{ scope.row.summary[2].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue3Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[2]">
                {{ scope.row.summary[2].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[2].totalEnergyInput" :label="$t('monthValue3')"/>-->
            <!--<ods-table-column prop="summary[2].totalEnergyInputPercent" :label="$t('monthValue3Percent')"/>-->
            <ods-table-column :label="$t('monthValue4')" >
              <template slot-scope="scope" v-if="scope.row.summary[3]">
                {{ scope.row.summary[3].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue4Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[3]">
                {{ scope.row.summary[3].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[3].totalEnergyInput" :label="$t('monthValue4')"/>-->
            <ods-table-column :label="$t('monthValue5')" >
              <template slot-scope="scope" v-if="scope.row.summary[4]">
                {{ scope.row.summary[4].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue5Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[4]">
                {{ scope.row.summary[4].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[4].totalEnergyInput" :label="$t('monthValue5')"/>-->
            <ods-table-column :label="$t('monthValue6')" >
              <template slot-scope="scope" v-if="scope.row.summary[5]">
                {{ scope.row.summary[5].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue6Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[5]">
                {{ scope.row.summary[5].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[5].totalEnergyInput" :label="$t('monthValue6')"/>-->
            <ods-table-column :label="$t('monthValue7')" >
              <template slot-scope="scope" v-if="scope.row.summary[6]">
                {{ scope.row.summary[6].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue7Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[6]">
                {{ scope.row.summary[6].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[6].totalEnergyInput" :label="$t('monthValue7')"/>-->
            <ods-table-column :label="$t('monthValue8')" >
              <template slot-scope="scope" v-if="scope.row.summary[7]">
                {{ scope.row.summary[7].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue8Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[7]">
                {{ scope.row.summary[7].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[7].totalEnergyInput" :label="$t('monthValue8')"/>-->
            <ods-table-column :label="$t('monthValue9')" >
              <template slot-scope="scope" v-if="scope.row.summary[8]">
                {{ scope.row.summary[8].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue9Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[8]">
                {{ scope.row.summary[8].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[8].totalEnergyInput" :label="$t('monthValue9')"/>-->
            <ods-table-column :label="$t('monthValue10')" >
              <template slot-scope="scope" v-if="scope.row.summary[9]">
                {{ scope.row.summary[9].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue10Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[9]">
                {{ scope.row.summary[9].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[9].totalEnergyInput" :label="$t('monthValue10')"/>-->
            <ods-table-column :label="$t('monthValue11')" >
              <template slot-scope="scope" v-if="scope.row.summary[10]">
                {{ scope.row.summary[10].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue11Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[10]">
                {{ scope.row.summary[10].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[10].totalEnergyInput" :label="$t('monthValue11')"/>-->
            <ods-table-column :label="$t('monthValue12')" >
              <template slot-scope="scope" v-if="scope.row.summary[11]">
                {{ scope.row.summary[11].totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('monthValue12Percent')" >
              <template slot-scope="scope" v-if="scope.row.summary[11]">
                {{ scope.row.summary[11].totalEnergyInputPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="summary[11].totalEnergyInput" :label="$t('monthValue12')"/>-->
            <ods-table-column align="right" width="100">
              <template slot-scope="scope">
                <table-delete-less-danger icon @click="deleteMeter(scope.row.id)"></table-delete-less-danger>
              </template>
            </ods-table-column>
          </ods-table>
        </ods-module>

        <ods-module class="mt-1 pt-1" v-if="selectedNodes.length">
          <ods-checkbox
              v-model="includeLosses"
              :label="$t('includeLosses')">
          </ods-checkbox>

          <div id="chart" style="height:400px;"></div>
        </ods-module>
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import BalanceAreaService from '@/services/BalanceArea'
import BalanceService from '@/services/Balance'
import handlePromise from '@/utils/promise'
import EnergyInputTree from './Tree'
import TableDeleteLessDanger from '@/custom-components/Buttons/TableDeleteLessDanger'
import findIndex from 'lodash/findIndex'
import * as echarts from 'echarts'
import _ from 'lodash'
import ListHeader from '@/custom-components/ListHeader'

export default {
  name: 'MeasurementAnalysis',
  components: {
    EnergyInputTree,
    TableDeleteLessDanger,
    ListHeader
  },
  mounted () {
    this.fetchBalanceAreas()
  },
  data () {
    return {
      loading: false,
      selectedNodes: [],
      areas: [],
      includeLosses: false,
      energyTypes: [
        { id: 'active', name: 'active' },
        { id: 'reactive', name: 'reactive' },
        { id: 'power', name: 'power' }
      ],
      chart: null,
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          name: 'Month',
          nameLocation: 'middle',
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        yAxis: [],
        series: []
      }
    }
  },
  watch: {
    selectedNodes: function () {
      if (this.selectedNodes.length) {
        setTimeout(() => {
          this.chart = echarts.init(document.getElementById('chart'))
          this.chartOptions = {
            ...this.chartOptions,
            yAxis: [{
              type: 'value',
              name: 'Input'
              /* axisLabel: {
                    formatter: '{value} ml'
                  } */
            }],
            series: _.map(this.selectedNodes, item => ({
              name: item.networkUnit.name,
              type: 'line',
              data: _.map(item.summary, 'totalEnergyInput')
            }))
          }

          if (this.includeLosses) {
            this.chartOptions.yAxis.push({
              type: 'value',
              name: 'Losses'
            })

            this.chartOptions.series = this.chartOptions.series.concat(_.map(this.selectedNodes, item => ({
              name: `${item.networkUnit.name} Losses`,
              type: 'line',
              yAxisIndex: 1,
              data: _.map(item.summary, 'energyLossesOriginKwh')
            })))
          }
          this.chart.setOption(this.chartOptions, true)
        }, 100)
      }
    },
    includeLosses: function () {
      if (this.includeLosses) {
        this.chartOptions.yAxis.push({
          type: 'value',
          name: 'Losses'
        })

        this.chartOptions.series = this.chartOptions.series.concat(_.map(this.selectedNodes, item => ({
          name: `${item.networkUnit.name} Losses`,
          type: 'line',
          yAxisIndex: 1,
          data: _.map(item.summary, 'energyLossesOriginKwh')
        })))
        this.chart.setOption(this.chartOptions, true)
      } else {
        this.chartOptions.yAxis.splice(1, 1)
        this.chartOptions.series = this.chartOptions.series.filter(item => !item.yAxisIndex)
        this.chart.setOption(this.chartOptions, true)
      }
    }
  },
  methods: {
    async fetchBalanceAreas () {
      this.toggleLoading()
      const [, response, responseData] = await handlePromise(BalanceAreaService.getAreas({ limit: 0 }))
      this.toggleLoading()
      if (response.ok) this.areas = responseData.data
    },
    async fetchMeter ({ data }) {
      const nodeAddedIndex = _.findIndex(this.selectedNodes, { networkUnit: { id: data.networkUnit.id } })
      if (nodeAddedIndex !== -1) {
        this.selectedNodes.splice(nodeAddedIndex, 1)
      } else {
        this.toggleLoading()
        const [error, response, responseData] = await handlePromise(BalanceService.getBalancePeriodHistory({ node: data.id }))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

        this.selectedNodes.push({
          ...data,
          average: responseData[0].average,
          summary: responseData[0].balanceNodePeriodCalculations
        })
      }
    },
    deleteMeter (id) {
      const index = findIndex(this.selectedNodes, { id })
      if (index !== -1) this.selectedNodes.splice(index, 1)
    },
    async downloadTable () {
      const params = {
        nodes: this.selectedNodes.map(item => item.id)
      }
      const [error, response, data] = await handlePromise(BalanceService.downloadEnergyInputTable(params))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      return window.open(data.url)
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
