<template>
  <section>
    <ods-form :model="model" ref="nodeForm" v-loading="formLoading" disabled>
      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('networkElement')" prop="networkElement">
            <ods-input v-model="model.networkElement" disabled></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('originSystemId')" prop="originSystemId">
            <ods-input v-model="model.originSystemId" disabled></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('name')" prop="name">
            <ods-input v-model="model.name" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('technicalLossesPercent')" prop="technicalLosses">
            <ods-input v-model="model.technicalLosses" :disabled="model.disabled" type="number"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

       <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('latitude')" prop="lat" :rules="[errors.required]">
            <ods-input v-model="model.lat" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('longitude')" prop="long" :rules="[errors.required]">
            <ods-input v-model="model.long" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('region')" prop="region">
           <ods-select v-model="model.region" remote :disabled="model.disabled">
              <ods-option
                  v-for="item in regions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('district')" prop="district">
            <ods-input v-model="model.district" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('location')" prop="location">
            <ods-input v-model="model.location" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('address')" prop="address">
            <ods-input v-model="model.address" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('serialNumber')" prop="serialNumber">
            <ods-input v-model="model.serialNumber" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('manufacturer')" prop="manufacturer">
            <ods-input v-model="model.manufacturer" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('neighbors')" prop="neighbors">
              <ods-select multiple v-model="model.neighbors" remote clearable :disabled="model.disabled">
                <ods-option
                    v-for="item in neighbors"
                    :key="item.id"
                    :label="item.networkUnit.name"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('volume')" prop="volume">
            <ods-input v-model="model.volume" type="number" :disabled="model.disabled"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>
      <!-- <ods-row
          type="flex"
          justify="center"
          v-if="!model.disabled || (model.attributes && model.attributes.length && !model.disabledAttributes)">
        <ods-button type="primary" @click="submitForm">
          {{$t('save')}}
        </ods-button>
      </ods-row> -->
    </ods-form>
  </section>
</template>

<script>
import NetworkUnitService from '@/services/NetworkUnit'
import NetworkNodeService from '@/services/NetworkNode'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'

export default {
  name: 'EditNode',
  props: {
    model: Object,
    hasPermission: {
      type: Boolean,
      default: true
    },
    parentLevel: Number,
    tree: String
  },
  watch: {
    'model': function () {
      this.getNeighbors()
    }
  },
  data () {
    return {
      formLoading: false,
      regions: [
        { id: 'north', name: 'NORTH' },
        { id: 'south', name: 'SOUTH' },
        { id: 'east', name: 'EAST' },
        { id: 'west', name: 'WEST' }
      ],
      errors,
      neighbors: []
    }
  },
  methods: {
    async submitForm () {
      this.$refs['nodeForm'].validate(async (valid) => {
        if (valid) {
          this.formLoading = true

          let data = {}
          if (!this.model.disabled) {
            data = {
              name: this.model.name,
              technicalLosses: this.model.technicalLosses,
              originSystemId: this.model.originSystemId,
              district: this.model.district,
              location: this.model.location,
              address: this.model.address,
              serialNumber: this.model.serialNumber,
              manufacturer: this.model.manufacturer,
              volume: this.model.volume,
              region: this.model.region,
              geometry: {
                type: 'Point',
                coordinates: [
                  this.model.lat,
                  this.model.long
                ]
              },
              neighbors: this.model.neighbors
            }
          }
          const [error, response] = await handlePromise(NetworkUnitService.updateNetworkUnitWater(this.model.id, data))
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('reloadData')
        }
      })
    },
    async getNeighbors () {
      this.neighbors = []
      const [, response, data] = await handlePromise(NetworkNodeService.getNeighborsWater(this.parentLevel / 10, this.tree))
      if (response.ok) {
        data.map(item => {
          if (item.id !== this.model.extraData2.id) {
            this.neighbors.push(item)
          }
        })
      }
    }
  }
}
</script>
