<template>
  <section>
    <ods-module class="mt-1">
      <template slot="header">
        {{ $t('consumptions') }}
      </template>

      <ods-table
          :data="data.data"
          size="mini"
      >
        <ods-table-column prop="meter.serialNumber" :label="$t('meter')"/>
        <ods-table-column prop="date" :label="$t('date')" v-if="hasPermission">
          <template slot-scope="scope">
            <ods-button type="text" class="p-0" @click="toggleDialog(scope.row.id, scope.row.meter.serialNumber)">
              {{ scope.row.date | date }}
            </ods-button>
          </template>
        </ods-table-column>
        <ods-table-column prop="date" :label="$t('date')" v-if="!hasPermission">
          <template slot-scope="scope">
              {{ scope.row.date | date }}
          </template>
        </ods-table-column>
        <ods-table-column prop="type" :label="$t('type')">
          <template slot-scope="scope">
            {{$t(scope.row.type)}}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('readingForecast')" >
              <template slot-scope="scope">
                {{ scope.row.readingForecast | formatNumber }}
              </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumption')" >
              <template slot-scope="scope">
                {{ scope.row.consumptionForecast | formatNumber }}
              </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumptionForecast')" >
              <template slot-scope="scope">
                {{ scope.row.consumptionForecastToDay | formatNumber }}
              </template>
        </ods-table-column>
        <!--<ods-table-column prop="status" :label="$t('anomalies')">
          <template slot-scope="scope">
            <ods-tooltip class="item" effect="light" placement="top-start"
                         v-if="scope.row.highEstimationStatus === 'pending' || scope.row.highDeviationStatus === 'pending'
                         || scope.row.lowEstimationStatus === 'pending' || scope.row.noReadingStatus === 'pending'
                         || scope.row.potentialTurnoverStatus === 'pending' || scope.row.readingBoundsStatus === 'pending'
                          || scope.row.consumptionBoundsStatus === 'pending'">
              <div slot="content">
                <div v-if="scope.row.highEstimationStatus === 'pending'">- {{$t('highEstimation')}}</div>
                <div v-if="scope.row.highDeviationStatus === 'pending'">- {{$t('highDeviation')}}</div>
                <div v-if="scope.row.lowEstimationStatus === 'pending'">- {{$t('lowEstimation')}}</div>
                <div v-if="scope.row.noReadingStatus === 'pending'">- {{$t('noReading')}}</div>
                <div v-if="scope.row.potentialTurnoverStatus === 'pending'">- {{$t('potentialTurnover')}}</div>
                <div v-if="scope.row.readingBoundsStatus === 'pending'">- {{$t('readingBounds')}}</div>
                <div v-if="scope.row.consumptionBoundsStatus === 'pending'">- {{$t('consumptionBounds')}}</div>
              </div>
              <ods-icon name="info-alert" color="#DE2F2F" size="20" style="line-height: 1"/>
            </ods-tooltip>
          </template>
        </ods-table-column> -->
      </ods-table>
    </ods-module>

    <dialog-form
        :title="toEdit ? 'editConsumptionForMeter' : 'addConsumptionForMeter'"
        :secondTitle="serialNumber"
        :visible="editDialogVisible"
        @submit="submitForm"
        @cancel="toggleDialog()"
    >
      <ods-form
          :model="form"
          ref="form"
          v-loading="formLoading"
      >
        <p style="margin-bottom:10px;margin-top:5px;font-size:120%;font-weight: 490">Date: {{form.date}}</p>
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('consumptionForecast')" prop="consumptionForecastToDay" :rules="[errors.required]">
              <ods-input v-model="form.consumptionForecastToDay"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item :label="$t('consumption')" prop="consumptionForecast" :rules="[errors.required]">
              <ods-input v-model="form.consumptionForecast" disabled></ods-input>
            </ods-form-item>
          </ods-col>
          <!--<ods-col :md="24"  style="margin-bottom:10px;margin-top:5px;font-size:120%;font-weight: 490"
            v-show="form.consumptionBoundsStatus !== 'solved' || form.highDeviationStatus !== 'solved' || form.highEstimationStatus !== 'solved'
            || form.lowEstimationStatus !== 'solved' || form.noReadingStatus !== 'solved' || form.potentialTurnoverStatus !== 'solved'
            || form.readingBoundsStatus !== 'solved'"
          >
                {{ $t('anomalies')  }}:
          </ods-col>
          <ods-col :md="12" v-show="form.consumptionBoundsStatus != 'solved'">
            <ods-form-item :label="$t('consumptionBounds')" prop="consumptionBoundsStatus" :rules="[errors.required]">
              <ods-select v-model="form.consumptionBoundsStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.highDeviationStatus != 'solved'">
            <ods-form-item :label="$t('highDeviation')" prop="highDeviationStatus" :rules="[errors.required]">
              <ods-select v-model="form.highDeviationStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.highEstimationStatus != 'solved'">
            <ods-form-item :label="$t('highEstimation')" prop="highEstimationStatus" :rules="[errors.required]">
              <ods-select v-model="form.highEstimationStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.lowEstimationStatus != 'solved'">
            <ods-form-item :label="$t('lowEstimation')" prop="lowEstimationStatus" :rules="[errors.required]">
              <ods-select v-model="form.lowEstimationStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.noReadingStatus != 'solved'">
            <ods-form-item :label="$t('noReading')" prop="noReadingStatus" :rules="[errors.required]">
              <ods-select v-model="form.noReadingStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.potentialTurnoverStatus != 'solved'">
            <ods-form-item :label="$t('potentialTurnover')" prop="potentialTurnoverStatus" :rules="[errors.required]">
              <ods-select v-model="form.potentialTurnoverStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.readingBoundsStatus != 'solved'">
            <ods-form-item :label="$t('readingBounds')" prop="readingBoundsStatus" :rules="[errors.required]">
              <ods-select v-model="form.readingBoundsStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>-->
        </ods-row>
      </ods-form>
    </dialog-form>
  </section>
</template>

<script>
import errors from '@/config/InputErrors'
import MeterReadingForecastService from '@/services/MeterReadingForecast'
import DialogForm from '@/custom-components/DialogForm'
import handlePromise from '@/utils/promise'
import Vue from 'vue'

export default {
  name: 'ValidationReadings',
  props: {
    data: Object,
    meter: Object,
    hasPermission: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DialogForm
  },
  data () {
    return {
      form: {},
      lastReading: {},
      formLoading: false,
      errors,
      editDialogVisible: false,
      toEdit: null,
      serialNumber: null,
      statuses: [
        { id: 'pending', name: 'pending' },
        { id: 'ignored', name: 'ignored' }
      ]
    }
  },
  methods: {
    toggleDialog (id, meter) {
      this.toEdit = id
      this.serialNumber = meter

      if (this.editDialogVisible && this.$refs['form']) {
        this.form = {}
        this.$refs['form'].resetFields()
      }
      this.editDialogVisible = !this.editDialogVisible
      if (id) this.getReading()
    },
    async getReading () {
      this.formLoading = true

      const [error, response, data] = await handlePromise(MeterReadingForecastService.getMeterReadingWater(
        this.data.meter, this.toEdit))
      this.formLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.form = {
        date: Vue.filter('date')(data.date),
        consumptionForecastToDay: Vue.filter('formatNumberForm')(data.consumptionForecastToDay),
        // consumptionBoundsStatus: data.consumptionBoundsStatus,
        // highDeviationStatus: data.highDeviationStatus,
        // highEstimationStatus: data.highEstimationStatus,
        // lowEstimationStatus: data.lowEstimationStatus,
        // noReadingStatus: data.noReadingStatus,
        // potentialTurnoverStatus: data.potentialTurnoverStatus,
        // readingBoundsStatus: data.readingBoundsStatus,
        consumptionForecast: Vue.filter('formatNumberForm')(data.consumptionForecast)
      }
      this.lastReading = data.lastReading
    },
    checkString (val) {
      if (typeof val === 'string') {
        return true
      }
      return false
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.formLoading = true
          const toSend = {
            consumptionForecastToDay: this.checkString(this.form.consumptionForecastToDay) ? this.form.consumptionForecastToDay.replace(/,/g, '') : this.form.consumptionForecastToDay,
            // consumptionBoundsStatus: this.form.consumptionBoundsStatus,
            // highDeviationStatus: this.form.highDeviationStatus,
            // highEstimationStatus: this.form.highEstimationStatus,
            // lowEstimationStatus: this.form.lowEstimationStatus,
            // noReadingStatus: this.form.noReadingStatus,
            // potentialTurnoverStatus: this.form.potentialTurnoverStatus,
            // readingBoundsStatus: this.form.readingBoundsStatus,
            consumptionForecast: this.checkString(this.form.consumptionForecast) ? this.form.consumptionForecast.replace(/,/g, '') : this.form.consumptionForecast
          }
          const [error, response] = await handlePromise(
            MeterReadingForecastService.updateMeterReadingWater(this.data.meter, this.toEdit, toSend))
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('reload-data')
          this.toggleDialog()
        }
      })
    }
  },
  watch: {
    'form.consumptionForecastToDay': function (val) {
      this.form.consumptionForecastToDay = this.checkString(val) ? Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, '')) : this.form.consumptionForecastToDay
    },
    'form.consumptionForecast': function (val) {
      this.form.consumptionForecast = this.checkString(val) ? Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, '')) : this.form.consumptionForecast
    }
  }
}
</script>
