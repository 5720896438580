import api from '@/config/Api'

const baseEndpoint = (id) => `/measurement/meter/${id}/meter-reading`
const baseEndpoint2 = (id) => `/measurement/meter/${id}/meter-water-reading`

export default {
  getMeterReadings (meterId, params) {
    return api.get(baseEndpoint(meterId), params)
  },

  getMeterReadingsWater (meterId, params) {
    return api.get(baseEndpoint2(meterId), params)
  },

  getMeterReadingsByCriteria (params) {
    return api.get('/measurement/meter-reading/by-criteria', params)
  },

  getMeterReadingsByCriteriaWater (params) {
    return api.get('/measurement/meter-water-reading/by-criteria', params)
  },

  getMeterReading (meterId, id) {
    return api.get(`${baseEndpoint(meterId)}/${id}`)
  },

  getMeterReadingWater (meterId, id) {
    return api.get(`${baseEndpoint2(meterId)}/${id}`)
  },

  saveMeterReading (meterId, data) {
    return api.post(baseEndpoint(meterId), data)
  },

  saveMeterReadingWater (meterId, data) {
    return api.post(baseEndpoint2(meterId), data)
  },

  updateMeterReading (meterId, id, data) {
    return api.put(`${baseEndpoint(meterId)}/${id}`, data)
  },

  updateMeterReadingWater (meterId, id, data) {
    return api.put(`${baseEndpoint2(meterId)}/${id}`, data)
  },

  ignoreAnomalies (params, data) {
    let paramsString = ''
    params.anomalies.map(item => {
      paramsString = paramsString + 'anomalies[]=' + item + '&'
    })
    return api.put(`/measurement/meter-reading-anomaly/by-type?${paramsString}`, data)
  },

  ignoreAnomaliesWater (params, data) {
    let paramsString = ''
    params.anomalies.map(item => {
      paramsString = paramsString + 'anomalies[]=' + item + '&'
    })
    return api.put(`/measurement/meter-water-reading-anomaly/by-type?${paramsString}`, data)
  },

  resetOrigin (data) {
    return api.put(`/measurement/meter-reading/reset-to-origin`, data)
  },

  resetOriginWater (data) {
    return api.put(`/measurement/meter-water-reading/reset-to-origin`, data)
  },

  bulkValidation () {
    return api.get('/measurement/meter-reading/massive-validation')
  },

  bulkValidationWater () {
    return api.get('/measurement/meter-water-reading/massive-validation')
  },

  bulkValidationReport () {
    return api.get('/measurement/report/validation?type=bulk_validation')
  },

  bulkValidationReportWater () {
    return api.get('/measurement/report/validation?type=bulk_validation')
  },

  noReadingValidation () {
    return api.get('/measurement/meter-reading/no-reading-validation')
  },

  noReadingValidationWater () {
    return api.get('/measurement/meter-water-reading/no-reading-validation')
  },

  noReadingValidationReport () {
    return api.get('/measurement/report/validation?type=no_reading_validation')
  },

  noReadingValidationReportWater () {
    return api.get('/measurement/report/validation?type=no_reading_validation')
  },

  massiveEstimation () {
    return api.get('/measurement/meter-reading/massive-estimation')
  },

  massiveEstimationWater () {
    return api.get('/measurement/meter-water-reading/massive-estimation')
  },

  massiveEstimationReport () {
    return api.get('/measurement/report/validation?type=massive_estimation')
  },

  massiveEstimationReportWater () {
    return api.get('/measurement/report/validation?type=massive_estimation')
  },

  getLastReport () {
    return api.get('/measurement/report/validation/all')
  },

  getLastReportWater () {
    return api.get('/measurement/report/validation/all')
  }
}
