<template>
  <section>
    <ods-module :header="$t('maneuvers')">
      <template slot="header">
        <list-header title="maneuvers" :hasPermission=hasPermission>
          <template slot="right">
              <ods-button size="medium" @click="applyManeuvers">
                {{$t('applyManeuvers')}}
              </ods-button>
          </template>
        </list-header>
      </template>
      <async-table
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <template v-slot:filters="props">
          <ods-form :model="searchForm" class="mb-1">
            <ods-row :gutter="20" type="flex" align="center">
              <ods-col :md="5">
                <ods-form-item inlineMessage prop="status" class="m-0">
                  <ods-select
                      clearable
                      v-model="searchForm.status"
                      :placeholder="$t('status')"
                  >
                    <ods-option
                        v-for="item in statuses"
                        :key="item.id"
                        :label="$t(item.name)"
                        :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
              <ods-col :md="2">
                <ods-button size="small" icon="ods-icon-search" @click="props.filterData(searchForm)">
                  search
                </ods-button>
              </ods-col>
            </ods-row>
          </ods-form>
        </template>

        <ods-table-column prop="name" :label="$t('name')">
          <template slot-scope="scope">
            <router-link v-if="scope.row.id" :to="{ name: 'ManeuverDetail', params: { id: scope.row.id }}">
              <ods-button type="text" class="p-0">{{scope.row.name}}</ods-button>
            </router-link>
          </template>
        </ods-table-column>

        <ods-table-column prop="createdAt" :label="$t('createdAt')">
          <template slot-scope="scope">
            {{scope.row.createdAt | date}}
          </template>
        </ods-table-column>
        <ods-table-column prop="status" :label="$t('status')">
          <template slot-scope="scope">
            <ods-badge
                v-if="scope.row.status"
                :value="$t(scope.row.status).toUpperCase()"
                :type="getStatusBadgeType(scope.row.status)"
            />
          </template>
        </ods-table-column>
        <ods-table-column prop="type" :label="$t('type')">
          <template slot-scope="scope">
            {{$t(scope.row.type)}}
          </template>
        </ods-table-column>
        <ods-table-column prop="logNetworkChanges" :label="$t('changes')">
          <template slot-scope="scope">
            {{scope.row.logNetworkChangesTotal}}
          </template>
        </ods-table-column>
        <ods-table-column prop="owner" :label="$t('owner')">
          <template slot-scope="scope">
            <span v-if="scope.row.owner && scope.row.owner.userProfile && scope.row.owner.userProfile.name">
              {{scope.row.owner.userProfile.name}}
            </span>
            <span v-else-if="scope.row.owner && scope.row.owner.email">
              {{scope.row.owner.email}}
            </span>
          </template>
        </ods-table-column>
      </async-table>
    </ods-module>
  </section>
</template>

<script>
import ManeuverService from '@/services/NetwortManeuver'
import AsyncTable from '@/custom-components/AsyncTable'
import asyncTableCommon from '@/mixins/async-table'
import maneuverCommon from '@/mixins/maneuver'
import ListHeader from '@/custom-components/ListHeader'
import handlePromise from '@/utils/promise'
import { mapState } from 'vuex'

export default {
  name: 'ManeuverDashboard',
  components: {
    AsyncTable,
    ListHeader
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.handlePermission()
  },
  mixins: [asyncTableCommon, maneuverCommon],
  data () {
    return {
      hasPermission: true,
      searchForm: {},
      statuses: [
        { id: 'draft', name: 'draft' },
        { id: 'accepted', name: 'accepted' },
        { id: 'discard', name: 'discard' },
        { id: 'approved', name: 'approved' },
        { id: 'rejected', name: 'rejected' },
        { id: 'done', name: 'done' },
        { id: 'error', name: 'error' }
      ],
      promise: (params) => ManeuverService.getManeuversWater(params)
    }
  },
  methods: {
    async applyManeuvers () {
      this.loading = true
      const [error, response] = await handlePromise(ManeuverService.executeManeuversWater())

      if (!response.ok) {
        this.loading = false
        return this.$store.commit('settings/toggleAlert', error)
      }

      this.getData(this.tableParams)
      return this.$store.commit('settings/toggleSuccessAlert', this.$t('maneuversApplied'))
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
