<template>
  <section>
        <ods-module :header="$t('strategicPlan')">
          <template slot="header">
            <list-header
                title="strategicPlan"
                btn-label="createPlan"
                url-key="PlanNew"
                :hasPermission=hasPermission
            />
          </template>
          <async-table
            :data="data"
            :total="total"
            :loading="loading"
            @fetchData="getData"
          >
            <ods-table-column :label="$t('name')" >
              <template slot-scope="scope">
                <router-link v-if="scope.row.id" :to="{ name: 'PlanEdit', params: { id: scope.row.id }}">
                  <ods-button type="text" class="p-0">{{scope.row.name}}</ods-button>
                </router-link>
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('area')" >
              <template slot-scope="scope">
                {{ scope.row.balanceArea.name }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('description')" >
              <template slot-scope="scope">
                {{ scope.row.description | max20 }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('initialLossesPercent')" >
              <template slot-scope="scope">
                {{ scope.row.lossesInitial | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('lossReductionTarget')" >
              <template slot-scope="scope">
                {{ scope.row.lossesObjective | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('startPeriod')" >
              <template slot-scope="scope">
                {{ scope.row.from.name }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('timePeriod')" >
              <template slot-scope="scope">
                {{ scope.row.timePeriod }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('interventionsPlanned')" >
              <template slot-scope="scope">
                {{ scope.row.interventionsPlanned | max20 }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('estimatedCost')" >
              <template slot-scope="scope">
                {{ scope.row.costEstimated | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('status')" >
              <template slot-scope="scope">
                <ods-badge
                v-if="scope.row.status"
                :value="$t(scope.row.status).toUpperCase()"
                :type="getStatusBadgeType(scope.row.status)"
                />
              </template>
            </ods-table-column>
            <ods-table-column align="right" v-if=hasPermission>
              <template slot-scope="scope">
                <table-delete @click="toggleDialog(scope.row.id)" :disabled ="scope.row.inProgress"></table-delete>
              </template>
            </ods-table-column>
          </async-table>
        </ods-module>
        <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDialog()"
        />
  </section>
</template>

<script>
import StrategicService from '@/services/Strategic'
import handlePromise from '@/utils/promise'
import AsyncTable from '@/custom-components/AsyncTable'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import asyncTableCommon from '@/mixins/async-table'
import ListHeader from '@/custom-components/ListHeader'
import { mapState } from 'vuex'

export default {
  name: 'MeasurementAnalysis',
  components: {
    ListHeader,
    AsyncTable,
    TableDelete,
    DialogConfirmation
  },
  mixins: [asyncTableCommon],
  mounted: function () {
    this.handlePermission()
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  data () {
    return {
      hasPermission: true,
      loading: false,
      createDialogVisible: false,
      selectedNodes: [],
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      promise: (params) => StrategicService.getStrategicPlans(params)
    }
  },
  methods: {
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    },
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(StrategicService.deleteStrategicPlan(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    getStatusBadgeType (status) {
      switch (status) {
        case 'CLOSED':
          return 'secondary'
        case 'OPEN':
          return 'primary'
        default:
          return 'primary'
      }
    }
  }
}
</script>
