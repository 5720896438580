<template>
  <section>
    <ods-module v-loading="loading">
      <template slot="header">
        <ods-row :gutter="20">
          <ods-col :md="12">
            {{this.data.name}}
          </ods-col>
          <ods-col :md="12" class="text-right" v-if="this.data.status === 'accepted'">
            <ods-button size="small" @click="changeStatus('approved')">
              {{$t('approve')}}
            </ods-button>
            <ods-button size="small" type="danger" @click="changeStatus('rejected')">
              {{$t('reject')}}
            </ods-button>
          </ods-col>
          <ods-col :md="12" class="text-right" v-else-if="this.data.status === 'failed'">
            <ods-button size="small" @click="changeStatus('restored')">
              {{$t('restore')}}
            </ods-button>
          </ods-col>
        </ods-row>
      </template>

      <ods-row>
        <ods-col :md="8">
          <p>{{$t('status')}}:
            <ods-badge
                v-if="data.status"
                :value="$t(data.status).toUpperCase()"
                :type="getStatusBadgeType(data.status)"
            />
            <span v-else>---</span>
          </p>
          <p>{{$t('type')}}: {{$t(this.data.type) || '---'}}</p>
        </ods-col>
        <ods-col :md="14">
          <p>{{$t('owner')}}:
            <span v-if="this.data.owner && this.data.owner.userProfile && this.data.owner.userProfile.name">
              {{this.data.owner.userProfile.name}}
            </span>
            <span v-if="this.data.owner && this.data.owner.email">
              {{this.data.owner.email}}
            </span>
            <span v-else>---</span>
          </p>
          <p>{{$t('createdAt')}}:
            <span v-if="this.data.createdAt">{{this.data.createdAt | datetime}}</span>
            <span v-else>---</span>
          </p>
        </ods-col>
      </ods-row>

      <ods-accordion
          :accordion="false"
          value="1"
          class="custom-accordion"
          v-if="data.logNetworkChanges && data.logNetworkChanges.length">
        <ods-accordion-item name="1" :title="$t('actions')">
          <ods-module bodyStyle="padding: 10px 20px"
                      class="mb-1 border-success"
                      v-bind:class="{'border-success': item.action === 'created', 'border-danger':  item.action === 'deleted', 'border-primary':  item.action === 'modified'}"
                      v-for="item in data.logNetworkChanges"
                      v-bind:key="item.id"
          >
            <p class="font-weight-bold">{{item.action ? $t(item.action).toUpperCase() : '---'}}</p>
            <ods-row>
              <ods-col :md="8">
                <p>
                  <span class="font-weight-bold">{{$t('status')}}:</span>
                  <ods-badge
                      v-if="item.status"
                      :value="$t(item.status).toUpperCase()"
                      :type="item.status === 'pending' ? 'secondary' : 'success'"
                  />
                  <span v-else>---</span>
                </p>

                <p v-if="item.action !== 'modified'">
                  <span class="font-weight-bold">{{$t('networkTree')}}:</span>
                  {{item.networkTree && item.networkTree.firstNode ? item.networkTree.firstNode.networkUnit.name :
                  '---'}}
                </p>
                <p v-else>
                  <span class="font-weight-bold">{{$t('networkTree')}}:</span>
                  <span v-if="item.originTree">
             {{item.originTree.firstNode.networkUnit.name}}
            -
            {{item.networkTree.firstNode.networkUnit.name}}
          </span>
                  <span v-if="item.destinationTree">
            {{item.networkTree.firstNode.networkUnit.name}}
            -
            {{item.destinationTree.firstNode.networkUnit.name}}
          </span>
                </p>

                <p v-if="item.executeDate">
                  <span class="font-weight-bold">{{$t('executionDate')}}:</span>
                  {{item.executeDate | date}}
                </p>
              </ods-col>
              <ods-col :md="14">
                <p v-if="item.networkNode && item.networkNode.networkUnit">
                  <span class="font-weight-bold">{{$t('networkUnit')}}:</span>
                  <span v-if="item.networkNode && item.networkNode.networkUnit">
            {{item.networkNode.networkUnit.name}}
            <span v-if="item.networkNode.networkUnit.networkElement">
              ({{item.networkNode.networkUnit.networkElement.name}} - {{item.networkNode.networkUnit.networkElement.elementType}})
            </span>
          </span>
                  <span v-else>---</span>
                </p>
                <p v-if="item.action === 'modified' && item.origin && item.origin.networkUnit">
                  <span class="font-weight-bold">{{$t('origin')}}:</span>
                  <span v-if="item.origin && item.origin.networkUnit">
            {{item.origin.networkUnit.name}}
            <span v-if="item.origin.networkUnit.networkElement">
              ({{item.origin.networkUnit.networkElement.name}} - {{item.origin.networkUnit.networkElement.elementType}})
            </span>
          </span>
                </p>
                <p v-if="(item.action === 'created' || item.action === 'modified') && item.destination && item.destination.networkUnit">
                  <span class="font-weight-bold">{{$t('destination')}}:</span>
                  <span>
            {{item.destination.networkUnit.name}}
            <span v-if="item.destination.networkUnit.networkElement">
              ({{item.destination.networkUnit.networkElement.name}} - {{item.destination.networkUnit.networkElement.elementType}})
            </span>
          </span>
                </p>
              </ods-col>
            </ods-row>
          </ods-module>
        </ods-accordion-item>
      </ods-accordion>

      <ods-row type="flex" justify="center" class="mt-1">
        <router-link :to="{ name: 'ManeuverDashboard'}">
          <ods-button type="secondary">{{$t('back')}}</ods-button>
        </router-link>
      </ods-row>
    </ods-module>

  </section>
</template>

<script>
import ManeuverService from '@/services/NetwortManeuver'
import handlePromise from '@/utils/promise'
import maneuverCommon from '@/mixins/maneuver'
import _ from 'lodash'

export default {
  name: 'ManeuverDetail',
  mixins: [maneuverCommon],
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      data: {}

    }
  },
  methods: {
    async getData () {
      this.loading = true

      const [error, response, data] = await handlePromise(ManeuverService.getManeuverWater(this.$route.params.id))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      _.map(data.logNetworkChanges, change => {
        if (change.action === 'modified') {
          const secondMov = _.find(data.logNetworkChanges,
            item => item.id !== change.id && item.refModified === change.refModified && !change.mapped
          )
          if (!secondMov) return change
          if (change.origin) {
            change.destination = { ...secondMov.destination }
            change.destinationTree = { ...secondMov.networkTree }
          } else {
            change.origin = { ...secondMov.origin }
            change.originTree = { ...secondMov.networkTree }
          }
          secondMov.mapped = true
        }
      })
      data.logNetworkChanges = _.filter(data.logNetworkChanges, item => !item.mapped)
      this.data = data
    },
    async changeStatus (status) {
      this.loading = true

      const [error, response] = await handlePromise(ManeuverService.updateManeuverWater(this.data.id, { status }))
      if (!response.ok) {
        this.loading = false
        return this.$store.commit('settings/toggleAlert', error)
      }

      this.getData()
    }
  }
}
</script>
