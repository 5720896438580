<template>
  <section>
    <ods-module class="mt-1" :header="$t('strategicActivities')">
      <template slot="header">
        <list-header title="strategicActivities" :hasPermission="hasPermission" v-if="closed !== 'CLOSED'">
          <template slot="right" >
            <ods-button size="medium" @click="toggleDialog()">
              {{$t('addActionActivitie')}}
            </ods-button>
          </template>
        </list-header>
      </template>

      <async-table
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <ods-table-column prop="networkElement" :label="$t('networkElement')">
          <template slot-scope="scope">
            <ods-button type="text" class="p-0" @click="toggleDetailDialog(scope.row.id)">
              {{scope.row.networkNode.networkUnit.name}}
            </ods-button>
          </template>
        </ods-table-column>

        <ods-table-column prop="action" :label="$t('action')"/>

        <ods-table-column prop="result" :label="$t('result')">
          <template slot-scope="scope">
              <ods-badge
                v-if="scope.row.result"
                :value="$t(scope.row.result).toUpperCase()"
                :type="getStatusBadgeType(scope.row.result)"
            />
          </template>
        </ods-table-column>
        <ods-table-column prop="expectedCost" :label="$t('expectedRealCost')">
          <template slot-scope="scope">
              {{scope.row.expectedCost | formatCurrency}}
          </template>
        </ods-table-column>

        <ods-table-column prop="expectedExecutionDate" :label="$t('expectedExecutionDate')">
          <template slot-scope="scope">
              {{scope.row.expectedExecutionDate | date}}
          </template>
        </ods-table-column>

        <ods-table-column prop="expectedComment" :label="$t('comment')">
          <template slot-scope="scope">
              {{scope.row.expectedComment | max20}}
          </template>
        </ods-table-column>

        <ods-table-column prop="realCost" :label="$t('realCost')">
          <template slot-scope="scope">
              {{scope.row.realCost | formatCurrency}}
          </template>
        </ods-table-column>

        <ods-table-column prop="executionDate" :label="$t('executionDate')">
          <template slot-scope="scope">
              {{scope.row.executionDate | date}}
          </template>
        </ods-table-column>

        <ods-table-column prop="expectedComment" :label="$t('otherComment')">
          <template slot-scope="scope">
              {{scope.row.comment | max20}}
          </template>
        </ods-table-column>

        <ods-table-column width="58" align="right" v-if="closed !== 'CLOSED'">
          <template slot-scope="scope">
            <table-edit @click="toggleDialog(scope.row.id)"></table-edit>
          </template>
        </ods-table-column>
        <ods-table-column width="58" align="right">
          <template slot-scope="scope">
            <table-history @click="toggleHistoryDialog(scope.row.id)"></table-history>
          </template>
        </ods-table-column>
        <ods-table-column width="58" align="right" v-if="closed !== 'CLOSED'">
          <template slot-scope="scope">
            <table-delete v-if="hasPermission" @click="toggleDeleteDialog(scope.row.id)"></table-delete>
          </template>
        </ods-table-column>

      </async-table>
    </ods-module>

    <DetailActivityModal
      v-show="detailDialogVisible"
      v-if="detailDialogVisible"
      @close="closeDetailModal"
      :valueToDetail = valueToDetail
      :plan = plan
     />

    <EditActivityModal
      v-show="dialogVisible"
      v-if="dialogVisible"
      @close="closeModal"
      :valueToEdit = valueToEdit
      :area = area
      :plan = plan
      :hasPermission= hasPermission
    />

    <HistoryActivityModal
      v-show="historyDialogVisible"
      v-if="historyDialogVisible"
      @close="closeHistoryModal"
      :valueToHistory = valueToHistory
      :plan = plan
    />

    <dialog-confirmation
        :visible="deleteDialogVisible"
        :loading="deleteLoading"
        :plan=plan
        @confirm="deleteValue"
        @cancel="toggleDeleteDialog()"
    />
  </section>
</template>

<script>
import StrategicService from '@/services/Strategic'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import TableEdit from '@/custom-components/Buttons/TableEdit'
import TableHistory from '@/custom-components/Buttons/TableHistory'
import AsyncTable from '@/custom-components/AsyncTable'
import ListHeader from '@/custom-components/ListHeader'
import asyncTableCommon from '@/mixins/async-table'
import EditActivityModal from './EditActivityModal.vue'
import HistoryActivityModal from './HistoryActivityModal.vue'
import DetailActivityModal from './DetailActivityModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'Activity',
  mounted: function () {
    this.getData()
    this.handlePermission()
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  components: {
    TableDelete,
    TableEdit,
    TableHistory,
    DialogConfirmation,
    EditActivityModal,
    HistoryActivityModal,
    DetailActivityModal,
    AsyncTable,
    ListHeader
  },
  props: {
    plan: String,
    area: String,
    closed: {
      type: String,
      default: 'OPEN'
    }
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      hasPermission: true,
      dialogVisible: false,
      deleteLoading: false,
      form: {},
      errors,
      detailDialogVisible: false,
      valueToDetail: null,
      editDialogVisible: false,
      valueToEdit: null,
      deleteDialogVisible: false,
      valueToDelete: null,
      historyDialogVisible: false,
      valueToHistory: null,
      promise: (params) => StrategicService.getActivityByPlan(this.plan, params)
    }
  },
  methods: {
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    },
    toggleDialog (id) {
      this.valueToEdit = id
      this.dialogVisible = true
    },
    closeModal () {
      this.getData(this.tableParams)
      this.dialogVisible = false
    },
    toggleDetailDialog (id) {
      this.valueToDetail = id
      this.detailDialogVisible = !this.detailDialogVisible
    },
    toggleDeleteDialog (id) {
      this.valueToDelete = id
      this.deleteDialogVisible = !this.deleteDialogVisible
    },
    toggleHistoryDialog (id) {
      this.valueToHistory = id
      this.historyDialogVisible = !this.historyDialogVisible
    },
    closeHistoryModal () {
      this.historyDialogVisible = false
    },
    closeDetailModal () {
      this.detailDialogVisible = false
    },
    async deleteValue () {
      this.deleteLoading = true
      const [error, response] = await handlePromise(StrategicService.deleteActivity(this.plan, this.valueToDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.toggleDeleteDialog()
      return this.getData(this.tableParams)
    },
    getStatusBadgeType (status) {
      switch (status) {
        case 'NEGATIVE':
          return 'danger'
        case 'POSITIVE':
          return 'success'
        default:
          return 'primary'
      }
    }
  }
}
</script>
