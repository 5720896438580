<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
             {{ $t('replaceMeter')}} - {{serialNumber}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
        <ods-module v-loading="loading">
          <section class="modal-body">
              <slot name="body">
                  <ods-form :model="form" ref="form">
                    <ods-row :gutter="20">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('originSystemId')" prop="originSystemId">
                          <ods-input v-model="form.originSystemId" disabled></ods-input>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('brand')" prop="brand">
                          <ods-select v-model="form.brand" placeholder="" @input="onBrandChange">
                            <ods-option
                                v-for="item in brands"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </ods-option>
                          </ods-select>
                        </ods-form-item>
                      </ods-col>
                    </ods-row>

                    <ods-row :gutter="20">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('model')" prop="model">
                          <ods-select v-model="model" placeholder="">
                            <ods-option
                                v-for="item in models"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id"
                            >
                            </ods-option>
                          </ods-select>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('serialNumber')" prop="serialNumber" :rules="[errors.required]">
                          <ods-input v-model="form.serialNumber"></ods-input>
                        </ods-form-item>
                      </ods-col>
                    </ods-row>

                    <ods-row :gutter="20">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('type')" prop="type">
                          <ods-select v-model="form.type" placeholder="">
                            <ods-option
                                v-for="item in types"
                                :key="item.id"
                                :label="$t(item.name)"
                                :value="item.id"
                            >
                            </ods-option>
                          </ods-select>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('position')" prop="position" :rules="[errors.required]">
                          <ods-select v-model="form.position" placeholder="">
                            <ods-option
                                v-for="item in positions"
                                :key="item.id"
                                :label="$t(item.name)"
                                :value="item.id"
                            >
                            </ods-option>
                          </ods-select>
                        </ods-form-item>
                      </ods-col>
                    </ods-row>

                    <ods-row :gutter="20">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('readingTypes')" prop="readingTypes">
                          <ods-select v-model="form.readingTypes" placeholder="" multiple>
                            <ods-option
                                v-for="item in readingTypes"
                                :key="item.id"
                                :label="$t(item.name)"
                                :value="item.id"
                            >
                            </ods-option>
                          </ods-select>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('serviceType')" prop="serviceType">
                          <ods-select v-model="form.serviceType" placeholder="">
                            <ods-option
                                v-for="item in serviceTypes"
                                :key="item.id"
                                :label="$t(item.name)"
                                :value="item.id"
                            >
                            </ods-option>
                          </ods-select>
                        </ods-form-item>
                      </ods-col>
                    </ods-row>

                    <ods-row :gutter="20">
                      <ods-col :md="12">
                          <ods-form-item :label="$t('installationDate')" prop="installDate" :rules="[errors.required]">
                            <ods-date-picker
                                type="date"
                                v-model="form.installDate"
                                style="width: 100%"
                                />
                          </ods-form-item>
                      </ods-col>
                    </ods-row>
                  </ods-form>
              </slot>
          </section>
        </ods-module>
        <slot name="footer" class="modal-footer">
            <form-buttons
                @submit="submitForm"
                @cancel="$emit('close')"
            ></form-buttons>
        </slot>
        </div>
    </div>
  </transition>
</template>

<script>
import errors from '@/config/InputErrors'
import MeterService from '@/services/Meter'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import BrandModelService from '@/services/BrandModel'
import handlePromise from '@/utils/promise'
import moment from 'moment'

export default {
  name: 'ReplaceMeterModal',
  props: {
    serialNumber: String,
    meterId: String,
    networkUnitId: String,
    originSystemId: String
  },
  components: {
    FormButtons
  },
  mounted () {
    this.getParams()
  },
  data () {
    return {
      formLoading: false,
      loading: false,
      hasMeter: true,
      form: {},
      errors,
      brands: [],
      models: [],
      model: {},
      types: [{ id: 'network', name: 'network' }, { id: 'customer', name: 'customer' }],
      positions: [{ id: 'input', name: 'input' }, { id: 'output', name: 'output' }],
      serviceTypes: [{ id: 'prepaid', name: 'prepaid' }, { id: 'postpaid', name: 'postpaid' }],
      readingTypes: [{ id: 'active', name: 'active' }, { id: 'power', name: 'power' }, { id: 'reactive', name: 'reactive' }]
    }
  },
  methods: {
    async getParams () {
      this.toggleLoading()
      const [, modelsResponse, brands] = await handlePromise(BrandModelService.getBrands())
      this.toggleLoading()
      if (modelsResponse.ok) this.brands = brands.data
      this.form.originSystemId = this.originSystemId
    },
    async onBrandChange (event) {
      this.toggleLoading()
      const [, brandResponse, models] = await handlePromise(BrandModelService.getModelsByBrand(event))
      this.toggleLoading()
      if (brandResponse.ok) {
        this.models = models.data
        this.model = ''
      }
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const data = {
            brand: this.form.brand,
            model: this.model,
            serialNumber: this.form.serialNumber,
            type: this.form.type,
            position: this.form.position,
            serviceType: this.form.serviceType,
            readingTypes: this.form.readingTypes,
            originSystemId: this.form.originSystemId,
            name: this.form.name,
            largePower: this.form.largePower ? '1' : '0',
            installDate: this.form.installDate ? moment.utc(this.form.installDate).format('YYYY-MM-DD') : null
          }
          this.toggleLoading()
          const [error, response] = await handlePromise(
            MeterService.replaceMeterWater(this.networkUnitId, this.meterId, data))
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('closeOk')
        }
      })
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style scoped>
  ::-webkit-scrollbar {
     width: 6px;
   }

   ::-webkit-scrollbar-thumb {
      background: rgb(224, 223, 223);
      border-radius: 10px;
   }

  ::-webkit-scrollbar-thumb:hover {
      background: rgb(197, 197, 197);
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 60%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 40vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
