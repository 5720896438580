import api from '@/config/Api'

const baseEndpoint = (id) => `/measurement/meter/${id}/meter-reading-forecast`
const baseEndpointWater = (id) => `/measurement/meter/${id}/meter-water-reading-forecast`

export default {
  getMeterReadings (meterId, params) {
    return api.get(baseEndpoint(meterId), params)
  },

  getMeterReadingsWater (meterId, params) {
    return api.get(baseEndpointWater(meterId), params)
  },

  getMeterReadingsByCriteria (params) {
    return api.get('/measurement/meter-reading-forecast/by-criteria', params)
  },

  getMeterReadingsByCriteriaWater (params) {
    return api.get('/measurement/meter-water-reading-forecast/by-criteria', params)
  },

  getMeterReading (meterId, id) {
    return api.get(`${baseEndpoint(meterId)}/${id}`)
  },

  getMeterReadingWater (meterId, id) {
    return api.get(`${baseEndpointWater(meterId)}/${id}`)
  },

  saveMeterReading (meterId, data) {
    return api.post(baseEndpoint(meterId), data)
  },

  saveMeterReadingWater (meterId, data) {
    return api.post(baseEndpointWater(meterId), data)
  },

  updateMeterReading (meterId, id, data) {
    return api.put(`${baseEndpoint(meterId)}/${id}`, data)
  },

  updateMeterReadingWater (meterId, id, data) {
    return api.put(`${baseEndpointWater(meterId)}/${id}`, data)
  },

  bulkValidation () {
    return api.get('/measurement/meter-reading/massive-validation')
  },

  bulkValidationWater () {
    return api.get('/measurement/meter-water-reading/massive-validation')
  },

  bulkValidationReport () {
    return api.get('/measurement/report/validation?type=bulk_validation')
  },

  bulkValidationReportWater () {
    return api.get('/measurement/report/validation?type=bulk_validation')
  },

  noReadingValidation () {
    return api.get('/measurement/meter-reading-forecast/no-reading-validation')
  },

  noReadingValidationWater () {
    return api.get('/measurement/meter-water-reading-forecast/no-reading-validation')
  },

  noReadingValidationReport () {
    return api.get('/measurement/report/validation?type=no_reading_validation')
  },

  noReadingValidationReportWater () {
    return api.get('/measurement/report/validation?type=no_reading_validation')
  },

  massiveEstimation () {
    return api.get('/measurement/meter-reading/massive-estimation')
  },

  massiveEstimationWater () {
    return api.get('/measurement/meter-water-reading/massive-estimation')
  },

  massiveEstimationReport () {
    return api.get('/measurement/report/validation?type=massive_estimation')
  },

  massiveEstimationReportWater () {
    return api.get('/measurement/report/validation?type=massive_estimation')
  },

  getLastReport () {
    return api.get('/measurement/report/validation/all')
  },

  getLastReportWater () {
    return api.get('/measurement/report/validation/all')
  }
}
