import Vue from 'vue'
let isDragging = false

export default function (element, options) {
  if (Vue.prototype.$isServer) return
  const moveFn = function (event) {
    if (options.drag) {
      options.drag(event)
    }
  }
  const upFn = function (event) {
    document.removeEventListener('mousemove', moveFn)
    document.removeEventListener('mouseup', upFn)
    document.onselectstart = null
    document.ondragstart = null

    isDragging = false

    if (options.end) {
      options.end(event)
    }
  }
  element.addEventListener('mousedown', function (event) {
    if (isDragging) return
    document.onselectstart = function () { return false }
    document.ondragstart = function () { return false }

    document.addEventListener('mousemove', moveFn)
    document.addEventListener('mouseup', upFn)
    isDragging = true

    if (options.start) {
      options.start(event)
    }
  })
}
