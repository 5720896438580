<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="showDetail ? 7 : 11">
        <network-tree
            @node-click="fetchMeter"
            @toggle-loading="toggleLoading"
            @toggleShowDetail="toggleShowDetail"
            v-bind:detailed="true"
            :showDetail="showDetail"
            ref="treeComp"
        >
        </network-tree>
        <network-graph v-if="chartDataTree.categories"
            :chartData="chartDataTree"
            @node-click="fetchMeter"
            ref="graph"
        >
        </network-graph>
      </ods-col>

      <ods-col :md="showDetail ? 17 : 13">
        <ods-module :header="$t('forecastMeasurementAnalysis')">
          <ods-form :model="search" ref="form">
            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('period')" prop="period">
                  <ods-select v-model="search.period" placeholder="" @change="searchMeterReadings">
                    <ods-option
                        v-for="item in periods"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
            </ods-row>
          </ods-form>

          <ods-table
              :data="selectedNodes"
              size="mini"
          >
            <ods-table-column :label="$t('number')" >
              <template slot-scope="scope" v-if="scope.row.meter.serialNumber">
                {{ scope.row.meter.serialNumber }}
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="meter.serialNumber" :label="$t('number')"/>-->
            <ods-table-column prop="meter.brand.description" :label="$t('brand')"/>
            <ods-table-column prop="meter.brand.description" :label="$t('networkElement')">
              <template slot-scope="scope">
                <span v-if="scope.row.networkUnit">{{scope.row.networkUnit.name}}</span>

                (<span v-if="scope.row.networkUnit && scope.row.networkUnit.networkElement">{{scope.row.networkUnit.networkElement.elementType}}</span>
                <span v-if="scope.row.networkTree && scope.row.networkTree.masterName">
                    - {{scope.row.networkTree.masterName}}
                  </span>
                <span v-if="scope.row.networkTree && scope.row.networkTree.code">
                    - {{scope.row.networkTree.code}}
                  </span>
                )
              </template>
            </ods-table-column>
            <ods-table-column align="right" width="100">
              <template slot-scope="scope">
                <table-delete @click="deleteMeter(scope.row.id)"></table-delete>
              </template>
            </ods-table-column>
          </ods-table>
        </ods-module>

        <ods-module class="mt-1" :header="$t('analysis')" v-if="searchEnabled">
         <!-- <ods-dataviz-line
              :data="chartData"
              :colors="colors"
              renderType="svg"
              :animation="true"
              :rotateLabel="true"
              height="360px"
              width="100%"
              :show-tooltip="true"
              trigger="axis"
              :zoom="true"
              :smooth-lines="true"
              :showXFilterZoom="true" /> -->
              <div id="chart" style="height:400px;"></div>
        </ods-module>

        <analysis-readings
            v-if="searchEnabled"
            :data="readings"
        />
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import MeterService from '@/services/Meter'
import PeriodService from '@/services/Period'
import MeterReadingForecastService from '@/services/MeterReadingForecast'
import handlePromise from '@/utils/promise'
import NetworkTree from '@/custom-components/Network/TreeWater'
import NetworkGraph from '@/custom-components/Network/NetworkGraph'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import AnalysisReadings from './Reading'
import findIndex from 'lodash/findIndex'
import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
import NetworkNodeService from '@/services/NetworkNode'

export default {
  name: 'MeasurementAnalysis',
  components: {
    NetworkTree,
    NetworkGraph,
    AnalysisReadings,
    TableDelete
  },
  mounted () {
    this.fetchPeriods()
  },
  data () {
    return {
      loading: false,
      periods: [],
      search: {},
      selectedNodes: [],
      readings: [],
      searchEnabled: false,
      showDetail: true,
      chartDataTree: {},
      colors: [
        '#3982D0',
        '#D96D73',
        '#5A59A6',
        '#6DB6A1',
        '#F78B41',
        '#61B3CB',
        '#418A8B',
        '#A2578D',
        '#E5B75D',
        '#234E63'
      ],
      chartData: {
        'xAxis': [],
        'lines': [ ]
      },
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: [
          {
            name: 'Date',
            type: 'time',
            boundaryGap: false,
            axisLabel: {
              formatter: function (value) {
                return moment(new Date(Number(`${value}`))).format('DD/MM')
              }
            }
          }
        ],
        yAxis: [
          {
            name: 'Consumption Forecast',
            type: 'value'
          }
        ],
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
          filterMode: 'none'
        }, {
          start: 0,
          end: 100,
          filterMode: 'none'
        }]
      }
    }
  },
  methods: {
    toggleShowDetail () {
      this.showDetail = !this.showDetail
    },
    async fetchPeriods () {
      const [, response, responseData] = await handlePromise(PeriodService.getPeriodsWater({ limit: 0 }))
      if (response.ok) this.periods = responseData.data
    },
    async fetchMeter ({ data }) {
      if (this.selectedNodes.length === 5) return this.$store.commit('settings/toggleAlert', this.$t('noMoreFiveComparison'))

      this.toggleLoading()
      const [error, response, responseData] = await handlePromise(NetworkNodeService.getNodeWater(data.firstNode ? data.firstNode.id : data.id))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      const nodeAddedIndex = _.findIndex(this.selectedNodes, { networkUnit: { id: responseData.networkUnit.id } })
      if (nodeAddedIndex !== -1) {
        this.selectedNodes.splice(nodeAddedIndex, 1)
        this.searchMeterReadings()
      } else {
        this.toggleLoading()
        const [error2, response2, responseData2] = await handlePromise(MeterService.getMeterWater(responseData.networkUnit.id))
        this.toggleLoading()
        if (!response2.ok) return this.$store.commit('settings/toggleAlert', this.$t(error2))
        if (!responseData2.serialNumber) return this.$store.commit('settings/toggleAlert', this.$t('noMeterSerialNumber'))
        this.selectedNodes.push({
          ...responseData,
          meter: responseData2
        })
        if (data.firstNode) {
          this.toggleLoading()
          const [error3, response3, responseData3] = await handlePromise(NetworkNodeService.getGrapgWater(data.id))
          this.toggleLoading()
          if (!response3.ok) return this.$store.commit('settings/toggleAlert', this.$t(error3))
          this.chartDataTree = responseData3
        }
        this.searchMeterReadings()
      }
    },
    deleteMeter (id) {
      const index = findIndex(this.selectedNodes, { id })
      if (index !== -1) this.selectedNodes.splice(index, 1)

      if (!this.selectedNodes.length) {
        this.searchEnabled = false
      }
      this.searchMeterReadings()
    },
    async searchMeterReadings () {
      if (!this.search.period || !this.selectedNodes.length) {
        this.searchEnabled = false
        if (this.loading) this.toggleLoading()
        return
      }

      this.searchEnabled = true
      if (!this.loading) this.toggleLoading()
      const params = {
        limit: 0,
        period: this.search.period,
        meters: this.selectedNodes.map(item => item.meter.id)
      }

      const [error, response, data] = await handlePromise(MeterReadingForecastService.getMeterReadingsByCriteriaWater(params))
      if (this.loading) this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
      this.readings = data.data

      // this.chartData.xAxis = _.chain(data.data).uniqBy('date').map(item => moment(new Date(Number(`${item.date}000`))).format('DD/MM')).value()
      /* this.chartData.xAxis = {
        type: 'time',
        boundaryGap: false,
        axisLabel: {
          formatter: function (value) {
            return value
          }
        }
      } */
      const chartData = []
      _.chain(data.data).groupBy('meter.serialNumber').map((meters, serialNumber) => {
        chartData.push({
          name: serialNumber,
          type: 'line',
          smooth: true,
          data: _.map(meters, m => [new Date(moment.utc(Number(`${m.date}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00'), m.consumptionForecast])
        })
      }).value()
      this.chartData.lines = chartData
      setTimeout(() => {
        // let aux1 = moment.utc(Number(`${1610669945}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00'
        // let aux2 = moment.utc(Number(`${1612052345}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00'

        /* console.log(chartData, 'chartData')
        let historyRefuelling = [[new Date(moment.utc(Number(`${1610669945}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00'), 5], [new Date(aux2), 7]]

        let historyTheft = [
          [new Date(aux1), 1], [new Date(aux2), 2]
        ] */
        this.chart = echarts.init(document.getElementById('chart'))
        this.chartOptions = {
          ...this.chartOptions,
          series: chartData
        }
        this.chart.setOption(this.chartOptions, true)
      }, 100)
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
