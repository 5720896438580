import api from '@/config/Api'

const baseEndpoint = '/network/network-unit'
const baseEndpointWater = '/network/network-unit-water'

export default {
  getMeter (id) {
    return api.get(`${baseEndpoint}/${id}/meter`)
  },

  getMeterWater (id) {
    return api.get(`${baseEndpointWater}/${id}/meter`)
  },

  getMeterBySearch (params) {
    return api.get(`/network/meter/by-criteria`, params)
  },

  getMeterBySearchWater (params) {
    return api.get(`/network/meter-water/by-criteria`, params)
  },

  updateMeter (id, meterId, data) {
    return api.put(`${baseEndpoint}/${id}/meter/${meterId}`, data)
  },

  updateMeterWater (id, meterId, data) {
    return api.put(`${baseEndpointWater}/${id}/meter/${meterId}`, data)
  },

  replaceMeter (networkUnitId, meterId, data) {
    return api.post(`${baseEndpoint}/${networkUnitId}/meter/${meterId}`, data)
  },

  replaceMeterWater (networkUnitId, meterId, data) {
    return api.post(`${baseEndpointWater}/${networkUnitId}/meter/${meterId}`, data)
  },

  historyMeter (id) {
    return api.get(`${baseEndpoint}/${id}/meter-historical`)
  },

  historyMeterWater (id) {
    return api.get(`${baseEndpointWater}/${id}/meter-historical`)
  }
}
