const maneuverCommon = {
  methods: {
    getStatusBadgeType (status) {
      switch (status) {
        case 'draft':
          return 'secondary'
        case 'discard':
          return 'warning'
        case 'rejected':
        case 'error':
          return 'danger'
        case 'done':
          return 'success'
        default:
          return 'primary'
      }
    }
  }
}

export default maneuverCommon
