<template>
  <section>
    <ods-module>
      <template slot="header">
        <list-header title="metersVertical">
          <template slot="right">
            <ods-tooltip
              class="item"
              effect="dark"
              :content="$t('downloadTemplate')"
              placement="top"
              style="float: right"
            >
              <ods-button
                v-if="hasPermission"
                size="small"
                @click="downloadTemplate()"
                icon="ods-icon-download"
                circle
              />
            </ods-tooltip>
            <ods-upload
              v-if="hasPermission"
              style="float: right"
              ref="upload"
              :auto-upload="false"
              :show-file-list="false"
              :limit="1"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :on-change="uploadFile"
              :action="''"
            >
              <ods-tooltip
                class="item"
                effect="dark"
                :content="$t('import')"
                placement="top"
                style="float: right"
              >
                <ods-button
                  icon="ods-icon-upload"
                  size="small"
                  :loading="uploading"
                  circle
                />
              </ods-tooltip>
            </ods-upload>
            <ods-tooltip
              class="item"
              effect="dark"
              :content="$t('refresh')"
              placement="top"
              style="float: right"
            >
              <ods-button
                v-if="hasPermission"
                size="small"
                @click="getData(tableParams)"
                icon="ods-icon-refresh"
                circle
              />
            </ods-tooltip>
          </template>
        </list-header>
      </template>

      <async-table
        :data="data"
        :total="total"
        :loading="loading"
        page-start-zero
        @fetchData="getData"
        :defaultSort="{ prop: 'createdAt', order: 'descending' }"
      >
        <ods-table-column
          prop="name"
          :label="$t('name')"
          v-if="hasPermission"
          sortable
        >
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.id"
              :to="{ name: 'MeterImportVerticalDetail', params: { id: scope.row.id } }"
            >
              <ods-button type="text" class="p-0">{{
                scope.row.name
              }}</ods-button>
            </router-link>
          </template>
        </ods-table-column>
        <ods-table-column
          prop="name"
          :label="$t('name')"
          v-if="!hasPermission"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('importRow')" prop="importRow">
          <template slot-scope="scope" v-if="scope.row.importRow">
            {{ scope.row.importRow | formatNumber }}
          </template>
        </ods-table-column>
        <!--<ods-table-column prop="importRow" :label="$t('importRow')"/>-->
        <ods-table-column :label="$t('processRow')">
          <template slot-scope="scope" v-if="scope.row.processRow">
            {{ scope.row.processRow | formatNumber }}
          </template>
        </ods-table-column>
        <!--<ods-table-column prop="processRow" :label="$t('processRow')"/>-->
        <ods-table-column :label="$t('validRow')">
          <template slot-scope="scope" v-if="scope.row.validRow">
            {{ scope.row.validRow | formatNumber }}
          </template>
        </ods-table-column>
        <!--<ods-table-column prop="validRow" :label="$t('validRow')"/>-->
        <ods-table-column prop="createdAt" :label="$t('createdAt')" sortable>
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </ods-table-column>
        <ods-table-column prop="status" :label="$t('status')" sortable>
          <template slot-scope="scope">
            <ods-badge
              v-if="scope.row.status"
              :value="$t(scope.row.status).toUpperCase()"
              :type="getStatusBadgeType(scope.row.status)"
            />
          </template>
        </ods-table-column>

        <ods-table-column align="right" v-if="hasPermission">
          <template slot-scope="scope">
            <ods-button
              size="small"
              @click="downloadFile(scope.row.base64, scope.row.name)"
              type="text"
            >
              Download
            </ods-button>
          </template>
        </ods-table-column>
      </async-table>
    </ods-module>
  </section>
</template>

<script>
import ImportService from "@/services/ImportMeter";
import TemplateService from "@/services/Template";
import AsyncTable from "@/custom-components/AsyncTable";
import ListHeader from "@/custom-components/ListHeader";
import asyncTableCommon from "@/mixins/async-table";
import importCommon from "@/mixins/import";
import handlePromise from "@/utils/promise";
import { mapState } from "vuex";

export default {
  name: "MeterImportDashboard",
  components: {
    AsyncTable,
    ListHeader,
  },
  computed: {
    ...mapState({
      userRole: (state) => state.userRoles.data,
    }),
  },
  mounted() {
    this.handlePermission();
  },
  mixins: [asyncTableCommon, importCommon],
  data() {
    return {
      hasPermission: true,
      isAdmin: false,
      uploading: false,
      promise: (params) => ImportService.getImports(params),
    };
  },
  methods: {
    async uploadFile(file) {
      this.uploading = true;
      const [error, response] = await handlePromise(
        ImportService.importFile({
          file: file.raw,
          originalName: file.name,
        })
      );
      this.$refs.upload.clearFiles();
      this.uploading = false;
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));

      this.getData(this.tableParams);
      return this.$store.commit(
        "settings/toggleSuccessAlert",
        this.$t("fileImported")
      );
    },
    async downloadTemplate() {
      const params = {
        type: "meter",
      };
      const [error, response, data] = await handlePromise(
        TemplateService.downloadTemplate(params)
      );
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.downloadFile(data.base64, data.name);
    },
    downloadFile(base64, name) {
      const linkSource = base64;
      const downloadLink = document.createElement("a");
      const fileName = name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    handlePermission() {
      let rolesArray = Object.values(this.userRole);
      rolesArray.map((role) => {
        if (role === "ROLE_SUPER_ADMIN" || role === "ROLE_ADMIN") {
          this.isAdmin = true;
        }
        if (role === "ROLE_OPERATOR") {
          this.hasPermission = false;
          rolesArray.map((role2) => {
            if (role2 === "ROLE_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_SUPER_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_MANAGER") {
              this.hasPermission = true;
            }
          });
        }
      });
    },
  },
};
</script>
