<template>
  <section>
    <ods-module v-loading="loading">
      <template slot="header">
        <ods-row :gutter="20" type="flex">
          <ods-col :md="24">
            {{
              $route.params.id
                ? $t("editBalanceVertical")
                : $t("createBalanceVertical")
            }}
          </ods-col>
        </ods-row>
      </template>

      <ods-form :model="form" ref="form" v-loading="loading">
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item
              :label="$t('name')"
              prop="name"
              :rules="[errors.required]"
            >
              <ods-input v-model="form.name"></ods-input>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item
              :label="$t('balanceType')"
              prop="balanceType"
              :rules="[errors.required]"
            >
              <ods-select
                v-model="form.balanceType"
                @change="handleBalanceTypeChange"
                placeholder=""
                remote
              >
                <ods-option
                  v-for="item in balanceTypes"
                  :key="item.id"
                  :label="`${item.description} (${item.code})`"
                  :value="item.value"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item :label="$t('region')" prop="region">
              <ods-select
                v-model="form.region"
                placeholder=""
                remote
                clearable
                :disabled="form.balanceType !== 'Balanço de distribuição'"
              >
                <ods-option
                  v-for="item in regions"
                  :key="item.id"
                  :label="`${item.description} (${item.code})`"
                  :value="item.value"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item
              :label="$t('transportationDivision')"
              prop="transportDivision"
            >
              <ods-select
                v-model="form.transportDivision"
                placeholder=""
                remote
                clearable
                :disabled="form.balanceType !== 'Balanço de transporte'"
              >
                <ods-option
                  v-for="item in transportationDivisions"
                  :key="item.id"
                  :label="`${item.description} (${item.code})`"
                  :value="item.value"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item
              :label="$t('commercialOffice')"
              prop="commercialOffice"
            >
              <ods-select
                v-model="form.commercialOffice"
                placeholder=""
                remote
                clearable
              >
                <ods-option
                  v-for="item in commercialOffices"
                  :key="item.id"
                  :label="`${item.description} (${item.code})`"
                  :value="item.value"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item :label="$t('activationDate')" prop="activationDate">
              <ods-date-picker
                type="date"
                v-model="form.activationDate"
                style="width: 100%"
              />
            </ods-form-item>
          </ods-col>

          <ods-col :md="12" v-if="$route.params.id">
            <ods-form-item
              :label="$t('deactivationDate')"
              prop="deactivationDate"
            >
              <ods-date-picker
                type="date"
                v-model="form.deactivationDate"
                style="width: 100%"
              />
            </ods-form-item>
          </ods-col>

          <ods-col :md="12" class="mt-1">
            <ods-form-item label="" prop="status">
              <ods-checkbox v-model="form.status" :label="$t('active')">
              </ods-checkbox>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="20" style="margin-bottom: 20px">
          <ods-col :md="12">
            <fieldset>
              <legend>{{ $t("entries") }}</legend>
              <ods-form-item
                :label="$t('elementTypes')"
                prop="elementsTypeInput"
              >
                <ods-select
                  v-model="form.elementsTypeInput"
                  placeholder=""
                  remote
                  clearable
                  multiple
                  @change="handleElementsTypeInputChange"
                >
                  <ods-option
                    v-for="item in elementTypes"
                    :key="item.id"
                    :label="`${item.name} (${item.elementType})`"
                    :value="item.elementType"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item>

              <ods-form-item
                v-if="
                  form.elementsTypeInput &&
                  form.elementsTypeInput.length > 0 &&
                  form.elementsTypeInput.includes('BPO')
                "
                :label="$t('borderType')"
                prop="borderPointsInput"
              >
                <ods-select
                  v-model="form.borderPointsInput"
                  placeholder=""
                  remote
                  clearable
                >
                  <ods-option
                    v-for="item in borderPoints"
                    :key="item.id"
                    :label="`${item.description} (${item.code})`"
                    :value="item.value"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item>

              <ods-form-item
                :label="$t('generationTypes')"
                prop="generatorsTypeInput"
              >
                <ods-select
                  v-model="form.generatorsTypeInput"
                  placeholder=""
                  remote
                  clearable
                  multiple
                >
                  <ods-option
                    v-for="item in generationTypes"
                    :key="item.id"
                    :label="`${item.description} (${item.code})`"
                    :value="item.value"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item>
            </fieldset>
          </ods-col>
          <ods-col :md="12">
            <fieldset>
              <legend>{{ $t("outs") }}</legend>
              <ods-form-item
                :label="$t('elementTypes')"
                prop="elementsTypeOutput"
              >
                <ods-select
                  v-model="form.elementsTypeOutput"
                  placeholder=""
                  remote
                  clearable
                  multiple
                  @change="handleElementsTypeOutputChange"
                >
                  <ods-option
                    v-for="item in elementTypes"
                    :key="item.id"
                    :label="`${item.name} (${item.elementType})`"
                    :value="item.elementType"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item>

              <ods-form-item
                v-if="
                  form.elementsTypeOutput &&
                  form.elementsTypeOutput.length > 0 &&
                  form.elementsTypeOutput.includes('BPO')
                "
                :label="$t('borderType')"
                prop="borderPointsInput"
              >
                <ods-select
                  v-model="form.borderPointsOutput"
                  placeholder=""
                  remote
                  clearable
                >
                  <ods-option
                    v-for="item in borderPoints"
                    :key="item.id"
                    :label="`${item.description} (${item.code})`"
                    :value="item.value"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item>

              <ods-form-item
                :label="$t('clientTypes')"
                prop="clientsTypeOutput"
              >
                <ods-select
                  v-model="form.clientsTypeOutput"
                  placeholder=""
                  remote
                  clearable
                  multiple
                >
                  <ods-option
                    v-for="item in clientTypes"
                    :key="item.id"
                    :label="`${item.description} (${item.code})`"
                    :value="item.value"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item>

              <!-- <ods-form-item
                :label="$t('generationTypes')"
                prop="generatorsTypeOutput"
              >
                <ods-select
                  v-model="form.generatorsTypeOutput"
                  placeholder=""
                  remote
                  clearable
                  multiple
                >
                  <ods-option
                    v-for="item in generationTypes"
                    :key="item.id"
                    :label="`${item.description} (${item.code})`"
                    :value="item.value"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item> -->
            </fieldset>
          </ods-col>
        </ods-row>

        <form-buttons
          @submit="submitForm"
          cancel-to="BalanceManagementVertical"
          cancelText="back"
        ></form-buttons>
      </ods-form>
    </ods-module>
  </section>
</template>

<script>
import _ from "lodash";
import BalanceService from "@/services/Balance";
import NetworkElementService from "@/services/NetworkElement";
import LovService from "@/services/Lov";
import handlePromise from "@/utils/promise";
import errors from "@/config/InputErrors";
import FormButtons from "@/custom-components/Buttons/FormButtons";

export default {
  name: "BalanceManagementEdit",
  components: {
    FormButtons,
  },
  mounted: function () {
    this.getBalanceTypes();
    this.getElementTypes();
    this.getClientTypes();
    this.getGenerationTypes();
    this.getRegions();
    this.getTransportationDivisions();
    this.getCommercialOffices();
    this.getBorderPoints();
    if (this.$route.params.id) {
      this.getData();
    }
  },
  data() {
    return {
      loading: false,
      form: {},
      elementTypes: [],
      clientTypes: [],
      generationTypes: [],
      regions: [],
      transportationDivisions: [],
      commercialOffices: [],
      borderPoints: [],
      balanceTypes: [],
      errors,
      submitErrors: {},
      formLoading: false,
    };
  },
  methods: {
    async getBalanceTypes() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("BT")
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.balanceTypes = data.lovValues;
    },
    async getElementTypes() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        NetworkElementService.getElements()
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.elementTypes = data.data;
    },
    async getClientTypes() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("CT")
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.clientTypes = data.lovValues;
    },
    async getGenerationTypes() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("GT")
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.generationTypes = data.lovValues;
    },
    async getRegions() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("R")
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.regions = data.lovValues;
    },
    async getTransportationDivisions() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("ETD")
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.transportationDivisions = data.lovValues;
    },
    async getCommercialOffices() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("NCO")
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.commercialOffices = data.lovValues;
    },
    async getBorderPoints() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("BPT")
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.borderPoints = data.lovValues;
    },
    async getData() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        BalanceService.getConfigurationBalance(this.$route.params.id)
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.form = {
        name: data.name,
        region: data.region,
        balanceType: data.balanceType,
        transportDivision: data.transportDivision,
        commercialOffice: data.commercialOffice,
        activationDate: data.activationDate,
        deactivationDate: data.deactivationDate,
        status: data.status === "active",
        elementsTypeInput: data.elementsInput,
        borderPointsInput: data.borderTypeInput,
        generatorsTypeInput: data.generatorsInput,
        elementsTypeOutput: data.elementsOutput,
        clientsTypeOutput: data.clientsOutput,
        borderPointsOutput: data.borderTypeOutput,
        // generatorsTypeOutput: data.generatorsOutput
      };
    },
    handleBalanceTypeChange() {
      console.log(this.form, "Form");
      if (this.form.balanceType) {
        if (this.form.balanceType === "Balanço da empresa") {
          this.form = { ...this.form, region: null, transportDivision: null };
        }
        if (this.form.balanceType === "Balanço de transporte") {
          this.form = { ...this.form, region: null };
        }
        if (this.form.balanceType === "Balanço de distribuição") {
          this.form = { ...this.form, transportDivision: null };
        }
      }
      console.log(this.form, "Form2");
    },
    handleElementsTypeInputChange() {
      if (this.form.elementsTypeInput.includes("BPO")) return;
      this.form = { ...this.form, borderPointsInput: null };
    },
    handleElementsTypeOutputChange() {
      if (this.form.elementsTypeOutput.includes("BPO")) return;
      this.form = { ...this.form, borderPointsOutput: null };
    },
    async submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;

          let toSend = {
            name: this.form.name,
            region: this.form.region,
            balanceType: this.form.balanceType,
            transportDivision: this.form.transportDivision,
            commercialOffice: this.form.commercialOffice,
            activationDate: this.form.activationDate,
            deactivationDate: this.form.deactivationDate,
            status: this.form.status ? "active" : "inactive",
            elementsTypeInput: this.form.elementsTypeInput,
            borderTypeInput: this.form.borderPointsInput,
            generatorsTypeInput: this.form.generatorsTypeInput,
            elementsTypeOutput: this.form.elementsTypeOutput,
            clientsTypeOutput: this.form.clientsTypeOutput,
            borderTypeOutput: this.form.borderPointsOutput,
            // generatorsTypeOutput: this.form.generatorsTypeOutput
          };

          if (this.$route.params.id) {
            toSend = {
              name: this.form.name,
              region: this.form.region,
              balanceType: this.form.balanceType,
              transportDivision: this.form.transportDivision,
              commercialOffice: this.form.commercialOffice,
              activationDate: this.form.activationDate,
              deactivationDate: this.form.deactivationDate,
              status: this.form.status ? "active" : "inactive",
              elementsTypeInput: this.form.elementsTypeInput,
              borderTypeInput: this.form.borderPointsInput,
              generatorsTypeInput: this.form.generatorsTypeInput,
              elementsTypeOutput: this.form.elementsTypeOutput,
              clientsTypeOutput: this.form.clientsTypeOutput,
              borderTypeOutput: this.form.borderPointsOutput,
              // generatorsTypeOutput: this.form.generatorsTypeOutput
            };
          }

          this.loading = false;
          const [error, response, responseData] = await handlePromise(
            this.$route.params.id
              ? BalanceService.updateConfigurationBalance(
                  this.$route.params.id,
                  toSend
                )
              : BalanceService.saveConfigurationBalance(toSend)
          );
          this.loading = false;
          if (!response.ok) {
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          }

          return this.$router.push({ name: "BalanceManagementVertical" });
          // return this.$router.push(
          //   this.$route.params.id
          //     ? { name: "BalanceManagement" }
          //     : { name: "BalanceEdit", params: { id: responseData.id } }
          // );
        }
      });
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
  },
};
</script>
<style scoped>
fieldset {
  margin: 0px;
  border: 1px solid silver;
  padding: 8px;
  border-radius: 4px;
}

legend {
  padding: 2px;
}
</style>
