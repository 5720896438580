<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
             {{$t('networkElementDetail')}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
        <ods-module v-loading="loading">
          <section class="modal-body">
              <slot name="body">
                  <ods-row :gutter="20" style="font-size:120%">
                          <ods-col :md="24">
                              {{$t('name')}}: {{form.name ? form.name : '---'}}
                          </ods-col>
                          <ods-col :md="24">
                              {{$t('technicalLosses')}}: {{form.technicalLosses ? form.technicalLosses : '---'}}
                          </ods-col>
                          <ods-col :md="24">
                              {{$t('originSystemId')}}: {{form.originSystemId ? form.originSystemId : '---'}}
                          </ods-col>
                          <ods-col :md="24">
                              {{$t('elementType')}}: {{form.elementType ? form.elementType : '---'}}
                          </ods-col>
                  </ods-row>
              </slot>
          </section>
        </ods-module>
        </div>
    </div>
  </transition>
</template>

<script>
import errors from '@/config/InputErrors'
import StrategicService from '@/services/Strategic'
import handlePromise from '@/utils/promise'

export default {
  name: 'CreateStegicPlanModal',
  props: {
    valueToDetail: {
      type: String,
      default: null
    },
    plan: String
  },
  mounted: function () {
    if (this.valueToDetail != null) { this.getValue() }
  },
  data () {
    return {
      loading: false,
      errors,
      form: {}
    }
  },
  methods: {
    async getValue () {
      this.loading = true

      const [error, response, data] = await handlePromise(StrategicService.getActivity(this.plan, this.valueToDetail))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        name: data.networkNode.networkUnit.name,
        technicalLosses: data.networkNode.networkUnit.technicalLosses,
        originSystemId: data.networkNode.networkUnit.originSystemId,
        elementType: data.networkNode.networkUnit.networkElement.name
      }
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 40%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 20vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
