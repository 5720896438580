<template>
  <section v-loading="loading">
        <ods-module :header="$t('measurementBulkValidation')">
          <ods-form :model="search" ref="form">
            <ods-row :gutter="20" type="flex" align="center">
                <ods-col :md="6">
                  <ods-form-item :label="$t('period')" prop="period">
                    <ods-select v-model="search.period" placeholder="" remote clearable>
                      <ods-option
                          v-for="item in periods"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                      </ods-option>
                    </ods-select>
                  </ods-form-item>
                </ods-col>

                <ods-col :md="12">
                   <ods-form-item :label="$t('anomalies')" prop="anomalies">
                    <ods-select v-model="search.anomalies" placeholder="" multiple>
                      <ods-option
                          v-for="item in anomalies"
                          :key="item.id"
                          :label="$t(item.name)"
                          :value="item.id"
                      >
                      </ods-option>
                    </ods-select>
                  </ods-form-item>
                </ods-col>

                <ods-col :md="4">
                  <ods-button type="primary" @click="searchMeterReadings">
                    {{ $t('search') }}
                  </ods-button>
                </ods-col>

            </ods-row>
          </ods-form>
        </ods-module>

        <validation-readings
            v-if="searchEnabled"
            :meter="selectedMeter"
            :data="readings"
            :params ="params"
            :hasPermission=hasPermission
            @reload-data="searchMeterReadings"
        />
  </section>
</template>

<script>
import MeterReadingService from '@/services/MeterReading'
import PeriodService from '@/services/Period'
import handlePromise from '@/utils/promise'
import ValidationReadings from './Reading'
import errors from '@/config/InputErrors'
import { mapState } from 'vuex'

export default {
  name: 'MeasurementBulkValidation',
  components: {
    ValidationReadings
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.fetchActivePeriod()
    this.fetchPeriods()

    this.handlePermission()
  },
  data () {
    return {
      params: {},
      hasPermission: true,
      loading: false,
      searchEnabled: false,
      selectedMeter: null,
      search: {},
      periods: [],
      lastReportDate: null,
      validateEmptyReading: null,
      bulkValidation: null,
      bulkEstimation: null,
      readings: [],
      anomalies: [
        { id: 'highEstimationStatus', name: 'highEstimation' },
        { id: 'highDeviationStatus', name: 'highDeviation' },
        { id: 'lowEstimationStatus', name: 'lowEstimation' },
        { id: 'noReadingStatus', name: 'noReading' },
        { id: 'potentialTurnoverStatus', name: 'potentialTurnover' },
        { id: 'readingBoundsStatus', name: 'readingBounds' },
        { id: 'consumptionBoundsStatus', name: 'consumptionBounds' }
      ],
      errors
    }
  },
  methods: {
    async fetchPeriods () {
      const [, response, responseData] = await handlePromise(PeriodService.getPeriods({ limit: 0 }))
      if (response.ok) this.periods = responseData.data
    },
    async fetchActivePeriod () {
      const [, response, responseData] = await handlePromise(PeriodService.getActivePeriods({ limit: 0 }))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t('activePeriodsNotFound'))
      this.search = {
        period: responseData.id
      }
      this.searchMeterReadings()
    },
    async bulkValidations () {
      const [error, response] = await handlePromise(MeterReadingService.bulkValidation())
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }

      return this.$store.commit('settings/toggleSuccessAlert', this.$t('bulkValidationStarted'))
    },
    async searchMeterReadings () {
      this.$refs['form'].validate(async (valid) => {
        this.searchEnabled = false
        if (!valid && this.loading) this.toggleLoading()

        if (valid) {
          if (!this.loading) this.toggleLoading()
          this.params = {}
          if ((this.search.period || this.search.period !== '') && this.search.anomalies) {
            this.params = {
              'limit': 0,
              'periods[]': this.search.period,
              anomalies: this.search.anomalies.map(item => item)
            }
          }
          if ((this.search.period || this.search.period !== '') && !this.search.anomalies) {
            this.params = {
              'limit': 0,
              'periods[]': this.search.period
            }
          }
          if ((!this.search.period || this.search.period === '') && this.search.anomalies) {
            this.params = {
              'limit': 0,
              anomalies: this.search.anomalies.map(item => item)
            }
          }
          if ((!this.search.period || this.search.period === '') && !this.search.anomalies) {
            this.params = {
              'limit': 0
            }
          }
          const [error, response, data] = await handlePromise(MeterReadingService.getMeterReadingsByCriteria(this.params))
          if (this.loading) this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.searchEnabled = true
          this.readings = data
        }
      })
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
