import api from '@/config/Api'

const baseEndpoint = '/network/brand'

export default {
  getBrands (params) {
    return api.get(baseEndpoint, params)
  },

  getBrand (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  saveBrand (data) {
    return api.post(baseEndpoint, data)
  },

  updateBrand (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  deleteBrand (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  getModelsByBrand (id) {
    return api.get(`${baseEndpoint}/${id}/models`)
  },

  getModel (id, modelId) {
    return api.get(`${baseEndpoint}/${id}/model/${modelId}`)
  },

  deleteModel (id, modelId) {
    return api.delete(`${baseEndpoint}/${id}/model/${modelId}`)
  },

  updateModel (id, modelId, data) {
    return api.put(`${baseEndpoint}/${id}/model/${modelId}`, data)
  },

  saveModel (id, data) {
    return api.post(`${baseEndpoint}/${id}/model`, data)
  }
}
