import api from '@/config/Api'

const baseEndpoint = '/network/network-tree-historical'
const baseEndpoint2 = '/network/network-tree-water-historical'

export default {
  getHistory (params) {
    return api.get(baseEndpoint, params)
  },

  getNodeHistory (id) {
    return api.get(`${baseEndpoint}/node/${id}`)
  },

  getNodeHistoryWater (id) {
    return api.get(`${baseEndpoint2}/node/${id}`)
  },

  getRootHistory (code) {
    return api.get(code ? `${baseEndpoint}/${code}` : baseEndpoint)
  },

  getRootHistoryWater (code) {
    return api.get(code ? `${baseEndpoint2}/${code}` : baseEndpoint2)
  }
}
