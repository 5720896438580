<template>
  <section>
    <ods-module :header="$t('editNetworkElement')">
      <ods-form
          :model="form"
          ref="form"
          v-loading="loading"
      >
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item
                :label="$t('name')"
                prop="name"
                :rules="[errors.required]"
            >
              <ods-input v-model="form.name"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
                :label="$t('type')"
                prop="elementType"
                :rules="[errors.required]"
            >
              <ods-input v-model="form.elementType" disabled></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('description')" prop="description">
              <ods-input v-model="form.description" type="textarea"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
                :label="$t('order')"
                prop="order"
                :rules="[errors.required]"
            >
              <ods-input v-model="form.order" disabled></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('technicalLossesPercent')" prop="technicalLosses">
              <ods-input v-model="form.technicalLosses" type="number"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <form-buttons
            @submit="submitForm"
            cancel-to="NetworkElementDashboard"
        ></form-buttons>
      </ods-form>
    </ods-module>

   <!-- <network-element-attributes
        :element="$route.params.id">
    </network-element-attributes>-->
  </section>
</template>

<script>
import NetworkElementService from '@/services/NetworkElement'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'

export default {
  name: 'NetworkElementEdit',
  components: {
    FormButtons
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      errors
    }
  },
  methods: {
    async getData () {
      this.loading = true

      const [error, response, data] = await handlePromise(
        NetworkElementService.getElement(this.$route.params.id))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.form = {
        name: data.name,
        description: data.description,
        order: data.order,
        elementType: data.elementType,
        technicalLosses: data.technicalLosses
      }
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid && this.$route.params.id) {
          this.loading = true

          const data = {
            name: this.form.name,
            description: this.form.description,
            technicalLosses: this.form.technicalLosses
          }
          const [error, response] = await handlePromise(
            NetworkElementService.updateElement(this.$route.params.id, data)
          )
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
          return this.$router.push({ name: 'NetworkElementDashboard' })
        }
      })
    }
  }
}
</script>
