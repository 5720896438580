<template>
  <section v-loading="loading">
    <ods-module class="mt-1" :header="$t('network')">
      <ods-form :model="form" ref="form">
        <ods-row :gutter="20">
          <ods-col :md="8">
            <ods-form-item :label="$t('voltageType')" prop="voltageType">
              <ods-select v-model="form.voltageType" clearable>
                <ods-option
                  v-for="item in voltages"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="8">
            <ods-form-item
              :label="$t('transportationDivision')"
              prop="transportDivision"
            >
              <ods-select v-model="form.transportDivision" clearable>
                <ods-option
                  v-for="item in transportes"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="8">
            <ods-form-item :label="$t('region')" prop="region">
              <ods-select v-model="form.region" clearable>
                <ods-option
                  v-for="item in regiones"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="8">
            <ods-form-item label="ASC" prop="asc">
              <ods-select v-model="form.asc" clearable>
                <ods-option
                  v-for="item in ascs"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="8">
            <ods-form-item :label="$t('generationType')" prop="generationType">
              <ods-select v-model="form.generationType" clearable>
                <ods-option
                  v-for="item in generations"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="8">
            <ods-form-item :label="$t('clientType')" prop="clientType">
              <ods-select v-model="form.clientType" clearable>
                <ods-option
                  v-for="item in clients"
                  :key="item.id"
                  :label="item.description"
                  :value="item"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="8">
            <ods-form-item :label="$t('networkElement')" prop="networkElement">
              <ods-select v-model="form.networkElement" clearable>
                <ods-option
                  v-for="item in networkElements"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
      </ods-form>

      <div class="actionContainer">
        <ods-button
          type="primary"
          class="ml-1"
          :circle="true"
          @click="clearFilters"
          icon="ods-icon-eraser"
        />
        <ods-button
          type="primary"
          class="ml-1"
          :circle="true"
          @click="toggleDialog()"
          icon="ods-icon-plus"
        />
        <ods-button
          type="primary"
          class="ml-1"
          :circle="true"
          @click="getData"
          icon="ods-icon-search"
        />
      </div>

      <ods-table
        :data="data"
        v-loading="loading"
        @sort-change="handleSort"
        size="mini"
        style="width: 100%"
      >
        <ods-table-column prop="name" :label="$t('name')" width="150">
          <template slot-scope="scope">
            {{ scope.row.name ? scope.row.name : "---" }}
          </template>
        </ods-table-column>
        <ods-table-column
          prop="tension_type"
          :label="$t('voltageType')"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.tension_type ? scope.row.tension_type : "---" }}
          </template>
        </ods-table-column>
        <ods-table-column
          prop="energy_transport_division"
          :label="$t('transportationDivision')"
          width="300"
        >
          <template slot-scope="scope">
            {{
              scope.row.energy_transport_division
                ? scope.row.energy_transport_division
                : "---"
            }}
          </template>
        </ods-table-column>
        <ods-table-column prop="region" :label="$t('region')" width="150">
          <template slot-scope="scope">
            {{ scope.row.region ? scope.row.region : "---" }}
          </template>
        </ods-table-column>
        <ods-table-column prop="commercial_office" label="ASC" width="150">
          <template slot-scope="scope">
            {{
              scope.row.commercial_office ? scope.row.commercial_office : "---"
            }}
          </template>
        </ods-table-column>
        <ods-table-column
          prop="generation_type"
          :label="$t('generationType')"
          width="250"
        >
          <template slot-scope="scope">
            {{ scope.row.generation_type ? scope.row.generation_type : "---" }}
          </template>
        </ods-table-column>
        <ods-table-column
          prop="customer_type"
          :label="$t('clientType')"
          width="350"
        >
          <template slot-scope="scope">
            {{ scope.row.customer_type ? scope.row.customer_type : "---" }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('actions')" width="200">
          <template slot-scope="scope">
            <ods-button
              size="mini"
              type="warning"
              icon="ods-icon-pencil"
              @click="toggleDialog(scope.row)"
            />
            <ods-button
              size="mini"
              type="danger"
              icon="ods-icon-delete"
              @click="toggleDeleteDialog(scope.row)"
            />
          </template>
        </ods-table-column>
      </ods-table>
      <ods-pagination
        :total="total"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        layout="prev, pager, next, jumper, sizes, ->, total"
      >
      </ods-pagination>
      <EditNetworkElement
        width="60%"
        :dialogVisible="dialogVisible"
        @cancel="toggleDialog"
        @submit="handleDialogSubmit"
        :borderTypes="borderTypes"
        :voltages="voltages"
        :transportes="transportes"
        :regiones="regiones"
        :ascs="ascs"
        :generations="generations"
        :clients="clients"
        :networkElements="networkElements"
        :toEdit="toEdit"
      />

      <DeleteConfirm
        :visible="deleteConfirmation"
        :loading="loading"
        @confirm="deleteItem"
        @cancel="toggleDeleteDialog()"
      />
    </ods-module>
  </section>
</template>

<script>
import handlePromise from "@/utils/promise";
import NetworkService from "@/services/NetworkUnit";
import NetworkNodeService from "@/services/NetworkNode";
import NetworkElementService from "@/services/NetworkElement";
import LovService from "@/services/Lov";
import map from "lodash/map";
import errors from "@/config/InputErrors";
import ListHeader from "@/custom-components/ListHeader";
import DeleteConfirm from "../../../custom-components/DialogConfirmation.vue";
import EditNetworkElement from "./EditDialog.vue";

export default {
  name: "Payments",
  props: {
    params: Object,
  },
  components: {
    ListHeader,
    DeleteConfirm,
    EditNetworkElement,
  },
  data() {
    return {
      loading: false,
      form: {},
      data: [],
      voltages: [],
      transportes: [],
      regiones: [],
      ascs: [],
      generations: [],
      clients: [],
      networkElements: [],
      borderTypes: [],
      pageSize: 10,
      pageIndex: 0,
      pageStartZero: true,
      dialogVisible: false,
      toEdit: null,
      deleteConfirmation: false,
      toDelete: null,
      total: 0,
      sort: null,
      errors,
    };
  },
  mounted() {
    var self = this;
    addEventListener("keypress", function (e) {
      if (e.key === "Enter") {
        self.getData();
      }
    });
    this.getData();

    this.getBorderTypes();
    this.getVoltages();
    this.getTransports();
    this.getRegions();
    this.getASCs();
    this.getGenerations();
    this.getClients();
    this.getNetworkElements();
  },
  methods: {
    async getBorderTypes() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("BPT")
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.borderTypes = data.lovValues;
    },
    async getVoltages() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("TV")
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.voltages = data.lovValues;
    },
    async getTransports() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("ETD")
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.transportes = data.lovValues;
    },
    async getRegions() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("R")
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.regiones = data.lovValues;
    },
    async getASCs() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("NCO")
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.ascs = data.lovValues;
    },
    async getGenerations() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("GT")
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.generations = data.lovValues;
    },
    async getClients() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        LovService.getLov("CT")
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.clients = data.lovValues;
    },
    async getNetworkElements() {
      const [, response, data] = await handlePromise(
        NetworkElementService.getElements({ limit: 0, "filters[networkType]": "horizontal" })
      );
      if (response.ok) {
        this.networkElements = data.data;
      }
    },
    clearFilters() {
      this.form = {};
    },
    async getData() {
      let auxParams = {};

      if (this.form.name) {
        auxParams = {
          "filters[name]": this.form.name,
          ...auxParams,
        };
      }

      if (this.form.voltageType) {
        auxParams = {
          "filters[tensionType]": this.form.voltageType.id,
          ...auxParams,
        };
      }

      if (this.form.transportDivision) {
        auxParams = {
          "filters[energyTransportDivision]": this.form.transportDivision.id,
          ...auxParams,
        };
      }

      if (this.form.region) {
        auxParams = {
          "filters[region]": this.form.region.id,
          ...auxParams,
        };
      }

      if (this.form.asc) {
        auxParams = {
          "filters[commercialOffice]": this.form.asc.id,
          ...auxParams,
        };
      }

      if (this.form.generationType) {
        auxParams = {
          "filters[generationType]": this.form.generationType.id,
          ...auxParams,
        };
      }

      if (this.form.clientType) {
        auxParams = {
          "filters[customerType]": this.form.clientType.id,
          ...auxParams,
        };
      }

      if (this.form.networkElement) {
        auxParams = {
          "filters[networkElement]": this.form.networkElement,
          ...auxParams,
        };
      }

      let params = {
        offset:
          this.pageIndex === 0 && this.pageStartZero
            ? 0
            : (this.pageIndex - 1) * this.pageSize,
        limit: this.pageSize,
        ...auxParams,
      };

      if (this.sort && this.sort.prop) {
        params[`sortby[${this.sort.prop}]`] =
          this.sort.order === "ascending" ? "ASC" : "DESC";
      }
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        NetworkService.getNetwork(params)
      );
      this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.data = data.data;
      this.total = data.total;
    },
    handleSort(val) {
      this.sort = { prop: val.prop, order: val.order };
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handlePageChange(val) {
      this.pageIndex = val;
      this.getData();
    },
    handleFilters(filters) {
      this.filters = {};
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter;
      });
      this.getData();
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    toggleDeleteDialog(row) {
      if (row) {
        this.toDelete = row;
      }

      this.deleteConfirmation = !this.deleteConfirmation;
    },
    async deleteItem() {
      this.toggleLoading();
      const [error, response] = await handlePromise(
        NetworkService.deleteNetworkUnit(this.toDelete.id)
      );
      if (this.loading) this.toggleLoading();
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.toggleDeleteDialog();
      this.getData();
      this.$store.commit(
        "settings/toggleSuccessAlert",
        this.$t("networkUnitDeletedSuccessfully")
      );
    },
    toggleDialog(row) {
      if (row) {
        this.toEdit = row;
      } else {
        this.toEdit = null;
      }
      this.dialogVisible = !this.dialogVisible;
    },
    handleDialogSubmit() {
      this.toEdit = null;
      this.getData();
      this.dialogVisible = !this.dialogVisible;
    },
  },
};
</script>
<style scoped>
.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
