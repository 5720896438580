import api from '@/config/Api'
import { formatFormDataBody } from '@/utils/format'

const baseEndpointList = '/network/import-files-meter'
const baseEndpoint = '/network/import-file-meter'

const baseEndpointListWater = '/network/import-water-files-meter'
const baseEndpointWater= '/network/import-water-file-meter'

export default {
  importFile (data) {
    return api.post(baseEndpoint, formatFormDataBody(data))
  },

  getImportFile (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  getImports (params) {
    return api.get(baseEndpointList, params)
  },

  importFileWater (data) {
    return api.post(baseEndpointWater, formatFormDataBody(data))
  },

  getImportFileWater (id) {
    return api.get(`${baseEndpointWater}/${id}`)
  },

  getImportsWater (params) {
    return api.get(baseEndpointListWater, params)
  }
}
