import api from '@/config/Api'
import env from 'my-env'

export default {
  login (data) {
    return api.post('oauth/v2/token', {
      ...data,
      client_id: env.VUE_APP_CLIENT_ID,
      client_secret: env.VUE_APP_CLIENT_SECRET,
      grant_type: 'password'
    })
  }
}
