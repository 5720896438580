var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.ranged && !_vm.hiddenInputs)?_c('ods-input',_vm._b({directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.handleClose),expression:"handleClose"}],ref:"reference",staticClass:"ods-date-editor",class:[
    'ods-date-editor--' + _vm.type,
    _vm.inputSize ? 'ods-input--' + _vm.inputSize : '',
    _vm.inputLabel ? 'ods-input--has-label' : ''
  ],attrs:{"label":_vm.inputLabel,"isWhite":_vm.isWhite,"data-name":"OdsDatePicker","readonly":!_vm.editable || _vm.readonly,"disabled":_vm.pickerDisabled,"name":_vm.name,"placeholder":_vm.placeholder,"value":_vm.displayValue,"validateEvent":false},on:{"focus":_vm.handleFocus,"input":function (value) { return _vm.userInput = value; },"change":_vm.handleChange},nativeOn:{"keydown":function($event){return _vm.handleKeydown.apply(null, arguments)},"mouseenter":function($event){return _vm.handleMouseEnter.apply(null, arguments)},"mouseleave":function($event){_vm.showClose = false}}},'ods-input',_vm.firstInputId,false),[(!_vm.hidePrefix)?_c('span',{staticClass:"ods-input__icon",class:_vm.triggerClass,attrs:{"slot":"prefix"},on:{"click":_vm.handleFocus},slot:"prefix"}):_vm._e(),(_vm.haveTrigger)?_c('span',{staticClass:"ods-input__icon",class:[!_vm.valueIsEmpty && _vm.clearable ? '' + _vm.clearIcon : ''],attrs:{"slot":"suffix"},on:{"click":_vm.handleClickIcon},slot:"suffix"}):_vm._e()]):(_vm.ranged && !_vm.hiddenInputs)?_c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.handleClose),expression:"handleClose"}],ref:"reference",staticClass:"ods-date-editor ods-range-editor ods-input",class:[
    { 'ods-input--white': _vm.isWhite },
    'ods-date-editor--' + _vm.type,
    _vm.pickerSize ? ("ods-range-editor--" + _vm.pickerSize) : '',
    _vm.pickerDisabled ? 'is-disabled' : '',
    _vm.pickerVisible ? 'is-active' : '' ],on:{"click":_vm.handleRangeClick,"mouseenter":_vm.handleMouseEnter,"mouseleave":function($event){_vm.showClose = false},"keydown":_vm.handleKeydown}},[(_vm.icon)?_c('span',{class:['ods-input__icon', 'ods-range__icon', _vm.triggerClass]}):_vm._e(),_c('input',_vm._b({staticClass:"ods-input__inner",attrs:{"autoComplete":"off","placeholder":_vm.startPlaceholder,"disabled":_vm.pickerDisabled,"readonly":!_vm.editable || _vm.readonly,"name":_vm.name && _vm.name[0]},domProps:{"value":_vm.displayValue && _vm.displayValue[0]},on:{"input":_vm.handleStartInput,"change":_vm.handleStartChange,"focus":_vm.handleFocus}},'input',_vm.firstInputId,false)),_c('span',{staticClass:"ods-range-separator"},[_vm._v(_vm._s(_vm.rangeSeparator))]),_c('input',_vm._b({staticClass:"ods-input__inner",attrs:{"autoComplete":"off","placeholder":_vm.endPlaceholder,"disabled":_vm.pickerDisabled,"readonly":!_vm.editable || _vm.readonly,"name":_vm.name && _vm.name[1]},domProps:{"value":_vm.displayValue && _vm.displayValue[1]},on:{"input":_vm.handleEndInput,"change":_vm.handleEndChange,"focus":_vm.handleFocus}},'input',_vm.secondInputId,false)),(_vm.haveTrigger && _vm.clearable)?_c('span',{staticClass:"ods-input__icon ods-range__close-icon",class:[!_vm.valueIsEmpty ? '' + _vm.clearIcon : ''],on:{"click":_vm.handleClickIcon}}):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }