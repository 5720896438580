import api from '@/config/Api'

const baseEndpoint = '/network'

export default {
  getMyManeuvers () {
    return api.get(`${baseEndpoint}/network-maneuver-me`)
  },

  getManeuvers (params) {
    return api.get(`${baseEndpoint}/network-maneuver`, params)
  },

  getMyManeuversWater (params) {
    return api.get(`${baseEndpoint}/network-water-maneuver-me`, params)
  },

  getManeuversWater (params) {
    return api.get(`${baseEndpoint}/network-water-maneuver`, params)
  },

  getManeuver (id) {
    return api.get(`${baseEndpoint}/network-maneuver/${id}`)
  },

  getManeuverWater (id) {
    return api.get(`${baseEndpoint}/network-water-maneuver/${id}`)
  },

  updateManeuver (id, data) {
    return api.put(`${baseEndpoint}/network-maneuver/${id}`, data)
  },

  updateManeuverWater (id, data) {
    return api.put(`${baseEndpoint}/network-water-maneuver/${id}`, data)
  },

  executeManeuvers () {
    return api.get(`${baseEndpoint}/network-maneuver/approved/execute`)
  },

  executeManeuversWater () {
    return api.get(`${baseEndpoint}/network-water-maneuver/approved/execute`)
  }
}
