<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
             {{valueToEdit === null ? $t('createActivity') : $t('editActivity')}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
        <ods-module v-loading="loading">
          <section class="modal-body">
              <slot name="body">
                  <ods-form
                      :model="form"
                      ref="form"
                      v-loading="formLoading"
                  >
                    <ods-row :gutter="20">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('networkElement')" prop="networkElement"
                                :rules="[errors.required]">
                            <ods-select v-model="form.networkElement" placeholder="">
                                <ods-option
                                    v-for="item in nodes"
                                    :key="item.id"
                                    :label="item.networkUnit.name"
                                    :value="item.id"
                                >
                                </ods-option>
                            </ods-select>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('action')" prop="action" :rules="[errors.required]">
                            <ods-select v-model="form.action" placeholder="">
                                <ods-option
                                    v-for="item in actions"
                                    :key="item.id"
                                    :label="$t(item.value)"
                                    :value="item.code"
                                >
                                </ods-option>
                            </ods-select>
                        </ods-form-item>
                      </ods-col>
                    </ods-row>
                    <ods-row :gutter="20">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('result')" prop="result" :rules="[errors.required]">
                            <ods-select v-model="form.result" placeholder="">
                                <ods-option
                                    v-for="item in results"
                                    :key="item.id"
                                    :label="$t(item.name)"
                                    :value="item.id"
                                >
                                </ods-option>
                            </ods-select>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('expectedRealCost')" prop="estimatedRealCost" :rules="[errors.required]">
                          <ods-input v-model="form.estimatedRealCost"
                            :disabled="valueToEdit != null && !hasPermission ? true : false"
                          ></ods-input>
                        </ods-form-item>
                      </ods-col>
                    </ods-row>
                    <ods-row :gutter="20">
                      <ods-col :md="12">
                          <ods-form-item :label="$t('comment')" prop="estimatedComment">
                              <ods-input v-model="form.estimatedComment" v-bind:autosize="true" type="textarea"
                                :disabled="valueToEdit != null && !hasPermission ? true : false"
                              ></ods-input>
                          </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('expectedExecutionDate')" prop="estimatedExecutionDate" :rules="[errors.required]">
                          <ods-date-picker
                          :disabled="valueToEdit != null && !hasPermission ? true : false"
                          type="date"
                          v-model="form.estimatedExecutionDate"
                          style="width: 100%"
                          />
                        </ods-form-item>
                      </ods-col>
                    </ods-row>
                    <ods-row :gutter="20" v-if="valueToEdit != null">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('realCost')" prop="realCost">
                          <ods-input v-model="form.realCost"></ods-input>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('executionDate')" prop="executionDate">
                          <ods-date-picker
                          type="date"
                          v-model="form.executionDate"
                          style="width: 100%"
                          />
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                          <ods-form-item :label="$t('otherComment')" prop="comment">
                              <ods-input v-model="form.comment" v-bind:autosize="true" type="textarea"></ods-input>
                          </ods-form-item>
                      </ods-col>
                    </ods-row>
                  </ods-form>
              </slot>
          </section>
        </ods-module>
        <slot name="footer" class="modal-footer">
            <form-buttons
                @submit="submitForm"
                @cancel="$emit('close')"
            ></form-buttons>
        </slot>
        </div>
    </div>
  </transition>
</template>

<script>
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import BalanceAreaService from '@/services/BalanceArea'
import NetworkNodeService from '@/services/NetworkNode'
import StrategicService from '@/services/Strategic'
import LovService from '@/services/Lov'
import handlePromise from '@/utils/promise'
import moment from 'moment'
import Vue from 'vue'
import { mapState } from 'vuex'
import UserService from '@/services/User'

export default {
  name: 'CreateStegicPlanModal',
  props: {
    valueToEdit: {
      type: String,
      default: null
    },
    plan: String,
    area: String,
    hasPermission: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FormButtons
  },
  computed: {
    ...mapState({
      userData: state => state.user.data
    })
  },
  mounted: function () {
    this.getParams()
    if (this.valueToEdit != null) { this.getValue() }
  },
  data () {
    return {
      formLoading: false,
      loading: false,
      form: {},
      actions: {},
      userId: String,
      nodes: [],
      results: [{ id: 'NEGATIVE', name: 'negative' }, { id: 'POSITIVE', name: 'positive' }],
      errors
    }
  },
  methods: {
    async getParams () {
      this.toggleLoading()
      const [, responseDataUser, dataUser] = await handlePromise(UserService.getUser(this.userData.user.id))
      if (responseDataUser.ok) this.userId = dataUser.id
      const [, modelsResponse, actions] = await handlePromise(LovService.getLov('5847'))
      if (modelsResponse.ok) this.actions = actions.lovValues

      const [, response, data] = await handlePromise(BalanceAreaService.getArea(this.area))
      let initialNodes
      if (response.ok) {
        initialNodes = data.networkNodes
      }

      initialNodes.map(async (item, index) => {
        const [error, response] = await handlePromise(
          NetworkNodeService.getNetworkByNode(item.id)
        )

        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        response.data.map(item2 => {
          this.nodes.push(item2)
        })
        this.toggleLoading()
      })
    },
    async getValue () {
      this.formLoading = true

      const [error, response, data] = await handlePromise(StrategicService.getActivity(this.plan, this.valueToEdit))
      this.formLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        networkElement: data.networkNode.id,
        action: data.action,
        result: data.result,
        realCost: Vue.filter('formatNumber')(data.realCost),
        comment: data.comment,
        executionDate: data.executionDate ? moment(Number(`${data.executionDate}000`)).utc().format('MM/DD/YYYY') : null,
        estimatedRealCost: Vue.filter('formatNumber')(data.expectedCost),
        estimatedComment: data.expectedComment,
        estimatedExecutionDate: data.expectedExecutionDate ? moment(Number(`${data.expectedExecutionDate}000`)).utc().format('MM/DD/YYYY') : null
      }
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let toSend = {
            networkNode: this.form.networkElement,
            action: this.form.action,
            result: this.form.result,
            expectedComment: this.form.estimatedComment,
            expectedCost: this.checkString(this.form.estimatedRealCost) ? this.form.estimatedRealCost.replace(/,/g, '') : this.form.estimatedRealCost,
            expectedExecutionDate: this.form.estimatedExecutionDate ? moment.utc(this.form.estimatedExecutionDate).format('YYYY-MM-DD') : null,
            updatedBy: this.userId
          }
          let toSend2 = {
            networkNode: this.form.networkElement,
            action: this.form.action,
            result: this.form.result,
            realCost: this.checkString(this.form.realCost) ? this.form.realCost.replace(/,/g, '') : this.form.realCost,
            comment: this.form.comment,
            executionDate: this.form.executionDate ? moment(this.form.executionDate).utc().format('YYYY-MM-DD') : null,
            expectedCost: this.checkString(this.form.estimatedRealCost) ? this.form.estimatedRealCost.replace(/,/g, '') : this.form.estimatedRealCost,
            expectedComment: this.form.estimatedComment,
            expectedExecutionDate: this.form.estimatedExecutionDate ? moment(this.form.estimatedExecutionDate).utc().format('YYYY-MM-DD') : null,
            updatedBy: this.userId
          }

          this.formLoading = true
          const [error, response] = await handlePromise(
            this.valueToEdit
              ? StrategicService.updateActivity(this.plan, this.valueToEdit, toSend2)
              : StrategicService.saveActivity(this.plan, toSend)
          )
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('close')
        }
      })
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    checkString (val) {
      if (typeof val === 'string') {
        return true
      }
      return false
    }
  },
  watch: {
    'form.realCost': function (val) {
      this.form.realCost = Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, ''))
    },
    'form.estimatedRealCost': function (val) {
      this.form.estimatedRealCost = Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, ''))
    }
  }
}
</script>
<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 60%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 40vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
