import api from "@/config/Api";

// const baseEndpoint = '/balances/balance-calculation-monthly'

export default {
  getBalanceLtm(params) {
    return api.get(`/balances/balance-ltm-calculation`, params);
  },
  getBalanceInput(params) {
    return api.get(`/network/network-unit/input`, params);
  },

  getBalanceOutput(params) {
    return api.get(`/network/network-unit/output`, params);
  },

  getBalancePeriodHistory(params) {
    return api.get("/balances/balance-node-period-calculation/history", params);
  },

  getBalancePeriodHistoryWater(params) {
    return api.get(
      "/balances/balance-water-node-period-calculation/history",
      params
    );
  },

  getBalancePeriodCalculations(id) {
    return api.get(
      `/balances/balance-node-period-calculation/period/${id}/balance-area`
    );
  },

  getBalancePeriodCalculationsWater(id) {
    return api.get(
      `/balances/balance-water-node-period-calculation/period/${id}/balance-area`
    );
  },

  getBalanceLog(period) {
    return api.get(`/balances/balance-log/${period}`);
  },

  getValidationLog(type) {
    return api.get(`/measurement/meter-reading/logs/${type}`);
  },

  getBalanceCustomersByArea(area, period, params) {
    return api.get(
      `/network/balance-area/meterCustomer/${period}/${area}`,
      params
    );
  },

  getBalanceCustomersByAreaWater(area, period, params) {
    return api.get(
      `/network/balance-water-area/meterCustomer/${period}/${area}`,
      params
    );
  },

  startDailyBalance() {
    return api.get(`/balances/balance-node-daily/start`);
  },

  startDailyBalanceWater() {
    return api.get(`/balances/balance-water-node-daily/start`);
  },

  startPeriodBalance() {
    return api.get(`/balances/balance-node-period-calculation/start`);
  },

  startPeriodBalanceWater() {
    return api.get(`/balances/balance-water-node-period-calculation/start`);
  },

  monetaryCalculation(period) {
    return api.get(
      `/balances/balance-node-period-calculation/${period}/monetary-calculation`
    );
  },

  monetaryCalculationWater(period) {
    return api.get(
      `/balances/balance-water-node-period-calculation/${period}/monetary-calculation`
    );
  },

  downloadEnergyInputTable(params) {
    return api.get("/measurement/report/energyInput", params);
  },

  downloadEnergyInputTableWater(params) {
    return api.get("/measurement/report/energyInput", params);
  },

  getConfigurationBalances(params) {
    return api.get(`/balances/configuration-balance`, params);
  },

  getConfigurationBalance(id) {
    return api.get(`/balances/configuration-balance/${id}`);
  },

  saveConfigurationBalance(data) {
    return api.post(`/balances/configuration-balance`, data);
  },

  updateConfigurationBalance(id, data) {
    return api.put(`/balances/configuration-balance/${id}`, data);
  },

  deleteConfigurationBalance(id) {
    return api.delete(`/balances/configuration-balance/${id}`);
  },
};
