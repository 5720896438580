/* eslint-disable no-unused-vars */
<template>
  <section v-loading="loading">
    <ods-row :gutter="20" type="flex">
      <ods-col :md="9">
        <ods-module class="tree__module history-tree">
            <network-tree
            @node-click="loadNodeData"
            @toggle-loading="toggleLoading"
            ref="treeComp"
        >
        </network-tree>
        </ods-module>
      </ods-col>

      <ods-col :md="15" style="min-height: 100%;">
        <ods-module :header="$t('networkHistory')" style="min-height: 100%">
          <ods-row v-if="!data.length" type="flex" justify="center" style="margin-top: 2rem">
            <ods-icon name="info" size="100" class="custom-placeholder"/>
          </ods-row>
          <ods-timeline
              class="mt-1"
              :reverse="false">
            <ods-timeline-item
                v-for="(activity, index) in data"
                :key="index"
                :icon="activity.code ? 'ods-icon-pushpin' : 'ods-icon-shield'"
                placement="right"
                size="large"
                :color="activity.code ? '#36B348' : '#2E6C99'"
                hide-timestamp
            >
              <div v-if="activity.code">
                <p>{{activity.fromDate | date(true)}}</p>
                <p>
                  <span class="font-weight-bold">{{$t('version')}}:</span> {{activity.version}}
                  <span v-if="activity.firstNode && activity.firstNode.networkUnit">
                  -
                  {{activity.firstNode.networkUnit.name}}
                  (
                  <span v-if="activity.firstNode.networkUnit.networkElement">{{activity.firstNode.networkUnit.networkElement.elementType}} - </span>
                  <span v-if="activity.masterName">{{activity.masterName}} - </span>
                  {{activity.code}}
                  )
                </span>
                </p>

              </div>
              <div v-else>
                <p>{{activity.createdAt | date(true)}}</p>
                <p>
                  <span class="font-weight-bold">{{$t(activity.action).toUpperCase()}}</span>
                  <ods-badge
                      style="margin-left: 10px; margin-right: 10px"
                      v-if="activity.status"
                      :value="$t(activity.status).toUpperCase()"
                      :type="activity.status === 'pending' ? 'secondary' : 'success'"
                  />
                  {{activity.owner.email}}
                </p>
              </div>
            </ods-timeline-item>
          </ods-timeline>
        </ods-module>
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import NetworkHistoricalService from '@/services/NetworkHistorical'
import handlePromise from '@/utils/promise'
import NetworkTree from '@/custom-components/Network/Tree'
// import get from 'lodash/get'
import _ from 'lodash'

export default {
  name: 'ReconfigurationDashboard',
  components: { NetworkTree },
  data () {
    return {
      loading: false,
      data: [],
      props: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      },
      filterText: ''
    }
  },
  methods: {
    /* async loadTreeNode (node, resolve) {
      this.toggleLoading()
      const params = { limit: 100 }
      if (this.filterText) params['filters[unit]'] = this.filterText

      const [error, response, data] = await handlePromise(NetworkHistoricalService.getHistory(params))
      this.toggleLoading()
      if (response.ok) {
        resolve(data.data.map(item => ({
          id: item._id.code,
          name: `${get(item, '_id.firstNode.networkUnit.name')}
          (${(get(item, '_id.firstNode.networkUnit.networkElement.elementType'))}${(item._id.masterName ? ' - ' + item._id.masterName : '')} - ${(item._id.code)})`,
          leaf: true,
          data: item
        })))
      } else {
        this.$store.commit('settings/toggleAlert', error)
        resolve([])
      }
    }, */
    async loadNodeData ({ data }) {
      this.toggleLoading()
      if (data.networkTree) {
        const [error, response, responseData] = await handlePromise(NetworkHistoricalService.getRootHistory(data.networkTree.code))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

        const changes = []
        _.orderBy(responseData.networkTress, 'fromDate', 'desc').map((tree, index) => {
          tree.fromDate = Number(`${tree.fromDate}00${index}`)
          changes.push(tree)

          const logs = _.filter(responseData.logNetworkChanges, { networkTree: { id: tree.id } })
          _.chain(logs)
            .map((log, logIndex) => {
              log.createdAt = Number(`${log.createdAt}00${logIndex}`)
            })
            .orderBy('fromDate', 'desc')
            .value()

          changes.push(...logs)
        })
        this.data = changes
      } else if (!data.networkTree) {
        const [error, response, responseData] = await handlePromise(NetworkHistoricalService.getNodeHistory(data.id))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

        const changes = []
        _.orderBy(responseData.networkTress, 'fromDate', 'desc').map((tree, index) => {
          tree.fromDate = Number(`${tree.fromDate}00${index}`)
          changes.push(tree)

          const logs = _.filter(responseData.logNetworkChanges, { networkTree: { id: tree.id } })
          _.chain(logs)
            .map((log, logIndex) => {
              log.createdAt = Number(`${log.createdAt}00${logIndex}`)
            })
            .orderBy('fromDate', 'desc')
            .value()

          changes.push(...logs)
        })
        this.data = changes
      } else {
        await handlePromise(NetworkHistoricalService.getNodeHistory())
        this.toggleLoading()
        return this.$store.commit('settings/toggleAlert', this.$t('noHistorical'))
      }
    },
    async filterTree () {
      this.$refs.tree.reload()
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
