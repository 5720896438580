<template>
  <section v-loading="loading">
    <ods-module>
        <template slot="header">
          <ods-row :gutter="20" type="flex">
            <ods-col :md="16">
              {{ $route.params.id ? $t('editPurchase') : $t('newPurchase') }}
            </ods-col>
            <ods-col :md="8">
              <form-buttons
                @submit="submitForm"
                cancel-to="PurchaseDashboard"
                ></form-buttons>
            </ods-col>
          </ods-row>

        </template>
      <ods-row :gutter="20" type="flex">
        <ods-col :md="8">
          <ods-module>
            <ods-form class="mt-2"
                :model="form"
                ref="form"
                v-loading="loading"
            >
              <ods-form-item
                  :label="$t('billOriginalReference')"
                  prop="billOriginalReference"
                  :rules="[errors.required]"
              >
                <ods-input v-model="form.billOriginalReference"></ods-input>
              </ods-form-item>

              <ods-form-item :label="$t('from')" prop="fromDate"
                            :rules="[errors.required]">
                <ods-date-picker
                    type="date"
                    :clearable="false"
                    v-model="form.fromDate"
                    style="width: 100%"/>
              </ods-form-item>

              <ods-form-item :label="$t('to')" prop="toDate"
                            :rules="[errors.required]">
              <ods-date-picker
                    type="date"
                    :clearable="false"
                    v-model="form.toDate"
                    :picker-options="{disabledDate: disabledDateTo}"
                    style="width: 100%"/>
              </ods-form-item>

              <ods-form-item
                  :label="$t('totalEnergy')"
                  prop="totalEnergy"
                  type="number"
                  :rules="[errors.required]"
              >
                <ods-input v-model="form.totalEnergy"></ods-input>
              </ods-form-item>

              <ods-form-item
                  :label="$t('totalAmount')"
                  prop="totalAmount"
                  type="number"
                  :rules="[errors.required]"
              >
                <ods-input v-model="form.totalAmount"></ods-input>
              </ods-form-item>

              <ods-form-item :label="$t('status')" prop="status" v-if="$route.params.id">
                <ods-select v-model="form.status" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
                </ods-select>
              </ods-form-item>

              <ods-form-item :label="$t('description')" prop="description">
                <ods-input v-model="form.description" type="textarea"></ods-input>
              </ods-form-item>
            </ods-form>
          </ods-module>
        </ods-col>

        <ods-col :md="8">
          <ods-module>
            <template slot="header">
              {{ $t( 'selectedNodes' ) }}
              <div style="font-size: 12px; padding-top: 8px" class="custom-note font-weight-normal">
                {{ $t( 'selectNodesInfo' ) }}
              </div>
            </template>

            <div class="purchase--tree__selected">
              <div
                  v-for="item in selectedNodes"
                  :key="item.id"
              >
                <ods-row type="flex">
                  <span @click="deleteNode(item.id)" class="cursor-pointer" style="margin-top: 2px; margin-right: 5px">
                    <ods-icon name="close" size="16" class="custom-note"/>
                  </span>
                  <span v-if="item.networkUnit">{{ item.networkUnit.name }}</span>
                  <div style="margin-left: 2px">
                    (<span
                      v-if="item.networkUnit && item.networkUnit.networkElement">{{ item.networkUnit.networkElement.elementType }}</span>
                    <span v-if="item.networkTree && item.networkTree.masterName">
                      - {{ item.networkTree.masterName }}
                    </span>
                    <span v-if="item.networkTree && item.networkTree.code">
                      - {{ item.networkTree.code }}
                    </span>
                    )
                  </div>
                </ods-row>
              </div>
            </div>
          </ods-module>
        </ods-col>

        <ods-col :md="8" style="min-height: 100%;">
          <network-tree
              container-class="purchase--tree"
              :selected-nodes="selectedNodes"
              :filters="{}"
              @node-click="addNode"
              @toggle-loading="toggleLoading"
          >
          </network-tree>
        </ods-col>
      </ods-row>
    </ods-module>
  </section>
</template>

<script>
import PurchaseService from '@/services/Purchase'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import NetworkTree from '@/custom-components/Network/Tree'
import moment from 'moment'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import Vue from 'vue'
import APPCONFIG from '@/config/Config'

export default {
  name: 'PurchaseEdit',
  components: {
    FormButtons,
    NetworkTree
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      submitErrors: null,
      form: {},
      validations: {},
      errors,
      selectedNodes: [],
      statuses: [
        { id: 'new', name: 'new' },
        { id: 'processed', name: 'processed' }
      ]
    }
  },
  methods: {
    disabledDateTo (date) {
      return moment(this.form.fromDate).isSameOrAfter(date, 'day')
    },
    async getData () {
      const [error, response, data] = await handlePromise(PurchaseService.getPurchase(this.$route.params.id))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        billOriginalReference: data.billOriginalReference,
        description: data.description,
        fromDate: data.fromDate ? moment.utc(Number(`${data.fromDate}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00' : null,
        toDate: data.fromDate ? moment.utc(Number(`${data.toDate}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00' : null,
        totalEnergy: Vue.filter('formatNumberForm')(data.totalEnergy),
        totalAmount: Vue.filter('formatCurrencyForm')(data.totalAmount),
        status: data.status
      }
      this.selectedNodes = data.networkNodes
    },
    checkString (val) {
      if (typeof val === 'string') {
        return true
      }
      return false
    },
    async submitForm () {
      let fromDate = ''
      if (this.form.fromDate) {
        fromDate = moment(String(this.form.fromDate)).format()
      }
      if (new Date(fromDate) >= new Date(this.form.toDate)) {
        this.submitErrors = 'dateGreaterThanFromDate'
        return this.$store.commit('settings/toggleAlert', this.$t(this.submitErrors))
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const data = {
            billOriginalReference: this.form.billOriginalReference,
            description: this.form.description,
            fromDate: this.form.fromDate ? moment.utc(this.form.fromDate).format('YYYY-MM-DD') : null,
            toDate: this.form.toDate ? moment.utc(this.form.toDate).format('YYYY-MM-DD') : null,
            totalEnergy: this.checkString(this.form.totalEnergy) ? this.form.totalEnergy.replace(/,/g, '') : this.form.totalEnergy,
            totalAmount: this.checkString(this.form.totalAmount) ? this.form.totalAmount.replace(/,/g, '').replace(APPCONFIG.currencyRegularExpresion, '') : this.form.totalAmount,
            status: !this.$route.params.id ? 'new' : this.form.status,
            networkNodes: this.selectedNodes.map(item => item.id)
          }
          this.toggleLoading()
          const [error, response] = await handlePromise(
            this.$route.params.id
              ? PurchaseService.updatePurchase(this.$route.params.id, data)
              : PurchaseService.savePurchase(data)
          )
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
          return this.$router.push({ name: 'PurchaseDashboard' })
        }
      })
    },
    addNode (node) {
      if (!find(this.selectedNodes, { id: node.id })) this.selectedNodes.push(node.data)
    },
    deleteNode (id) {
      const index = findIndex(this.selectedNodes, { id })
      if (index !== -1) this.selectedNodes.splice(index, 1)
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  },
  watch: {
    'form.totalAmount': function (val) {
      this.form.totalAmount = Vue.filter('formatCurrencyForm')(val.replace(/[^\d.-]/g, ''))
    },
    'form.totalEnergy': function (val) {
      this.form.totalEnergy = Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, ''))
    }
  }
}
</script>

<style lang="scss">
</style>
