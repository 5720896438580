<template>
  <section>
    <ods-module :header="$t('balanceManagementHorizontal')">
      <template slot="header">
        <list-header
          title="balanceManagementHorizontal"
          btn-label="createBalance"
          url-key="BalanceNewHorizontal"
        />
      </template>
      <async-table
        :data="data"
        :total="total"
        :loading="loading"
        @fetchData="getData"
      >
        <ods-table-column :label="$t('name')">
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.id"
              :to="{ name: 'BalanceEditHorizontal', params: { id: scope.row.id } }"
            >
              <ods-button type="text" class="p-0">{{
                scope.row.name
              }}</ods-button>
            </router-link>
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('region')">
          <template slot-scope="scope">
            {{ scope.row.region }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('transportationDivision')">
          <template slot-scope="scope">
            {{ scope.row.transportDivision }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('commercialOffice')">
          <template slot-scope="scope">
            {{ scope.row.commercialOffice }}
          </template>
        </ods-table-column>
        <ods-table-column prop="status" :label="$t('active')">
          <template slot-scope="scope">
            <ods-icon
              name="checkmark-2"
              v-if="scope.row.status === 'active'"
            ></ods-icon>
            <ods-icon
              name="close"
              v-if="scope.row.status !== 'active'"
            ></ods-icon>
          </template>
        </ods-table-column>
        <ods-table-column align="right">
          <template slot-scope="scope">
            <table-delete @click="toggleDialog(scope.row.id)"></table-delete>
          </template>
        </ods-table-column>
      </async-table>
    </ods-module>
    <dialog-confirmation
      :visible="dialogVisible"
      :loading="deleteLoading"
      @confirm="deleteItem"
      @cancel="toggleDialog()"
    />
  </section>
</template>

<script>
import BalanceService from "@/services/Balance";
import handlePromise from "@/utils/promise";
import AsyncTable from "@/custom-components/AsyncTable";
import TableDelete from "@/custom-components/Buttons/TableDelete";
import DialogConfirmation from "@/custom-components/DialogConfirmation";
import asyncTableCommon from "@/mixins/async-table";
import ListHeader from "@/custom-components/ListHeader";
import { mapState } from "vuex";

export default {
  name: "BalanceManagement",
  components: {
    ListHeader,
    AsyncTable,
    TableDelete,
    DialogConfirmation,
  },
  mixins: [asyncTableCommon],
  computed: {
    ...mapState({
      userRole: (state) => state.userRoles.data,
    }),
  },
  data() {
    return {
      loading: false,
      createDialogVisible: false,
      selectedNodes: [],
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      promise: (params) => BalanceService.getConfigurationBalances(params),
    };
  },
  methods: {
    toggleDialog(id) {
      this.toDelete = id;
      this.dialogVisible = !this.dialogVisible;
    },
    async deleteItem() {
      this.deleteLoading = true;

      const [error, response] = await handlePromise(
        BalanceService.deleteConfigurationBalance(this.toDelete)
      );
      this.deleteLoading = false;
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));

      this.toggleDialog();
      this.getData(this.tableParams);
    },
  },
};
</script>
