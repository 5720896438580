<template>
  <section>
    <ods-module class="mt-1" :header="$t('consumptions')">
      <ods-row :gutter="20" type="flex"   v-if="hasPermission">
        <ods-col :md="40">
              <ods-form
                :model="form2"
                ref="form2"
                v-loading="formLoading"
              >
                  <ods-button style="margin-right:5px;margin-top:20px;float:left" size="medium" type="warning" @click="bulkValidations">
                    {{ $t('bulkValidations') }}
                  </ods-button>
                  <ods-button style="margin-right:5px;margin-top:20px;float:left" size="medium" type="warning" @click="resetOrigin">
                    {{ $t('resetOrigin') }}
                  </ods-button>
                  <ods-form-item :label="$t('anomalies')" prop="anomalies" :rules="[errors.required]"
                                  style="margin-left:0;width:210px; float:right">
                    <ods-select v-model="form2.anomalies" placeholder="" multiple>
                      <ods-option
                          v-for="item in anomalies"
                          :key="item.id"
                          :label="$t(item.name)"
                          :value="item.id"
                      >
                      </ods-option>
                    </ods-select>
                  </ods-form-item>
                  <ods-button style="margin-right:10px;margin-top:20px;float:right" size="medium" type="primary" @click="ignoreAnomalies()">
                    <ods-icon name = "info-alert"  style="margin-left:-10px; display:inherit; float:left; padding-right:10px"  size="18"/>{{ $t('ignoreAnomalies') }}
                  </ods-button>
            </ods-form>
        </ods-col>
      </ods-row>

      <ods-table
          :data="consumption"
          v-loading="formLoading"
          size="mini"
          style="width: 100%"
      >
        <ods-table-column prop="meter.serialNumber" :label="$t('meter')"/>
        <ods-table-column prop="date" :label="$t('date')" v-if="hasPermission">
          <template slot-scope="scope">
            <ods-button type="text" class="p-0" @click="toggleDialog(scope.row.id, scope.row.meter.id, scope.row.meter.serialNumber)">
              {{ scope.row.date | date }}
            </ods-button>
          </template>
        </ods-table-column>
        <ods-table-column prop="date" :label="$t('date')">
          <template slot-scope="scope">
              {{ scope.row.date | date }}
          </template>
        </ods-table-column>
        <ods-table-column prop="type" :label="$t('type')"/>
         <ods-table-column :label="$t('readingOrigin')" >
              <template slot-scope="scope">
                {{ scope.row.readingOrigin | formatNumber }}
              </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumptionOrigin')" >
              <template slot-scope="scope">
                {{ scope.row.consumptionOrigin | formatNumber }}
              </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumption')" >
              <template slot-scope="scope">
                {{ scope.row.consumptionToDay | formatNumber }}
              </template>
        </ods-table-column>
        <ods-table-column prop="status" :label="$t('anomalies')">
          <template slot-scope="scope">
            <ods-tooltip class="item" effect="light" placement="top-start"
                         v-if="scope.row.highEstimationStatus === 'pending' || scope.row.highDeviationStatus === 'pending'
                         || scope.row.lowEstimationStatus === 'pending' || scope.row.noReadingStatus === 'pending'
                         || scope.row.potentialTurnoverStatus === 'pending' || scope.row.readingBoundsStatus === 'pending'
                          || scope.row.consumptionBoundsStatus === 'pending'">
              <div slot="content">
                <div v-if="scope.row.highEstimationStatus === 'pending'">- {{$t('highEstimation')}}</div>
                <div v-if="scope.row.highDeviationStatus === 'pending'">- {{$t('highDeviation')}}</div>
                <div v-if="scope.row.lowEstimationStatus === 'pending'">- {{$t('lowEstimation')}}</div>
                <div v-if="scope.row.noReadingStatus === 'pending'">- {{$t('noReading')}}</div>
                <div v-if="scope.row.potentialTurnoverStatus === 'pending'">- {{$t('potentialTurnover')}}</div>
                <div v-if="scope.row.readingBoundsStatus === 'pending'">- {{$t('readingBounds')}}</div>
                <div v-if="scope.row.consumptionBoundsStatus === 'pending'">- {{$t('consumptionBounds')}}</div>
              </div>
              <ods-icon name="info-alert" color="#DE2F2F" size="20" style="line-height: 1"/>
            </ods-tooltip>
          </template>
        </ods-table-column>
      </ods-table>
      <div class="block">
        <ods-pagination
            :total="total"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            layout="prev, pager, next, jumper, sizes, ->, total"
        >
        </ods-pagination>
      </div>
    </ods-module>

    <dialog-form
        :title="toEdit ? 'editConsumptionForMeter' : 'addConsumptionForMeter'"
        :secondTitle="serialNumber"
        :visible="editDialogVisible"
        @submit="submitForm"
        @cancel="toggleDialog()"
    >
      <p style="margin-bottom:10px;margin-top:5px;font-size:120%;font-weight: 490">Date: {{form.date}}</p>
      <ods-form
          :model="form"
          ref="form"
          v-loading="formLoading"
      >
        <ods-row :gutter="20">
           <ods-col :md="12">
            <ods-form-item :label="$t('consumption')" prop="consumptionToDay" :rules="[errors.required]">
              <ods-input v-model="form.consumptionToDay"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item :label="$t('consumptionOrigin')" prop="consumptionOrigin" :rules="[errors.required]">
              <ods-input v-model="form.consumptionOrigin" disabled></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="24"  style="margin-bottom:10px;margin-top:5px;font-size:120%;font-weight: 490"
            v-show="form.consumptionBoundsStatus !== 'solved' || form.highDeviationStatus !== 'solved' || form.highEstimationStatus !== 'solved'
            || form.lowEstimationStatus !== 'solved' || form.noReadingStatus !== 'solved' || form.potentialTurnoverStatus !== 'solved'
            || form.readingBoundsStatus !== 'solved'"
          >
                {{ $t('anomalies') }}:
          </ods-col>
          <ods-col :md="12" v-show="form.consumptionBoundsStatus != 'solved'">
            <ods-form-item :label="$t('consumptionBounds')" prop="consumptionBoundsStatus" :rules="[errors.required]">
              <ods-select v-model="form.consumptionBoundsStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.highDeviationStatus != 'solved'">
            <ods-form-item :label="$t('highDeviation')" prop="highDeviationStatus" :rules="[errors.required]">
              <ods-select v-model="form.highDeviationStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.highEstimationStatus != 'solved'">
            <ods-form-item :label="$t('highEstimation')" prop="highEstimationStatus" :rules="[errors.required]">
              <ods-select v-model="form.highEstimationStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.lowEstimationStatus != 'solved'">
            <ods-form-item :label="$t('lowEstimation')" prop="lowEstimationStatus" :rules="[errors.required]">
              <ods-select v-model="form.lowEstimationStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.noReadingStatus != 'solved'">
            <ods-form-item :label="$t('noReading')" prop="noReadingStatus" :rules="[errors.required]">
              <ods-select v-model="form.noReadingStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.potentialTurnoverStatus != 'solved'">
            <ods-form-item :label="$t('potentialTurnover')" prop="potentialTurnoverStatus" :rules="[errors.required]">
              <ods-select v-model="form.potentialTurnoverStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-show="form.readingBoundsStatus != 'solved'">
            <ods-form-item :label="$t('readingBounds')" prop="readingBoundsStatus" :rules="[errors.required]">
              <ods-select v-model="form.readingBoundsStatus" placeholder="">
                  <ods-option
                      v-for="item in statuses"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                  >
                  </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
      </ods-form>
    </dialog-form>
  </section>
</template>

<script>
import map from 'lodash/map'
import errors from '@/config/InputErrors'
import MeterReadingService from '@/services/MeterReading'
import DialogForm from '@/custom-components/DialogForm'
import handlePromise from '@/utils/promise'
import Vue from 'vue'

export default {
  name: 'ValidationReadings',
  props: {
    data: Object,
    hasPermission: {
      type: Boolean,
      default: true
    },
    params: Object
  },
  components: {
    DialogForm
  },
  mounted () {
    this.getData()
  },
  data () {
    return {
      consumption: [],
      pageSize: 10,
      pageIndex: 0,
      total: 0,
      sort: null,
      form: {},
      form2: {},
      lastReading: {},
      formLoading: false,
      errors,
      editDialogVisible: false,
      toEdit: null,
      serialNumber: null,
      meter: null,
      statuses: [
        { id: 'pending', name: 'pending' },
        { id: 'ignored', name: 'ignored' }
      ],
      anomalies: [
        { id: 'highEstimationStatus', name: 'highEstimation' },
        { id: 'highDeviationStatus', name: 'highDeviation' },
        { id: 'lowEstimationStatus', name: 'lowEstimation' },
        { id: 'noReadingStatus', name: 'noReading' },
        { id: 'potentialTurnoverStatus', name: 'potentialTurnover' },
        { id: 'readingBoundsStatus', name: 'readingBounds' },
        { id: 'consumptionBoundsStatus', name: 'consumptionBounds' }
      ]
    }
  },
  methods: {
    toggleDialog (id, meter, serialNumber) {
      this.toEdit = id
      this.meter = meter
      this.serialNumber = serialNumber
      if (this.editDialogVisible && this.$refs['form']) {
        this.form = {}
        this.$refs['form'].resetFields()
      }
      this.editDialogVisible = !this.editDialogVisible
      if (id) this.getReading()
    },
    async getReading () {
      this.formLoading = true
      const [error, response, data] = await handlePromise(MeterReadingService.getMeterReadingWater(
        this.meter, this.toEdit))
      this.formLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.form = {
        date: Vue.filter('date')(data.date),
        consumptionToDay: Vue.filter('formatNumberForm')(data.consumptionToDay),
        consumptionBoundsStatus: data.consumptionBoundsStatus,
        highDeviationStatus: data.highDeviationStatus,
        highEstimationStatus: data.highEstimationStatus,
        lowEstimationStatus: data.lowEstimationStatus,
        noReadingStatus: data.noReadingStatus,
        potentialTurnoverStatus: data.potentialTurnoverStatus,
        readingBoundsStatus: data.readingBoundsStatus,
        consumptionOrigin: Vue.filter('formatNumberForm')(data.consumptionOrigin)
      }
      this.lastReading = data.lastReading
    },
    checkString (val) {
      if (typeof val === 'string') {
        return true
      }
      return false
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.formLoading = true
          const toSend = {
            consumptionToDay: this.checkString(this.form.consumptionToDay) ? this.form.consumptionToDay.replace(/,/g, '') : this.form.consumptionToDay,
            consumptionBoundsStatus: this.form.consumptionBoundsStatus,
            highDeviationStatus: this.form.highDeviationStatus,
            highEstimationStatus: this.form.highEstimationStatus,
            lowEstimationStatus: this.form.lowEstimationStatus,
            noReadingStatus: this.form.noReadingStatus,
            potentialTurnoverStatus: this.form.potentialTurnoverStatus,
            readingBoundsStatus: this.form.readingBoundsStatus,
            consumptionOrigin: this.checkString(this.form.consumptionOrigin) ? this.form.consumptionOrigin.replace(/,/g, '') : this.form.consumptionOrigin
          }
          const [error, response] = await handlePromise(
            MeterReadingService.updateMeterReadingWater(this.meter, this.toEdit, toSend))
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('reload-data')
          this.toggleDialog()
        }
      })
    },
    async bulkValidations () {
      const [error, response] = await handlePromise(MeterReadingService.bulkValidationWater())
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }

      return this.$store.commit('settings/toggleSuccessAlert', this.$t('bulkValidationStarted'))
    },
    async resetOrigin () {
      const toSend = {
        meterReadings: this.data.data.map(item => item.id)
      }
      this.toggleLoading()
      const [error, response] = await handlePromise(MeterReadingService.resetOriginWater(toSend))
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.$emit('reload-data')
      this.getData()
    },
    async ignoreAnomalies () {
      this.$refs['form2'].validate(async (valid) => {
        if (valid) {
          this.formLoading = true
          const toSend = {
            meterReadings: this.data.data.map(item => item.id)
          }
          let params = {
            anomalies: this.form2.anomalies.map(item => item)
          }
          const [error, response] = await handlePromise(
            MeterReadingService.ignoreAnomaliesWater(params, toSend))
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('reload-data')
          this.getData()
        }
      })
    },
    async getData () {
      let auxParams = {}
      if (this.params.anomalies && this.params['periods[]']) {
        auxParams = {
          'periods[]': this.params['periods[]'],
          anomalies: this.params.anomalies.map(item => item)
        }
      }
      if (!this.params.anomalies && this.params['periods[]']) {
        auxParams = {
          'periods[]': this.params['periods[]']
        }
      }
      if (this.params.anomalies && !this.params['periods[]']) {
        auxParams = {
          anomalies: this.params.anomalies.map(item => item)
        }
      }

      let params = {
        offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize) + 1,
        limit: this.pageSize,
        ...auxParams
      }

      if (this.sort && this.sort.prop) params[`sortby[${this.sort.prop}]`] = this.sort.order === 'ascending' ? 'ASC' : 'DESC'
      this.toggleLoading()
      const [error, response, data] = await handlePromise(MeterReadingService.getMeterReadingsByCriteriaWater(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.consumption = data.data
      this.total = data.total
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })

      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    toggleLoading () {
      this.formLoading = !this.formLoading
    }
  },
  watch: {
    'form.consumptionToDay': function (val) {
      this.form.consumptionToDay = this.checkString(val) ? Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, '')) : this.form.consumptionToDay
    },
    'form.consumptionOrigin': function (val) {
      this.form.consumptionOrigin = this.checkString(val) ? Vue.filter('formatNumberForm')(val.replace(/[^\d.-]/g, '')) : this.form.consumptionOrigin
    }
  }
}
</script>
