<template>
  <section>
    <ods-row type="flex" justify="space-between" align="center">
      <span>{{data.name}}</span>
    </ods-row>

    <ods-row type="flex" justify="space-between" align="center">
      <span class="custom-note">{{$t('status')}}: {{$t(data.status)}}</span>
      <span>{{data.createdAt | date}}</span>
    </ods-row>
    <ods-row class="mt-1" :gutter="20" v-if="showActions">
      <ods-col :md="12">
        <ods-button size="small" type="success" @click="changeStatus('accepted')" full>
          {{$t('accept')}}
        </ods-button>
      </ods-col>
      <ods-col :md="12">
        <ods-button size="small" type="danger" @click="changeStatus('discard')" full>
          {{$t('discard')}}
        </ods-button>
      </ods-col>
    </ods-row>

    <ods-timeline
        class="mt-1"
        :reverse="false">
      <ods-timeline-item
          v-for="(activity, index) in data.logNetworkChanges"
          :key="index"
          icon="ods-icon-shield"
          placement="right"
          size="large"
          color="rgba(183,83,83,1)"
          hide-timestamp
      >
        <span class="font-weight-bold" v-if="activity.action">{{$t(activity.action).toUpperCase()}}</span>

        <p v-if="activity.action !== 'modified'">
          <span class="font-weight-bold">{{$t('networkTree')}}:</span>
          {{activity.networkTree && activity.networkTree.firstNode ? activity.networkTree.firstNode.networkUnit.name :
          '---'}}
        </p>
        <p v-else>
          <span class="font-weight-bold">{{$t('networkTree')}}:</span>
          <span v-if="activity.originTree">
             {{activity.originTree.firstNode.networkUnit.name}}
            -
            {{activity.networkTree.firstNode.networkUnit.name}}
          </span>
          <span v-if="activity.destinationTree">
            {{activity.networkTree.firstNode.networkUnit.name}}
            -
            {{activity.destinationTree.firstNode.networkUnit.name}}
          </span>
        </p>

        <p>
          <span class="font-weight-bold">{{$t('networkUnit')}}:</span>
          <span v-if="activity.networkNode && activity.networkNode.networkUnit">
            {{activity.networkNode.networkUnit.name}}
            <span v-if="activity.networkNode.networkUnit.networkElement">
              ({{activity.networkNode.networkUnit.networkElement.name}} - {{activity.networkNode.networkUnit.networkElement.elementType}})
            </span>
          </span>
          <span v-else>---</span>
        </p>

        <p v-if="activity.action === 'modified' && activity.origin && activity.origin.networkUnit">
          <span class="font-weight-bold">{{$t('origin')}}:</span>
          {{activity.origin.networkUnit.name}}
          <span v-if="activity.origin.networkUnit.networkElement">
            ({{activity.origin.networkUnit.networkElement.name}} - {{activity.origin.networkUnit.networkElement.elementType}})
          </span>
        </p>

        <p v-if="(activity.action === 'created' || activity.action === 'modified') && activity.destination && activity.destination.networkUnit">
          <span class="font-weight-bold">{{$t('destination')}}:</span>
          {{activity.destination.networkUnit.name}}
          <span v-if="activity.destination.networkUnit.networkElement">
            ({{activity.destination.networkUnit.networkElement.name}} - {{activity.destination.networkUnit.networkElement.elementType}})
          </span>
        </p>

        <p v-if="activity.executeDate">
          <span class="font-weight-bold">{{$t('executionDate')}}:</span>
          {{activity.executeDate | date}}
        </p>
      </ods-timeline-item>
    </ods-timeline>
  </section>
</template>

<script>
import ManeuverService from '@/services/NetwortManeuver'
import handlePromise from '@/utils/promise'

export default {
  name: 'NetworkLogs',
  props: {
    data: Object,
    showActions: Boolean,
    isWater: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async changeStatus (status) {
      this.$emit('toggle-loading')
      if (this.isWater) {
        const [error, response] = await handlePromise(ManeuverService.updateManeuverWater(this.data.id, { status }))
        this.$emit('toggle-loading')
        if (!response.ok) {
          return this.$store.commit('settings/toggleAlert', error)
        }
      } else {
        const [error, response] = await handlePromise(ManeuverService.updateManeuver(this.data.id, { status }))
        this.$emit('toggle-loading')
        if (!response.ok) {
          return this.$store.commit('settings/toggleAlert', error)
        }
      }

      this.$emit('reload')
    }
  }
}
</script>
