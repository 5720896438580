import api from '@/config/Api'

const baseEndpoint = '/network/energy-purchase'
const baseEndpointWater = '/network/water-purchase'

export default {
  getPurchases (params) {
    return api.get(baseEndpoint, params)
  },

  getPurchasesWater (params) {
    return api.get(baseEndpointWater, params)
  },

  getPurchase (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  getPurchaseWater (id) {
    return api.get(`${baseEndpointWater}/${id}`)
  },

  savePurchase (data) {
    return api.post(baseEndpoint, data)
  },

  savePurchaseWater (data) {
    return api.post(baseEndpointWater, data)
  },

  updatePurchase (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  updatePurchaseWater (id, data) {
    return api.put(`${baseEndpointWater}/${id}`, data)
  },

  deletePurchase (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  deletePurchaseWater (id) {
    return api.delete(`${baseEndpointWater}/${id}`)
  }
}
