import api from '@/config/Api'

const baseEndpoint = '/network/balance-area'
const baseEndpointWater = '/network/balance-water-area'

export default {
  getAreas (params) {
    return api.get(baseEndpoint, params)
  },

  getAreasWater (params) {
    return api.get(baseEndpointWater, params)
  },

  getArea (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  getAreaWater (id) {
    return api.get(`${baseEndpointWater}/${id}`)
  },

  saveArea (data) {
    return api.post(baseEndpoint, data)
  },

  saveAreaWater (data) {
    return api.post(baseEndpointWater, data)
  },

  updateArea (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  updateAreaWater (id, data) {
    return api.put(`${baseEndpointWater}/${id}`, data)
  },

  deleteArea (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  deleteAreaWater (id) {
    return api.delete(`${baseEndpointWater}/${id}`)
  },

  getTreeByArea (id) {
    return api.get(`${baseEndpoint}/${id}/input-nodes`)
  },

  getTreeByAreaWater (id) {
    return api.get(`${baseEndpointWater}/${id}/input-nodes`)
  },

  validateSelectedNodeArea (data) {
    return api.post('/network/is-valid-node', data)
  }
}
