<template>
  <section v-loading="loading">
    <ods-row :gutter="20">

      <ods-col :md="24">
        <ods-module>
          <template slot="header">
            <list-header title="consumptions">
              <template slot="right">
                <ods-button type="transparent" size="medium" @click="noReadingValidationsLastReport">
                    <ods-icon name = "download"  style="margin-left:-10px;display:inherit;float:left"  size="15"/> {{ $t('lastReport') }} - {{validateEmptyReading | date2}}
                </ods-button>
                <ods-button style="float:right;padding-left:20px" size="medium"  @click="noReadingValidations">
                   {{ $t('validateEmptyReadings') }}
                </ods-button>
              </template>
            </list-header>
          </template>
          <ods-form :model="search" ref="form">
            <ods-row :gutter="20" type="flex" align="center">
               <ods-col :md="8">
                <network-tree
                    @node-click="searchByNetworkUnit"
                    @toggle-loading="toggleLoading"
                    :hideLastNode= hideLastNode
                >
                </network-tree>
              </ods-col>
              <ods-col :md="6">
                <ods-form-item :label="$t('number')" prop="serialNumber">
                  <ods-input v-model="search.serialNumber" disabled></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="6">
                <ods-form-item :label="$t('period')" prop="period">
                  <ods-select v-model="search.period" placeholder="" remote clearable>
                    <ods-option
                        v-for="item in periods"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>

              <ods-col :md="4">
                <ods-button type="primary" @click="searchBySerialNumber">
                  {{ $t('search') }}
                </ods-button>
              </ods-col>
            </ods-row>
          </ods-form>
        </ods-module>

        <validation-readings
            v-if="searchEnabled"
            :meter="selectedMeter"
            :data="readings"
            :hasPermission=hasPermission
            @reload-data="searchMeterReadings"
        />
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import MeterService from '@/services/Meter'
import MeterReadingForecastService from '@/services/MeterReadingForecast'
import PeriodService from '@/services/Period'
import handlePromise from '@/utils/promise'
import NetworkTree from '@/custom-components/Network/Tree'
import ValidationReadings from './Reading'
import errors from '@/config/InputErrors'
import { mapState } from 'vuex'
import ListHeader from '@/custom-components/ListHeader'

export default {
  name: 'MeasurementLoading',
  components: {
    NetworkTree,
    ValidationReadings,
    ListHeader
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.fetchPeriods()
    this.fetchLastReportDate()
    this.handlePermission()
  },
  data () {
    return {
      hideLastNode: true,
      hasPermission: true,
      loading: false,
      searchEnabled: false,
      selectedMeter: {},
      search: {},
      periods: [],
      lastReportDate: null,
      validateEmptyReading: null,
      bulkValidation: null,
      bulkEstimation: null,
      readings: [],
      errors
    }
  },
  methods: {
    async fetchPeriods () {
      const [, response, responseData] = await handlePromise(PeriodService.getPeriods({ limit: 0 }))
      if (response.ok) this.periods = responseData.data
    },
    async fetchLastReportDate () {
      const [, response, responseData] = await handlePromise(MeterReadingForecastService.getLastReport())
      if (response.ok) this.lastReportDate = responseData.data
      this.validateEmptyReading = responseData[1].updateDate
    },
    async noReadingValidations () {
      const [error, response] = await handlePromise(MeterReadingForecastService.noReadingValidation())
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      return this.$store.commit('settings/toggleSuccessAlert', this.$t('bulkValidationStarted'))
    },
    async noReadingValidationsLastReport () {
      const [error, response, data] = await handlePromise(MeterReadingForecastService.noReadingValidationReport())
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      return window.open(data.url)
      // return window.open(data.url),this.$store.commit('settings/toggleSuccessAlert', this.$t('bulkValidationStarted'))
    },
    async searchByNetworkUnit ({ data }) {
      this.toggleLoading()
      const [error, response, responseData] = await handlePromise(MeterService.getMeterBySearch({
        networkUnit: data.networkUnit.id
      }))
      if (!response.ok) {
        this.toggleLoading()
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.selectedMeter = responseData
      this.search = {
        serialNumber: responseData.serialNumber
      }
      this.$refs['form'].validateField('serialNumber')
      setTimeout(() => {
        this.searchMeterReadings()
      }, 100)
    },
    async searchBySerialNumber () {
      this.$refs['form'].validate(async (valid) => {
        this.searchEnabled = false
        if (valid) {
          this.toggleLoading()
          const [error, response, responseData] = await handlePromise(MeterService.getMeterBySearch({
            serialNumber: this.search.serialNumber
          }))
          if (!response.ok) {
            this.toggleLoading()
            return this.$store.commit('settings/toggleAlert', this.$t(error))
          }
          this.selectedMeter = responseData
          this.searchMeterReadings()
        }
      })
    },
    async searchMeterReadings () {
      this.$refs['form'].validate(async (valid) => {
        this.searchEnabled = false
        if (!valid && this.loading) this.toggleLoading()

        if (valid) {
          if (!this.loading) this.toggleLoading()
          const params = { limit: 0 }
          if (this.search.period) params['filters[period]'] = this.search.period

          const [error, response, data] = await handlePromise(MeterReadingForecastService.getMeterReadings(this.selectedMeter.id, params))
          if (this.loading) this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.searchEnabled = true
          this.readings = data
        }
      })
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
