<template>
  <section>
    <ods-module :header="$route.params.id ? $t('editPeriod') : $t('newPeriod')">
      <ods-form
          :model="form"
          ref="form"
          v-loading="loading"
          :rules="validations"
      >
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item
                :label="$t('name')"
                prop="name"
                :rules="[errors.required]"
            >
              <ods-input v-model="form.name"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-if="$route.params.id">
            <ods-form-item :label="$t('status')" prop="status">
              <ods-select v-model="form.status" placeholder="">
                <ods-option
                    v-for="item in statuses"
                    :key="item.id"
                    :label="$t(item.name)"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="8">
            <ods-form-item :label="$t('initialDate')" prop="initialDate">
              <ods-date-picker
                  type="date"
                  :clearable="false"
                  v-model="form.initialDate"
                  style="width: 100%"
                  @change="onChangeDates()"
                  disabled
                  />
            </ods-form-item>
          </ods-col>
          <ods-col :md="8">
            <ods-form-item :label="$t('lastDayReadingsCapture')" prop="lastDayReadingsCapture">
              <ods-date-picker
                  type="date"
                  :clearable="false"
                  v-model="form.lastDayReadingsCapture"
                  style="width: 100%"
                   @change="onChangeDates()"
                   disabled
                  />
            </ods-form-item>
          </ods-col>
          <ods-col :md="8">
            <ods-form-item :label="$t('closureDate')" prop="closureDate">
              <ods-date-picker
                  type="date"
                  :clearable="false"
                  v-model="form.closureDate"
                  style="width: 100%"
                  @change="onChangeDates()"
                  />
            </ods-form-item>
          </ods-col>
        </ods-row>

        <form-buttons
            @submit="submitForm"
            cancel-to="PeriodDashboard"
        ></form-buttons>
      </ods-form>
    </ods-module>
  </section>
</template>

<script>
import PeriodService from '@/services/Period'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import moment from 'moment'

export default {
  name: 'PeriodEdit',
  components: {
    FormButtons
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      errors,
      validations: {},
      statuses: [
        { id: 'pending', name: 'pending' },
        { id: 'open', name: 'open' },
        { id: 'processing', name: 'processing' },
        { id: 'closed', name: 'closed' }
      ]
    }
  },
  methods: {
    async getData () {
      this.loading = true

      const [error, response, data] = await handlePromise(PeriodService.getPeriodWater(this.$route.params.id))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.form = {
        name: data.name,
        status: data.status,
        year: data.year,
        month: data.month,
        initialDate: data.initialDate ? moment.utc(Number(`${data.initialDate}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00' : null,
        lastDayReadingsCapture: data.lastDayReadingsCapture ? moment.utc(Number(`${data.lastDayReadingsCapture}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00' : null,
        closureDate: data.closureDate ? moment.utc(Number(`${data.closureDate}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00' : null
      }
    },
    onChangeDates () {
      let form2 = this.form
      this.validations = {
        /* initialDate: [
          { required: true, message: 'errors.required', trigger: 'blur' },
          {
            validator (rule, value, callback) {
              return (new Date(value).getTime() - new Date(form2.lastDayReadingsCapture).getTime()) <= 0
            },
            message: 'initialDateLessOrEqualsThanLastReadingDateCapture'
          },
          {
            validator (rule, value, callback) {
              return (new Date(value).getTime() - new Date(form2.closureDate).getTime()) <= 0
            },
            message: 'initialDateLessOrEqualsThanClosureDate'
          }
        ], */
        /* lastDayReadingsCapture: [
          { required: true, message: 'errors.required', trigger: 'blur' },
          {
            validator (rule, value, callback) {
              return (new Date(value).getTime() - new Date(form2.initialDate).getTime()) >= 0
            },
            message: 'lastDayReadingsCaptureGreatherOrEqualsThanInitialDate'
          },
          {
            validator (rule, value, callback) {
              return (new Date(value).getTime() - new Date(form2.closureDate).getTime()) <= 0
            },
            message: 'lastDayReadingsCaptureLessOrEqualsThanClosureDate'
          }
        ] */
        closureDate: [
          { required: true, message: 'errors.required', trigger: 'blur' },
          {
            validator (rule, value, callback) {
              return (new Date(value).getTime() - new Date(form2.initialDate).getTime()) >= 0
            },
            message: 'initialDateGreatherOrEqualsThanInitialDate'
          },
          {
            validator (rule, value, callback) {
              return (new Date(value).getTime() - new Date(form2.lastDayReadingsCapture).getTime()) >= 0
            },
            message: 'initialDateGreatherOrEqualsThanLastReadingDateCapture'
          }
        ]
      }
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const data = {
            name: this.form.name,
            year: this.form.year,
            month: this.form.month,
            status: this.$route.params.id ? this.form.status : undefined,
            initialDate: this.form.initialDate ? moment.utc(this.form.initialDate).format('YYYY-MM-DD') : null,
            lastDayReadingsCapture: this.form.lastDayReadingsCapture ? moment(this.form.lastDayReadingsCapture).format('YYYY-MM-DD') : null,
            closureDate: this.form.closureDate ? moment.utc(this.form.closureDate).format('YYYY-MM-DD') : null
          }

          const [error, response] = await handlePromise(
            this.$route.params.id
              ? PeriodService.updatePeriodWater(this.$route.params.id, data)
              : PeriodService.savePeriodWater(data)
          )
          this.loading = false
          if (!response.ok) {
            return this.$store.commit('settings/toggleAlert', this.$t(error))
          }
          return this.$router.push({ name: 'PeriodDashboard' })
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>
