<template>
  <main class="ods-main" data-name="OdsMain">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: 'OdsMain',
  componentName: 'OdsMain',
  syncStatus: 'aligned'
}
</script>
