<template>
  <section v-loading="loading">
    <ods-module :header="$t('balanceDashboardVertical')">
      <ods-form :model="search" ref="form">
        <ods-row :gutter="20">
          <ods-col :md="4">
            <ods-form-item :label="$t('year')" prop="year">
              <ods-select
                v-model="search.year"
                placeholder=""
                @change="handleChangeYear"
              >
                <ods-option
                  v-for="item in years"
                  :key="item.id"
                  :label="item.value"
                  :value="item.value"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="4">
            <ods-form-item
              :label="$t('period')"
              prop="period"
              :rules="[errors.required]"
            >
              <ods-select v-model="search.period" placeholder="">
                <ods-option
                  v-for="item in periods"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="6">
            <ods-form-item
              :label="$t('balance')"
              prop="balance"
              :rules="[errors.required]"
            >
              <ods-select v-model="search.balance" placeholder="">
                <ods-option
                  v-for="item in balances"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="10" style="text-align: right">
            <ods-button
              size="medium"
              class="mt-1"
              @click="loadData"
              type="primary"
            >
              {{ $t("getBalance") }}
            </ods-button>

            <ods-tooltip placement="top">
              <div slot="content">
                <ods-button
                  type="secundary"
                  size="small"
                  :disabled="
                    dataInputs.length === 0 &&
                    dataOutputs.length === 0 &&
                    balance.length === 0
                  "
                  @click="download('csv')"
                >
                  .CSV
                </ods-button>
                <hr />
                <ods-button
                  type="secundary"
                  size="small"
                  :disabled="
                    dataInputs.length === 0 &&
                    dataOutputs.length === 0 &&
                    balance.length === 0
                  "
                  @click="download('xlsx')"
                >
                  .XLSX
                </ods-button>
              </div>
              <ods-button
                :disabled="dataInputs.length === 0 && dataOutputs.length === 0"
                type="primary"
                class="ml-1"
                size="medium"
                icon="ods-icon-download"
              />
            </ods-tooltip>
          </ods-col>
        </ods-row>
      </ods-form>

      <ods-module class="mb-1" :header="$t('Energy')">
        <ods-table :data="dataInputs" size="mini" :border="true">
          <ods-table-column type="expand" width="65">
            <template slot-scope="props">
              <ods-table
                :data="props.row.meterReadings"
                :border="true"
                size="mini"
              >
                <ods-table-column
                  prop="consumption"
                  :label="$t('consumptions')"
                  align="right"
                >
                  <template slot-scope="scope">
                    {{ scope.row.consumption | formatNumberNoDecimals }}
                  </template>
                </ods-table-column>
              </ods-table>
            </template>
          </ods-table-column>
          <ods-table-column prop="type" :label="$t('type')" width="370px">
            <template slot-scope="scope">
              {{
                scope.row.generationType
                  ? scope.row.generationType
                  : scope.row.customerType
                  ? scope.row.customerType
                  : scope.row.networkElement
                  ? scope.row.networkElement.elementType
                  : "---"
              }}
            </template>
          </ods-table-column>
          <ods-table-column prop="name" :label="$t('name')">
            <template slot-scope="scope">
              {{ scope.row.name ? scope.row.name : "---" }}
            </template>
          </ods-table-column>
          <ods-table-column
            prop="energyTransportDivision"
            :label="$t('transportationDivision')"
          >
            <template slot-scope="scope">
              {{
                scope.row.energyTransportDivision
                  ? scope.row.energyTransportDivision
                  : "---"
              }}
            </template>
          </ods-table-column>
          <ods-table-column prop="region" :label="$t('region')">
            <template slot-scope="scope">
              {{ scope.row.region ? scope.row.region : "---" }}
            </template>
          </ods-table-column>
          <ods-table-column
            prop="commercialOffice"
            :label="$t('commercialOffice')"
          >
            <template slot-scope="scope">
              {{
                scope.row.commercialOffice ? scope.row.commercialOffice : "---"
              }}
            </template>
          </ods-table-column>
          <ods-table-column
            align="right"
            prop="totalConsumption"
            :label="$t('consumption')"
          >
            <template slot-scope="scope">
              {{ scope.row.totalConsumption | formatNumberNoDecimals }}
            </template>
          </ods-table-column>
        </ods-table>
        <ods-table :data="overallInputs" size="mini" border>
          <ods-table-column
            prop="reading"
            :label="$t('inputTotalConsumption')"
            align="right"
          >
            <template slot-scope="scope">
              {{ scope.row.consumption | formatNumberNoDecimals }}
            </template>
          </ods-table-column>
        </ods-table>
      </ods-module>

      <ods-module class="mb-1" :header="$t('billing')" v-if="balanceReady">
        <div class="" v-if="!isTransportBalance">
          <ods-table :data="dataOutputs" size="mini" :border="true">
            <ods-table-column type="expand" width="65">
              <template slot-scope="props">
                <ods-table :data="props.row.units" :border="true" size="mini">
                  <ods-table-column
                    prop="type"
                    :label="$t('type')"
                    width="370px"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.generationType
                          ? scope.row.generationType
                          : scope.row.customerType
                          ? scope.row.customerType
                          : scope.row.networkElement
                          ? scope.row.networkElement.elementType
                          : "---"
                      }}
                    </template>
                  </ods-table-column>
                  <ods-table-column prop="name" :label="$t('name')">
                    <template slot-scope="scope">
                      {{ scope.row.name ? scope.row.name : "---" }}
                    </template>
                  </ods-table-column>

                  <ods-table-column
                    prop="energyTransportDivision"
                    :label="$t('transportationDivision')"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.energyTransportDivision
                          ? scope.row.energyTransportDivision
                          : "---"
                      }}
                    </template>
                  </ods-table-column>

                  <ods-table-column
                    prop="consumption"
                    :label="$t('consumptions')"
                    align="right"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.consumptionByPeriod.consumption
                          | formatNumberNoDecimals
                      }}
                    </template>
                  </ods-table-column>
                </ods-table>
              </template>
            </ods-table-column>
            <!-- <ods-table-column prop="name" :label="$t('name')">
            <template slot-scope="scope">
              {{ scope.row.name ? scope.row.name : "---" }}
            </template>
          </ods-table-column> -->
            <ods-table-column prop="region" :label="$t('region')">
              <template slot-scope="scope">
                {{ scope.row.region ? scope.row.region : "---" }}
              </template>
            </ods-table-column>
            <ods-table-column
              prop="commercialOffice"
              :label="$t('commercialOffice')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.commercialOffice
                    ? scope.row.commercialOffice
                    : "---"
                }}
              </template>
            </ods-table-column>

            <ods-table-column
              align="right"
              prop="consumptionClient"
              :label="$t('consumption')"
            >
              <template slot-scope="scope">
                {{ scope.row.consumptionClient | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
          </ods-table>
        </div>

        <div class="" v-if="isTransportBalance">
          <ods-table :data="dataOutputs" size="mini" :border="true">
            <ods-table-column prop="type" :label="$t('type')" width="370px">
              <template slot-scope="scope">
                {{
                  scope.row.generationType
                    ? scope.row.generationType
                    : scope.row.customerType
                    ? scope.row.customerType
                    : scope.row.networkElement
                    ? scope.row.networkElement.elementType
                    : "---"
                }}
              </template>
            </ods-table-column>
            <ods-table-column prop="name" :label="$t('name')">
              <template slot-scope="scope">
                {{ scope.row.name ? scope.row.name : "---" }}
              </template>
            </ods-table-column>
            <ods-table-column
              prop="energyTransportDivision"
              :label="$t('transportationDivision')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.energyTransportDivision
                    ? scope.row.energyTransportDivision
                    : "---"
                }}
              </template>
            </ods-table-column>

            <ods-table-column prop="region" :label="$t('region')">
              <template slot-scope="scope">
                {{ scope.row.region ? scope.row.region : "---" }}
              </template>
            </ods-table-column>
            <ods-table-column
              prop="commercialOffice"
              :label="$t('commercialOffice')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.commercialOffice
                    ? scope.row.commercialOffice
                    : "---"
                }}
              </template>
            </ods-table-column>
            <ods-table-column
              align="right"
              prop="totalConsumption"
              :label="$t('consumption')"
            >
              <template slot-scope="scope">
                {{ scope.row.totalConsumption | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
          </ods-table>
        </div>

        <ods-table :data="overallOutputs" size="mini" border>
          <ods-table-column
            prop="reading"
            :label="$t('outputTotalConsumption')"
            align="right"
          >
            <template slot-scope="scope">
              {{ scope.row.consumption | formatNumberNoDecimals }}
            </template>
          </ods-table-column>
        </ods-table>
      </ods-module>

      <ods-module class="mb-1" :header="$t('balance')">
        <ods-table :data="overallInputs" size="mini" border>
          <ods-table-column
            prop="consumption"
            :label="$t('inputTotalConsumption')"
            align="right"
          >
            <template slot-scope="scope">
              {{ scope.row.consumption | formatNumberNoDecimals }}
            </template>
          </ods-table-column>
        </ods-table>
        <ods-table :data="overallOutputs" size="mini" border>
          <ods-table-column
            prop="consumption"
            :label="$t('outputTotalConsumption')"
            align="right"
          >
            <template slot-scope="scope">
              {{ scope.row.consumption | formatNumberNoDecimals }}
            </template>
          </ods-table-column>
        </ods-table>
        <ods-table :data="balance" size="mini" border>
          <ods-table-column
            prop="lossesPercent"
            :label="$t('lossesPercent')"
            align="right"
          >
            <template slot-scope="scope">
              {{ scope.row.lossesPercent | formatNumberNoDecimals }} %
            </template>
          </ods-table-column>
          <ods-table-column
            prop="consumption"
            :label="$t('balanceConsumption')"
            align="right"
          >
            <template slot-scope="scope">
              {{ scope.row.consumption | formatNumberNoDecimals }}
            </template>
          </ods-table-column>
        </ods-table>
      </ods-module>
    </ods-module>
  </section>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import errors from "@/config/InputErrors";
import LovService from "@/services/Lov";
import PeriodService from "@/services/Period";
import BalanceService from "@/services/Balance";
import handlePromise from "@/utils/promise";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "MeasurementAnalysis",
  mounted() {
    this.getData();
    this.handlePermission();
  },
  computed: {
    ...mapState({
      userRole: (state) => state.userRoles.data,
    }),
  },
  data() {
    return {
      errors,
      hasPermission: true,
      loading: false,
      balanceReady: false,
      isTransportBalance: false,
      years: [],
      periods: [],
      balances: [],
      search: {},
      dataInputs: [],
      dataOutputs: [],
      overallInputs: [],
      overallOutputs: [],
      balance: [],
    };
  },
  methods: {
    async getData() {
      await this.getYears();
      this.fetchPeriods(this.search.year ?? null);
      this.fetchBalances();
    },
    async getYears() {
      const [, response, years] = await handlePromise(
        LovService.getLov("YEARS")
      );
      if (response.ok) this.years = years.lovValues;

      if (_.find(this.years, { value: moment().year().toString() })) {
        this.search = {
          ...this.search,
          year: moment().year().toString(),
        };
      }
    },
    async fetchPeriods(year) {
      this.toggleLoading();
      let params = {
        limit: 0,
      };

      if (year) {
        params = { ...params, year: year };
      }
      params["sortby[initialDate]"] = "DESC";
      const [, response, responseData] = await handlePromise(
        PeriodService.getPeriods(params)
      );
      this.toggleLoading();
      if (response.ok) this.periods = responseData.data;
    },
    async fetchBalances() {
      this.toggleLoading();
      const params = {
        limit: 0,
      };
      const [, response, responseData] = await handlePromise(
        BalanceService.getConfigurationBalances(params)
      );
      this.toggleLoading();
      if (response.ok) this.balances = responseData.data;
    },
    async loadData() {
      this.balanceReady = false;
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const params = {
            period: this.search.period,
            configurationBalance: this.search.balance,
          };
          await this.getInputs(params);
          await this.getOutputs(params);

          this.balance = [
            {
              lossesPercent:
                this.overallInputs &&
                this.overallInputs.length &&
                this.overallInputs.length > 0 &&
                this.overallInputs[0].consumption !== 0
                  ? ((this.overallInputs[0].consumption -
                      this.overallOutputs[0].consumption) /
                      this.overallInputs[0].consumption) *
                    100
                  : 0,
              consumption:
                this.overallInputs[0].consumption -
                this.overallOutputs[0].consumption,
            },
          ];

          this.balanceReady = true;
        }
      });
    },

    async getInputs(params) {
      this.toggleLoading();
      const [, response, responseData] = await handlePromise(
        BalanceService.getBalanceInput(params)
      );
      if (response.ok) {
        this.dataInputs = _.map(responseData.data, (d) => {
          return {
            ...d,
            totalConsumption: d.consumptionByPeriod.consumption,
            // _.includes(d.generationType, "Exporta") ||
            // _.includes(d.customerType, "Exporta")
            //   ? -_.sumBy(d.meterReadings, (c) => {
            //       return c.consumption;
            //     })
            //   : _.sumBy(d.meterReadings, (c) => {
            //       return c.consumption;
            //     }),
          };
        });
        this.overallInputs = [
          {
            consumption: _.sumBy(this.dataInputs, (c) => {
              return c.totalConsumption;
            }),
          },
        ];
      }
    },

    async getOutputs(params) {
      this.isTransportBalance = !!(
        _.find(this.balances, { id: this.search.balance }) &&
        _.find(this.balances, { id: this.search.balance }).balanceType.includes(
          "transporte"
        )
      );
      const [, response, responseData] = await handlePromise(
        BalanceService.getBalanceOutput(params)
      );

      if (response.ok) {
        let data = [];

        if (this.isTransportBalance) {
          responseData.data.map((d) => {
            d.units.map((u) => {
              data.push(u);
            });
          });
        }

        this.dataOutputs = this.isTransportBalance
          ? _.map(data, (d) => {
              return {
                ...d,
                totalConsumption: d.consumptionByPeriod.consumption,
                // _.includes(d.generationType, 'Exporta') ||
                // _.includes(d.customerType, 'Exporta')
                //   ? -_.sumBy(d.consumptionByPeriod, (c) => {
                //     console.log(c, "C");
                //     return c.consumption
                //   })
                //   : _.sumBy(d.consumptionByPeriod, (c) => {
                //     console.log(c, "C2");
                //     return c.consumption
                //   })
              };
            })
          : responseData.data;
        this.overallOutputs = [
          {
            consumption: await _.sumBy(responseData.data, (c) => {
              return c.consumptionClient;
            }),
          },
        ];
      }
      this.toggleLoading();
    },

    toggleLoading() {
      this.loading = !this.loading;
    },

    async download(type) {
      const Separator = [" ", " ", " ", " ", " "];

      const InputsTitle = ["Energy", " ", " ", " ", " "];
      const InputsHeaders = [
        "Name",
        "ASC",
        "Region",
        "Transportation Division",
        "Consumption",
      ];
      const InputsData = await _.map(this.dataInputs, (d) => {
        return [
          d.name ? d.name : "---",
          d.commercialOffice ? d.commercialOffice : "---",
          d.region ? d.region : "---",
          d.energyTransportDivision ? d.energyTransportDivision : "---",
          d.totalConsumption
            ? Vue.filter("formatNumberNoDecimals")(d.totalConsumption)
            : "---",
        ];
      });

      const OverallInputs = [
        " ",
        " ",
        " ",
        " ",
        this.overallInputs && this.overallInputs.length > 0
          ? Vue.filter("formatNumberNoDecimals")(
              this.overallInputs[0].consumption
            )
          : 0,
      ];

      const OutputsTitle = ["Billing", " ", " ", " ", " "];
      const OutputsHeaders = this.isTransportBalance
        ? ["Name", "ASC", "Region", "Transportation Division", "Consumption"]
        : ["Name", "ASC", "Region", " ", "Consumption"];

      const OutputsData = await _.map(this.dataOutputs, (d) => {
        if (this.isTransportBalance) {
          return [
            d.name ? d.name : "---",
            d.commercialOffice ? d.commercialOffice : "---",
            d.region ? d.region : "---",
            d.energyTransportDivision ? d.energyTransportDivision : "---",
            d.totalConsumption
              ? Vue.filter("formatNumberNoDecimals")(d.totalConsumption)
              : "---",
          ];
        } else {
          return [
            d.name ? d.name : "---",
            d.commercialOffice ? d.commercialOffice : "---",
            d.region ? d.region : "---",
            " ",
            d.consumptionClient
              ? Vue.filter("formatNumberNoDecimals")(d.consumptionClient)
              : "---",
          ];
        }
      });

      const OverallOutputs = [
        " ",
        " ",
        " ",
        " ",
        this.overallOutputs && this.overallOutputs.length > 0
          ? Vue.filter("formatNumberNoDecimals")(
              this.overallOutputs[0].consumption
            )
          : 0,
      ];

      const BalanceTitle = [" ", " ", " ", "Losses %", "Balance"];

      const BalanceData = [
        " ",
        " ",
        " ",
        Vue.filter("formatNumberNoDecimals")(
          this.balance &&
            this.balance.length > 0 &&
            this.balance[0].lossesPercent
            ? this.balance[0].lossesPercent
            : 0
        ),
        Vue.filter("formatNumberNoDecimals")(
          this.overallOutputs &&
            this.overallOutputs.length > 0 &&
            this.overallInputs &&
            this.overallInputs.length > 0
            ? this.overallInputs[0].consumption -
                this.overallOutputs[0].consumption
            : 0
        ),
      ];

      const data = [
        InputsTitle,
        InputsHeaders,
        ...InputsData,
        OverallInputs,
        Separator,
        OutputsTitle,
        OutputsHeaders,
        ...OutputsData,
        OverallOutputs,
        Separator,
        BalanceTitle,
        BalanceData,
      ];

      const sheetName = `Balance of ${
        _.find(this.periods, { id: this.search.period }).name
      }`;

      const name = "BALANCE";
      this.$store.commit("exports/downloadEXCEL", {
        data: data,
        sheetName: sheetName,
        name: name,
        type: type,
      });
    },

    async handleChangeYear() {
      await this.fetchPeriods(this.search.year);
    },

    handlePermission() {
      let rolesArray = Object.values(this.userRole);
      rolesArray.map((role) => {
        if (role === "ROLE_OPERATOR") {
          this.hasPermission = false;
          rolesArray.map((role2) => {
            if (role2 === "ROLE_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_SUPER_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_MANAGER") {
              this.hasPermission = true;
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.invalid {
  color: rgb(255, 0, 0);
}
</style>
