<template>
  <dialog-form
      :title="`${$t('moveNode')} ${from.networkUnit ? from.networkUnit.name : ''}`"
      :translateTitle="false"
      :width="`80%`"
      :visible="visible"
      @submit="submitForm"
      @cancel="$emit('cancel')"
  >
  <ods-row :gutter="20" type="flex" v-loading="loading">
    <ods-col :md="7">
      <network-tree
          @node-click="loadNodeData"
          @toggle-loading="toggleLoading"
          ref="treeComp"
      >
      </network-tree>
      <ods-module :header="$t('destination')">
          <ods-form
              :model="form"
              ref="form"
              v-loading="loading"
          >
            <ods-row :gutter="20">
              <ods-col :md="20">
                <ods-form-item :label="$t('selectedNode')" prop="destination">
                  <ods-select v-model="form.destination" disabled>
                    <ods-option
                        v-for="item in destinations"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
            </ods-row>
          </ods-form>
      </ods-module>
    </ods-col>
     <ods-col :md="16">
        <network-graph v-if="chartData.categories"
          :chartData="chartData"
          @node-click="loadNodeData"
        >
        </network-graph>
    </ods-col>
  </ods-row>
  </dialog-form>
</template>

<script>
import NetworkNodeService from '@/services/NetworkNode'
import handlePromise from '@/utils/promise'
import DialogForm from '@/custom-components/DialogForm'
import NetworkTree from '@/custom-components/Network/TreeWater'
import NetworkGraph from '@/custom-components/Network/NetworkGraph'

export default {
  name: 'MoveNode',
  components: {
    DialogForm,
    NetworkTree,
    NetworkGraph
  },
  props: {
    visible: Boolean,
    from: Object
  },
  data () {
    return {
      loading: false,
      errors: null,
      chartData: {},
      form: {},
      parentLevel: {},
      destinations: []
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.errors = null
      }
    }
  },
  methods: {
    toggleDialog (action = 'cancel') {
      this.$emit(action)
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    selectNode (node) {
      this.destination = node.id
    },
    async loadNodeData ({ data }, loading = true) {
      if (loading) this.toggleLoading()
      const [error2, response2, responseData2] = await handlePromise(NetworkNodeService.getNodeWater(data.firstNode ? data.firstNode.id : data.id))
      if (!response2.ok) return this.$store.commit('settings/toggleAlert', error2)
      this.parentLevel = responseData2.networkUnit.networkElement.order

      if (data.firstNode) {
        this.destinations = [{ id: data.firstNode.id, name: data.firstNode.networkUnit.name }]
        this.form.destination = this.destinations[0]
        const [error, response, responseData] = await handlePromise(NetworkNodeService.getGrapgWater(data.id))
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.chartData = responseData
      } else {
        this.destinations = [{ id: data.id, name: data.name }]
        this.form.destination = this.destinations[0]
      }
      if (loading) this.toggleLoading()
    },
    async submitForm () {
      if (!this.form.destination) {
        this.errors = 'selectDestinationNode'
        return this.$store.commit('settings/toggleAlert', this.$t(this.errors))
      }
      if (this.form.destination && this.from.id !== this.destination) {
        this.toggleLoading()
        const data = {
          destination: this.form.destination.id
        }
        const [error, response] = await handlePromise(NetworkNodeService.updateNodeWater(this.from.id, data))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.toggleDialog('reload')
      }
    }
  }
}
</script>
