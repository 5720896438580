<template>
  <section v-loading="loading">
    <ods-module :header="$t('balanceDashboardHorizontal')">
      <ods-form :model="search" ref="form">
        <ods-row :gutter="20">
          <ods-col :md="3">
            <ods-form-item :label="$t('year')" prop="year">
              <ods-select
                v-model="search.year"
                placeholder=""
                @change="handleChangeYear"
              >
                <ods-option
                  v-for="item in years"
                  :key="item.id"
                  :label="item.value"
                  :value="item.value"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="5">
            <ods-form-item
              :label="$t('period')"
              prop="period"
              :rules="[errors.required]"
            >
              <ods-select v-model="search.period" placeholder="" multiple>
                <ods-option
                  v-for="item in periods"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>

          <ods-col :md="5">
            <ods-form-item
              :label="$t('balance')"
              prop="balance"
              :rules="[errors.required]"
            >
              <ods-select v-model="search.balance" placeholder="">
                <ods-option
                  v-for="item in balances"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="2">
            <ods-checkbox
              style="margin-top: 15px"
              v-model="ltm"
              :border="true"
              size="medium"
            >
              {{ $t("ltmBalance") }}
            </ods-checkbox>
          </ods-col>

          <ods-col :md="9" style="text-align: right">
            <ods-button
              size="medium"
              class="mt-1"
              @click="loadData"
              type="primary"
            >
              {{ $t("getBalance") }}
            </ods-button>

            <ods-tooltip placement="top">
              <div slot="content">
                <ods-button
                  :disabled="!balanceReady"
                  type="secundary"
                  size="small"
                  @click="download('csv')"
                >
                  .CSV
                </ods-button>
                <hr />
                <ods-button
                  :disabled="!balanceReady"
                  type="secundary"
                  size="small"
                  @click="download('xlsx')"
                >
                  .XLSX
                </ods-button>
              </div>
              <ods-button
                :disabled="!balanceReady"
                type="primary"
                class="ml-1"
                size="medium"
                icon="ods-icon-download"
              />
            </ods-tooltip>
          </ods-col>
        </ods-row>
      </ods-form>

      <div v-if="!ltm">
        <ods-module class="mb-1" :header="$t('Energy')">
          <ods-table :data="dataInputs" size="mini" :border="true" v-if="!ltm">
            <ods-table-column prop="type" :label="$t('type')" width="370px">
              <template slot-scope="scope">
                {{
                  scope.row.generationType
                    ? scope.row.generationType
                    : scope.row.customerType
                    ? scope.row.customerType
                    : scope.row.networkElement
                    ? scope.row.networkElement.elementType
                    : "---"
                }}
              </template>
            </ods-table-column>
            <ods-table-column prop="name" :label="$t('name')">
              <template slot-scope="scope">
                {{ scope.row.name ? scope.row.name : "---" }}
              </template>
            </ods-table-column>
            <ods-table-column
              prop="energyTransportDivision"
              :label="$t('transportationDivision')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.energyTransportDivision
                    ? scope.row.energyTransportDivision
                    : "---"
                }}
              </template>
            </ods-table-column>
            <ods-table-column prop="region" :label="$t('region')">
              <template slot-scope="scope">
                {{ scope.row.region ? scope.row.region : "---" }}
              </template>
            </ods-table-column>
            <ods-table-column
              prop="commercialOffice"
              :label="$t('commercialOffice')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.commercialOffice
                    ? scope.row.commercialOffice
                    : "---"
                }}
              </template>
            </ods-table-column>
            <ods-table-column
              prop="periodsValues"
              v-for="period in search.period"
              :key="period"
              :label="periods.find((p) => p.id === period).name"
            >
              <template slot-scope="scope">
                {{
                  scope.row.periodsValues.find((d) => d.period === period)
                    ? scope.row.periodsValues.find((d) => d.period === period)
                        .totalConsumption
                    : "---"
                }}
              </template>
            </ods-table-column>
          </ods-table>

          <ods-table :data="overallInputs" size="mini" border v-if="!ltm">
            <ods-table-column
              align="right"
              prop="periodsValues"
              v-for="period in search.period"
              :key="period"
              :label="periods.find((p) => p.id === period).name"
            >
              <template slot-scope="scope">
                {{
                  scope.row.periodsValues.find((d) => d.period === period)
                    ? scope.row.periodsValues.find((d) => d.period === period)
                        .consumption
                    : "---"
                }}
              </template>
            </ods-table-column>
          </ods-table>
        </ods-module>

        <ods-module class="mb-1" :header="$t('billing')" v-if="balanceReady">
          <div v-if="!isTransportBalance">
            <ods-table
              :data="dataOutputs"
              size="mini"
              :border="true"
              v-if="!ltm"
            >
              <ods-table-column type="expand" width="65">
                <template slot-scope="props">
                  <ods-table :data="props.row.units" :border="true" size="mini">
                    <ods-table-column
                      prop="type"
                      :label="$t('type')"
                      width="370px"
                    >
                      <template slot-scope="scope">
                        {{
                          scope.row.generationType
                            ? scope.row.generationType
                            : scope.row.customerType
                            ? scope.row.customerType
                            : scope.row.networkElement
                            ? scope.row.networkElement.elementType
                            : "---"
                        }}
                      </template>
                    </ods-table-column>
                    <ods-table-column prop="name" :label="$t('name')">
                      <template slot-scope="scope">
                        {{ scope.row.name ? scope.row.name : "---" }}
                      </template>
                    </ods-table-column>

                    <ods-table-column
                      prop="energyTransportDivision"
                      :label="$t('transportationDivision')"
                    >
                      <template slot-scope="scope">
                        {{
                          scope.row.energyTransportDivision
                            ? scope.row.energyTransportDivision
                            : "---"
                        }}
                      </template>
                    </ods-table-column>
                    <ods-table-column
                      prop="periodsValues"
                      v-for="period in search.period"
                      :key="period"
                      :label="periods.find((p) => p.id === period).name"
                    >
                      <template slot-scope="scope">
                        {{
                          scope.row.periodsValues.find(
                            (d) => d.period === period
                          )
                            ? scope.row.periodsValues.find(
                                (d) => d.period === period
                              ).totalConsumption
                            : "---"
                        }}
                      </template>
                    </ods-table-column>
                  </ods-table>
                </template>
              </ods-table-column>
              <ods-table-column prop="region" :label="$t('region')">
                <template slot-scope="scope">
                  {{ scope.row.region ? scope.row.region : "---" }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="commercialOffice"
                :label="$t('commercialOffice')"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.commercialOffice
                      ? scope.row.commercialOffice
                      : "---"
                  }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="periodsValues"
                v-for="period in search.period"
                :key="period"
                :label="periods.find((p) => p.id === period).name"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.periodsValues.find((d) => d.period === period)
                      ? scope.row.periodsValues.find((d) => d.period === period)
                          .totalConsumption
                      : "---"
                  }}
                </template>
              </ods-table-column>
            </ods-table>
          </div>

          <div class="" v-if="isTransportBalance">
            <ods-table
              :data="dataOutputs"
              size="mini"
              :border="true"
              v-if="!ltm"
            >
              <ods-table-column prop="type" :label="$t('type')" width="370px">
                <template slot-scope="scope">
                  {{
                    scope.row.generationType
                      ? scope.row.generationType
                      : scope.row.customerType
                      ? scope.row.customerType
                      : scope.row.networkElement
                      ? scope.row.networkElement.elementType
                      : "---"
                  }}
                </template>
              </ods-table-column>
              <ods-table-column prop="name" :label="$t('name')">
                <template slot-scope="scope">
                  {{ scope.row.name ? scope.row.name : "---" }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="energyTransportDivision"
                :label="$t('transportationDivision')"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.energyTransportDivision
                      ? scope.row.energyTransportDivision
                      : "---"
                  }}
                </template>
              </ods-table-column>

              <ods-table-column prop="region" :label="$t('region')">
                <template slot-scope="scope">
                  {{ scope.row.region ? scope.row.region : "---" }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="commercialOffice"
                :label="$t('commercialOffice')"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.commercialOffice
                      ? scope.row.commercialOffice
                      : "---"
                  }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="periodsValues"
                v-for="period in search.period"
                :key="period"
                :label="periods.find((p) => p.id === period).name"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.periodsValues.find((d) => d.period === period)
                      ? scope.row.periodsValues.find((d) => d.period === period)
                          .totalConsumption
                      : "---"
                  }}
                </template>
              </ods-table-column>
            </ods-table>
          </div>

          <ods-table :data="overallOutputs" size="mini" border v-if="!ltm">
            <ods-table-column
              align="right"
              prop="periodsValues"
              v-for="period in search.period"
              :key="period"
              :label="periods.find((p) => p.id === period).name"
            >
              <template slot-scope="scope">
                {{
                  scope.row.periodsValues.find((d) => d.period === period)
                    ? scope.row.periodsValues.find((d) => d.period === period)
                        .consumption
                    : "---"
                }}
              </template>
            </ods-table-column>
          </ods-table>
        </ods-module>

        <ods-module class="mb-1" :header="$t('balance')">
          <ods-table :data="overallInputs" size="mini" border>
            <ods-table-column
              type="expand"
              width="65"
              v-if="byRegionOverallInputs.length > 0"
            >
              <template slot-scope="props">
                <ods-table
                  :data="byRegionOverallInputs"
                  :border="true"
                  size="mini"
                >
                  <ods-table-column
                    prop="region"
                    :label="$t('region')"
                    width="370px"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.label ? scope.row.label : "---" }}
                    </template>
                  </ods-table-column>

                  <ods-table-column
                    prop="periodsValues"
                    v-for="period in search.period"
                    :key="period"
                    :label="periods.find((p) => p.id === period).name"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.periodsValues.find((d) => d.period === period)
                          ? scope.row.periodsValues.find(
                              (d) => d.period === period
                            ).totalConsumption
                          : "---"
                      }}
                    </template>
                  </ods-table-column>
                </ods-table>
              </template>
            </ods-table-column>
            <ods-table-column prop="inputTotalConsumption" align="right">
              <template slot-scope="scope">
                {{ $t("inputTotalConsumption") }}
              </template>
            </ods-table-column>

            <ods-table-column
              align="right"
              prop="periodsValues"
              v-for="period in search.period"
              :key="period"
              :label="periods.find((p) => p.id === period).name"
            >
              <template slot-scope="scope">
                {{
                  scope.row.periodsValues.find((d) => d.period === period)
                    ? scope.row.periodsValues.find((d) => d.period === period)
                        .consumption
                    : "---"
                }}
              </template>
            </ods-table-column>
          </ods-table>
          <ods-table :data="overallOutputs" size="mini" border>
            <ods-table-column
              type="expand"
              width="65"
              v-if="byRegionOverallOutputs.length > 0"
            >
              <template slot-scope="props">
                <ods-table
                  :data="byRegionOverallOutputs"
                  :border="true"
                  size="mini"
                >
                  <ods-table-column
                    prop="region"
                    :label="$t('region')"
                    width="370px"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.label ? scope.row.label : "---" }}
                    </template>
                  </ods-table-column>

                  <ods-table-column
                    prop="periodsValues"
                    v-for="period in search.period"
                    :key="period"
                    :label="periods.find((p) => p.id === period).name"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.periodsValues.find((d) => d.period === period)
                          ? scope.row.periodsValues.find(
                              (d) => d.period === period
                            ).totalConsumption
                          : "---"
                      }}
                    </template>
                  </ods-table-column>
                </ods-table>
              </template>
            </ods-table-column>
            <ods-table-column prop="lossesPercent" align="right">
              <template slot-scope="scope">
                {{ $t("outputTotalConsumption") }}
              </template>
            </ods-table-column>
            <ods-table-column
              align="right"
              prop="periodsValues"
              v-for="period in search.period"
              :key="period"
              :label="periods.find((p) => p.id === period).name"
            >
              <template slot-scope="scope">
                {{
                  scope.row.periodsValues.find((d) => d.period === period)
                    ? scope.row.periodsValues.find((d) => d.period === period)
                        .consumption
                    : "---"
                }}
              </template>
            </ods-table-column>
          </ods-table>
          <ods-table :data="balance" size="mini" border>
            <ods-table-column prop="lossesPercent" align="right">
              <template slot-scope="scope">
                {{ $t(scope.row.periodsValues[0].label) }}
              </template>
            </ods-table-column>
            <ods-table-column
              align="right"
              prop="periodsValues"
              v-for="period in search.period"
              :key="period"
              :label="periods.find((p) => p.id === period).name"
            >
              <template slot-scope="scope">
                {{
                  scope.row.periodsValues.find((d) => d.period === period)
                    ? scope.row.periodsValues.find((d) => d.period === period)
                        .consumption
                    : "---"
                }}
              </template>
            </ods-table-column>
          </ods-table>
        </ods-module>

        <div v-if="delegationsBalance && delegationsBalance.length > 0">
          <div v-for="delegation in delegationsBalance" :key="delegation.label">
            <ods-module class="mb-1" :header="delegation.label">
              <ods-table :data="delegation.input" size="mini" border>
                <ods-table-column prop="inputTotalConsumption" align="right">
                  <template slot-scope="scope">
                    {{ $t("inputTotalConsumption") }}
                  </template>
                </ods-table-column>

                <ods-table-column
                  align="right"
                  prop="periodsValues"
                  v-for="period in search.period"
                  :key="period"
                  :label="periods.find((p) => p.id === period).name"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row &&
                      scope.row.periodsValues &&
                      scope.row.periodsValues.find((d) => d.period === period)
                        ? scope.row.periodsValues.find(
                            (d) => d.period === period
                          ).totalConsumption
                        : "---"
                    }}
                  </template>
                </ods-table-column>
              </ods-table>
              <ods-table :data="delegation.output" size="mini" border>
                <ods-table-column prop="lossesPercent" align="right">
                  <template slot-scope="scope">
                    {{ $t("outputTotalConsumption") }}
                  </template>
                </ods-table-column>
                <ods-table-column
                  align="right"
                  prop="periodsValues"
                  v-for="period in search.period"
                  :key="period"
                  :label="periods.find((p) => p.id === period).name"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row &&
                      scope.row.periodsValues &&
                      scope.row.periodsValues.find((d) => d.period === period)
                        ? scope.row.periodsValues.find(
                            (d) => d.period === period
                          ).totalConsumption
                        : "---"
                    }}
                  </template>
                </ods-table-column>
              </ods-table>
              <ods-table :data="delegation.balance" size="mini" border>
                <ods-table-column prop="lossesPercent" align="right">
                  <template slot-scope="scope">
                    {{ $t(scope.row.periodsValues[0].label) }}
                  </template>
                </ods-table-column>
                <ods-table-column
                  align="right"
                  prop="periodsValues"
                  v-for="period in search.period"
                  :key="period"
                  :label="periods.find((p) => p.id === period).name"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.periodsValues.find((d) => d.period === period)
                        ? scope.row.periodsValues.find(
                            (d) => d.period === period
                          ).consumption
                        : "---"
                    }}
                  </template>
                </ods-table-column>
              </ods-table>
            </ods-module>
          </div>
        </div>
      </div>

      <div v-if="ltm">
        <ods-module class="mb-1" :header="$t('balance')">
          <div>
            <ods-table :data="ltmBalance" size="mini" :border="true" v-if="ltm">
              <ods-table-column type="expand" width="65">
                <template slot-scope="props">
                  <ods-table
                    :data="props.row.periods"
                    :border="true"
                    size="mini"
                  >
                    <ods-table-column prop="period" :label="$t('period')">
                      <template slot-scope="scope">
                        {{ scope.row.period }}
                      </template>
                    </ods-table-column>
                    <ods-table-column
                      prop="input"
                      :label="
                        selectedBalance.name === 'Balanco Distribuciao'
                          ? $t('distributedGrossEnergy')
                          : selectedBalance.name === 'Balanco da Empresa'
                          ? $t('availableGrossEnergy')
                          : selectedBalance.name === 'Balanco de Transporte'
                          ? $t('transportedGrossEnergy')
                          : $t('input')
                      "
                    >
                      <template slot-scope="scope">
                        {{ scope.row.input | formatNumberNoDecimals }}
                      </template>
                    </ods-table-column>
                    <ods-table-column
                      prop="output"
                      :label="
                        selectedBalance.name === 'Balanco Distribuciao'
                          ? $t('billedEnergy')
                          : selectedBalance.name === 'Balanco da Empresa'
                          ? $t('billedEnergy')
                          : selectedBalance.name === 'Balanco de Transporte'
                          ? $t('energyDeliveredToDistribution')
                          : $t('output')
                      "
                    >
                      <template slot-scope="scope">
                        {{ scope.row.output | formatNumberNoDecimals }}
                      </template>
                    </ods-table-column>
                    <ods-table-column
                      prop="balance"
                      :label="
                        selectedBalance.name === 'Balanco Distribuciao'
                          ? $t('MWhLosses')
                          : selectedBalance.name === 'Balanco da Empresa'
                          ? $t('MWhLosses')
                          : selectedBalance.name === 'Balanco de Transporte'
                          ? $t('MWhLosses')
                          : $t('balance')
                      "
                    >
                      <template slot-scope="scope">
                        {{ scope.row.balance | formatNumberNoDecimals }}
                      </template>
                    </ods-table-column>
                    <ods-table-column
                      prop="lossesPercent"
                      :label="$t('lossesPercent')"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.lossesPercent | formatNumberNoDecimals }}
                      </template>
                    </ods-table-column>
                  </ods-table>
                </template>
              </ods-table-column>
              <ods-table-column prop="period" :label="$t('period')">
                <template slot-scope="scope">
                  {{ scope.row.period }}
                </template>
              </ods-table-column>

              <ods-table-column
                prop="input"
                :label="
                  selectedBalance.name === 'Balanco Distribuciao'
                    ? $t('distributedGrossEnergy')
                    : selectedBalance.name === 'Balanco da Empresa'
                    ? $t('availableGrossEnergy')
                    : selectedBalance.name === 'Balanco de Transporte'
                    ? $t('transportedGrossEnergy')
                    : $t('input')
                "
              >
                <template slot-scope="scope">
                  {{ scope.row.input | formatNumberNoDecimals }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="output"
                :label="
                  selectedBalance.name === 'Balanco Distribuciao'
                    ? $t('billedEnergy')
                    : selectedBalance.name === 'Balanco da Empresa'
                    ? $t('billedEnergy')
                    : selectedBalance.name === 'Balanco de Transporte'
                    ? $t('energyDeliveredToDistribution')
                    : $t('output')
                "
              >
                <template slot-scope="scope">
                  {{ scope.row.output | formatNumberNoDecimals }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="balance"
                :label="
                  selectedBalance.name === 'Balanco Distribuciao'
                    ? $t('MWhLosses')
                    : selectedBalance.name === 'Balanco da Empresa'
                    ? $t('MWhLosses')
                    : selectedBalance.name === 'Balanco de Transporte'
                    ? $t('MWhLosses')
                    : $t('balance')
                "
              >
                <template slot-scope="scope">
                  {{ scope.row.balance | formatNumberNoDecimals }}
                </template>
              </ods-table-column>
              <ods-table-column
                prop="lossesPercent"
                :label="$t('lossesPercent')"
              >
                <template slot-scope="scope">
                  {{ scope.row.lossesPercent | formatNumberNoDecimals }}
                </template>
              </ods-table-column>
            </ods-table>
          </div>
        </ods-module>
      </div>
    </ods-module>
  </section>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import errors from "@/config/InputErrors";
import LovService from "@/services/Lov";
import PeriodService from "@/services/Period";
import BalanceService from "@/services/Balance";
import handlePromise from "@/utils/promise";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "MeasurementAnalysis",
  mounted() {
    this.getData();
    this.handlePermission();
  },
  computed: {
    ...mapState({
      userRole: (state) => state.userRoles.data,
    }),
  },
  data() {
    return {
      errors,
      hasPermission: true,
      loading: false,
      balanceReady: false,
      isTransportBalance: false,
      ltm: false,
      years: [],
      periods: [],
      balances: [],
      search: {},
      selectedBalance: {},
      dataInputs: [],
      dataOutputs: [],
      overallInputs: [],
      overallOutputs: [],
      byRegionOverallInputs: [],
      byRegionOverallOutputs: [],
      balance: [],
      ltmBalance: [],
      delegationsBalance: [],
    };
  },
  methods: {
    async getData() {
      await this.getYears();
      this.fetchPeriods(this.search.year ?? null);
      this.fetchBalances();
    },
    async getYears() {
      const [, response, years] = await handlePromise(
        LovService.getLov("YEARS")
      );
      if (response.ok) this.years = years.lovValues;

      if (_.find(this.years, { value: moment().year().toString() })) {
        this.search = {
          ...this.search,
          year: moment().year().toString(),
        };
      }
    },
    async fetchPeriods(year) {
      this.search = { ...this.search, period: [] };
      this.toggleLoading();
      let params = {
        limit: 0,
      };

      if (year) {
        params = { ...params, year: year };
      }
      params["sortby[initialDate]"] = "DESC";
      const [, response, responseData] = await handlePromise(
        PeriodService.getPeriods(params)
      );
      this.toggleLoading();
      if (response.ok) this.periods = responseData.data;
    },
    async fetchBalances() {
      this.toggleLoading();
      const params = {
        limit: 0,
      };
      const [, response, responseData] = await handlePromise(
        BalanceService.getConfigurationBalances(params)
      );
      this.toggleLoading();
      if (response.ok) this.balances = responseData.data;
    },
    async loadData() {
      this.balanceReady = false;
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.ltm) {
            const params = {
              period: this.search.period,
              configurationBalance: this.search.balance,
            };
            await this.getInputs(params);

            await this.getOutputs(params);

            const lossesPercent = _.map(
              this.overallInputs[0].periodsValues,
              (b, index) => ({
                label: "lossesPercent",
                period: b.period,
                consumption:
                  this.overallInputs[0].periodsValues[index]
                    .numberConsumption !== 0
                    ? Vue.filter("formatNumberNoDecimals")(
                        ((this.overallInputs[0].periodsValues[index]
                          .numberConsumption -
                          this.overallOutputs[0].periodsValues[index]
                            .numberConsumption) /
                          this.overallInputs[0].periodsValues[index]
                            .numberConsumption) *
                          100
                      )
                    : 0,
              })
            );

            const balanceConsumption = _.map(
              this.overallOutputs[0].periodsValues,
              (b, index) => {
                const input =
                  this.overallInputs[0].periodsValues[index].numberConsumption;
                const output = b.numberConsumption;
                const consumption = input - output;
                return {
                  label: "balanceConsumption",
                  period: b.period,
                  consumption: Vue.filter("formatNumberNoDecimals")(
                    consumption
                  ),
                };
              }
            );

            const delegationsBalanceAux =
              await this.calculateBalanceDelegations();

            this.delegationsBalance = delegationsBalanceAux;

            this.balance = [
              {
                periodsValues: lossesPercent,
              },
              {
                periodsValues: balanceConsumption,
              },
            ];

            this.balanceReady = true;
            this.toggleLoading();
          } else {
            await this.getLtmBalance();
          }
        }
      });
    },

    async calculateBalanceDelegations() {
      const delegationInputs = _.cloneDeep(this.byRegionOverallInputs);
      const delegationOutputs = _.cloneDeep(this.byRegionOverallOutputs);

      const mergedData = _.uniqBy(
        _.concat(delegationInputs, delegationOutputs),
        (d) => d.label
      );

      const delegationsBalanceAux = await Promise.all(
        mergedData.map(async (b) => {
          let balance = [];

          if (
            _.find(this.byRegionOverallInputs, (d) => d.label === b.label) &&
            _.find(this.byRegionOverallOutputs, (d) => d.label === b.label)
          ) {
            const overallInputs = _.find(
              this.byRegionOverallInputs,
              (d) => d.label === b.label
            );
            const overallOutputs = _.find(
              this.byRegionOverallOutputs,
              (d) => d.label === b.label
            );

            const lossesPercentPromises = overallInputs.periodsValues.map(
              async (b, index) => {
                const input = parseFloat(
                  overallInputs.periodsValues[index].totalConsumption.replace(
                    ",",
                    ""
                  )
                );
                const output = parseFloat(
                  overallOutputs.periodsValues[index].totalConsumption.replace(
                    ",",
                    ""
                  )
                );

                return {
                  label: "lossesPercent",
                  period: b.period,
                  consumption:
                    input !== 0
                      ? Vue.filter("formatNumberNoDecimals")(
                          ((input - output) / input) * 100
                        )
                      : 0,
                };
              }
            );

            const balanceConsumptionPromises = overallOutputs.periodsValues.map(
              async (b, index) => {
                const input = parseFloat(
                  overallInputs.periodsValues[index].totalConsumption.replace(
                    ",",
                    ""
                  )
                );
                const output = parseFloat(b.totalConsumption.replace(",", ""));
                const consumption = input - output;

                return {
                  label: "balanceConsumption",
                  period: b.period,
                  consumption: Vue.filter("formatNumberNoDecimals")(
                    consumption
                  ),
                };
              }
            );

            const lossesPercent = await Promise.all(lossesPercentPromises);
            const balanceConsumption = await Promise.all(
              balanceConsumptionPromises
            );

            balance = [
              { periodsValues: lossesPercent },
              { periodsValues: balanceConsumption },
            ];
          }

          return {
            label: b.label,
            input: [
              _.find(this.byRegionOverallInputs, (d) => d.label === b.label),
            ] ?? [
              {
                periodsValues: [],
                label: b.label,
              },
            ],
            output: [
              _.find(this.byRegionOverallOutputs, (d) => d.label === b.label),
            ] ?? [
              {
                periodsValues: [],
                label: b.label,
              },
            ],
            balance: balance,
          };
        })
      );

      return delegationsBalanceAux;
    },

    async getLtmBalance() {
      this.toggleLoading();
      const [error, response, responseData] = await handlePromise(
        BalanceService.getBalanceLtm({
          periods: _.map(this.search.period),
          configurationBalance: this.search.balance,
        })
      );
      this.toggleLoading();
      this.ltmBalance = _.map(responseData, (b) => {
        const keysArray = Object.keys(b.inputs);
        return {
          period: `${moment(keysArray[0], "YYYYMM").format(
            "MM-YYYY"
          )} / ${moment(_.last(keysArray), "YYYYMM").format("MM-YYYY")}`,
          periods: _.map(b.inputs, (p, k) => {
            const input = p;
            const output = b.outputs[k] ?? 0;
            return {
              period: `${moment(k, "YYYYMM").format("MM-YYYY")}`,
              input: input,
              output: output,
              balance: input - output,
              lossesPercent:
                input !== 0 ? ((input - output) / output) * 100 : 0,
            };
          }),
          input: b.totalInputs,
          output: b.totalOutputs,
          balance: b.totalInputs - b.totalOutputs,
          lossesPercent:
            b.totalInputs !== 0
              ? ((b.totalInputs - b.totalOutputs) / b.totalInputs) * 100
              : 0,
        };
      });
      this.balanceReady = true;
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
    },

    async getInputs(params) {
      this.toggleLoading();
      this.dataInputs = [];
      this.overallInputs = [];
      let index = 0;
      let unformatedDataInputs = [];
      let unformatedoverallInputs = [];
      let byRegionOverallInputs = [];
      let baseByRegionOverallInputs = [];

      while (index < params.period.length) {
        const [error, response, responseData] = await handlePromise(
          BalanceService.getBalanceInput({
            ...params,
            period: params.period[index],
          })
        );
        if (!response.ok) {
          index = params.period.length;
          if (this.loading) this.toggleLoading();
          return this.$store.commit("settings/toggleAlert", this.$t(error));
        }
        unformatedDataInputs = [
          ...unformatedDataInputs,
          {
            [params.period[index]]: {
              period: params.period[index],
              data: _.map(responseData.data, (d) => {
                return {
                  ...d,
                  totalConsumption: d.consumptionByPeriod.consumption,
                };
              }),
            },
          },
        ];

        if (
          responseData.outputByRegions &&
          responseData.outputByRegions.length > 0
        ) {
          byRegionOverallInputs = [
            ...byRegionOverallInputs,
            {
              [params.period[index]]: {
                period: params.period[index],
                data:
                  responseData.outputByRegions &&
                  responseData.outputByRegions.length > 0
                    ? await _.map(responseData.outputByRegions, (d) => {
                        return {
                          ...d,
                          totalConsumption: d.value,
                        };
                      })
                    : [],
              },
            },
          ];

          await _.map(byRegionOverallInputs, async (k) => {
            await _.map(k, async (k2) => {
              await _.map(k2.data, async (d, index) => {
                baseByRegionOverallInputs.push({
                  ...d,
                  periodsValues: [
                    {
                      period: k2.period,
                      totalConsumption:
                        d && d.totalConsumption
                          ? Vue.filter("formatNumberNoDecimals")(
                              d.totalConsumption
                            )
                          : 0,
                    },
                  ],
                });
              });
            });
          });
        }

        unformatedoverallInputs.push({
          periodsValues: {
            period: params.period[index],
            numberConsumption: _.sumBy(
              unformatedDataInputs[index][params.period[index]].data,
              (c) => {
                return c.totalConsumption;
              }
            ),
            consumption: Vue.filter("formatNumberNoDecimals")(
              _.sumBy(
                unformatedDataInputs[index][params.period[index]].data,
                (c) => {
                  return c.totalConsumption;
                }
              )
            ),
          },
        });

        index++;
      }

      let baseDataInput = [];

      await _.map(unformatedDataInputs, async (k) => {
        await _.map(k, async (k2) => {
          await _.map(k2.data, async (d, index) => {
            baseDataInput.push({
              ...d,
              periodsValues: [
                {
                  period: k2.period,
                  numberConsumption:
                    d &&
                    d.consumptionByPeriod &&
                    d.consumptionByPeriod.consumption
                      ? d.consumptionByPeriod.consumption
                      : 0,
                  totalConsumption:
                    d &&
                    d.consumptionByPeriod &&
                    d.consumptionByPeriod.consumption
                      ? Vue.filter("formatNumberNoDecimals")(
                          d.consumptionByPeriod.consumption
                        )
                      : 0,
                },
              ],
            });
          });
        });
      });

      const groupedData = _.groupBy(baseDataInput, "_id");
      const unifiedData = _.map(groupedData, (group) => {
        const mergedObject = _.merge({}, ...group);
        const mergedPeriodsValues = _.flatMap(group, "periodsValues");
        mergedObject.periodsValues = mergedPeriodsValues;
        return mergedObject;
      });

      const groupedOverallData = [{ periodsValues: [] }];
      _.map(unformatedoverallInputs, (d) => {
        groupedOverallData[0].periodsValues.push(d.periodsValues);
      });

      this.dataInputs = unifiedData;
      this.overallInputs = groupedOverallData;
      this.byRegionOverallInputs = baseByRegionOverallInputs;
    },

    async getOutputs(params) {
      this.isTransportBalance = !!(
        _.find(this.balances, { id: this.search.balance }) &&
        _.find(this.balances, { id: this.search.balance }).balanceType.includes(
          "transporte"
        )
      );

      this.dataOutputs = [];
      this.overallOutputs = [];
      let index = 0;
      let unformatedDataOutputs = [];
      let unformatedoverallOutputs = [];
      let byRegionOverallOutputs = [];
      let baseByRegionOverallOutputs = [];

      while (index < params.period.length) {
        const [error, response, responseData] = await handlePromise(
          BalanceService.getBalanceOutput({
            ...params,
            period: params.period[index],
          })
        );
        if (!response.ok) {
          index = params.period.length;
          if (this.loading) this.toggleLoading();
          return this.$store.commit("settings/toggleAlert", this.$t(error));
        }
        unformatedDataOutputs = [
          ...unformatedDataOutputs,
          {
            [params.period[index]]: {
              period: params.period[index],
              data: _.map(responseData.data, (d) => {
                return {
                  ...d,
                  totalConsumption: d.consumptionClient,
                };
              }),
            },
          },
        ];

        if (
          responseData.outputByRegions &&
          responseData.outputByRegions.length > 0
        ) {
          byRegionOverallOutputs = [
            ...byRegionOverallOutputs,
            {
              [params.period[index]]: {
                period: params.period[index],
                data:
                  responseData.outputByRegions &&
                  responseData.outputByRegions.length > 0
                    ? await _.map(responseData.outputByRegions, (d) => {
                        return {
                          ...d,
                          totalConsumption: d.value,
                        };
                      })
                    : [],
              },
            },
          ];

          await _.map(byRegionOverallOutputs, async (k) => {
            await _.map(k, async (k2) => {
              await _.map(k2.data, async (d, index) => {
                baseByRegionOverallOutputs.push({
                  ...d,
                  periodsValues: [
                    {
                      period: k2.period,
                      totalConsumption:
                        d && d.totalConsumption
                          ? Vue.filter("formatNumberNoDecimals")(
                              d.totalConsumption
                            )
                          : 0,
                    },
                  ],
                });
              });
            });
          });
        }

        unformatedoverallOutputs.push({
          periodsValues: {
            period: params.period[index],
            numberConsumption: _.sumBy(
              unformatedDataOutputs[index][params.period[index]].data,
              (c) => {
                return c.totalConsumption;
              }
            ),
            consumption: Vue.filter("formatNumberNoDecimals")(
              _.sumBy(
                unformatedDataOutputs[index][params.period[index]].data,
                (c) => {
                  return c.totalConsumption;
                }
              )
            ),
          },
        });

        index++;
      }

      let baseDataOutputs = [];

      await _.map(unformatedDataOutputs, async (k) => {
        await _.map(k, async (k2) => {
          if (this.isTransportBalance) {
            await _.map(k2.data, async (du, index) => {
              await _.map(du.units, (d) => {
                baseDataOutputs.push({
                  ...d,
                  periodsValues: [
                    {
                      period: k2.period,
                      numberConsumption:
                        d &&
                        d.consumptionByPeriod &&
                        d.consumptionByPeriod.consumption
                          ? d.consumptionByPeriod.consumption
                          : 0,
                      totalConsumption:
                        d &&
                        d.consumptionByPeriod &&
                        d.consumptionByPeriod.consumption
                          ? Vue.filter("formatNumberNoDecimals")(
                              d.consumptionByPeriod.consumption
                            )
                          : 0,
                    },
                  ],
                });
              });
            });
          } else {
            await _.map(k2.data, async (d, index) => {
              const units = _.map(d.units, (u) => ({
                ...u,
                periodsValues: [
                  {
                    period: k2.period,
                    numberConsumption:
                      u &&
                      u.consumptionByPeriod &&
                      u.consumptionByPeriod.consumption
                        ? u.consumptionByPeriod.consumption
                        : 0,
                    totalConsumption:
                      u &&
                      u.consumptionByPeriod &&
                      u.consumptionByPeriod.consumption
                        ? Vue.filter("formatNumberNoDecimals")(
                            u.consumptionByPeriod.consumption
                          )
                        : 0,
                  },
                ],
              }));

              baseDataOutputs.push({
                ...d,
                units: units,
                periodsValues: [
                  {
                    period: k2.period,
                    numberConsumption:
                      d && d.consumptionClient ? d.consumptionClient : 0,
                    totalConsumption:
                      d && d.consumptionClient
                        ? Vue.filter("formatNumberNoDecimals")(
                            d.consumptionClient
                          )
                        : 0,
                  },
                ],
              });
            });
          }
        });
      });

      const groupedData = _.groupBy(
        baseDataOutputs,
        this.isTransportBalance ? "_id" : "name"
      );

      const unifiedData = _.map(groupedData, (group) => {
        const mergedObject = _.merge({}, ...group);
        const mergedPeriodsValues = _.flatMap(group, "periodsValues");
        if (!this.isTransportBalance) {
          const mergedUnits = _.flatMap(group, "units");

          const groupedUnits = _.groupBy(mergedUnits, "_id");
          const unifiedUnits = _.map(groupedUnits, (gu) => {
            const mergedUnitObject = _.merge({}, ...gu);
            const mergedUnitsPeriodsValues = _.flatMap(gu, "periodsValues");
            mergedUnitObject.periodsValues = mergedUnitsPeriodsValues;
            return mergedUnitObject;
          });
          mergedObject.units = unifiedUnits;
        }

        mergedObject.periodsValues = mergedPeriodsValues;
        return mergedObject;
      });

      const groupedOverallData = [{ periodsValues: [] }];
      _.map(unformatedoverallOutputs, (d) => {
        groupedOverallData[0].periodsValues.push(d.periodsValues);
      });

      this.byRegionOverallOutputs = baseByRegionOverallOutputs;
      this.dataOutputs = unifiedData;
      this.overallOutputs = groupedOverallData;
    },

    toggleLoading() {
      this.loading = !this.loading;
    },

    async download(type) {
      let data = [];
      if (!this.ltm) {
        const Separator = [" ", " ", " ", " ", " ", " "];

        const InputsTitle = ["Energy", " ", " ", " ", " "];
        const InputsHeaders = [
          "Type",
          "Name",
          "Transportation Division",
          "Region",
          "ASC",
          ..._.map(
            this.search.period,
            (period) => this.periods.find((p) => p.id === period).name
          ),
        ];

        const InputsData = await _.map(this.dataInputs, (d) => {
          return [
            d.generationType
              ? d.generationType
              : d.customerType
              ? d.customerType
              : d.networkElement
              ? d.networkElement.elementType
              : "---",
            d.name ? d.name : "---",
            d.energyTransportDivision ? d.energyTransportDivision : "---",
            d.region ? d.region : "---",
            d.commercialOffice ? d.commercialOffice : "---",
            ..._.map(this.search.period, (period) => {
              if (d.periodsValues.find((d) => d.period === period)) {
                const value = Vue.filter("formatNumberNoDecimals")(
                  d.periodsValues.find((d) => d.period === period)
                    .totalConsumption
                );
                return value;
              }
              return "---";
            }),
          ];
        });

        const OverallInputs = [
          " ",
          " ",
          " ",
          " ",
          " ",
          ..._.map(this.search.period, (period) => {
            if (
              this.overallInputs[0].periodsValues.find(
                (d) => d.period === period
              )
            ) {
              const value = Vue.filter("formatNumberNoDecimals")(
                this.overallInputs[0].periodsValues.find(
                  (d) => d.period === period
                ).consumption
              );

              return value;
            }
            return "---";
          }),
        ];

        const OutputsTitle = ["Billing", " ", " ", " ", " "];
        const OutputsHeaders = [
          "Name",
          "ASC",
          "Region",
          "Transportation Division",
          "Consumption",
          ..._.map(
            this.search.period,
            (period) => this.periods.find((p) => p.id === period).name
          ),
        ];

        const OutputsData = [];
        await _.map(this.dataOutputs, async (d) => {
          if (this.isTransportBalance) {
            OutputsData.push([
              d.generationType
                ? d.generationType
                : d.customerType
                ? d.customerType
                : d.networkElement
                ? d.networkElement.elementType
                : "---",
              d.name ? d.name : "---",
              d.energyTransportDivision ? d.energyTransportDivision : "---",
              d.region ? d.region : "---",
              d.commercialOffice ? d.commercialOffice : "---",
              ..._.map(this.search.period, (period) => {
                if (d.periodsValues.find((d) => d.period === period)) {
                  const value = Vue.filter("formatNumberNoDecimals")(
                    d.periodsValues.find((d) => d.period === period)
                      .totalConsumption
                  );
                  return value;
                }
                return "---";
              }),
            ]);
          } else {
            await _.map(d.units, (u) => {
              OutputsData.push([
                u.generationType
                  ? u.generationType
                  : u.customerType
                  ? u.customerType
                  : u.networkElement
                  ? u.networkElement.elementType
                  : "---",
                u.name ? u.name : "---",
                u.energyTransportDivision ? u.energyTransportDivision : "---",
                u.region ? u.region : "---",
                u.commercialOffice ? u.commercialOffice : "---",

                ..._.map(this.search.period, (period) => {
                  if (u.periodsValues.find((d) => d.period === period)) {
                    const value = Vue.filter("formatNumberNoDecimals")(
                      u.periodsValues.find((d) => d.period === period)
                        .totalConsumption
                    );
                    return value;
                  }
                  return "---";
                }),
              ]);
            });
          }
        });

        const OverallOutputs = [
          " ",
          " ",
          " ",
          " ",
          " ",
          ..._.map(this.search.period, (period) => {
            if (
              this.overallOutputs[0].periodsValues.find(
                (d) => d.period === period
              )
            ) {
              const value = Vue.filter("formatNumberNoDecimals")(
                this.overallOutputs[0].periodsValues.find(
                  (d) => d.period === period
                ).consumption
              );

              return value;
            }
            return "---";
          }),
        ];

        const BalanceTitle = [
          " ",
          " ",
          " ",
          " ",
          " ",
          ..._.map(
            this.search.period,
            (period) => this.periods.find((p) => p.id === period).name
          ),
        ];

        const BalanceData = [
          [
            ...[" ", " ", " ", " ", "Input total consumption"],
            ..._.map(this.search.period, (period) => {
              if (
                this.overallInputs[0].periodsValues.find(
                  (d) => d.period === period
                )
              ) {
                const value = Vue.filter("formatNumberNoDecimals")(
                  this.overallInputs[0].periodsValues.find(
                    (d) => d.period === period
                  ).consumption
                );

                return value;
              }
              return "---";
            }),
          ],
          [
            ...[" ", " ", " ", " ", "Output total consumption"],
            ..._.map(this.search.period, (period) => {
              if (
                this.overallOutputs[0].periodsValues.find(
                  (d) => d.period === period
                )
              ) {
                const value = Vue.filter("formatNumberNoDecimals")(
                  this.overallOutputs[0].periodsValues.find(
                    (d) => d.period === period
                  ).consumption
                );

                return value;
              }
              return "---";
            }),
          ],
        ];

        _.map(this.balance, (b) => {
          const name = b.periodsValues[0].label;
          const values = [];
          _.map(this.search.period, (period) => {
            if (b.periodsValues.find((d) => d.period === period)) {
              const value = Vue.filter("formatNumberNoDecimals")(
                b.periodsValues.find((d) => d.period === period).consumption
              );
              values.push(value);
            } else {
              values.push("---");
            }
          });
          BalanceData.push([" ", " ", " ", " ", this.$t(name), ...values]);
        });

        data = [
          InputsTitle,
          InputsHeaders,
          ...InputsData,
          OverallInputs,
          Separator,
          OutputsTitle,
          OutputsHeaders,
          ...OutputsData,
          OverallOutputs,
          Separator,
          BalanceTitle,
          ...BalanceData,
        ];
      }

      if (this.ltm) {
        const balanceHeader = [
          this.$t("period"),
          this.selectedBalance.name === "Balanco Distribuciao"
            ? this.$t("distributedGrossEnergy")
            : this.selectedBalance.name === "Balanco da Empresa"
            ? this.$t("availableGrossEnergy")
            : this.selectedBalance.name === "Balanco de Transporte"
            ? this.$t("transportedGrossEnergy")
            : this.$t("input"),
          this.selectedBalance.name === "Balanco Distribuciao"
            ? this.$t("billedEnergy")
            : this.selectedBalance.name === "Balanco da Empresa"
            ? this.$t("billedEnergy")
            : this.selectedBalance.name === "Balanco de Transporte"
            ? this.$t("energyDeliveredToDistribution")
            : this.$t("output"),
          this.selectedBalance.name === "Balanco Distribuciao"
            ? this.$t("MWhLosses")
            : this.selectedBalance.name === "Balanco da Empresa"
            ? this.$t("MWhLosses")
            : this.selectedBalance.name === "Balanco de Transporte"
            ? this.$t("MWhLosses")
            : this.$t("balance"),
          this.$t("lossesPercent"),
        ];

        let balance = [];
        await _.map(this.ltmBalance, async (b) => {
          balance = [
            ...balance,
            [" ", " ", " ", " ", " "],
            ..._.map(b.periods, (d) => {
              return [
                d.period,
                Vue.filter("formatNumberNoDecimals")(d.input),
                Vue.filter("formatNumberNoDecimals")(d.output),
                Vue.filter("formatNumberNoDecimals")(d.balance),
                Vue.filter("formatNumberNoDecimals")(d.lossesPercent),
              ];
            }),
          ];
        });

        data = [balanceHeader, ...balance];
      }

      const sheetName = `Balance of ${_.map(
        this.search.period,
        (period) => this.periods.find((p) => p.id === period).name
      ).join(",")}`;

      const name = "BALANCE";
      this.$store.commit("exports/downloadEXCEL", {
        data: data,
        sheetName: sheetName,
        name: name,
        type: type,
      });
    },

    async handleChangeYear() {
      await this.fetchPeriods(this.search.year);
      this.balance = [];
      this.dataInputs = [];
      this.overallInputs = [];
      this.dataOutputs = [];
      this.overallOutputs = [];
    },

    handlePermission() {
      let rolesArray = Object.values(this.userRole);
      rolesArray.map((role) => {
        if (role === "ROLE_OPERATOR") {
          this.hasPermission = false;
          rolesArray.map((role2) => {
            if (role2 === "ROLE_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_SUPER_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_MANAGER") {
              this.hasPermission = true;
            }
          });
        }
      });
    },
  },
  watch: {
    "search.balance": function (val) {
      this.selectedBalance = this.balances.find((b) => b.id === val);
    },
  },
};
</script>
<style scoped>
.invalid {
  color: rgb(255, 0, 0);
}
</style>
