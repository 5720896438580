<template>
  <dialog-form
      title="newParentWater"
      :visible="visible"
      @submit="submitForm"
      @cancel="toggleDialog"
  >
    <ods-form
        :model="form"
        ref="form"
        v-loading="loading"
    >
      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('name')" prop="name" :rules="[errors.required]">
            <ods-input v-model="form.name"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('originSystemId')" prop="originSystemId" :rules="[errors.required]">
            <ods-input v-model="form.originSystemId"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('technicalLosses')" prop="technicalLosses" :rules="[errors.required]">
            <ods-input v-model="form.technicalLosses"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('networkElement')" prop="networkElement">
            <ods-select v-model="form.networkElement" remote disabled>
              <ods-option
                  v-for="item in networkElements"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('latitude')" prop="lat" :rules="[errors.required]">
            <ods-input v-model="form.lat"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('longitude')" prop="long" :rules="[errors.required]">
            <ods-input v-model="form.long"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('region')" prop="region">
           <ods-select v-model="form.region" remote>
              <ods-option
                  v-for="item in regions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('district')" prop="district">
            <ods-input v-model="form.district"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('location')" prop="location">
            <ods-input v-model="form.location"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('address')" prop="address">
            <ods-input v-model="form.address"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('serialNumber')" prop="serialNumber">
            <ods-input v-model="form.serialNumber"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('manufacturer')" prop="manufacturer">
            <ods-input v-model="form.manufacturer"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('volume')" prop="volume">
            <ods-input v-model="form.volume" type="number"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

    </ods-form>
  </dialog-form>
</template>

<script>
import DialogForm from '@/custom-components/DialogForm'
import errors from '@/config/InputErrors'
import NetworkNodeService from '@/services/NetworkNode'
import NetworkElementService from '@/services/NetworkElement'
import handlePromise from '@/utils/promise'
import attributes from '@/config/data/Attributes'

export default {
  name: 'AddWaterPlant',
  components: {
    DialogForm
  },
  props: {
    visible: Boolean
  },
  data () {
    return {
      form: {
        attributes
      },
      loading: false,
      errors,
      networkElements: [],
      regions: [
        { id: 'north', name: 'NORTH' },
        { id: 'south', name: 'SOUTH' },
        { id: 'east', name: 'EAST' },
        { id: 'west', name: 'WEST' }
      ]
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.getNetworkElements()
      }
    }
  },
  methods: {
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const data = {
            name: this.form.name,
            originSystemId: this.form.originSystemId,
            technicalLosses: this.form.technicalLosses,
            networkElement: this.networkElements[0].id,
            geometry: {
              type: 'Point',
              coordinates: [
                this.form.lat,
                this.form.long
              ]
            },
            district: this.form.district,
            location: this.form.location,
            address: this.form.address,
            serialNumber: this.form.serialNumber,
            manufacturer: this.form.manufacturer,
            volume: this.form.volume,
            region: this.form.region

          }
          const [error, response] = await handlePromise(NetworkNodeService.saveBaseNodeWater(data))
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$(error))
          this.toggleDialog('reload')
        }
      })
    },
    toggleDialog (action = 'cancel') {
      if (this.visible && this.$refs['form']) {
        this.$refs['form'].resetFields()
        this.form = {
          attributes
        }
      }
      this.$emit(action)
    },
    async getNetworkElements () {
      const [, response, data] = await handlePromise(NetworkElementService.getElementsWater({ limit: 0 }))
      if (response.ok) {
        this.networkElements = data.data
        this.form.networkElement = data.data[0].name
      }
    },
    async getAttributes (networkElId) {
      this.loading = true

      const [error, response, data] = await handlePromise(
        NetworkElementService.getElementAttributes(networkElId, { limit: 0 }))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
      this.form.attributes = data.data
    }
  }
}
</script>
