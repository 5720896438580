<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="12">
        <ods-module :header="$route.params.id ? $t('editArea') : $t('newArea')" class="mb-1">
          <ods-form :model="form" ref="form">
            <ods-form-item :label="$t('name')" prop="name" :rules="[errors.required]">
              <ods-input v-model="form.name"></ods-input>
            </ods-form-item>

            <ods-form-item label="" prop="active">
              <ods-checkbox border size="large" v-model="form.active" :label="$t('active')">
              </ods-checkbox>
            </ods-form-item>
            <br>
            <ods-col :md="24">
              <form-buttons class="mb-1" @submit="submitForm" cancel-to="AreaDefinitionDashboard"></form-buttons>
            </ods-col>
          </ods-form>
        </ods-module>
      </ods-col>

      <ods-col :md="12" style="min-height: 100%;">
        <ods-module style="min-height: 100%;">
          <template slot="header">
            <div style="display: flex; justify-content: space-between;">
              <div>{{ $t('selectedNodes') }}
                <div style="font-size: 12px; padding-top: 8px" class="custom-note font-weight-normal">
                  {{ $t('selectNodesInfo') }}
                </div>
              </div>
              <div>
                <ods-button size="small" @click="dialogVisible = true" icon="ods-icon-plus" circle />
              </div>
            </div>
          </template>
          <div class="area-definition--tree__selected">
            <div v-for="item in selectedNodes" :key="item.id">
              <ods-row type="flex">
                <span v-if="findNode(item.id)" @click="deleteNode(item.id)" class="cursor-pointer"
                  style="margin-top: 2px; margin-right: 5px">
                  <ods-icon name="close" size="16" class="custom-note" />
                </span>
                <span v-if="item.networkUnit">{{ item.networkUnit.name }}</span>
                <div style="margin-left: 2px">
                  (<span v-if="item.networkUnit && item.networkUnit.networkElement">{{
                      item.networkUnit.networkElement.elementType
                  }}</span>
                  <span v-if="item.networkTree && item.networkTree.masterName">
                    - {{ item.networkTree.masterName }}
                  </span>
                  <span v-if="item.networkTree && item.networkTree.code">
                    - {{ item.networkTree.code }}
                  </span>
                  )
                </div>
              </ods-row>
            </div>
          </div>
        </ods-module>
      </ods-col>
    </ods-row>
    <ods-dialog :title="$t('networkTree')" :visible.sync="dialogVisible" width="50%" :before-close="handleClose"
      :append-to-body="true">
      <div style="font-size: 12px;" class="custom-note font-weight-normal">
        {{ $t('selectNodesInfo2') }}
      </div>
      <div style="font-size: 12px" class="custom-note font-weight-normal mt-1">
        {{ $t('until') }}
      </div>
      <ods-select v-model="form.until" placeholder="">
        <ods-option v-for="item in untiles" :key="item.id" :label="item.name" :value="item.id">
        </ods-option>
      </ods-select>
      <network-tree v-if="form.until" container-class="area-definition--tree" :selected-nodes="selectedNodes"
        @node-click="addNode" @toggle-loading="toggleLoading">
      </network-tree>
    </ods-dialog>
  </section>
</template>

<script>
import NetworkTree from '@/custom-components/Network/Tree'
import BalanceAreaService from '@/services/BalanceArea'
import NetworkNodeService from '@/services/NetworkNode'
import handlePromise from '@/utils/promise'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import errors from '@/config/InputErrors'
import _ from 'lodash'
import findIndex from 'lodash/findIndex'

export default {
  name: 'MeterManagement',
  components: {
    NetworkTree,
    FormButtons
  },
  mounted() {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data() {
    return {
      dialogVisible: false,
      isFoundparent: false,
      parentsNodes: [],
      loading: false,
      form: {},
      errors,
      selectedNodes: [],
      nodesToSend: [],
      untiles: [{ id: 'PRI', name: 'PRI' }, { id: 'SEC', name: 'SEC' }, { id: 'MET', name: 'MET' }, { id: 'ALL', name: 'ALL' }]

    }
  },
  methods: {
    async getData() {
      this.loading = true
      const [error, response, data] = await handlePromise(BalanceAreaService.getArea(this.$route.params.id))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        name: data.name,
        active: data.active,
      }
      this.nodesToSend.push(data.networkNodes[0])
      this.selectedNodes = data.networkNodes
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const data = {
            name: this.form.name,
            active: this.form.active,
            networkNodes: this.selectedNodes.map(item => item.id)
          }
          this.toggleLoading()
          const [error, response] = await handlePromise(
            this.$route.params.id
              ? BalanceAreaService.updateArea(this.$route.params.id, data)
              : BalanceAreaService.saveArea(data)
          )
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          return this.$router.push({ name: 'AreaDefinitionDashboard' })
        }
      })
    },
    async addNode(node) {
      this.isFoundparent = false
      this.toggleLoading()
      const [error, response] = await handlePromise(
        NetworkNodeService.getNetworkByNode(node.id, this.form.until === 'ALL' ? null : this.form.until)
      )
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      if (this.nodesToSend.length < 1) {
        this.nodesToSend.push(response.data[0])
        response.data.map(item => {
          this.selectedNodes.push(item)
        })
      } else if (this.nodesToSend.length > 0) {
        this.isFoundparent = false
        response.data.map(item2 => {
          if (_.find(this.selectedNodes, { id: item2.id })) {
            this.isFoundparent = true
          }
        })
        if (!_.find(this.selectedNodes, { id: node.id }) && !this.isFoundparent) {
          this.nodesToSend.push(response.data[0])
          response.data.map(item3 => {
            this.selectedNodes.push(item3)
          })
        } else {
          this.$store.commit('settings/toggleAlert', this.$t('ERROR_MESSAGE_INVALID_ORDER_BETWEEN_NODE_AND_PARENT'))
        }
      } else {
        this.$store.commit('settings/toggleAlert', this.$t('ERROR_MESSAGE_INVALID_ORDER_BETWEEN_NODE_AND_PARENT'))
      }
      this.dialogVisible = false
    },
    async deleteNode(id) {
      this.toggleLoading()
      const [error, response] = await handlePromise(
        NetworkNodeService.getNetworkByNode(id, this.form.until === 'ALL' ? null : this.form.until)
      )
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      for (let i = 0; i < this.selectedNodes.length; i++) {
        for (let j = 0; j < response.data.length; j++) {
          if (this.selectedNodes[i].id === response.data[j].id) {
            this.selectedNodes.splice(i, 1)
          }
        }
      }
      const index = findIndex(this.nodesToSend, { id })
      if (index !== -1) this.nodesToSend.splice(index, 1)
    },
    findNode(id) {
      if (_.find(this.nodesToSend, { id: id })) {
        return true
      } else {
        return false
      }
    },
    toggleLoading() {
      this.loading = !this.loading
    },
    handleClose() {
      this.dialogVisible = !this.dialogVisible
    }
  }
}
</script>
