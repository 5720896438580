<template>
  <dialog-form
      :title="`${$t('moveNode')} ${from.networkUnit ? from.networkUnit.name : ''}`"
      :translateTitle="false"
      :width="`80%`"
      :visible="visible"
      @submit="submitForm"
      @cancel="$emit('cancel')"
  >
  <ods-row :gutter="20" type="flex" v-loading="loading">
    <ods-col :md="7">
      <network-tree
          @node-click="loadNodeData"
          @toggle-loading="toggleLoading"
          ref="treeComp"
      >
      </network-tree>
      <ods-module :header="$t('destination')">
          <ods-form
              :model="form"
              ref="form"
              v-loading="loading"
              :rules="validations"
          >
            <ods-row :gutter="20">
              <ods-col :md="20">
                <ods-form-item :label="$t('selectedNode')" prop="destination">
                  <ods-select v-model="form.destination" disabled>
                    <ods-option
                        v-for="item in destinations"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
              <ods-col :md="20">
                <ods-form-item :label="$t('from')" prop="from">
                  <ods-date-picker
                      type="date"
                      :clearable="false"
                      v-model="form.from"
                      :picker-options="{disabledDate: disabledDateFrom}"
                      style="width: 100%"/>
                </ods-form-item>
              </ods-col>
              <ods-col :md="20">
                <ods-form-item :label="$t('to')" prop="to">
                  <ods-date-picker
                      type="date"
                      :clearable="false"
                      v-model="form.to"
                      :picker-options="{disabledDate: disabledDateTo}"
                      style="width: 100%"/>
                </ods-form-item>
              </ods-col>
            </ods-row>
          </ods-form>
      </ods-module>
    </ods-col>
     <ods-col :md="16">
        <network-graph v-if="chartData.categories"
          :chartData="chartData"
          @node-click="loadNodeData"
        >
        </network-graph>
    </ods-col>
  </ods-row>
  </dialog-form>
</template>

<script>
import moment from 'moment'
import NetworkNodeService from '@/services/NetworkNode'
import PeriodService from '@/services/Period'
import handlePromise from '@/utils/promise'
import DialogForm from '@/custom-components/DialogForm'
import NetworkTree from '@/custom-components/Network/TreeWater'
import NetworkGraph from '@/custom-components/Network/NetworkGraph'
import errors from '@/config/InputErrors'

export default {
  name: 'MoveNode',
  components: {
    DialogForm,
    NetworkTree,
    NetworkGraph
  },
  props: {
    visible: Boolean,
    from: Object
  },
  mounted () {
    this.fetchActivePeriods()
  },
  data () {
    return {
      loading: false,
      errors,
      chartData: {},
      form: {
        from: moment().utc()
      },
      parentLevel: {},
      destinations: [],
      validations: {
        destination: [
          { required: true, message: 'errors.required', trigger: 'blur' }
        ],
        from: [
          { required: true, message: 'errors.required', trigger: 'blur' }
        ],
        to: [
          { required: true, message: 'errors.required', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.errors = null
      }
    }
  },
  methods: {
    disabledDateTo (date) {
      return moment(this.form.from).isSameOrAfter(date, 'day')
    },
    disabledDateFrom (date) {
      if (this.form.activePeriod) {
        return moment.utc(new Date(moment.utc(Number(`${this.form.activePeriod.initialDate}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00')).isSameOrAfter(date, 'day')
      }
    },
    async fetchActivePeriods () {
      const [, response, responseData] = await handlePromise(PeriodService.getActivePeriods({ limit: 0 }))
      if (response.ok) {
        this.form = {
          from: moment(),
          activePeriod: responseData,
          ...this.form
        }
      }
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t('activePeriodsNotFound'))
    },
    toggleDialog (action = 'cancel') {
      this.$emit(action)
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    selectNode (node) {
      this.destination = node.id
    },
    async loadNodeData ({ data }, loading = true) {
      if (loading) this.toggleLoading()
      const [error2, response2, responseData2] = await handlePromise(NetworkNodeService.getNodeWater(data.firstNode ? data.firstNode.id : data.id))
      if (!response2.ok) return this.$store.commit('settings/toggleAlert', error2)
      this.parentLevel = responseData2.networkUnit.networkElement.order

      if (data.firstNode) {
        this.destinations = [{ id: data.firstNode.id, name: data.firstNode.networkUnit.name }]
        this.form.destination = this.destinations[0]
        const [error, response, responseData] = await handlePromise(NetworkNodeService.getGrapgWater(data.id))
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.chartData = responseData
      } else {
        this.destinations = [{ id: data.id, name: data.name }]
        this.form.destination = this.destinations[0]
      }
      if (loading) this.toggleLoading()
    },
    async submitForm () {
      if (!this.form.destination) {
        this.errors = 'selectDestinationNode'
        return this.$store.commit('settings/toggleAlert', this.$t(this.errors))
      }
      if (new Date(this.form.from) >= new Date(this.form.to)) {
        this.submitErrors = 'dateGreaterThanFromDate'
        return this.$store.commit('settings/toggleAlert', this.$t(this.submitErrors))
      }
      if (this.form.destination && this.from.id !== this.form.destination) {
        this.toggleLoading()
        const data = {
          destination: this.form.destination.id,
          executeDate: moment.utc(this.form.to).format('YYYY-MM-DD'),
          initialDate: moment.utc(this.form.from).format('YYYY-MM-DD')
        }
        const [error, response] = await handlePromise(NetworkNodeService.updateReconfigurationWater(this.from.id, data))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.toggleDialog('reload')
      }
    }
  }
}
</script>
