<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="7">
        <energy-input-tree
            @node-click="fetchMeter"
            @toggle-loading="toggleLoading"
            :selected-nodes="[selectedNode]"
            :options="{areas, energyTypes}"
        >
        </energy-input-tree>
      </ods-col>

      <ods-col :md="17">
        <ods-module :header="$t('historical')">
          <ods-table
              :data="periods"
              size="mini"
          >
            <ods-table-column prop="period.name" :label="$t('element')"/>
            <ods-table-column :label="$t('input')" >
              <template slot-scope="scope" v-if="scope.row.totalEnergyInput">
                {{ scope.row.totalEnergyInput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="totalEnergyInput" :label="$t('input')"/>-->
            <ods-table-column :label="$t('output')" >
              <template slot-scope="scope" v-if="scope.row.totalEnergyOutput">
                {{ scope.row.totalEnergyOutput | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="totalEnergyOutput" :label="$t('output')"/>-->
            <ods-table-column :label="$t('losses')" >
              <template slot-scope="scope" v-if="scope.row.energyLossesOriginKwh">
                {{ scope.row.energyLossesOriginKwh | formatNumberNoDecimals }}
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="energyLossesOriginKwh" :label="$t('losses')"/>-->
            <ods-table-column :label="$t('lossesPercent')" >
              <template slot-scope="scope" v-if="scope.row.energyLossesOriginPercent">
                {{ scope.row.energyLossesOriginPercent | formatNumberNoDecimals }} %
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="energyLossesOriginPercent" :label="$t('lossesPercent')"/> -->
          </ods-table>
        </ods-module>

        <ods-module class="mt-1 pt-1" v-if="selectedNode">
          <div id="chart" style="height:400px;"></div>
        </ods-module>
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import BalanceAreaService from '@/services/BalanceArea'
import BalanceService from '@/services/Balance'
import handlePromise from '@/utils/promise'
import EnergyInputTree from './Tree'
import * as echarts from 'echarts'
import _ from 'lodash'

export default {
  name: 'MeasurementAnalysis',
  components: {
    EnergyInputTree
  },
  mounted () {
    this.fetchBalanceAreas()
  },
  data () {
    return {
      loading: false,
      selectedNode: null,
      periods: [],
      areas: [],
      energyTypes: [
        { id: 'active', name: 'active' },
        { id: 'reactive', name: 'reactive' },
        { id: 'power', name: 'power' }
      ],
      chart: null,
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          data: []
        },
        yAxis: [],
        series: []
      }
    }
  },
  methods: {
    async fetchBalanceAreas () {
      this.toggleLoading()
      const [, response, responseData] = await handlePromise(BalanceAreaService.getAreas({ limit: 0 }))
      this.toggleLoading()
      if (response.ok) this.areas = responseData.data
    },
    async fetchMeter ({ data }) {
      this.selectedNode = data.networkUnit.id

      this.toggleLoading()
      const [error, response, responseData] = await handlePromise(BalanceService.getBalancePeriodHistory({ node: data.id }))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.periods = responseData[0].balanceNodePeriodCalculations

      setTimeout(() => {
        this.chart = echarts.init(document.getElementById('chart'))
        this.chartOptions = {
          ...this.chartOptions,
          xAxis: {
            data: _.map(this.periods, 'period.name')
          },
          yAxis: [{
            name: 'Output',
            type: 'value'
          },
          {
            type: 'value',
            name: 'Losses %'
          }
          ],
          series: [
            {
              name: 'Input',
              type: 'line',
              data: _.map(this.periods, 'totalEnergyInput'),
              itemStyle: { color: '#2E6C99' }
            },
            {
              name: 'Output',
              type: 'line',
              data: _.map(this.periods, 'totalEnergyOutput'),
              itemStyle: { color: 'red' }
            },
            {
              name: 'Losses',
              type: 'line',
              data: _.map(this.periods, 'energyLossesOriginKwh'),
              itemStyle: { color: '#FFAB20' }
            },
            {
              name: 'Losses %',
              type: 'line',
              yAxisIndex: 1,
              data: _.map(this.periods, 'energyLossesOriginPercent'),
              itemStyle: { color: '#505D66' }
            }
          ]
        }
        this.chart.setOption(this.chartOptions, true)
      }, 100)
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
