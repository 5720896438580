import api from '@/config/Api'

const baseEndpoint = '/network/network-element'
const baseEndpoint2 = '/network/network-element-water'
const baseEndpoint3 = '/network/network-trees-water'

export default {
  getElements (params) {
    return api.get(baseEndpoint, params)
  },

  getElementsWater (params) {
    return api.get(baseEndpoint2, params)
  },

  getNetworkTreesWater (params) {
    return api.get(baseEndpoint3, params)
  },

  getElementAttributes (id, params) {
    return api.get(`${baseEndpoint}/${id}/attribute`, params)
  },

  getElementAttributesWater (id, params) {
    return api.get(`${baseEndpoint2}/${id}/attribute`, params)
  },

  getElement (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  getElementWater (id) {
    return api.get(`${baseEndpoint2}/${id}`)
  },

  getElementAttribute (id, valueId) {
    return api.get(`${baseEndpoint}/${id}/attribute/${valueId}`)
  },

  getElementAttributeWater (id, valueId) {
    return api.get(`${baseEndpoint2}/${id}/attribute/${valueId}`)
  },

  saveElement (data) {
    return api.post(baseEndpoint, data)
  },

  saveElementWater (data) {
    return api.post(baseEndpoint2, data)
  },

  saveElementAttribute (id, data) {
    return api.post(`${baseEndpoint}/${id}/attribute`, data)
  },

  saveElementAttributeWater (id, data) {
    return api.post(`${baseEndpoint2}/${id}/attribute`, data)
  },

  updateElement (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  updateElementWater (id, data) {
    return api.put(`${baseEndpoint2}/${id}`, data)
  },

  updateElementAttribute (id, valueId, data) {
    return api.put(`${baseEndpoint}/${id}/attribute/${valueId}`, data)
  },

  updateElementAttributeWater (id, valueId, data) {
    return api.put(`${baseEndpoint2}/${id}/attribute/${valueId}`, data)
  },

  deleteElement (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  deleteElementWater (id) {
    return api.delete(`${baseEndpoint2}/${id}`)
  },

  deleteElementAttribute (id, valueId) {
    return api.delete(`${baseEndpoint}/${id}/attribute/${valueId}`)
  },

  deleteElementAttributeWater (id, valueId) {
    return api.delete(`${baseEndpoint2}/${id}/attribute/${valueId}`)
  }
}
