<template>
  <section>
    <ods-module :header="$t('lovs')">
      <!-- <template slot="header">
        <list-header
            title="lovs"
            btn-label="newLov"
            url-key="LovNew"
        />
      </template> -->
      <ods-col :md="24" v-if="!hasPermission" style="min-height: 100%;">
        <ods-module style="min-height: 100%">
          <ods-row type="flex" justify="center" style="margin-top: 2rem; font-size:120%">
            {{$t('userDontHaveAcces')}}
          </ods-row>
          <ods-row type="flex" justify="center" style="margin-top: 2rem">
            <ods-icon name="info" size="100" class="custom-placeholder"/>
          </ods-row>
        </ods-module>
      </ods-col>
      <async-table
          v-if="hasPermission"
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <ods-table-column prop="name" :label="$t('name')">
          <template slot-scope="scope">
            <router-link v-if="scope.row.id" :to="{ name: 'LovEdit', params: { id: scope.row.id }}">
              <ods-button type="text" class="p-0">{{scope.row.name}}</ods-button>
            </router-link>
          </template>
        </ods-table-column>

        <ods-table-column prop="code" :label="$t('code')"/>

        <ods-table-column align="right">
          <template slot-scope="scope">
            <table-delete @click="toggleDialog(scope.row.id)"></table-delete>
          </template>
        </ods-table-column>
      </async-table>

      <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDialog()"
      />
    </ods-module>
  </section>
</template>

<script>
import LovService from '@/services/Lov'
import handlePromise from '@/utils/promise'
import AsyncTable from '@/custom-components/AsyncTable'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import asyncTableCommon from '@/mixins/async-table'
import ListHeader from '@/custom-components/ListHeader'
import { mapState } from 'vuex'

export default {
  name: 'LovDashboard',
  components: {
    AsyncTable,
    TableDelete,
    DialogConfirmation,
    ListHeader
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.handlePermission()
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      hasPermission: true,
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      promise: (params) => LovService.getLovs(params)
    }
  },
  methods: {
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(LovService.deleteLov(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
