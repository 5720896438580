<template>
  <section v-loading="loading">
    <ods-row :gutter="20" type="flex">
      <ods-col :md="showDetail ? 7 : 13">
        <network-tree
            @node-click="loadNodeData"
            @toggle-loading="toggleLoading"
            @toggleShowDetail="toggleShowDetail"
            v-bind:detailed="true"
            :showDetail="showDetail"
            ref="treeComp"
        >
        </network-tree>
        <network-graph v-if="chartData.categories"
            :chartData="chartData"
            @node-click="loadNodeData"
            ref="graph"
        >
        </network-graph>
      </ods-col>

      <ods-col :md="11" v-if="nodeForm.id">
        <ods-module>
          <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="6">
                {{$t('network')}}
              </ods-col>
              <ods-col :md="18" class="text-right" v-if="!nodeForm.disabled">
                <ods-button type="secondary"  v-if="hasPermission"  size="small" @click="toggleAddNodeDialog">
                  {{$t('addChild')}}
                </ods-button>
                <ods-button
                    type="warning"
                    size="small"
                    @click="toggleMoveNodeDialog"
                    v-if="nodeForm && nodeForm.extraData2 && !nodeForm.extraData2.networkTree && hasPermission"
                >
                  {{$t('move')}}
                </ods-button>
                <ods-button  v-if="hasPermission" type="danger" size="small" @click="toggleDeleteDialog">
                  {{$t('delete')}}
                </ods-button>
              </ods-col>
            </ods-row>
          </template>

          <edit-node :hasPermission="hasPermission" :tree="tree"
                     :parentLevel="nodeForm.extraData2.networkUnit.networkElement.order"
                     :model="nodeForm"
                     @reloadData="reloadEditNode"
                     >
          </edit-node>
        </ods-module>
      </ods-col>
      <ods-col :md="11" v-if="!nodeForm.id" style="min-height: 100%;">
        <ods-module :header="$t('network')" style="min-height: 100%">
          <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="6">
                {{$t('network')}}
              </ods-col>
              <ods-col :md="18" class="text-right" v-if="hasPermission">
                <ods-button type="secondary" size="small" @click="toggleAddGeneratorDialog">
                  {{$t('newParentWater')}}
                </ods-button>
                <!--<ods-button type="danger" size="small" @click="toggleDeleteDialog">
                  {{$t('delete')}}
                </ods-button>-->
              </ods-col>
            </ods-row>
          </template>
          <ods-row type="flex" justify="center" style="margin-top: 2rem">
            <ods-icon name="info" size="100" class="custom-placeholder"/>
          </ods-row>
        </ods-module>
      </ods-col>
      <ods-col :md="6" v-show="showDetail">
        <ods-module class="pt-1 mb-1">
          <span class="font-weight-bold mb-1">{{$t('colorsLegend').toUpperCase()}}</span>
         <div>
           <ods-icon name="lock" size="15" style="margin-right: 5px; color: #2E6C99"/>{{$t('nodeBlocked')}}
         </div>

          <div class="flex-center" style="justify-content: space-between">
            <div class="flex-center">
              <ods-icon name="legend-point" size="20" style="margin-right: 5px;color: #36B348"/>{{$t('nodeCreated')}}
            </div>
            <div class="flex-center">
              <ods-icon name="legend-point" size="20" style="margin-right: 5px;color: #2E6C99"/>{{$t('nodeMoved')}}
            </div>
            <div class="flex-center">
              <ods-icon name="legend-point" size="20" style="margin-right: 5px;color: #DE2F2F"/>{{$t('nodeDeleted')}}
            </div>
          </div>
        </ods-module>
        <ods-module class="pt-1" v-if="map">
          <div class="google-map" ref="googleMap" style="height: 200px">
          </div>
        </ods-module>

        <ods-module class="pt-1" v-bind:class="{ 'mt-1': !!map }"
                    v-if="logs && logs.id">
          <network-logs
              :data="logs"
              v-bind:isWater="true"
              show-actions
              @toggle-loading="toggleLoading"
              @reload="reloadData"
          ></network-logs>
        </ods-module>
      </ods-col>
    </ods-row>

    <add-node
        v-if="nodeForm.id"
        :visible="addNodeDialogVisible"
        :parent="nodeForm.extraData2.id"
        :tree="tree"
        :parentLevel="nodeForm.extraData2.networkUnit.networkElement.order"
        @cancel="toggleAddNodeDialog"
        @reload="reloadNodeAdded"
    ></add-node>

    <add-generator
        :visible="addGeneratorDialogVisible"
        @cancel="toggleAddGeneratorDialog"
        @reload="reloadGeneratorAdded"
    ></add-generator>

    <move-node
        v-if="nodeForm.id"
        :visible="moveNodeDialogVisible"
        :from="nodeForm.extraData2"
        @cancel="toggleMoveNodeDialog"
        @reload="reloadNodeMoved"
    ></move-node>

    <dialog-confirmation
        :visible="deleteDialogVisible"
        :loading="deleteLoading"
        @confirm="deleteNode"
        @cancel="toggleDeleteDialog"
    />

    <Drawer :direction="'right'" :exist="true" :closeDrawer="closeClients" ref="LeftDrawer">
        <ods-module :header="$t('customers')" style="min-height: 100%">
          <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="16">
                {{$t('customers')}}: {{nodeName}}
              </ods-col>
              <ods-col :md="8" class="text-right">
                <button
                  type="button"
                  class="btn-close"
                  @click="closeClientsDrawer"
                  style="margin-top:-15px"
                  >
                  <ods-icon name = "close" size = "18" />
                </button>
                <!--<ods-button type="danger" size="small" @click="toggleDeleteDialog">
                  {{$t('delete')}}
                </ods-button>-->
              </ods-col>
            </ods-row>
          </template>
          <ods-row type="flex" justify="center">
            <ods-table :data="clientsData" size="mini">
              <ods-table-column :label="$t('name')">
                    <template slot-scope="scope">
                      <ods-button type="text" class="p-0" @click="loadNodeData({data:scope.row})">
                        {{scope.row.name}}
                      </ods-button>
                    </template>
              </ods-table-column>
              <ods-table-column :label="$t('type')" >
                    <template slot-scope="scope">
                      {{ scope.row.type }}
                    </template>
              </ods-table-column>
            </ods-table>
          </ods-row>
        </ods-module>
    </Drawer>
  </section>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import get from 'lodash/get'
import { mapState } from 'vuex'
import NetworkNodeService from '@/services/NetworkNode'
import NetwortManeuverService from '@/services/NetwortManeuver'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import AddGenerator from './AddGenerator.vue'
import AddNode from './AddNode'
import EditNode from './EditNode'
import MoveNode from './MoveNode'
import NetworkTree from '@/custom-components/Network/TreeWater'
import NetworkGraph from '@/custom-components/Network/NetworkGraph'
import Drawer from '@/custom-components/Network/clientList'
import NetworkLogs from '@/custom-components/Network/Logs'
import _ from 'lodash'
import env from 'my-env'

export default {
  name: 'NetworkDashboard',
  components: {
    NetworkTree,
    NetworkGraph,
    AddNode,
    AddGenerator,
    NetworkLogs,
    EditNode,
    MoveNode,
    DialogConfirmation,
    Drawer
  },
  data () {
    return {
      hasPermission: true,
      loading: false,
      nodeForm: {},
      logs: null,
      addGeneratorDialogVisible: false,
      addNodeDialogVisible: false,
      moveNodeDialogVisible: false,
      errors,
      google: null,
      map: null,
      deleteLoading: false,
      deleteDialogVisible: false,
      chartData: {},
      selectedNodes: [],
      tree: String,
      showDetail: true,
      closeClients: false,
      clientsData: [],
      nodeName: ''
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.data,
      userRole: state => state.userRoles.data
    })
  },
  async mounted () {
    this.loadNodeManeuvers()
    this.handlePermission()
    this.google = await GoogleMapsApiLoader({
      apiKey: env.VUE_APP_MAP_API_KEY
    })
  },
  methods: {
    closeClientsDrawer () {
      this.closeClients = !this.closeClients
    },
    openMenu () {
      if (this.$refs.LeftDrawer.active) {
        this.$refs.LeftDrawer.close()
      } else {
        this.$refs.LeftDrawer.open()
      }
    },
    toggleShowDetail () {
      this.showDetail = !this.showDetail
    },
    async loadNodeData ({ data }, loading = true) {
      if (data.type) {
        this.closeClientsDrawer()
      }
      if (loading) this.toggleLoading()
      const [error, response, responseData] = await handlePromise(NetworkNodeService.getNodeWater(data.firstNode ? data.firstNode.id : data.id))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
      if (data.firstNode) {
        this.tree = data.id
        const [error2, response2, responseData2] = await handlePromise(NetworkNodeService.getGrapgWater(data.id))
        if (!response2.ok) return this.$store.commit('settings/toggleAlert', this.$t(error2))
        this.chartData = responseData2
      }

      const [error3, response3, responseData3] = await handlePromise(NetworkNodeService.getClientsWater(data.firstNode ? data.firstNode.id : data.id))
      if (!response3.ok) return this.$store.commit('settings/toggleAlert', this.$t(error3))

      await this.loadNodeManeuvers()
      if (loading) this.toggleLoading()
      if (responseData.networkUnit) {
        let auxNeighbors = []
        responseData.neighbors.map(item => {
          auxNeighbors.push(item.id)
        })
        this.nodeForm = {
          id: responseData.networkUnit.id,
          name: responseData.networkUnit.name,
          technicalLosses: responseData.networkUnit.technicalLosses,
          originSystemId: responseData.networkUnit.originSystemId,
          networkElement: `${responseData.networkUnit.networkElement.name} ${responseData.networkUnit.networkElement.elementType}`,
          lat: responseData.networkUnit.geometry ? responseData.networkUnit.geometry.coordinates[0] : null,
          long: responseData.networkUnit.geometry ? responseData.networkUnit.geometry.coordinates[1] : null,
          district: responseData.networkUnit.district,
          location: responseData.networkUnit.location,
          address: responseData.networkUnit.address,
          serialNumber: responseData.networkUnit.serialNumber,
          manufacturer: responseData.networkUnit.manufacturer,
          region: responseData.networkUnit.region,
          volume: responseData.networkUnit.volume,
          neighbors: auxNeighbors,
          extraData: data,
          extraData2: responseData,
          disabled: get(responseData, 'parentNetworkTree.blocked') &&
              (get(responseData, 'parentNetworkTree.blockedBy') !== get(this.userData, 'user.id') ||
                (responseData.status === 'created' || responseData.status === 'deleted' || responseData.status === 'modified')),
          disabledAttributes: get(responseData, 'parentNetworkTree.blocked') &&
              get(responseData, 'parentNetworkTree.blockedBy') !== get(this.userData, 'user.id')
        }
      } else {
        this.nodeForm = {}
      }
      if (responseData.networkUnit.geometry) {
        this.map = {}
        setTimeout(() => {
          this.initializeMap(responseData.networkUnit.geometry)
        }, 100)
      }
      if (responseData3.length > 0) {
        this.clientsData = responseData3
        this.nodeName = responseData.networkUnit.name
        this.openMenu()
      }
    },
    async loadNodeManeuvers () {
      const [, response, data] = await handlePromise(NetwortManeuverService.getMyManeuversWater())
      if (response.ok) {
        _.map(data.logNetworkChanges, change => {
          if (change.action === 'modified') {
            const secondMov = _.find(data.logNetworkChanges,
              item => item.id !== change.id && item.refModified === change.refModified && !change.mapped
            )
            if (!secondMov) return change
            if (change.origin) {
              change.destination = { ...secondMov.destination }
              change.destinationTree = { ...secondMov.networkTree }
            } else {
              change.origin = { ...secondMov.origin }
              change.originTree = { ...secondMov.networkTree }
            }
            secondMov.mapped = true
          }
        })

        data.logNetworkChanges = _.filter(data.logNetworkChanges, item => !item.mapped)
        this.logs = data
      }
    },
    async deleteNode () {
      this.deleteLoading = true
      const [error, response] = await handlePromise(NetworkNodeService.deleteNodeWater(this.nodeForm.extraData2.id))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.nodeForm = {
        ...this.nodeForm,
        disabled: true
      }
      this.reloadData()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('nodeSaved'))
      this.toggleDeleteDialog()
    },
    initializeMap (marker) {
      const position = { lat: marker.coordinates[0], lng: marker.coordinates[1] }
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, { center: position, zoom: 8 })
      // eslint-disable-next-line no-new
      new this.google.maps.Marker({
        position,
        map: this.map
      })
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    toggleAddNodeDialog () {
      this.addNodeDialogVisible = !this.addNodeDialogVisible
    },
    toggleAddGeneratorDialog () {
      this.addGeneratorDialogVisible = !this.addGeneratorDialogVisible
    },
    toggleMoveNodeDialog () {
      this.moveNodeDialogVisible = !this.moveNodeDialogVisible
    },
    toggleDeleteDialog () {
      this.deleteDialogVisible = !this.deleteDialogVisible
    },
    reloadData () {
      this.$refs.treeComp.$refs.tree.reload()
      if (this.nodeForm.id) this.loadNodeData({ data: this.nodeForm.extraData }, false)
      else {
        this.loadNodeManeuvers()
      }
    },
    reloadEditNode () {
      this.reloadData()
      this.chartData = []
      this.$store.commit('settings/toggleSuccessAlert', this.$t('nodeSaved'))
    },
    reloadNodeAdded () {
      this.toggleAddNodeDialog()
      this.reloadData()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('nodeSaved'))
    },
    reloadGeneratorAdded () {
      this.toggleAddGeneratorDialog()
      this.reloadData()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('generatorSaved'))
    },
    reloadNodeMoved () {
      this.toggleMoveNodeDialog()
      this.reloadData()
      this.$store.commit('settings/toggleSuccessAlert', this.$t('nodeSaved'))
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
