<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        style="height: 250px" >
        <header class="modal-header" id="modalTitle">
            <slot name="header" v-if="logType === 'no-validation'">
            {{$t('noReadingsValidationLog')}}
            </slot>
            <slot name="header" v-else-if="logType === 'massive-validation'">
            {{$t('bulkValidationLog')}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
        <ods-module v-loading="loading">
        <section class="modal-body">
            <slot name="body">
              <ods-row :gutter="20">
                <ods-col :md="22">
                  <ods-progress :text-inside="true" :stroke-width="20" :percentage="dataLog.totalPercent" status="success" />
                </ods-col>
                <ods-col :md="2">
                  <span style="margin-left: 5px; cursor: pointer;" class="ods-icon-refresh" @click="updateData"></span>
                </ods-col>
              </ods-row>
              <ods-row :gutter="20">
                <ods-col :md="10">
                  <p style="margin-top: 20px">{{$t('status')}}:
                    <ods-badge
                        v-if="dataLog.status"
                        :value="$t(dataLog.status).toUpperCase()"
                    />
                    <span v-else>---</span>
                  </p>
                  <p style="margin-bottom: 10px; margin-top: 20px;">{{$t('initialDate')}}: {{dataLog.created | datehour}}</p>
                </ods-col>

                <ods-col :md="10">
                  <p style="margin-top: 20px">
                    {{$t('meterReadingsToProcess')}}: {{ dataLog.meterReadingsToProcess || '---'}}
                  </p>
                  <p style="margin-top: 20px">
                    {{$t('readingsProcessed')}}: {{ dataLog.meterReadingsProcessed || '---'}}
                  </p>
                </ods-col>
              </ods-row>
            </slot>
        </section>
        </ods-module>
        </div>
    </div>
  </transition>
</template>

<script>
import _ from 'lodash'

import BalanceService from '@/services/Balance'
import handlePromise from '@/utils/promise'

export default {
  name: 'Modal',
  props: {
    logType: {
      type: String,
      required: true,
      default: 'no-validation'
    }
  },
  data () {
    return {
      loading: false,
      dataLog: {},
      periodName: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async getData () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(BalanceService.getValidationLog(this.logType))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      if (!Array.isArray(data)) {
        this.dataLog = data
      }
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    updateData () {
      this.getData()
    },
  },
  mounted () {
    this.getData()
  }
}
</script>
<style scoped>
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(224, 223, 223);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(197, 197, 197);
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1400;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 50%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 70vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #292929;
    background: transparent;
  }

</style>
