import api from '@/config/Api'

const baseEndpoint = '/oauth/users'

export default {
  getUsers (params) {
    return api.get(baseEndpoint, params)
  },

  getUser (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  saveUser (data) {
    return api.post(baseEndpoint, data)
  },

  updateUser (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  deleteUser (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  }
}
