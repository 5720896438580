<template>
  <dialog-form
      :title="`${$t('moveNode')} ${from.networkUnit ? from.networkUnit.name : ''}`"
      :translateTitle="false"
      :visible="visible"
      @submit="submitForm"
      @cancel="toggleDialog"
  >
    <ods-form
        :model="form"
        ref="form"
        v-loading="loading"
    >
      <ods-row :gutter="20">
        <ods-col :md="8">
          <ods-form-item :label="$t('from')" prop="from" :rules="[errors.required]">
            <ods-date-picker
                type="date"
                :clearable="false"
                v-model="form.from"
                :picker-options="{disabledDate: disabledDateFrom}"
                style="width: 100%"/>
          </ods-form-item>
        </ods-col>
        <ods-col :md="16">
          <ods-form-item :label="$t('to')" prop="to" :rules="[errors.required]">
            <ods-date-picker
                type="date"
                :clearable="false"
                v-model="form.to"
                :picker-options="{disabledDate: disabledDateTo}"
                style="width: 100%"/>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <network-tree
          container-shadow="never"
          container-body-style="padding: 0"
          container-class="reconfig-modal-tree__module"
          v-if="visible"
          @node-click="selectNode"
          @toggle-loading="toggleLoading"
          ref="treeComp"
      >
      </network-tree>
    </ods-form>
  </dialog-form>
</template>

<script>
import moment from 'moment'
import NetworkNodeService from '@/services/NetworkNode'
import PeriodService from '@/services/Period'
import handlePromise from '@/utils/promise'
import DialogForm from '@/custom-components/DialogForm'
import NetworkTree from '@/custom-components/Network/Tree'
import errors from '@/config/InputErrors'

export default {
  name: 'MoveNode',
  components: {
    DialogForm,
    NetworkTree
  },
  props: {
    visible: Boolean,
    from: Object
  },
  mounted () {
    this.fetchActivePeriods()
  },
  data () {
    return {
      loading: false,
      destination: null,
      submitErrors: null,
      errors,
      props: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      },
      form: {
        from: moment().utc()
      }
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.destination = null
        this.submitErrors = null
      }
    }
  },
  methods: {
    disabledDateTo (date) {
      return moment(this.form.from).isSameOrAfter(date, 'day')
    },
    disabledDateFrom (date) {
      return moment.utc(new Date(moment.utc(Number(`${this.form.activePeriod.initialDate}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00')).isSameOrAfter(date, 'day')
    },
    toggleDialog (action = 'cancel') {
      if (this.visible && this.$refs['form']) {
        this.$refs['form'].resetFields()
        this.form = {
          from: moment()
        }
      }

      this.$emit(action)
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    selectNode (node) {
      this.destination = node.id
    },
    async fetchActivePeriods () {
      const [, response, responseData] = await handlePromise(PeriodService.getActivePeriods({ limit: 0 }))
      if (response.ok) {
        this.form = {
          from: moment(),
          activePeriod: responseData
        }
      }
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t('activePeriodsNotFound'))
    },
    async loadTreeNode (node, resolve) {
      if (node.level === 0) this.toggleLoading()

      const id = node.level === 0 ? null : node.data.id
      const [error, response, data] = await handlePromise(NetworkNodeService.getNodeTree(id))

      if (node.level === 0) this.toggleLoading()
      if (response.ok) {
        resolve(data.map(item => ({
          id: item.id,
          name: item.networkUnit.name,
          isLeaf: false,
          data: item,
          children: [{}]
        })))
      } else {
        this.$store.commit('settings/toggleAlert', error)
        resolve([])
      }
    },
    async submitForm () {
      if (!this.destination) {
        this.submitErrors = 'selectDestinationNode'
        return this.$store.commit('settings/toggleAlert', this.$t(this.submitErrors))
      }
      if (this.from.id === this.destination) {
        this.submitErrors = 'moveNodeSameDestination'
        return this.$store.commit('settings/toggleAlert', this.$t(this.submitErrors))
      }
      if (new Date(this.form.from) >= new Date(this.form.to)) {
        this.submitErrors = 'dateGreaterThanFromDate'
        return this.$store.commit('settings/toggleAlert', this.$t(this.submitErrors))
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.toggleLoading()
          const [error, response] = await handlePromise(NetworkNodeService.updateReconfiguration(this.from.id,
            {
              destination: this.destination,
              executeDate: moment.utc(this.form.to).format('YYYY-MM-DD'),
              initialDate: moment.utc(this.form.from).format('YYYY-MM-DD')
            }
          ))
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.toggleDialog('reload')
        }
      })
    }
  }
}
</script>
