<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="7">
        <network-tree-mozambique
          @node-click="fetchMeter"
          :selectedNodes="selectedNodes"
          @toggle-loading="toggleLoading"
          ref="treeComp"
        ></network-tree-mozambique>
      </ods-col>

      <ods-col :md="17">
        <ods-module :header="$t('measurementAnalysis')">
          <ods-form :model="search" ref="form">
            <ods-row :gutter="24">
              <ods-col :md="8">
                <ods-form-item
                  :label="$t('year')"
                  prop="year"
                  :rules="[errors.required]"
                >
                  <ods-select
                    v-model="search.year"
                    placeholder=""
                    @change="changeYear"
                  >
                    <ods-option
                      v-for="item in years"
                      :key="item.id"
                      :label="item.value"
                      :value="item.value"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
              <ods-col :md="8">
                <ods-form-item
                  :label="$t('filter')"
                  prop="filter"
                  :rules="[errors.required]"
                >
                  <ods-select
                    v-model="search.filter"
                    placeholder=""
                    @change="changeFilter"
                  >
                    <ods-option
                      v-for="item in filters"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
              <ods-col :md="8">
                <ods-form-item :label="$t('period')" prop="period">
                  <ods-select
                    v-model="search.period"
                    placeholder=""
                    @change="searchMeterReadings"
                  >
                    <ods-option
                      v-for="item in periods"
                      :key="item.id"
                      :label="$t(item.name)"
                      :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
            </ods-row>
          </ods-form>

          <ods-table :data="selectedNodes" size="mini">
            <ods-table-column :label="$t('number')">
              <template slot-scope="scope" v-if="scope.row.meter.serialNumber">
                {{ scope.row.meter.serialNumber }}
              </template>
            </ods-table-column>
            <!--<ods-table-column prop="meter.serialNumber" :label="$t('number')"/>-->
            <ods-table-column
              prop="meter.brand.description"
              :label="$t('brand')"
            />
            <!-- <ods-table-column prop="meter.brand.description" :label="$t('networkElement')">
              <template slot-scope="scope">
                <span v-if="scope.row.networkUnit">{{scope.row.networkUnit.name}}</span>

                (<span v-if="scope.row.networkUnit && scope.row.networkUnit.networkElement">{{scope.row.networkUnit.networkElement.elementType}}</span>
                <span v-if="scope.row.networkTree && scope.row.networkTree.masterName">
                    - {{scope.row.networkTree.masterName}}
                  </span>
                <span v-if="scope.row.networkTree && scope.row.networkTree.code">
                    - {{scope.row.networkTree.code}}
                  </span>
                )
              </template>
            </ods-table-column> -->
            <ods-table-column align="right" width="100">
              <template slot-scope="scope">
                <table-delete @click="deleteMeter(scope.row.id)"></table-delete>
              </template>
            </ods-table-column>
          </ods-table>
        </ods-module>

        <ods-module class="mt-1" v-if="searchEnabled">
          <template slot="header">
            <list-header title="analysis">
              <template slot="right">
                <ods-checkbox
                  v-model="includeForecast"
                  :label="$t('includeForecast')"
                >
                </ods-checkbox>
              </template>
            </list-header>
          </template>
          <!-- <ods-dataviz-line
              :data="chartData"
              :colors="colors"
              renderType="svg"
              :animation="true"
              :rotateLabel="true"
              height="360px"
              width="100%"
              :show-tooltip="true"
              trigger="axis"
              :zoom="true"
              :smooth-lines="true"
              :showXFilterZoom="true" /> -->
          <div id="chart" style="height: 400px"></div>
        </ods-module>

        <analysis-readings v-if="searchEnabled" :data="readings" />
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import MeterService from "@/services/Meter";
import ListHeader from "@/custom-components/ListHeader";
import PeriodService from "@/services/Period";
import MeterReadingService from "@/services/MeterReading";
import handlePromise from "@/utils/promise";
import NetworkTree from "@/custom-components/Network/Tree";
import NetworkTreeMozambique from "../../../../custom-components/Network/TreeMozambique.vue";
import TableDelete from "@/custom-components/Buttons/TableDelete";
import AnalysisReadings from "./Reading";
import LovService from "@/services/Lov";
import findIndex from "lodash/findIndex";
import _ from "lodash";
import moment from "moment";
import * as echarts from "echarts";
import errors from "@/config/InputErrors";

export default {
  name: "MeasurementAnalysis",
  components: {
    NetworkTree,
    NetworkTreeMozambique,
    AnalysisReadings,
    TableDelete,
    ListHeader,
  },
  mounted() {
    this.fetchPeriods();
  },
  data() {
    return {
      errors,
      includeForecast: false,
      loading: false,
      periods: [],
      periodsData: [],
      search: {},
      selectedNodes: [],
      readings: [],
      searchEnabled: false,
      years: [],
      filters: [
        { id: "p", name: "period" },
        { id: "r", name: "custom" },
      ],
      ranks: [
        { id: "1Trimestre", name: "firstTrimester" },
        { id: "2Trimestre", name: "secondTrimester" },
        { id: "3Trimestre", name: "thirdTrimester" },
        { id: "4Trimestre", name: "fourTrimester" },
        { id: "1Semestre", name: "firstSemester" },
        { id: "2Semestre", name: "secondSemester" },
        { id: "year", name: "annual" },
      ],
      colors: [
        "#3982D0",
        "#D96D73",
        "#5A59A6",
        "#6DB6A1",
        "#F78B41",
        "#61B3CB",
        "#418A8B",
        "#A2578D",
        "#E5B75D",
        "#234E63",
      ],
      chartData: {
        xAxis: [],
        lines: [],
      },
      chartOptions: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        xAxis: [
          {
            name: "Date",
            type: "time",
            boundaryGap: false,
            axisLabel: {
              formatter: function (value) {
                return moment(new Date(Number(`${value}`))).format("DD/MM");
              },
            },
          },
        ],
        yAxis: [
          {
            name: "Consumption",
            type: "value",
          },
        ],
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            filterMode: "none",
          },
          {
            start: 0,
            end: 100,
            filterMode: "none",
          },
        ],
      },
    };
  },
  methods: {
    async changeYear() {
      this.toggleLoading();
      const [, response, responseData] = await handlePromise(
        PeriodService.getPeriods({ limit: 0, year: this.search.year })
      );
      this.toggleLoading();
      if (response.ok) {
        this.periods = responseData.data;
        this.periodsData = responseData.data;
        this.search = {
          filter: "p",
          year: this.search.year,
          period: null,
        };
        this.searchMeterReadings();
        if (responseData.total < 1) {
          return this.$store.commit(
            "settings/toggleAlert",
            this.$t("periodNotFound")
          );
        }
      }
    },
    async changeFilter() {
      if (this.search.filter === "p") {
        this.periods = this.periodsData;
        this.search = {
          filter: this.search.filter,
          year: this.search.year,
          period: null,
        };
      } else {
        this.periods = this.ranks;
        this.search = {
          filter: this.search.filter,
          year: this.search.year,
          period: null,
        };
      }
    },
    async fetchPeriods() {
      this.toggleLoading();
      const [, response2, years] = await handlePromise(
        LovService.getLov("years")
      );
      this.toggleLoading();
      if (response2.ok) this.years = years.lovValues;
      this.toggleLoading();
      const [, response3, responseData3] = await handlePromise(
        PeriodService.getActivePeriods({ limit: 0 })
      );
      this.toggleLoading();
      if (response3.ok) {
        this.search = {
          year: responseData3.year,
          filter: "p",
          period: responseData3.id,
        };
        this.toggleLoading();
        const [, response, responseData] = await handlePromise(
          PeriodService.getPeriods({ limit: 0, year: this.search.year })
        );
        this.toggleLoading();
        if (response.ok) {
          this.periods = responseData.data;
          this.periodsData = responseData.data;
        }
      }
      if (!response3.ok)
        return this.$store.commit(
          "settings/toggleAlert",
          this.$t("activePeriodsNotFound")
        );
    },
    async fetchMeter({ data }) {
      if (this.selectedNodes.length === 5)
        return this.$store.commit(
          "settings/toggleAlert",
          this.$t("noMoreFiveComparison")
        );
      const nodeAddedIndex = _.findIndex(this.selectedNodes, {
        networkUnit: { id: data.id },
      });
      if (nodeAddedIndex !== -1) {
        this.selectedNodes.splice(nodeAddedIndex, 1);
        this.searchMeterReadings();
      } else {
        this.toggleLoading();
        const [error, response, responseData] = await handlePromise(
          MeterService.getMeter(data.id)
        );
        this.toggleLoading();
        if (!response.ok)
          return this.$store.commit("settings/toggleAlert", error);
        if (!responseData.serialNumber)
          return this.$store.commit(
            "settings/toggleAlert",
            this.$t("noMeterSerialNumber")
          );
        this.selectedNodes.push({
          ...data,
          meter: responseData,
        });
        this.searchMeterReadings();
      }
    },
    deleteMeter(id) {
      const index = findIndex(this.selectedNodes, { id });
      if (index !== -1) this.selectedNodes.splice(index, 1);

      if (!this.selectedNodes.length) {
        this.searchEnabled = false;
      }
      this.searchMeterReadings();
    },
    async searchMeterReadings() {
      if (!this.search.period || !this.selectedNodes.length) {
        this.searchEnabled = false;
        if (this.loading) this.toggleLoading();
        return;
      }
      let auxPeriods = [];
      if (this.search.period === "1Trimestre") {
        this.periodsData.map((item) => {
          if (item.month === 1 || item.month === 2 || item.month === 3) {
            auxPeriods.push(item.id);
          }
        });
      }
      if (this.search.period === "2Trimestre") {
        this.periodsData.map((item) => {
          if (item.month === 4 || item.month === 5 || item.month === 6) {
            auxPeriods.push(item.id);
          }
        });
      }
      if (this.search.period === "3Trimestre") {
        this.periodsData.map((item) => {
          if (item.month === 7 || item.month === 8 || item.month === 9) {
            auxPeriods.push(item.id);
          }
        });
      }
      if (this.search.period === "4Trimestre") {
        this.periodsData.map((item) => {
          if (item.month === 10 || item.month === 11 || item.month === 12) {
            auxPeriods.push(item.id);
          }
        });
      }
      if (this.search.period === "fiveSemestre") {
        this.periodsData.map((item) => {
          if (
            item.month === 1 ||
            item.month === 2 ||
            item.month === 3 ||
            item.month === 4 ||
            item.month === 5 ||
            item.month === 6
          ) {
            auxPeriods.push(item.id);
          }
        });
      }
      if (this.search.period === "2Semestre") {
        this.periodsData.map((item) => {
          if (
            item.month === 7 ||
            item.month === 8 ||
            item.month === 9 ||
            item.month === 10 ||
            item.month === 11 ||
            item.month === 12
          ) {
            auxPeriods.push(item.id);
          }
        });
      }
      if (this.search.period === "year") {
        this.periodsData.map((item) => {
          auxPeriods.push(item.id);
        });
      } else if (
        this.search.period !== "1Trimestre" &&
        this.search.period !== "2Trimestre" &&
        this.search.period !== "3Trimestre" &&
        this.search.period !== "4Trimestre" &&
        this.search.period !== "1Semestre" &&
        this.search.period !== "2Semestre" &&
        this.search.period !== "year"
      ) {
        auxPeriods.push(this.search.period);
      }
      this.searchEnabled = true;
      if (!this.loading) this.toggleLoading();
      const params = {
        limit: 0,
        periods: auxPeriods,
        meters: this.selectedNodes.map((item) => item.meter.id),
      };

      const [error, response, data] = await handlePromise(
        MeterReadingService.getMeterReadingsByCriteria(params)
      );
      if (this.loading) this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", error);
      this.readings = data.data;

      // this.chartData.xAxis = _.chain(data.data).uniqBy('date').map(item => moment(new Date(Number(`${item.date}000`))).format('DD/MM')).value()
      /* this.chartData.xAxis = {
        type: 'time',
        boundaryGap: false,
        axisLabel: {
          formatter: function (value) {
            return value
          }
        }
      } */
      const chartData = [];
      _.chain(data.data)
        .groupBy("meter.serialNumber")
        .map((meters, serialNumber) => {
          chartData.push({
            name: serialNumber,
            type: "line",
            smooth: true,
            data: _.map(meters, (m) => [
              new Date(
                moment.utc(Number(`${m.date}000`)).format("MM/DD/YYYY") +
                  " " +
                  "00:00:00"
              ),
              m.consumptionOrigin,
            ]),
          });
        })
        .value();
      if (this.includeForecast) {
        _.chain(data.data)
          .groupBy("meter.serialNumber")
          .map((meters, serialNumber) => {
            chartData.push({
              name: serialNumber + " forecast",
              type: "line",
              smooth: true,
              data: _.map(meters, (m) => [
                new Date(
                  moment.utc(Number(`${m.date}000`)).format("MM/DD/YYYY") +
                    " " +
                    "00:00:00"
                ),
                m.readingForecast.consumptionForecast,
              ]),
            });
          })
          .value();
      }

      this.chartData.lines = chartData;

      setTimeout(() => {
        // let aux1 = moment.utc(Number(`${1610669945}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00'
        // let aux2 = moment.utc(Number(`${1612052345}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00'

        /* console.log(chartData, 'chartData')
        let historyRefuelling = [[new Date(moment.utc(Number(`${1610669945}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00'), 5], [new Date(aux2), 7]]

        let historyTheft = [
          [new Date(aux1), 1], [new Date(aux2), 2]
        ] */
        this.chart = echarts.init(document.getElementById("chart"));
        this.chartOptions = {
          ...this.chartOptions,
          series: chartData,
        };
        this.chart.setOption(this.chartOptions, true);
      }, 100);
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
  },
  watch: {
    includeForecast: function () {
      this.searchMeterReadings();
    },
  },
};
</script>
