<template>
  <section>
    <ods-module>
      <template slot="header">
        <list-header
            title="purchases"
            btn-label="newPurchase"
            url-key="PurchaseNew"
            :hasPermission=hasPermission
        />
      </template>

      <async-table
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <ods-table-column prop="billOriginalReference" :label="$t('reference')" v-if="hasPermission">
          <template slot-scope="scope">
            <router-link v-if="scope.row.id" :to="{ name: 'PurchaseEdit', params: { id: scope.row.id }}">
              <ods-button type="text" class="p-0">{{scope.row.billOriginalReference}}</ods-button>
            </router-link>
          </template>
        </ods-table-column>
        <ods-table-column prop="billOriginalReference" :label="$t('reference')" v-if="!hasPermission">
          <template slot-scope="scope">
            {{scope.row.billOriginalReference}}
          </template>
        </ods-table-column>

        <ods-table-column prop="fromDate" :label="$t('from')">
          <template slot-scope="scope">
            {{scope.row.fromDate | date}}
          </template>
        </ods-table-column>

        <ods-table-column prop="toDate" :label="$t('to')">
          <template slot-scope="scope">
            {{scope.row.toDate | date}}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('totalWater')" >
              <template slot-scope="scope" v-if="scope.row.totalWater">
                {{ scope.row.totalWater | formatNumber }}
              </template>
        </ods-table-column>
        <!--<ods-table-column prop="totalEnergy" :label="$t('totalEnergy')"/>-->
        <ods-table-column :label="$t('totalAmount')" >
              <template slot-scope="scope" v-if="scope.row.totalAmount">
                {{ scope.row.totalAmount | formatCurrency }}
              </template>
        </ods-table-column>
        <!--<ods-table-column prop="totalAmount" :label="$t('totalAmount')"/>-->

        <ods-table-column prop="status" :label="$t('status')">
          <template slot-scope="scope">
            <ods-badge
                v-if="scope.row.status"
                :value="$t(scope.row.status).toUpperCase()"
                :type="getStatusBadgeType(scope.row.status)"
            />
          </template>
        </ods-table-column>

        <ods-table-column align="right" v-if="hasPermission">
          <template slot-scope="scope">
            <table-delete @click="toggleDialog(scope.row.id)"></table-delete>
          </template>
        </ods-table-column>
      </async-table>

      <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDialog()"
      />
    </ods-module>
  </section>
</template>

<script>
import PurchaseService from '@/services/Purchase'
import handlePromise from '@/utils/promise'
import AsyncTable from '@/custom-components/AsyncTable'
import ListHeader from '@/custom-components/ListHeader'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import asyncTableCommon from '@/mixins/async-table'
import { mapState } from 'vuex'

export default {
  name: 'PurchaseDashboard',
  components: {
    AsyncTable,
    TableDelete,
    DialogConfirmation,
    ListHeader
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    this.handlePermission()
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      hasPermission: true,
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      promise: (params) => PurchaseService.getPurchasesWater(params)
    }
  },
  methods: {
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(PurchaseService.deletePurchaseWater(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    getStatusBadgeType (status) {
      switch (status) {
        case 'new':
          return 'secondary'
        default:
          return 'primary'
      }
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
