import esLocale from '@/addons/locale/lang/es'
import Config from '../../config/Config'

export default {
  ...esLocale,
  label: 'Español',
  labelMobile: 'ES',
  serverError: 'Ha ocurrido un error inesperado en el servidor. Inténtalo de nuevo.',
  email: 'Email',
  password: 'Contraseña',
  login: 'Entrar',
  edit: 'Editar',
  back: 'Regresar',
  cancel: 'Cancelar',
  users: 'Usuarios',
  name: 'Nombre',
  roles: 'Roles',
  enabled: 'Activo',
  admin: 'Admin',
  operator: 'Operador',
  superAdmin: 'Superadmin',
  editUser: 'Editar usuario',
  newUser: 'Crear usuario',
  externalAuthentication: 'Autenticación externa',
  confirm: 'Confirmar',
  deleteAlert: '¿Estás seguro que deseas borrar este elemento?',
  code: 'Código',
  errors: {
    required: 'Este campo es requerido',
    email: 'Por favor introduzca un email correcto',
    url: 'Por favor introduzca un URL correcta',
    phone: 'Por favor introduzca un número de teléfono correcto'
  },
  bulkValidationStarted: 'El proceso ha comenzado. Tomará algún tiempo para terminar',
  description: 'Descripción',
  value: 'Valor',
  newValue: 'Nuevo valor',
  save: 'Salvar',
  lovValues: 'valores LOV',
  editLov: 'Editar LOV',
  lovs: 'LOVs',
  newLov: 'Crear LOV',
  delete: 'Eliminar',
  networkElements: 'Elementos de la Red',
  editNetworkElement: 'Editar elemento de la red',
  attributes: 'Atributos',
  newAttribute: 'Atributo nuevo',
  type: 'Tipo',
  mandatory: 'Obligatorio',
  lov: 'LOV',
  integer: 'Entero',
  INTEGER: 'Entero',
  addChild: 'Añadir nodo',
  editChild: 'Editar nodo',
  newParent: 'Crear generador',
  newFirstLevelNode: 'Nuevo nodo de primer nivel',
  newChild: 'Crear nodo',
  network: 'Red',
  networkHorizontal: 'Red horizontal',
  networkVertical: 'Red vertical',
  fileImported: 'Archivo importado correctamente',
  import: 'Importar',
  rows: 'Filas',
  created: 'Creado',
  status: 'Estado',
  maneuvers: 'Maniobras',
  maneuver: 'Maniobra',
  networkElement: 'Elemento de la red',
  technicalLosses: 'Pérdidas técnicas',
  technicalLossesPercent: 'Pérdidas técnicas (%)',
  originSystemId: 'ID. de sistema de origen',
  latitude: 'latitud',
  longitude: 'longitud',
  draft: 'Draft',
  accept: 'Aceptar',
  importRow: 'Importado',
  processRow: 'Procesado',
  validRow: 'Valido',
  createdAt: 'Creado en',
  done: 'hecho',
  filename: 'Nombre del archivo',
  importDetail: 'Importar detalle',
  download: 'Descargar',
  logs: 'Logs',
  networkTree: 'Árbol de red',
  updatedAt: 'Actualizado en',
  processLog: 'Procesar logs',
  approved: 'Aprovado',
  manual: 'Manual',
  accepted: 'Aceptado',
  processed: 'procesado',
  changes: 'Cambios',
  owner: 'Dueño',
  validationsLog: 'Validaciones de logs',
  networkUnit: 'Unidad de red',
  destination: 'Destino',
  deleted: 'Eliminado',
  action: 'Acción',
  pending: 'Pendiente',
  reject: 'Rechazar',
  approve: 'Aprovado',
  rejected: 'Rechazado',
  string: 'Cadena',
  select: 'Seleccionar',
  number: 'Número',
  move: 'Mover',
  selectDestinationNode: 'Seleccione un nodo de destino',
  moveNode: 'Mover nodo',
  discard: 'Descartar',
  networkTrees: 'Árboles de red',
  networkTreeLog: 'Logs de Árboles de red',
  modified: 'Modificado',
  error: 'Error',
  from: 'Desde',
  to: 'Hasta',
  dateRange: 'Rango de Fecha',
  restore: 'Restaurar',
  actions: 'Acciones',
  origin: 'Origen',
  version: 'Versión',
  reconfiguration: 'Reconfiguración',
  networkHistory: 'Historial de red',
  moveNodeSameDestination: 'Seleccione un nodo con destino diferente',
  executionDate: 'Fecha de ejecución',
  changePassword: 'Cambiar contraseña',
  confirmPassword: 'Confirmar contraseña',
  passwordDontMatch: 'La contraseña no coincide',
  meterManagement: 'Gestión de contadores',
  serialNumber: 'Numero de serie',
  readingTypes: 'Tipos de lectura',
  model: 'modelo',
  brand: 'marca',
  position: 'Posición',
  serviceType: 'Tipo de sevicio',
  serviceId: 'ID servicio',
  input: 'Entrada',
  output: 'Salida',
  prepaid: 'Prepago',
  postpaid: 'Postpago',
  active: 'Activo',
  power: 'Energía',
  reactive: 'Reactivo',
  customer: 'Cliente',
  customers: 'Clientes',
  areasDefinition: 'Definición de Áreas',
  newArea: 'Nueva Área',
  editArea: 'Editar área',
  selectedNodes: 'Nodos seleccionados',
  selectNodesInfo: '-Seleccione los nodos que definen esta área en el árbol.',
  periodsMaintenance: 'Mantenimiento de períodos',
  newPeriod: 'Crear Período',
  editPeriod: 'Editar Período',
  processing: 'Procesando',
  closed: 'Cerrado',
  open: 'Abierto',
  initialDate: 'Fecha inicial',
  lastDayReadingsCapture: 'Captura de lecturas del último día',
  closureDate: 'Fecha de cierre',
  meters: 'Medidores',
  metersHorizontal: 'Medidores horizontales',
  metersVertical: 'Medidores veticales',
  purchases: 'Compras',
  newPurchase: 'Nueva compra',
  editPurchase: 'Editar compra',
  totalAmount: 'Monto total',
  totalEnergy: 'Energía total',
  reference: 'Referencia',
  billOriginalReference: 'Referencia original de la cuenta',
  measurementLoading: 'Gestión de mediciones',
  measurementAnalysis: 'Análisis de mediciones',
  measurementValidation: 'Validación de mediciones',
  individualValidation: 'Mediciones validación individual',
  consumption: 'consumo',
  date: 'fecha',
  reading: 'lectura',
  period: 'Período',
  search: 'Buscar',
  readings: 'Lecturas',
  newReading: 'Nueva lectura',
  new: 'Nueva',
  estimate: 'Estimación',
  runValidation: 'Ejecutar validación',
  editReading: 'Editar lectura',
  analysis: 'Análisis',
  periodicity: 'Periodisidad',
  readingPeriod: 'Período de lectura',
  real: 'Real',
  estimated: 'Estimado',
  system_estimated: 'Estimación del sistema',
  hourly: 'Cada hora',
  daily: 'cada día',
  monthly: 'Cada mes',
  consumptionType: 'Tipo de consumo',
  consumptionOrigin: 'Origen de cosumo',
  readingOrigin: 'Origen de lectura',
  readingToBalance: 'Balance de lectura',
  consumptionToBalance: 'Balance de consumo',
  lastReport: 'Último reporte',
  validateEmptyReadings: 'Validar lecturas vacías',
  validateReadings: 'Validar lecturas',
  bulkValidations: 'Validación masiva',
  energyInput: 'Entrada de energía',
  energyType: 'Tipo de energía',
  balanceArea: 'Área de equilibrio',
  element: 'Elemento',
  average: 'Promedio',
  monthValue1: 'Mes 1 valor',
  monthValue2: 'Mes 2 valor',
  monthValue3: 'Mes 3 valor',
  monthValue4: 'Mes 4 valor',
  monthValue5: 'Mes 5 valor',
  monthValue6: 'Mes 6 valor',
  monthValue7: 'Mes 7 valor',
  monthValue8: 'Mes 8 valor',
  monthValue9: 'Mes 9 valor',
  monthValue10: 'Mes 10 valor',
  monthValue11: 'Mes 11 valor',
  monthValue12: 'Mes 12 valor',
  monthValue1Percent: 'M1 %',
  monthValue2Percent: 'M2 %',
  monthValue3Percent: 'M3 %',
  monthValue4Percent: 'M4 %',
  monthValue5Percent: 'M5 %',
  monthValue6Percent: 'M6 %',
  monthValue7Percent: 'M7 %',
  monthValue8Percent: 'M8 %',
  monthValue9Percent: 'M9 %',
  monthValue10Percent: 'M10 %',
  monthValue11Percent: 'M11 %',
  monthValue12Percent: 'M12 %',
  currentBalances: 'Balance actual',
  includeLosses: 'Incluir pérdidas',
  balanceDashboard: 'Panel de Balance',
  balanceDashboardHorizontal: 'Panel de Balance horizontal',
  balanceDashboardVertical: 'Panel de Balance vertical',
  lastMonthLosses: 'Pérdidas del último mes',
  losses: 'Pérdidas',
  lossesPercent: 'Pérdidas %',
  lastMonthLossesPercent: 'Pérdidas del último mes %',
  applyManeuvers: 'Aplicar maniobras',
  maneuversApplied: 'Maniobras aplicadas',
  nodeSaved: 'La operación se ha realizado satisfactoriamente',
  nodeCreated: 'Creado',
  nodeMoved: 'Movido',
  nodeDeleted: 'Eliminado',
  nodeBlocked: 'Bloquiado',
  colorsLegend: 'Legenda de Color',
  noMeterAssociated: 'No hay medidas asociadas',
  meter: 'Medida',
  highEstimation: 'Alta estimación',
  highDeviation: 'Alta desviación',
  lowEstimation: 'Baja estimación',
  potentialTurnover: 'Rotación potencial',
  readingBounds: 'Límites de lecturas',
  consumptionBounds: 'Límites de consumo',
  noReading: 'No hay lecturas',
  anomalies: 'Anomalías',
  noMeterSerialNumber: 'Este nodo no tiene medidas asociadas',
  noMoreFiveComparison: 'No puede seleccionar más de 5 nodos para comparar',
  noHistorical: 'Este nodo no tiene historial asociado',
  bulkEstimation: 'Estimación de bulto',
  historical: 'Histórico',
  balanceLog: 'Log',
  balanceLogTitle: 'Log del Balance',
  balanceLogTitleValidation: 'Log de la Validación',
  readingsProcessed: 'Lecturas procesadas',
  meterReadingsToProcess: 'Lecturas a ser procesadas',
  noReadingsValidationLog: 'Validate empty readings',
  validateReadingsLog: 'Validate readings',
  bulkValidationLog: 'Bulk validation',
  bulkEstimationLog: 'Bulk estimation',
  totalJobs: 'Jobs totales',
  executedJobs: 'Jobs ejecutados',
  dailyBalance: 'Balance diario',
  periodCalculation: 'calcular periodo',
  dailyBalanceStarted: 'Balance diario iniciado',
  periodCalculationStarted: 'Cálculo iniciado',
  ERROR_MESSAGE_REPORT_NOT_FOUND: 'El reporte no esta disponible',
  apiError: 'Ops se produjo un error',
  ERROR_MESSAGE_REQUEST_INVALID_USER_PASSWORD_COMBINATION: 'Usuario o contraseña incorrecta',
  ERROR_MESSAGE_THE_PROCESS_HAS_ALREADY_BEEN_STARTED: 'El proceso ya ha comenzado',
  ERROR_MESSAGE_THE_PROCESS_DAILY_BALANCE_IS_RUNNING: 'El proceso Balance Diario está en ejecución',
  ERROR_MESSAGE_METER_NOT_FOUND: 'Medidor no encontrado',
  ERROR_MESSAGE_ACTIVE_PERIOD_NOT_FOUND: 'Período activo no encontrado',
  ERROR_MESSAGE_INVALID_DATES_TO_CREATE_PERIOD: 'Fechas no válidas para crear un período',
  ERROR_MESSAGE_CLOSURE_DATE_HAS_TO_BE_GREATER_THAN_INITIAL_DATE: 'La fecha de cierre tiene que ser mayor que la fecha inicial',
  ERROR_MESSAGE_INVALID_LAST_DAY_READING_CAPTURE: 'La captura de lecturas del último día debe ser mayor que la fecha inicial y meno que la fecha de cierre',
  ERROR_MESSAGE_INVALID_DATE: 'Fecha no válida',
  ERROR_MESSAGE_BALANCE_AREA_NOT_FOUND: 'No se encontró el área',
  ERROR_MESSAGE_INVALID_ORDER_BETWEEN_NODE_AND_PARENT: 'Orden invalido entre el nodo y el padre',
  ERROR_MESSAGE_REPORT_TYPE_NOT_FOUND: 'Tipo de reporte no encontrado',
  ERROR_MESSAGE_INVALID_LAST_READING_DATE: 'La fecha tiene que ser mayor que la fecha de la última lectura',
  order: 'orden',
  process_tree: 'Árbol de procesos',
  done_pending: 'Hecho Esperando',
  ERROR_MESSAGE_PERIOD_NOT_FOUND: 'Período no encontrado',
  activePeriodsNotFound: 'No se encontraron períodos activos',
  REAL: 'Real',
  lastReadingDate: 'Fecha de la última lectura',
  dateGreaterThanLastReadingDate: 'La fecha tiene que ser mayor que la fecha de la última lectura',

  dateGreaterThanFromDate: 'La fecha tiene que ser mayor que la fecha inicial',

  initialDateLessOrEqualsThanLastReadingDateCapture: 'La fecha inicial tiene que ser menor o igual que la fecha de captura de la última lectura',
  initialDateLessOrEqualsThanClosureDate: 'La fecha inicial tiene que ser menor o igual que la fecha de cierre',
  lastDayReadingsCaptureGreatherOrEqualsThanInitialDate: 'La fecha de captura de la última lectura tiene que ser mayor o igual que la fecha inicial',
  lastDayReadingsCaptureLessOrEqualsThanClosureDate: 'La fecha de captura de la última lectura tiene que ser menor o igual que la fecha de cierre',
  initialDateGreatherOrEqualsThanInitialDate: 'La fecha de cierre tiene que ser mayor o igual que la fecha inicial',
  initialDateGreatherOrEqualsThanLastReadingDateCapture: 'La fecha de cierre lectura tiene que ser mayor o igual que la fecha de captura de la última lectura',

  readingImported: 'Lectura importada',
  consumptionImported: 'Consumo importado',

  ERROR_MESSAGE_ENERGY_PURCHASE_NOT_FOUND: 'Compra de energía no encontrada',
  ERROR_MESSAGE_THERE_IS_ALREADY_AN_ACTIVE_PERIOD: 'Ya existe un período activo',
  ERROR_MESSAGE_CAN_NOT_PERFORM_THIS_ACTION: 'No se puede realizar esta acción',
  ERROR_MESSAGE_NETWORK_TREE_IS_BLOCKED: 'La red del árbol está bloquiada',
  monetary: 'Monetario',
  selectPeriod: 'Primero debe seleccionar un período',
  monetaryCalculation: 'Cálculo monetario',
  nodeAlreadySelected: 'Este nodo ya está seleccionado',
  ERROR_MESSAGE_NO_INPUT_TYPE_METER_IS_FOUND: 'No se encontró un medidor de tipo entrada',
  selectNodesInfo2: '-Recuerde seleccionar como primer nodo un medidor de tipo entrada.',
  selectNodesInfo3: '-Al seleccionar un nodo, el resto del árbol perteneciente a este nodo será seleccionado.',
  ERROR_MESSAGE_INVALID_NODE_TO_FORM_PART_OF_AN_AREA: 'Este nodo no cumple con los requisitos para formar parte de un área',
  unitCost: 'Precio unitario',
  voltage: 'Voltage',
  region: 'Región',
  district: 'Distrito',
  location: 'Localización',
  tariff: 'Tarifa',
  solved: 'Resuelto',
  ignored: 'Ignorada',
  totalConsumption: 'Consumo total',
  strategicPlan: 'Mantenimiento del plan estratégico',
  initialLosses: 'Pérdidas iniciales',
  initialLossesPercent: 'Pérdidas iniciales (%)',
  lossesObjective: 'Objetivo de las pérdidas',
  lossesObjectivePercent: 'Objetivo de las pérdidas (%)',
  timePeriod: 'Período de tiempo(meses)',
  interventionsPlanned: 'Intervenciones planificadas',
  estimatedCost: 'Costo estimado',
  estimatedCostDollar: `Costo estimado (${Config.currency})`,
  createPlan: 'Crear plan',
  editPlan: 'Editar plan',
  createStategicPlan: 'Crear plan estratégico',
  area: 'Área',
  lessThan100: 'Este campo debe ser menor que 100',
  lessOrEqualsThan100: 'Este campo debe ser menor o igual que 100',
  greatherThan0: 'Este campo debe ser mayor que 0',
  lessThanValue: 'Este campo debe ser menor que ',
  lossesObjectiveLessThanInitialLosses: 'El objetivo de las pérdidas debe ser menor que las pérdidas iniciales',
  ERROR_MESSAGE_LOV_NOT_FOUND: 'LOV no encontrado',
  strategicActivities: 'Actividades estratégicas',
  newActivity: 'Nueva actividad',
  createActivity: 'Crear actividad',
  editActivity: 'Editar actividad',
  refresh: 'Actualizar',
  about: 'Acerca de',
  apiVersion: 'Versión de la api',
  mongoVersion: 'Versión de mongo',
  mysqlVersion: 'Versión de mysql',
  phpVersion: 'Versión de php',
  webVersion: 'Versión de la web',
  largePower: 'Alta potencia',
  result: 'Resultado',
  realCost: `Costo real (${Config.currency})`,
  comment: 'Comentario',
  addActionActivitie: 'Añadir acciones / actividades',
  negative: 'Negativo',
  positive: 'Positivo',
  NEGATIVE: 'Negativo',
  POSITIVE: 'Positivo',
  OPEN: 'Abierto',
  CLOSED: 'Cerrado',
  brandModels: 'Marcas/Modelos',
  brands: 'Marcas',
  createBrand: 'Crear marca',
  editBrand: 'Editar marca',
  createModel: 'Crear modelo',
  editModel: 'Editar modelo',
  models: 'Modelos',
  ERROR_MESSAGE_BRAND_NOT_FOUNT: 'Marca no encontrada',
  underConstruction: 'Esta área se encuentra en construcción',
  lossReductionTarget: 'Objetivo de reducción de pérdidas',
  lossReductionTargetPercent: 'Objetivo de reducción de pérdidas (%)',
  lossReductionTargetLessThanInitialLosses: 'El objetivo de reducción de pérdidas debe ser menor que las pérdidas iniciales',
  realCostRequired: 'El costo real es obligatorio',
  activityHistory: 'Historial de la actividad',
  strategicPlanDetail: 'Plan estratégico',
  detailPlan: 'Detalle plan',
  ROLE_MANAGER: 'Manager',
  userDontHaveAcces: 'Este usuario no tiene acceso a esta sección',
  networkElementDetail: 'Detalle del elemento de la red',
  elementType: 'Tipo de elemento',
  initialFields: 'Campos iniciales',
  fieldsUpdated: 'Campos editados',
  ERROR_MESSAGE_STRATEGIC_PLAN_NOT_FOUND: 'Plan estratégico no encontrado',
  expectedRealCost: `Costo esperado (${Config.currency})`,
  expectedExecutionDate: 'Fecha de ejecución esperada',
  otherComment: 'Otro comentario',
  forecastMeasurementLoading: 'Pronóstico de la Gestión de mediciones',
  forecastMeasurementAnalysis: 'Pronóstico del Análisis de mediciones',
  forecastMeasurementValidation: 'Pronóstico de la Validación de mediciones',
  consumptions: 'Consumos',
  consumptionsHorizontal: 'Consumos horizontales',
  consumptionsVertical: 'Consumos verticales',
  consumptionForecast: 'Consumo predicho',
  editConsumption: 'Editar consumo',
  addConsumption: 'Añadir consumo',
  readingForecast: 'Lectura predicha',
  readingForecastToBalance: 'Balance de lectura predicho',
  consumptionForecastToBalance: 'Balance de consumo predicho',
  INSPECTIONS: 'Inspecciones',
  REPLACEMENT: 'Reemplazo',
  CONTROL_METER: 'Control de medidor',
  MAINTENANCE: 'Mantenimiento',
  includeForecast: 'Incluir predicción',
  consumptionToDay: 'Consumo al día',
  consumptionForecastToDay: 'Consumo al día predicho',
  generatePeriods: 'Generar períodos',
  year: 'Año',
  noDecimal: 'Este campo no debe tener decimales',
  greatherThan2000: 'Este campo debe ser mayor que 2000',
  ERROR_MESSAGE_THIS_YEAR_ALREADY_EXISTS: 'Los períodos de este año ya han sido generados',
  startPeriod: 'Período de inicio',
  onlyActivePlans: 'Solo planes activos',
  currentLoss: 'Pérdida actual',
  currentLossPercent: 'Pérdida actual(%)',
  lossReduction: 'Reducción de pérdida',
  lossReductionPercent: 'Reducción de pérdida(%)',
  profit: 'Ganancia',
  profitPercent: 'Ganancia(%)',
  benefit: 'Beneficio',
  cost: 'Costo',
  monthlyLosses: 'Pérdidas mensuales',
  consumptionOriginForecast: 'Origen de cosumo predicho',
  detailStrategicPlan: 'Detalle del plan estratégico',
  totalLossReductionPercent: 'Reducción de pérdidas totales(%)',
  totalBenefit: 'Beneficio total',
  totalCost: 'Costo total',
  month: 'Mes',
  lossesStart: 'Pérdidas (iniciales)',
  lossesEnd: 'Pérdidas (finales)',
  rank: 'Rango',
  filter: 'Filtro',
  periodNotFound: 'No se encontró ningún período',
  firstTrimester: '1 Trimestre',
  secondTrimester: '2 Trimestre',
  thirdTrimester: '3 Trimestre',
  fourTrimester: '4 Trimestre',
  firstSemester: '1 Semestre',
  secondSemester: '2 Semestre',
  annual: 'Anual',
  downloadTable: 'Descargar tabla',
  custom: 'Personalizado',
  downloadTemplate: 'Descargar plantilla',
  uploadTemplate: 'Subir plantilla',
  templateUploaded: 'Plantilla generada',
  ERROR_MESSAGE_FILE_NOT_FOUND: 'Archivo no encontrado',
  generateTemplate: 'Generar plantilla',
  meterHistory: 'Historial del medidor',
  replaceMeter: 'Remplazar el medidor',
  installationDate: 'Fecha de instalación',
  ERROR_MESSAGE_THIS_PLAN_IS_STARTED: 'Este plan ya ha comenzado',
  ERROR_MESSAGE_YOU_MUST_SPECIFY_SERIAL_NUMBER_OR_NETWORK_UNIT: 'Debe especificar un número de serie o un elemento de la red',
  ignoreAnomalies: 'Ignorar anomalías',
  estimateAnomalies: 'Estimar anomalías',
  ERROR_MESSAGE_ANOMALY_TYPE_NOT_FOUND: 'Tipo de anomalía no encontrado',
  measurementBulkValidation: 'Validación masiva de mediciones',
  resetOrigin: 'Reiniciar origen',
  editConsumptionForMeter: 'Editar consumo del medidor',
  addConsumptionForMeter: 'Añadir consumo del medidor',
  section: 'Sección',
  Energy: 'Energía',
  Water: 'Water',
  ERROR_MESSAGE_INVALID_MODULE: 'Este usuario no tiene acceso a la sección seleccionada',
  newParentWater: 'Nueva planta de agua',
  address: 'Dirección',
  manufacturer: 'Manufacturero',
  volume: 'Volume',
  volumeRest: 'Remaining volume',
  waterplants: 'Plantas de agua',
  neighbors: 'Vecinos',
  hideDetails: 'Ocultar detalles',
  showDetails: 'Mostrar detalles',
  details: 'Detalles',
  ERROR_MESSAGE_INVALID_NEIGHBOR: 'Vecino incorrecto',
  ERROR_MESSAGE_NETWORK_UNIT_NOT_FOUND: 'Elemento de la red no encontrado',
  mostSelectLevel2: 'Solo puede seleccionar nodos con nivel 1 o 2',
  totalWater: 'Agua total',
  canOnlySelectBrotherOrChildren: 'Solo puede seleccionar un nodo hermano o un hijo',
  waterInput: 'Entrada de agua',
  invalidData: 'Dato inconsistente',
  ERROR_MESSAGE_INVALID_AREA: 'Área no configurada correctamente',
  ERROR_MESSAGE_THIS_NODE_CANNOT_BE_REMOVED: 'Este nodo no puede ser eliminado de la lista',
  ERROR_MESSAGE_INVALID_NETWORK_ELEMENT_TYPE: 'Tipo de elemento de red inválido',
  until: 'Hasta',
  ERROR_MESSAGE_INVALID_METER_READING: 'Lectura de medidor no válida',
  ERROR_MESSAGE_INVALID_METER: 'Medidor no válido',
  ERROR_MESSAGE_INVALID_ACTIVE_PERIOD_WITH_THIS_DATE: 'Período inválido con esta fecha',
  ERROR_MESSAGE_INVALID_FILE: 'Archivo no válido',
  filterKeyword: 'Filtrar',
  onlyActivePeriod: 'Solo se permiten periodos activos',
  balanceManagement: 'Gestión de balances',
  balanceManagementHorizontal: 'Gestión de balances horizontales',
  balanceManagementVertical: 'Gestión de balances verticales',
  createBalance: 'Crear balance',
  editBalance: 'Editar balance',
  editBalanceHorizontal: 'Editar balance horizontal',
  editBalanceVertical: 'Editar balance vertical',
  createBalanceHorizontal: 'Crear balance horizontal ',
  createBalanceVertical: 'Crear balance vertical ',
  entries: 'Entradas',
  outs: 'Salidas',
  clientType: 'Tipo de cliente',
  generationType: 'Tipo de generación',
  transportationDivision: 'División de transporte',
  voltageType: 'Tipo de voltage',
  activeFilters: 'Filtros activos',
  ERROR_MESSAGE_NETWORK_NODE_NOT_FOUND: 'Nodo de la red no encontrado',
  elementTypes: 'Tipos de elementos',
  clientTypes: 'Tipos de clientes',
  generationTypes: 'Tipos de generación',
  commercialOffice: 'ASC',
  activationDate: 'Fecha de activación',
  deactivationDate: 'Fecha de desactivación',
  getBalance: 'Obtener balance',
  balance: 'Balance',
  inputs: 'Entradas',
  outputs: 'Salidas',
  total: 'Total',
  totalReading: 'Lectura total',
  inputTotalReading: 'Entrada de lectura total',
  inputTotalConsumption: 'Entrada de consumo total',
  outputTotalReading: 'Salida de lectura total',
  outputTotalConsumption: 'Salida de consumo total',
  balanceConsumption: 'Balance de consumo',
  balanceReading: 'Balance de lectura',
  networkMap: 'Mapa de red',
  networkUnitDeletedSuccessfully: 'Elemento de la red eliminado satisfactoriamente',
  balanceType: 'Tipo de balance',
  borderType: 'Tipo de frontera',
  billing: 'Facturación',
  massiveImport: 'Importación masiva',
  ERROR_MESSAGE_THERE_IS_AN_IMPORT_FILE_READING_THAT_IS_ALREADY_BEING_IMPORTED: 'La lectura es un archivo de importación que ya se está importando',
  ltmBalance: "balance LTM",
  regionImport: "Región de importación",
  regionExport: "Región de exportación",
  ascImport: "ASC importación",
  ascExport: "ASC exportación",
  ERROR_MESSAGE_THERE_ARE_NO_DOCUMENTS_IN_THE_FOLDER_TO_IMPORT_THE_MEASUREMENTS: 'No hay documentos en la carpeta para importar las mediciones,',
  ERROR_MESSAGE_THERE_ARE_TO_MUCH_DOCUMENTS_IN_IMPORT_FOLDER: 'Hay demasiados documentos en la carpeta de importación.',
  importationLog: "Importación logs",
  distributedGrossEnergy: "Energía Bruta Distribuida",
  availableGrossEnergy: "Energía Bruta Disponible",
  transportedGrossEnergy: "Energía Bruta Transportada",
  billedEnergy: "Energía facturada",
  energyDeliveredToDistribution: "Energía entregada a la distribución",
  MWhLosses: "Pérdidas MWh"
}
