<template>
  <transition name="ods-notification-fade">
    <div
      data-name="OdsNotification"
      :class="['ods-notification', customClass, horizontalClass]"
      v-show="visible"
      :style="positionStyle"
      @mouseenter="clearTimer()"
      @mouseleave="startTimer()"
      @click="click"
      role="alert">

      <div class="ods-notification__group" :class="{ 'is-with-icon': typeClass || iconClass }">
        <p class="ods-notification__title">
          <span
            class="ods-notification__icon"
            :class="[ typeClass, iconClass ]"
            :style="{ color: typeColor }"
            v-if="type || iconClass"/>
          {{ title }}</p>
        <div class="ods-notification__content" v-show="message">
          <slot>
            <p v-if="!dangerouslyUseHTMLString">{{ message }}</p>
            <p v-else v-html="message"></p>
          </slot>
        </div>
        <div
          role="button"
          ref="close"
          class="ods-notification__closeBtn ods-icon-close"
          v-if="showClose"
          @click.stop="close"
          @keydown.enter="close"
          tabindex="1" />
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
import { setTimeout } from 'timers'

let typeMap = {
  success: 'checkmark-2',
  info: 'info',
  warning: 'warning',
  error: 'close'
}

let colorMap = {
  success: '#36B348',
  info: '#909399',
  warning: '#FFAB20',
  error: '#DE2F2F'
}

export default {
  data () {
    return {
      visible: false,
      title: '',
      message: '',
      duration: 4500,
      type: '',
      showClose: true,
      customClass: '',
      iconClass: '',
      onClose: null,
      onClick: null,
      closed: false,
      verticalOffset: 0,
      timer: null,
      dangerouslyUseHTMLString: false,
      position: 'top-right'
    }
  },
  name: 'OdsNotification',
  version: '2.0.0',
  category: 'data',
  lastDate: '13/04/2020',
  syncStatus: 'aligned-extended',

  computed: {
    typeClass () {
      return this.type && typeMap[this.type] ? `ods-icon-${typeMap[this.type]}` : ''
    },

    typeColor () {
      return colorMap[this.type]
    },

    horizontalClass () {
      return this.position.indexOf('right') > -1 ? 'right' : 'left'
    },

    verticalProperty () {
      return /^top-/.test(this.position) ? 'top' : 'bottom'
    },

    positionStyle () {
      return {
        [this.verticalProperty]: `${this.verticalOffset}px`
      }
    }
  },

  watch: {
    closed (newVal) {
      if (newVal) {
        this.visible = false
        this.$el.addEventListener('transitionend', this.destroyElement)
      }
    }
  },

  methods: {
    destroyElement () {
      this.$el.removeEventListener('transitionend', this.destroyElement)
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },

    click () {
      if (typeof this.onClick === 'function') {
        this.onClick()
      }
    },

    close () {
      this.closed = true
      if (typeof this.onClose === 'function') {
        this.onClose()
      }
    },

    clearTimer () {
      clearTimeout(this.timer)
    },

    startTimer () {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close()
          }
        }, this.duration)
      }
    },
    keydown (e) {
      if (e.keyCode === 46 || e.keyCode === 8) {
        this.clearTimer() // detele 取消倒计时
      } else if (e.keyCode === 27) { // esc关闭消息
        if (!this.closed) {
          this.close()
        }
      } else {
        this.startTimer() // 恢复倒计时
      }
    }
  },

  updated () {
    this.$refs.close && this.$refs.close.focus()
  },

  mounted () {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close()
        }
      }, this.duration)
    }
    document.addEventListener('keydown', this.keydown)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.keydown)
  }
}
</script>
