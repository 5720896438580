<template>
  <section>
    <ods-module v-loading="loading" :header="$route.params.id ? $t('editBrand') : $t('createBrand')">
      <ods-form
          :model="form"
          ref="form"
          v-loading="formLoading"
       >
          <ods-row :gutter="20">
              <ods-col :md="12">
                  <ods-form-item :label="$t('name')" prop="name"
                              :rules="[errors.required]">
                      <ods-input v-model="form.name"></ods-input>
                  </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                  <ods-form-item :label="$t('code')" prop="code"
                              :rules="[errors.required]">
                      <ods-input v-model="form.code"></ods-input>
                  </ods-form-item>
              </ods-col>
              <ods-col :md="12">
              <ods-form-item :label="$t('description')" prop="description"
                          :rules="[errors.required]">
                  <ods-input v-model="form.description" type="textarea"></ods-input>
              </ods-form-item>
          </ods-col>
        </ods-row>
        <form-buttons
            @submit="submitForm"
            cancel-to="BrandDashboard"
        ></form-buttons>
      </ods-form>
    </ods-module>

    <Model
        v-if="$route.params.id"
        :brand="$route.params.id"
        >
    </Model>
  </section>
</template>

<script>
import BrandModelService from '@/services/BrandModel'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import Model from './Model'
import FormButtons from '@/custom-components/Buttons/FormButtons'

export default {
  name: 'StrategicEdit',
  components: {
    Model,
    FormButtons
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      errors,
      formLoading: false
    }
  },
  methods: {
    async getData () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(BrandModelService.getBrand(this.$route.params.id))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.form = {
        name: data.name,
        code: data.code,
        description: data.description
      }
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const toSend = {
            name: this.form.name,
            description: this.form.description,
            code: this.form.code
          }
          const [error, response, responseData] = await handlePromise(
            this.$route.params.id
              ? BrandModelService.updateBrand(this.$route.params.id, toSend)
              : BrandModelService.saveBrand(toSend)
          )
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          return this.$router.push(this.$route.params.id
            ? { name: 'BrandDashboard' }
            : { name: 'BrandEdit', params: { id: responseData.id } })
        }
      })
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>

<style lang="scss">
</style>
