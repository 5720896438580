var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormVue',{attrs:{"width":_vm.width,"visible":_vm.dialogVisible,"title":_vm.toEdit ? 'editChild' : 'newChild',"loading":_vm.loading},on:{"submit":_vm.handleSubmit,"cancel":function($event){return _vm.$emit('cancel')}}},[_c('ods-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",staticClass:"mt-1",attrs:{"model":_vm.form}},[_c('ods-row',{attrs:{"gutter":20}},[_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('networkElement'),"prop":"networkElement","rules":[_vm.errors.required]}},[_c('ods-select',{attrs:{"remote":"","disabled":_vm.toEdit ? true : false},model:{value:(_vm.form.networkElement),callback:function ($$v) {_vm.$set(_vm.form, "networkElement", $$v)},expression:"form.networkElement"}},_vm._l((_vm.networkElements),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),(
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
            .elementType === 'BPO'
        )?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('borderType'),"prop":"borderType","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'BPO'
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.borderType),callback:function ($$v) {_vm.$set(_vm.form, "borderType", $$v)},expression:"form.borderType"}},_vm._l((_vm.borderTypes),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('originSystemId'),"prop":"originSystemId","rules":[_vm.errors.required]}},[_c('ods-input',{attrs:{"disabled":_vm.toEdit ? true : false},model:{value:(_vm.form.originSystemId),callback:function ($$v) {_vm.$set(_vm.form, "originSystemId", $$v)},expression:"form.originSystemId"}})],1)],1),_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('name'),"prop":"name","rules":[_vm.errors.required]}},[_c('ods-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),(
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
            .elementType === 'GEN'
        )?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('generationType'),"prop":"generationType","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'GEN'
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.generationType),callback:function ($$v) {_vm.$set(_vm.form, "generationType", $$v)},expression:"form.generationType"}},_vm._l((_vm.generations),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),(
          _vm.form.borderType !== 'Distribuição' &&
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
          (_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
            .elementType === 'SUB' ||
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'GEN' ||
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'BPO')
        )?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('transportationDivision'),"prop":"energyTransportDivision","rules":(_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
              _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
                .elementType === 'SUB') ||
            (_vm.form.borderType && _vm.form.borderType === 'Transporte')
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.energyTransportDivision),callback:function ($$v) {_vm.$set(_vm.form, "energyTransportDivision", $$v)},expression:"form.energyTransportDivision"}},_vm._l((_vm.transportes),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),(_vm.form.borderType !== 'Transporte')?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('region'),"prop":"region","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            (_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'SUB' ||
              _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
                .elementType === 'BPO')
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}},_vm._l((_vm.regiones),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),(
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
            .elementType === 'BPO'
        )?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('regionExport'),"prop":"regionExport","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'BPO'
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.regionExport),callback:function ($$v) {_vm.$set(_vm.form, "regionExport", $$v)},expression:"form.regionExport"}},_vm._l((_vm.regiones),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),(
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
            .elementType === 'BPO'
        )?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('regionImport'),"prop":"regionImport","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'BPO'
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.regionImport),callback:function ($$v) {_vm.$set(_vm.form, "regionImport", $$v)},expression:"form.regionImport"}},_vm._l((_vm.regiones),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":"ASC","prop":"asc","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'SUB'
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.commercialOffice),callback:function ($$v) {_vm.$set(_vm.form, "commercialOffice", $$v)},expression:"form.commercialOffice"}},_vm._l((_vm.ascs),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1),(
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
            .elementType === 'BPO'
        )?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('ascExport'),"prop":"ascExport","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'BPO'
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.ascExport),callback:function ($$v) {_vm.$set(_vm.form, "ascExport", $$v)},expression:"form.ascExport"}},_vm._l((_vm.ascs),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),(
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
          _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
            .elementType === 'BPO'
        )?_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('ascImport'),"prop":"ascImport","rules":_vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; }) &&
            _vm.networkElements.find(function (e) { return e.id === _vm.form.networkElement; })
              .elementType === 'BPO'
              ? [_vm.errors.required]
              : []}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.ascImport),callback:function ($$v) {_vm.$set(_vm.form, "ascImport", $$v)},expression:"form.ascImport"}},_vm._l((_vm.ascs),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1):_vm._e(),_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('voltageType'),"prop":"tensionType","rules":[_vm.errors.required]}},[_c('ods-select',{attrs:{"clearable":""},model:{value:(_vm.form.tensionType),callback:function ($$v) {_vm.$set(_vm.form, "tensionType", $$v)},expression:"form.tensionType"}},_vm._l((_vm.voltages),function(item){return _c('ods-option',{key:item.id,attrs:{"label":item.description,"value":item.value}})}),1)],1)],1),_c('ods-col',{attrs:{"md":12}},[_c('ods-form-item',{attrs:{"label":_vm.$t('voltage'),"prop":"voltage","rules":[_vm.errors.required]}},[_c('ods-input',{model:{value:(_vm.form.voltage),callback:function ($$v) {_vm.$set(_vm.form, "voltage", $$v)},expression:"form.voltage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }