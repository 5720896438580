<template>
  <ods-dialog
      :visible.sync="visible"
      :title="translateTitle ? $t(title) : title"
      :secondTitle ="secondTitle"
      modal
      :width="width"
      custom-class="custom-dialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
  >
    <slot name="default">
    </slot>
    <div slot="footer">
      <slot name="footer"></slot>
      <form-buttons
          @submit="$emit('submit')"
          @cancel="$emit('cancel')"
      ></form-buttons>
    </div>
  </ods-dialog>
</template>

<script>
import FormButtons from '@/custom-components/Buttons/FormButtons'

export default {
  name: 'DialogForm',
  components: {
    FormButtons
  },
  props: {
    visible: Boolean,
    translateTitle: {
      type: Boolean,
      default: true
    },
    secondTitle: String,
    title: String,
    width: {
      type: String,
      default: '40%'
    }
  },
  methods: {
    handleClose(){
      if(!this.loading) this.$emit('cancel')
    }
  }
}
</script>
