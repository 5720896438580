import api from '@/config/Api'

const baseEndpoint = '/network/period'
const baseEndpointWater = '/network/period-water'

export default {
  getActivePeriods (params) {
    return api.get(`${baseEndpoint}/active`, params)
  },

  getPeriods (params) {
    return api.get(baseEndpoint, params)
  },

  getPeriod (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  savePeriod (data) {
    return api.post(baseEndpoint, data)
  },

  updatePeriod (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  deletePeriod (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  getActivePeriodsWater (params) {
    return api.get(`${baseEndpointWater}/active`, params)
  },

  getPeriodsWater (params) {
    return api.get(baseEndpointWater, params)
  },

  getPeriodWater (id) {
    return api.get(`${baseEndpointWater}/${id}`)
  },

  savePeriodWater (data) {
    return api.post(baseEndpointWater, data)
  },

  updatePeriodWater (id, data) {
    return api.put(`${baseEndpointWater}/${id}`, data)
  },

  deletePeriodWater (id) {
    return api.delete(`${baseEndpointWater}/${id}`)
  }
}
