/* eslint-disable no-unused-vars */
<template>
  <section v-loading="loading">
    <ods-row :gutter="20" type="flex">
      <ods-col :md="14">
        <network-tree
            @node-click="loadNodeData"
            @toggle-loading="toggleLoading"
            ref="treeComp"
        >
        </network-tree>
        <network-graph v-if="chartData.categories"
            :chartData="chartData"
            @node-click="loadNodeData"
            ref="graph"
        >
        </network-graph>
      </ods-col>

      <ods-col :md="10" style="min-height: 100%;">
        <ods-module :header="`${$t('networkHistory')} ${selectedNode}`" style="min-height: 100%">
          <ods-row v-if="!data.length" type="flex" justify="center" style="margin-top: 2rem">
            <ods-icon name="info" size="100" class="custom-placeholder"/>
          </ods-row>
          <ods-timeline
              class="mt-1"
              :reverse="false">
            <ods-timeline-item
                v-for="(activity, index) in data"
                :key="index"
                :icon="activity.code ? 'ods-icon-pushpin' : 'ods-icon-shield'"
                placement="right"
                size="large"
                :color="activity.code ? '#36B348' : '#2E6C99'"
                hide-timestamp
            >
              <div v-if="activity.code">
                <p>{{activity.fromDate | date(true)}}</p>
                <p>
                  <span class="font-weight-bold">{{$t('version')}}:</span> {{activity.version}}
                  <span v-if="activity.firstNode && activity.firstNode.networkUnit">
                  -
                  {{activity.firstNode.networkUnit.name}}
                  (
                  <span v-if="activity.firstNode.networkUnit.networkElement">{{activity.firstNode.networkUnit.networkElement.elementType}} - </span>
                  <span v-if="activity.masterName">{{activity.masterName}} - </span>
                  {{activity.code}}
                  )
                </span>
                </p>

              </div>
              <div v-else>
                <p>{{activity.createdAt | date(true)}}</p>
                <p>
                  <span class="font-weight-bold">{{$t(activity.action).toUpperCase()}}</span>
                  <ods-badge
                      style="margin-left: 10px; margin-right: 10px"
                      v-if="activity.status"
                      :value="$t(activity.status).toUpperCase()"
                      :type="activity.status === 'pending' ? 'secondary' : 'success'"
                  />
                  {{activity.owner.email}}
                </p>
              </div>
            </ods-timeline-item>
          </ods-timeline>
        </ods-module>
      </ods-col>
    </ods-row>
    <Drawer :direction="'right'" :exist="true" :closeDrawer="closeClients" ref="LeftDrawer">
        <ods-module :header="$t('customers')" style="min-height: 100%">
          <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="16">
                {{$t('customers')}}: {{selectedNode}}
              </ods-col>
              <ods-col :md="8" class="text-right">
                <button
                  type="button"
                  class="btn-close"
                  @click="closeClientsDrawer"
                  style="margin-top:-15px"
                  >
                  <ods-icon name = "close" size = "18" />
                </button>
                <!--<ods-button type="danger" size="small" @click="toggleDeleteDialog">
                  {{$t('delete')}}
                </ods-button>-->
              </ods-col>
            </ods-row>
          </template>
          <ods-row type="flex" justify="center">
            <ods-table :data="clientsData" size="mini">
              <ods-table-column :label="$t('name')">
                    <template slot-scope="scope">
                      <ods-button type="text" class="p-0" @click="loadNodeData({data:scope.row})">
                        {{scope.row.name}}
                      </ods-button>
                    </template>
              </ods-table-column>
              <ods-table-column :label="$t('type')" >
                    <template slot-scope="scope">
                      {{ scope.row.type }}
                    </template>
              </ods-table-column>
            </ods-table>
          </ods-row>
        </ods-module>
    </Drawer>
  </section>
</template>

<script>
import NetworkHistoricalService from '@/services/NetworkHistorical'
import NetworkNodeService from '@/services/NetworkNode'
import handlePromise from '@/utils/promise'
import NetworkTree from '@/custom-components/Network/TreeWater'
import NetworkGraph from '@/custom-components/Network/NetworkGraph'
import Drawer from '@/custom-components/Network/clientList'
// import get from 'lodash/get'
import _ from 'lodash'

export default {
  name: 'ReconfigurationDashboard',
  components: { NetworkTree, NetworkGraph, Drawer },
  data () {
    return {
      loading: false,
      data: [],
      chartData: [],
      props: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      },
      selectedNode: '',
      filterText: '',
      closeClients: false,
      clientsData: []
    }
  },
  methods: {
    closeClientsDrawer () {
      this.closeClients = !this.closeClients
    },
    openMenu () {
      if (this.$refs.LeftDrawer.active) {
        this.$refs.LeftDrawer.close()
      } else {
        this.$refs.LeftDrawer.open()
      }
    },
    async loadNodeData ({ data }) {
      if (data.type) {
        this.closeClientsDrawer()
      }
      this.toggleLoading()
      const [error3, response3, responseData3] = await handlePromise(NetworkNodeService.getNodeWater(data.firstNode ? data.firstNode.id : data.id))
      this.toggleLoading()
      if (!response3.ok) return this.$store.commit('settings/toggleAlert', error3)

      if (data.firstNode) {
        this.tree = data.id
        this.toggleLoading()
        const [error2, response2, responseData2] = await handlePromise(NetworkNodeService.getGrapgWater(data.id))
        this.toggleLoading()
        if (!response2.ok) return this.$store.commit('settings/toggleAlert', this.$t(error2))
        this.chartData = responseData2
      }
      this.toggleLoading()
      if (responseData3.networkTree) {
        this.selectedNode = data.firstNode ? data.firstNode.networkUnit.name : responseData3.networkUnit.name
        const [error, response, responseData] = await handlePromise(NetworkHistoricalService.getRootHistoryWater(responseData3.networkTree.code))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        const changes = []
        _.orderBy(responseData.networkTress, 'fromDate', 'desc').map((tree, index) => {
          tree.fromDate = Number(`${tree.fromDate}00${index}`)
          changes.push(tree)

          const logs = _.filter(responseData.logNetworkChanges, { networkTree: { id: tree.id } })
          _.chain(logs)
            .map((log, logIndex) => {
              log.createdAt = Number(`${log.createdAt}00${logIndex}`)
            })
            .orderBy('fromDate', 'desc')
            .value()

          changes.push(...logs)
        })
        this.data = changes
      } else if (!responseData3.networkTree) {
        this.selectedNode = data.name
        const [error, response, responseData] = await handlePromise(NetworkHistoricalService.getNodeHistoryWater(responseData3.id))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        const changes = []

        const logs = responseData.logNetworkChanges
        _.chain(logs)
          .map((log, logIndex) => {
            log.createdAt = Number(`${log.createdAt}00${logIndex}`)
          })
          .orderBy('fromDate', 'desc')
          .value()

        changes.push(...logs)

        this.data = changes
      } else {
        await handlePromise(NetworkHistoricalService.getNodeHistory())
        this.toggleLoading()
        return this.$store.commit('settings/toggleAlert', this.$t('noHistorical'))
      }
      const [error4, response4, responseData4] = await handlePromise(NetworkNodeService.getClientsWater(data.firstNode ? data.firstNode.id : data.id))
      if (!response4.ok) return this.$store.commit('settings/toggleAlert', this.$t(error4))
      if (responseData4.length > 0) {
        this.clientsData = responseData4
        this.openMenu()
      }
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
