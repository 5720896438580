<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
            {{$t('about')}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
          <ods-module v-loading="loading">
            <section class="modal-body">
                <slot name="body">
                    <ods-row class="font-weight-bold" style="font-size: 110%">
                      <ods-col :md="12" style="margin-bottom: 20px">
                        <p>{{$t('webVersion')}}: {{webVersion || '---'}}</p>
                      </ods-col>
                      <ods-col :md="12" style="margin-bottom: 20px">
                        <p>{{$t('apiVersion')}}: {{data ? data.apiVersion : '---'}}</p>
                      </ods-col>
                      <ods-col :md="12" style="margin-bottom: 20px">
                        <p>{{$t('mongoVersion')}}: {{data ? data.mongoVersion : '---'}}</p>
                      </ods-col>
                      <ods-col :md="12" style="margin-bottom: 20px">
                        <p>{{$t('mysqlVersion')}}: {{data ? data.mysqlVersion : '---'}}</p>
                      </ods-col>
                      <ods-col :md="12" style="margin-bottom: 20px">
                        <p>{{$t('phpVersion')}}: {{data ? data.phpVersion : '---'}}</p>
                      </ods-col>
                    </ods-row>
                </slot>
            </section>
          </ods-module>
        </div>
    </div>
  </transition>
</template>

<script>
import AboutService from '@/services/About'
import handlePromise from '@/utils/promise'
import APPCONFIG from '../config/Config'

export default {
  name: 'AboutModal',
  async mounted () {
    await this.getData()
  },
  data () {
    return {
      loading: false,
      data: null,
      webVersion: null
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async getData () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(AboutService.getInfo())
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.data = data
      this.webVersion = APPCONFIG.version
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 30%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
     height: 20vh;
    overflow-y: auto;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #292929;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

    .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
