<template>
  <section v-loading="loading">
    <ods-row :gutter="20" type="flex">
      <ods-col :md="7">
        <network-tree-mozambique
          @node-click="loadNodeData"
          :selectedNodes="selectedNodes"
          @toggle-loading="toggleLoading"
          ref="treeComp"
        ></network-tree-mozambique>
      </ods-col>

      <ods-col :md="17" v-if="networkUnit.id">
        <ods-module>
          <template slot="header">
            <list-header title="meterManagement">
              <template slot="right">
                <ods-button size="medium" type="warning" v-if="hasPermission" @click="toggleHistoryModal()">
                  <ods-icon name="calendar-schedule"
                    style="margin-left:-10px; display:inherit; float:left; padding-right:10px" size="18" />{{
                        $t('meterHistory')
                    }}
                </ods-button>
                <ods-button size="medium" type="primary" v-if="hasPermission" @click="toggleReplaceMeterModal()"
                  :disabled="!hasMeter">
                  <ods-icon name="sync" style="margin-left:-10px; display:inherit; float:left; padding-right:10px"
                    size="18" />{{ $t('replaceMeter') }}
                </ods-button>
              </template>
            </list-header>
          </template>
          <ods-form :model="form" ref="form" :disabled="!hasPermission">
            <ods-row :gutter="20" class="mb-1" v-if="!hasMeter">
              <ods-col :md="24">
                <ods-alert :title="$t('noMeterAssociated')" type="error" :closable="false" />
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('originSystemId')" prop="originSystemId" :rules="[errors.required]">
                  <ods-input v-model="form.originSystemId"></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                <ods-form-item :label="$t('name')" prop="name" :rules="[errors.required]">
                  <ods-input v-model="form.name"></ods-input>
                </ods-form-item>
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('brand')" prop="brand">
                  <ods-select v-model="form.brand" placeholder="" @input="onBrandChange">
                    <ods-option v-for="item in brands" :key="item.id" :label="item.name" :value="item.id">
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                <ods-form-item :label="$t('model')" prop="model">
                  <ods-select v-model="form.model" placeholder="">
                    <ods-option v-for="item in models" :key="item.id" :label="item.value" :value="item.id">
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('serialNumber')" prop="serialNumber" :rules="[errors.required]">
                  <ods-input v-model="form.serialNumber"></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                <ods-form-item :label="$t('type')" prop="type" :rules="[errors.required]">
                  <ods-select v-model="form.type" placeholder="">
                    <ods-option v-for="item in types" :key="item.id" :label="$t(item.name)" :value="item.id">
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('position')" prop="position" :rules="[errors.required]">
                  <ods-select v-model="form.position" placeholder="">
                    <ods-option v-for="item in positions" :key="item.id" :label="$t(item.name)" :value="item.id">
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                <ods-form-item :label="$t('readingTypes')" prop="readingTypes">
                  <ods-select v-model="form.readingTypes" placeholder="" multiple>
                    <ods-option v-for="item in readingTypes" :key="item.id" :label="$t(item.name)" :value="item.id">
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('serviceId')" prop="serviceId">
                  <ods-input v-model="form.serviceId"></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                <ods-form-item :label="$t('serviceType')" prop="serviceType">
                  <ods-select v-model="form.serviceType" placeholder="">
                    <ods-option v-for="item in serviceTypes" :key="item.id" :label="$t(item.name)" :value="item.id">
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12" v-if="!hasMeter">
                <ods-form-item :label="$t('installationDate')" prop="installDate" :rules="[errors.required]">
                  <ods-date-picker type="date" v-model="form.installDate" style="width: 100%" />
                </ods-form-item>
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('address')" prop="address">
                  <ods-input v-model="form.address"></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                <ods-form-item :label="$t('email')" prop="email" :rules="[errors.email]">
                  <ods-input v-model="form.email"></ods-input>
                </ods-form-item>
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('phone')" prop="phone" :rules="[errors.phone]">
                  <ods-input v-model="form.phone"></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="12">
                <ods-form-item :label="$t('status')" prop="status">
                  <ods-input v-model="form.status" disabled></ods-input>
                </ods-form-item>
              </ods-col>
            </ods-row>

            <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-checkbox style="padding-left:20px;margin-top:20px;margin-bottom:20px" v-model="form.largePower"
                  :label="$t('largePower')">
                </ods-checkbox>
              </ods-col>
            </ods-row>

            <ods-row type="flex" justify="center">
              <ods-button type="primary" @click="submitForm">
                {{ $t('save') }}
              </ods-button>
            </ods-row>
          </ods-form>
        </ods-module>
      </ods-col>

      <module-placeholder v-if="!networkUnit.id" header="meterManagement" :col-size="17" />
    </ods-row>
    <HistoryMeterModal v-show="historyDialogVisible" v-if="historyDialogVisible" @close="closeHistoryModal"
      :networkUnitId=networkUnit.id />
    <ReplaceMeterModal v-show="replaceMeterDialogVisible" v-if="replaceMeterDialogVisible" @close="closeReplaceModal"
      @closeOk="closeReplaceModalOk" :networkUnitId=networkUnit.id :meterId=meter.id :serialNumber=meter.serialNumber
      :originSystemId=meter.originSystemId />
  </section>
</template>

<script>
import moment from 'moment'
import MeterService from '@/services/Meter'
import BrandModelService from '@/services/BrandModel'
import NetworkUnitService from '@/services/NetworkUnit'
import handlePromise from '@/utils/promise'
import NetworkTreeMozambique from '../../../custom-components/Network/TreeMozambique.vue'
import NetworkTree from '@/custom-components/Network/Tree'
import ModulePlaceholder from '@/custom-components/ModulePlaceholder'
import errors from '@/config/InputErrors'
import { mapState } from 'vuex'
import ListHeader from '@/custom-components/ListHeader'
import HistoryMeterModal from './HistoryMeterModal.vue'
import ReplaceMeterModal from './ReplaceMeterModal.vue'

export default {
  name: 'MeterManagement',
  components: {
    NetworkTree,
    NetworkTreeMozambique,
    ModulePlaceholder,
    ListHeader,
    HistoryMeterModal,
    ReplaceMeterModal
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted() {
    this.getParams()
    this.handlePermission()
  },
  data() {
    return {
      historyDialogVisible: false,
      replaceMeterDialogVisible: false,
      hasPermission: true,
      loading: false,
      hasMeter: true,
      form: {},
      networkUnit: {},
      meter: {},
      models: [],
      errors,
      brands: [],
      selectedNodes: [],
      types: [{ id: 'network', name: 'network' }, { id: 'customer', name: 'customer' }],
      positions: [{ id: 'input', name: 'input' }, { id: 'output', name: 'output' }],
      serviceTypes: [{ id: 'prepaid', name: 'prepaid' }, { id: 'postpaid', name: 'postpaid' }],
      readingTypes: [{ id: 'active', name: 'active' }, { id: 'power', name: 'power' }, { id: 'reactive', name: 'reactive' }]
    }
  },
  methods: {
    async getParams() {
      const [, modelsResponse, brands] = await handlePromise(BrandModelService.getBrands())
      if (modelsResponse.ok) this.brands = brands.data
    },
    async loadNodeData({ data }) {
      this.toggleLoading()
      this.selectedNodes = [{ id: data.id }]
      const [error, response, responseData] = await handlePromise(MeterService.getMeter(data.id))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.meter = responseData
      this.networkUnit = data
      this.hasMeter = responseData.originSystemId
      this.form = {
        id: responseData.id,
        brand: responseData.brand ? responseData.brand.id : null,
        model: responseData.model ? responseData.model.id : null,
        serialNumber: responseData.serialNumber,
        type: responseData.type,
        position: responseData.position,
        serviceId: responseData.serviceId,
        serviceType: responseData.serviceType,
        readingTypes: responseData.readingTypes,
        originSystemId: responseData.originSystemId,
        name: responseData.name,
        address: responseData.address,
        phone: responseData.phone,
        email: responseData.email,
        largePower: responseData.largePower,
        status: responseData.status,
        installDate: responseData.installDate ? moment(Number(`${responseData.installDate}000`)).format('MM/DD/YYYY') + ' ' + '00:00:00' : null
      }
      const [, modelsResponse, models] = await handlePromise(BrandModelService.getModelsByBrand(this.form.brand))
      if (modelsResponse.ok) this.models = models.data
      this.toggleLoading()
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const data = {
            brand: this.form.brand,
            model: this.form.model,
            serialNumber: this.form.serialNumber,
            type: this.form.type,
            position: this.form.position,
            serviceId: this.form.serviceId,
            serviceType: this.form.serviceType,
            readingTypes: this.form.readingTypes,
            originSystemId: this.form.originSystemId,
            name: this.form.name,
            address: this.form.address,
            phone: this.form.phone,
            email: this.form.email,
            largePower: this.form.largePower ? '1' : '0'
          }
          this.toggleLoading()
          const [error, response] = await handlePromise(this.hasMeter ?
            MeterService.updateMeter(this.networkUnit.id, this.form.id, data) :
            MeterService.replaceMeter(this.networkUnit.id, this.form.id, {...data, installDate: this.form.installDate ? moment(this.form.installDate).format('YYYY-MM-DD') : null}))
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.hasMeter = true
        }
      })
    },
    async onBrandChange(event) {
      this.toggleLoading()
      const [, brandResponse, models] = await handlePromise(BrandModelService.getModelsByBrand(event))
      this.toggleLoading()
      if (brandResponse.ok) {
        this.models = models.data
        this.form.model = null
      }
    },
    toggleHistoryModal() {
      this.historyDialogVisible = !this.historyDialogVisible
    },
    toggleReplaceMeterModal() {
      this.replaceMeterDialogVisible = !this.replaceMeterDialogVisible
    },
    closeHistoryModal() {
      this.historyDialogVisible = false
    },
    closeReplaceModal() {
      this.replaceMeterDialogVisible = false
    },
    closeReplaceModalOk() {
      this.replaceMeterDialogVisible = false
      this.networkUnit = {}
    },
    toggleLoading() {
      this.loading = !this.loading
    },
    handlePermission() {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
