import api from '@/config/Api'

const baseEndpoint = '/network/lov'

export default {
  getLovs (params) {
    return api.get(baseEndpoint, params)
  },

  getLovValues (id, params) {
    return api.get(`${baseEndpoint}/${id}/lov-value`, params)
  },

  getLov (code) {
    return api.get(`${baseEndpoint}/${code}`)
  },

  getLovId (id) {
    return api.get(`${baseEndpoint}/lov-id/${id}`)
  },

  getLovValue (id, valueId) {
    return api.get(`${baseEndpoint}/${id}/lov-value/${valueId}`)
  },

  saveLov (data) {
    return api.post(baseEndpoint, data)
  },

  saveLovValue (id, data) {
    return api.post(`${baseEndpoint}/${id}/lov-value`, data)
  },

  updateLov (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  updateLovValue (id, valueId, data) {
    return api.put(`${baseEndpoint}/${id}/lov-value/${valueId}`, data)
  },

  deleteLov (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  deleteLovValue (id, valueId) {
    return api.delete(`${baseEndpoint}/${id}/lov-value/${valueId}`)
  }
}
