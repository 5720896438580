import api from '@/config/Api'

const baseEndpoint = '/network/network-unit'
const baseEndpoint2 = '/network/network-unit-water'

export default {

  getNetwork (params) {
    return api.get(baseEndpoint, params)
  },

  getNetworkUnit (id) {
    return api.get(`${baseEndpoint}/${id}/detail`)
  },

  updateNetworkUnit (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  createNetworkUnit (data) {
    return api.post(baseEndpoint, data)
  },

  deleteNetworkUnit (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  updateNetworkUnitWater (id, data) {
    return api.put(`${baseEndpoint2}/${id}`, data)
  }
}
