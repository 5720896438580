<template>
  <section>
    <ods-module :header="$route.params.id ? $t('editLov') : $t('newLov')">
      <ods-form
          :model="form"
          ref="form"
          v-loading="loading"
      >
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item
                :label="$t('name')"
                prop="name"
                :rules="[errors.required]"
            >
              <ods-input v-model="form.name"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
                :label="$t('code')"
                prop="code"
                :rules="[errors.required]"
            >
              <ods-input v-model="form.code"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('description')" prop="description"
                           :rules="[errors.required]">
              <ods-input v-model="form.description" type="textarea" ></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <form-buttons
            @submit="submitForm"
            cancel-to="LovDashboard"
        ></form-buttons>
      </ods-form>
    </ods-module>

    <lov-values
        v-if="$route.params.id"
        :lov="$route.params.id">
    </lov-values>
  </section>
</template>

<script>
import LovService from '@/services/Lov'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import LovValues from './Value'
import FormButtons from '@/custom-components/Buttons/FormButtons'

export default {
  name: 'LovEdit',
  components: {
    LovValues,
    FormButtons
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      errors
    }
  },
  methods: {
    async getData () {
      this.loading = true
      console.log(this.$route.params);
      const [error, response, data] = await handlePromise(LovService.getLovId(this.$route.params.id))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.form = {
        name: data.name,
        description: data.description,
        code: data.code
      }
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const [error, response, responseData] = await handlePromise(
            this.$route.params.id
              ? LovService.updateLov(this.$route.params.id, this.form)
              : LovService.saveLov(this.form)
          )
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
          return this.$router.push(this.$route.params.id
            ? { name: 'LovDashboard' }
            : { name: 'LovEdit', params: { id: responseData.id } })
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>
