<template>
  <section>
    <ods-module class="mt-1" :header="$t('lovValues')">
      <template slot="header">
        <list-header title="lovValues">
          <template slot="right">
            <ods-button size="medium" @click="toggleDialog()">
              {{$t('newValue')}}
            </ods-button>
          </template>
        </list-header>
      </template>

      <async-table
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <ods-table-column prop="value" :label="$t('value')">
          <template slot-scope="scope">
            <ods-button type="text" class="p-0" @click="toggleDialog(scope.row.id)">
              {{scope.row.value}}
            </ods-button>
          </template>
        </ods-table-column>

        <ods-table-column prop="code" :label="$t('code')"/>

         <ods-table-column align="right">
          <template slot-scope="scope">
            <table-delete @click="toggleDeleteDialog(scope.row.id)"></table-delete>
          </template>
        </ods-table-column>

      </async-table>
    </ods-module>

    <dialog-form
        title="newValue"
        :visible="editDialogVisible"
        @submit="saveValue"
        @cancel="toggleDialog()"
    >
      <ods-form
          :model="form"
          ref="form"
          v-loading="formLoading"
      >
        <ods-row :gutter="20">
          <ods-col>
            <ods-form-item :label="$t('value')" prop="value"
                           :rules="[errors.required]">
              <ods-input v-model="form.value"></ods-input>
            </ods-form-item>
            <ods-form-item :label="$t('code')" prop="code"
                          :rules="[errors.required]">
              <ods-input v-model="form.code"></ods-input>
            </ods-form-item>
            <ods-form-item :label="$t('description')" prop="description"
                          :rules="[errors.required]">
              <ods-input v-model="form.description" type="textarea"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
      </ods-form>
    </dialog-form>

    <dialog-confirmation
        :visible="deleteDialogVisible"
        :loading="deleteLoading"
        @confirm="deleteValue"
        @cancel="toggleDeleteDialog()"
    />
  </section>
</template>

<script>
import LovService from '@/services/Lov'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import DialogForm from '@/custom-components/DialogForm'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import AsyncTable from '@/custom-components/AsyncTable'
import ListHeader from '@/custom-components/ListHeader'
import asyncTableCommon from '@/mixins/async-table'

export default {
  name: 'LovValues',
  mounted: function () {
    this.getData()
  },
  components: {
    DialogForm,
    TableDelete,
    DialogConfirmation,
    AsyncTable,
    ListHeader
  },
  props: {
    lov: String
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      formLoading: false,
      deleteLoading: false,
      form: {},
      errors,
      editDialogVisible: false,
      valueToEdit: null,
      deleteDialogVisible: false,
      valueToDelete: null,
      promise: (params) => LovService.getLovValues(this.lov, params)
    }
  },
  methods: {
    async getValue () {
      this.formLoading = true

      const [error, response, data] = await handlePromise(LovService.getLovValue(this.lov, this.valueToEdit))
      this.formLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)
      this.form = {
        value: data.value,
        description: data.description,
        code: data.code
      }
    },
    toggleDialog (id) {
      this.valueToEdit = id

      if (this.editDialogVisible && this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.editDialogVisible = !this.editDialogVisible
      if (id) this.getValue()
    },
    async saveValue () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.formLoading = true

          const [error, response] = await handlePromise(
            this.valueToEdit
              ? LovService.updateLovValue(this.lov, this.valueToEdit, this.form)
              : LovService.saveLovValue(this.lov, this.form)
          )
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.toggleDialog()
          return this.getData(this.tableParams)
        }
      })
    },
    toggleDeleteDialog (id) {
      this.valueToDelete = id
      this.deleteDialogVisible = !this.deleteDialogVisible
    },
    async deleteValue () {
      this.deleteLoading = true
      const [error, response] = await handlePromise(LovService.deleteLovValue(this.lov, this.valueToDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.toggleDeleteDialog()
      return this.getData(this.tableParams)
    }
  }
}
</script>
