import api from '@/config/Api'
import { formatFormDataBody } from '@/utils/format'

const baseEndpoint = '/network/import-file-example'
const baseEndpointWater = '/network/import-water-file-example'
const baseEndpointNetwork = '/network/import-file-example?type=network'

export default {
  downloadTemplate (params) {
    return api.get(baseEndpoint, params)
  },

  uploadTemplateNetwork (params) {
    return api.post(baseEndpoint, params)
  },

  downloadTemplateWater (params) {
    return api.get(baseEndpointWater, params)
  },

  uploadTemplateNetworkWater (params) {
    return api.post(baseEndpointWater, params)
  }
}
