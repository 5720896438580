<template>
  <section>
    <ods-module class="mt-1">
      <template slot="header">
        <ods-row :gutter="20">
          <ods-col :md="12">
            {{ $t("consumptions") }}
          </ods-col>
          <ods-col :md="12" class="text-right">
            <slot name="right">
              <ods-button
                circle
                icon="ods-icon-plus"
                :disabled="!meter"
                @click="toggleDialog()"
                size="small"
              />
            </slot>
          </ods-col>
        </ods-row>
      </template>

      <ods-table :data="data.data" size="mini">
        <ods-table-column prop="meter.serialNumber" :label="$t('meter')" />
        <ods-table-column prop="period.name" :label="$t('period')" />
        <ods-table-column prop="date" :label="$t('date')" v-if="hasPermission">
          <template slot-scope="scope">
            <ods-button
              type="text"
              class="p-0"
              @click="toggleDialog(scope.row.id, scope.row.meter.serialNumber)"
            >
              {{ scope.row.date | date }}
            </ods-button>
          </template>
        </ods-table-column>
        <ods-table-column prop="date" :label="$t('date')" v-if="!hasPermission">
          <template slot-scope="scope">
            {{ scope.row.date | date }}
          </template>
        </ods-table-column>
        <ods-table-column prop="type" :label="$t('type')">
          <template slot-scope="scope">
            {{ $t(scope.row.type) }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('readingOrigin')">
          <template slot-scope="scope">
            {{ scope.row.readingOrigin | formatNumber }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumptionOrigin')">
          <template slot-scope="scope">
            {{ scope.row.consumptionOrigin | formatNumber }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('consumption')">
          <template slot-scope="scope">
            {{ scope.row.consumptionToDay | formatNumber }}
          </template>
        </ods-table-column>
        <ods-table-column prop="status" :label="$t('anomalies')">
          <template slot-scope="scope">
            <ods-tooltip
              class="item"
              effect="light"
              placement="top-start"
              v-if="
                scope.row.highEstimationStatus === 'pending' ||
                scope.row.highDeviationStatus === 'pending' ||
                scope.row.lowEstimationStatus === 'pending' ||
                scope.row.noReadingStatus === 'pending' ||
                scope.row.potentialTurnoverStatus === 'pending' ||
                scope.row.readingBoundsStatus === 'pending' ||
                scope.row.consumptionBoundsStatus === 'pending'
              "
            >
              <div slot="content">
                <div v-if="scope.row.highEstimationStatus === 'pending'">
                  - {{ $t("highEstimation") }}
                </div>
                <div v-if="scope.row.highDeviationStatus === 'pending'">
                  - {{ $t("highDeviation") }}
                </div>
                <div v-if="scope.row.lowEstimationStatus === 'pending'">
                  - {{ $t("lowEstimation") }}
                </div>
                <div v-if="scope.row.noReadingStatus === 'pending'">
                  - {{ $t("noReading") }}
                </div>
                <div v-if="scope.row.potentialTurnoverStatus === 'pending'">
                  - {{ $t("potentialTurnover") }}
                </div>
                <div v-if="scope.row.readingBoundsStatus === 'pending'">
                  - {{ $t("readingBounds") }}
                </div>
                <div v-if="scope.row.consumptionBoundsStatus === 'pending'">
                  - {{ $t("consumptionBounds") }}
                </div>
              </div>
              <ods-icon
                name="info-alert"
                color="#DE2F2F"
                size="20"
                style="line-height: 1"
              />
            </ods-tooltip>
          </template>
        </ods-table-column>
      </ods-table>
    </ods-module>

    <dialog-form
      v-if="editDialogVisible"
      :title="toEdit ? 'editConsumptionForMeter' : 'addConsumptionForMeter'"
      :secondTitle="serialNumber"
      :visible="editDialogVisible"
      @submit="submitForm"
      @cancel="toggleDialog()"
    >
      <ods-form
        :model="form"
        ref="form"
        v-loading="formLoading"
        :rules="validations"
      >
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item
              :label="$t('period')"
              prop="period"
              :rules="[errors.required]"
            >
              <ods-select
                v-model="form.period"
                :disabled="toEdit ? true : false"
                placeholder=""
              >
                <ods-option
                  v-for="item in periods"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item :label="$t('periodicity')" prop="periodicity">
              <ods-select v-model="form.periodicity" placeholder="">
                <ods-option
                  v-for="item in periodicity"
                  :key="item.id"
                  :label="$t(item.name)"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('lastReadingDate')" v-if="toEdit">
              <ods-input disabled v-model="form.lastReadingDate"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
              :label="$t('consumptionType')"
              prop="consumptionType"
            >
              <ods-select v-model="form.consumptionType" placeholder="">
                <ods-option
                  v-for="item in consumptionTypes"
                  :key="item.id"
                  :label="$t(item.name)"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
              :label="$t('date')"
              prop="date"
              :rules="[errors.required]"
            >
              <ods-date-picker
                type="date"
                v-model="form.date"
                style="width: 100%"
                @change="onChangeDate()"
              />
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
              :label="$t('consumption')"
              prop="consumptionToDay"
              :rules="[errors.required]"
            >
              <ods-input v-model="form.consumptionToDay"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
      </ods-form>
    </dialog-form>
  </section>
</template>

<script>
import errors from "@/config/InputErrors";
import MeterReadingService from "@/services/MeterReading";
import DialogForm from "@/custom-components/DialogForm";
import MeterOverview from "@/custom-components/MeterOverview";
import handlePromise from "@/utils/promise";
import moment from "moment";
import Vue from "vue";

export default {
  name: "LoadingReadings",
  props: {
    data: Object,
    meter: Object,
    periods: Array,
    activePeriod: Object,
    hasPermission: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DialogForm,
    // eslint-disable-next-line vue/no-unused-components
    MeterOverview,
  },
  data() {
    return {
      form: {},
      lastReading: {},
      lastReadingDate: "",
      lastReadingDate2: "",
      formLoading: false,
      errors,
      validations: {},
      editDialogVisible: false,
      toEdit: null,
      serialNumber: null,
      types: [
        { id: "real", name: "real" },
        { id: "estimated", name: "estimated" },
        { id: "system_estimated", name: "system_estimated" },
        { id: "manual", name: "manual" },
      ],
      periodicity: [
        { id: "hourly", name: "hourly" },
        { id: "daily", name: "daily" },
        { id: "monthly", name: "monthly" },
      ],
      consumptionTypes: [
        { id: "active", name: "active" },
        { id: "reactive", name: "reactive" },
        { id: "power", name: "power" },
      ],
    };
  },
  methods: {
    toggleDialog(id, meter) {
      this.toEdit = id ? id : null;
      this.serialNumber = meter
        ? meter
        : this.meter.serialNumber
        ? this.meter.serialNumber
        : null;
      if (this.editDialogVisible && this.$refs["form"]) {
        this.form = {};
        this.$refs["form"].resetFields();
      }
      this.editDialogVisible = !this.editDialogVisible;
      if (id) this.getReading();
    },
    async getReading() {
      this.formLoading = true;

      const [error, response, data] = await handlePromise(
        MeterReadingService.getMeterReading(this.data.meter, this.toEdit)
      );
      this.formLoading = false;
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.lastReadingDate = data.lastReadingDate;
      this.form = {
        period: data.period && data.period.id ? data.period.id : data.period,
        periodicity: data.periodicity,
        readingPeriod: data.readingPeriod,
        lastReadingDate: moment(String(data.lastReadingDate)).format(
          "DD-MM-YYYY"
        ),
        date: data.date
          ? moment.utc(Number(`${data.date}000`)).format("MM/DD/YYYY") +
            " " +
            "00:00:00"
          : null,
        consumptionType: data.consumptionType,
        consumptionToDay: Vue.filter("formatNumberForm")(data.consumptionToDay),
      };
      this.lastReading = data.lastReading;
    },
    onChangeDate() {
      let lastReadingDate = moment(String(this.lastReadingDate)).format();
      this.validations = {
        date: [
          { required: true, message: "errors.required", trigger: "blur" },
          {
            validator(rule, value, callback) {
              // console.log(new Date(value),'value')
              // console.log(new Date(lastReadingDate),'value2')
              // console.log(new Date(value) < new Date(lastReadingDate),'result')
              return !(new Date(lastReadingDate) >= new Date(value));
            },
            message: "dateGreaterThanLastReadingDate",
          },
        ],
      };
    },
    checkString(val) {
      if (typeof val === "string") {
        return true;
      }
      return false;
    },
    async submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.activePeriod) { 
            return this.$store.commit(
              "settings/toggleAlert",
              this.$t("activePeriodsNotFound")
            );
          }

          if (this.activePeriod && this.activePeriod.id !== this.form.period && !this.toEdit) {
            return this.$store.commit(
              "settings/toggleAlert",
              this.$t("onlyActivePeriod")
            );
          }

          this.formLoading = true;
          this.lastReadingDate = moment(this.lastReadingDate).format(
            "YYYY-MM-DD"
          );
          const toSend = {
            period: this.form.period,
            periodicity: this.form.periodicity,
            date: this.form.date
              ? moment.utc(this.form.date).format("YYYY-MM-DD")
              : null,
            // type: this.form.type,
            consumptionType: this.form.consumptionType,
            [this.toEdit ? "consumptionToDay" : "consumptionOrigin"]:
              this.checkString(this.form.consumptionToDay)
                ? this.form.consumptionToDay.replace(/,/g, "")
                : this.form.consumptionToDay,
          };
          const [error, response] = await handlePromise(
            this.toEdit
              ? MeterReadingService.updateMeterReading(this.data.meter, this.toEdit, toSend)
              : MeterReadingService.saveMeterReading(this.data.meter, toSend)
          )
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('reload-data')
          this.toggleDialog()
        }
      });
    },
  },
  watch: {
    "form.consumptionToDay": function (val) {
      this.form.consumptionToDay = this.checkString(val)
        ? Vue.filter("formatNumberForm")(val.replace(/[^\d.-]/g, ""))
        : this.form.consumptionToDay;
    },
  },
};
</script>
