<template>
  <section>
    <ods-module v-loading="loading">
      <template slot="header">
        <ods-row :gutter="20">
          <ods-col :md="12">
            {{ data.name }}
          </ods-col>
          <ods-col :md="12" class="text-right" v-if="data.webPathFile">
            <a
              :href="data.webPathFile"
              target="_blank"
              class="ods-button ods-button--primary ods-button--medium is-round"
            >
              {{ $t("download") }}
            </a>
          </ods-col>
        </ods-row>
      </template>

      <ods-row :gutter="20">
        <ods-col :md="10">
          <p>{{ $t("filename") }}: {{ data.fileName || "---" }}</p>

          <p>
            {{ $t("status") }}:
            <ods-badge
              v-if="data.status"
              :value="$t(data.status).toUpperCase()"
              :type="getStatusBadgeType(data.status)"
            />
            <span v-else>---</span>
          </p>
          <p>
            {{ $t("createdAt") }}:
            <span v-if="data.createdAt">{{ data.createdAt | datetime }}</span>
            <span v-else>---</span>
          </p>
          <p>
            {{ $t("updatedAt") }}:
            <span v-if="data.updatedAt">{{ data.updatedAt | datetime }}</span>
            <span v-else>---</span>
          </p>
        </ods-col>

        <ods-col :md="5">
          <p>{{ $t("importRow") }}: {{ data.importRow || 0 }}</p>
          <p>{{ $t("processRow") }}: {{ data.processRow || 0 }}</p>
          <p>{{ $t("validRow") }}: {{ data.validRow || 0 }}</p>
        </ods-col>
      </ods-row>

      <ods-row class="mt-1">
        <ods-accordion :accordion="false" class="custom-accordion">
          <ods-accordion-item name="1" :title="$t('networkTrees')">
            <ul>
              <li v-for="item in data.networkTrees" v-bind:key="item.id">
                - {{ item.id }}
              </li>
            </ul>
          </ods-accordion-item>

          <ods-accordion-item name="2" :title="$t('networkTreeLog')">
            <pre v-if="data.networkTreeLog">{{ data.networkTreeLog }}</pre>
          </ods-accordion-item>

          <ods-accordion-item name="3" :title="$t('validationsLog')">
            <pre v-if="data.validationsLog">{{ data.validationsLog }}</pre>
          </ods-accordion-item>

          <ods-accordion-item name="4" :title="$t('processLog')">
            <pre v-if="data.processLog">{{ data.processLog }}</pre>
          </ods-accordion-item>
        </ods-accordion>
      </ods-row>

      <ods-row type="flex" justify="center" class="mt-2">
        <router-link :to="{ name: 'ImportDashboardHorizontal' }">
          <ods-button type="secondary">{{ $t("back") }}</ods-button>
        </router-link>
      </ods-row>
    </ods-module>
  </section>
</template>

<script>
import ImportService from "@/services/Import";
import importCommon from "@/mixins/import";
import handlePromise from "@/utils/promise";

export default {
  name: "ImportDetail",
  mixins: [importCommon],
  mounted: function () {
    if (this.$route.params.id) {
      this.getData();
    }
  },
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    async getData() {
      this.loading = true;

      const [error, response, data] = await handlePromise(
        ImportService.getImportFile(this.$route.params.id)
      );
      this.loading = false;
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", error);
      this.data = data;
    },
  },
};
</script>
