<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
             {{$t('detailStrategicPlan')}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
        <ods-module v-loading="loading">
          <section class="modal-body">
              <slot name="body">
                  <ods-row :gutter="24" style="font-size:120%">
                          <ods-col :md="6" :sm="6">
                              {{$t('name')}}: {{name}}
                              <br>
                              {{$t('area')}}: {{area}}
                              <br>
                              {{$t('status')}}: {{status}}
                          </ods-col>
                          <ods-col :md="6" :sm="6">
                              {{$t('from')}}: {{from}}
                              <br>
                              {{$t('to')}}: {{to}}
                          </ods-col>
                          <ods-col :md="6" :sm="6">
                              {{$t('initialLossesPercent')}}: {{iniLosses | formatNumberPercentNoDecimal }}
                              <br>
                              {{$t('currentLossPercent')}}: {{currentLosses | formatNumberPercentNoDecimal}}
                              <br>
                              {{$t('totalLossReductionPercent')}}: {{totalLosses | formatNumberPercentNoDecimal}}
                          </ods-col>
                          <ods-col :md="6" :sm="6">
                              {{$t('totalBenefit')}}: {{ benefit | formatNumber}}
                              <br>
                              {{$t('totalCost')}}: {{ cost | formatNumber}}
                              <br>
                              {{$t('profitPercent')}}: {{ profit | formatNumberPercentNoDecimal}}
                          </ods-col>
                  </ods-row>
                  <br>
                  <hr>
                  <br>
                  <ods-row :gutter="24">
                      <ods-table
                        :data="data"
                        size="mini"
                        :loading="loading"
                        >
                        <ods-table-column :label="$t('month')">
                        <template slot-scope="scope">
                            {{ scope.row.month }}
                        </template>
                        </ods-table-column>
                        <ods-table-column :label="$t('lossesStart')">
                        <template slot-scope="scope">
                            {{ scope.row.lossesInitial | formatNumberPercentNoDecimal }}
                        </template>
                        </ods-table-column>
                        <ods-table-column :label="$t('lossesEnd')">
                        <template slot-scope="scope">
                            {{ scope.row.currentLoss | formatNumberPercentNoDecimal }}
                        </template>
                        </ods-table-column>
                        <ods-table-column :label="$t('lossReductionPercent')">
                        <template slot-scope="scope">
                            {{ scope.row.lossReduction | formatNumberPercentNoDecimal }}
                        </template>
                        </ods-table-column>
                        <ods-table-column :label="$t('cost')">
                        <template slot-scope="scope">
                            {{ scope.row.cost | formatNumber }}
                        </template>
                        </ods-table-column>
                        <ods-table-column :label="$t('benefit')">
                        <template slot-scope="scope">
                            {{ scope.row.benefit | formatNumber }}
                        </template>
                        </ods-table-column>
                        <ods-table-column :label="$t('profitPercent')">
                        <template slot-scope="scope">
                            {{ scope.row.profit | formatNumberPercentNoDecimal }}
                        </template>
                        </ods-table-column>
                    </ods-table>
                  </ods-row>
                  <br>
                  <hr>
                  <br>
                  <ods-row :gutter="24">
                      <ods-col :md="24">
                          <div id="chart2" style="height:400px;"></div>
                      </ods-col>
                  </ods-row>
              </slot>
          </section>
        </ods-module>
        </div>
    </div>
  </transition>
</template>
<script>
import errors from '@/config/InputErrors'
import StrategicService from '@/services/Strategic'
import handlePromise from '@/utils/promise'
import * as echarts from 'echarts'
import _ from 'lodash'
export default {
  name: 'DetailModal',
  props: {
    valueToDetail: String,
    name: String,
    area: String,
    status: String,
    from: String,
    to: String,
    iniLosses: {
      type: Number,
      default: null
    },
    currentLosses: {
      type: Number,
      default: null
    },
    totalLosses: {
      type: Number,
      default: null
    },
    cost: {
      type: Number,
      default: null
    },
    benefit: {
      type: Number,
      default: null
    },
    profit: {
      type: Number,
      default: null
    }
  },
  mounted: function () {
    this.getData()
  },
  data () {
    return {
      loading: false,
      errors,
      data: [],
      chart: null,
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          data: []
        },
        yAxis: [],
        series: []
      }
    }
  },
  methods: {
    async getData () {
      this.loading = true
      const [error, response, data] = await handlePromise(StrategicService.getStrategicAnalisisDetail(this.valueToDetail))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.data = data.data
      setTimeout(() => {
        this.chart = echarts.init(document.getElementById('chart2'))
        this.chartOptions = {
          ...this.chartOptions,
          xAxis: {
            data: _.map(this.data, 'month')
          },
          yAxis: [
            {
              type: 'value',
              name: 'Losses %'
            },
            {
              name: 'Cost/Benefit',
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Cost',
              type: 'line',
              yAxisIndex: 1,
              data: _.map(this.data, 'cost'),
              itemStyle: { color: '#2E6C99' }
            },
            {
              name: 'Benefit',
              type: 'line',
              yAxisIndex: 1,
              data: _.map(this.data, 'benefit'),
              itemStyle: { color: 'red' }
            },
            {
              name: 'Losses %',
              type: 'line',
              data: _.map(this.data, 'currentLoss'),
              itemStyle: { color: '#505D66' }
            }
          ]
        }
        this.chart.setOption(this.chartOptions, true)
      }, 100)
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style scoped>
   ::-webkit-scrollbar {
     width: 6px;
   }

   ::-webkit-scrollbar-thumb {
      background: rgb(224, 223, 223);
      border-radius: 10px;
   }

  ::-webkit-scrollbar-thumb:hover {
      background: rgb(197, 197, 197);
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    width: 95%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
    transition: color 0.3s;
    max-height: 90%;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 60vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
