import ptLocale from '@/addons/locale/lang/pt'
import Config from '../../config/Config'

export default {
  ...ptLocale,
  label: 'Português',
  labelMobile: 'PR',
  serverError: 'Erro inesperado do servidor. Tente novamente.',
  email: 'O email',
  password: 'Senha',
  login: 'Conecte-se',
  edit: 'Editar',
  back: 'De volta',
  cancel: 'Cancelar',
  users: 'Utilizadores',
  name: 'Nome',
  roles: 'Papéis',
  enabled: 'Activado',
  ROLE_ADMIN: 'Administrador',
  ROLE_OPERATOR: 'Operador',
  ROLE_SUPER_ADMIN: 'Superadministrador',
  editUser: 'Editar usuário',
  newUser: 'Criar usuário',
  externalAuthentication: 'Autenticação externa',
  confirm: 'Confirme',
  deleteAlert: 'Tem certeza de que deseja excluir este item?',
  description: 'Descrição',
  code: 'Código',
  value: 'Valor',
  newValue: 'Novo valor',
  save: 'Salvar',
  lovValues: 'Valor LOV',
  editLov: 'Editar LOV',
  lovs: 'LOVs',
  newLov: 'Novo LOV',
  delete: 'Excluir',
  errors: {
    required: 'Este campo é obrigatório',
    email: 'Por favor, insira o endereço de e-mail correto',
    url: 'Insira o URL correto',
    phone: 'Insira um número de telefone correto'
  },
  networkElements: 'Elementos de rede',
  editNetworkElement: 'Editar elemento de rede',
  attributes: 'Atributos',
  newAttribute: 'Novo atributo',
  type: 'Tipo',
  mandatory: 'Obrigatoriedade',
  lov: 'LOV',
  integer: 'Inteiro',
  INTEGER: 'Inteiro',
  addChild: 'Adicionar nó',
  editChild: 'Editar nó',
  newParent: 'Novo gerador',
  newFirstLevelNode: 'Novo nó de primeiro nível',
  newChild: 'Novo nó',
  network: 'Rede',
  networkHorizontal: 'Rede horizontal',
  networkVertical: 'Rede vertical',
  fileImported: 'Arquivo importado corretamente',
  import: 'Importar',
  rows: 'Linhas',
  created: 'Criado',
  status: 'Estado',
  maneuvers: 'Manobras',
  maneuver: 'Manobra',
  networkElement: 'Elemento de Rede',
  technicalLosses: 'Perdas técnicas',
  technicalLossesPercent: 'Perdas técnicas (%)',
  originSystemId: 'Código do sistema de origem',
  latitude: 'latitude',
  longitude: 'longitude',
  draft: 'Rascunho',
  accept: 'Aceitar',
  importRow: 'Importado',
  processRow: 'Processado',
  validRow: 'Válido',
  createdAt: 'Criado em',
  done: 'Feito',
  filename: 'Nome do arquivo',
  importDetail: 'Detalhe da importação',
  download: 'Descarregar',
  logs: 'Histórico',
  networkTree: 'Árvore de rede',
  updatedAt: 'Atualizado em',
  processLog: 'Registros de processo',
  approved: 'Aprovado',
  manual: 'Manual',
  accepted: 'Aceitaram',
  processed: 'Processado',
  changes: 'Mudanças',
  owner: 'Proprietário',
  validationsLog: 'Registros de validações',
  networkUnit: 'Unidade de rede',
  destination: 'Destino',
  deleted: 'Excluído',
  action: 'Ação',
  pending: 'Pendente',
  reject: 'Rejeitar',
  approve: 'Aprovar',
  rejected: 'Rejeitado',
  string: 'Corda',
  select: 'Selecionar',
  number: 'Número',
  move: 'jogado',
  selectDestinationNode: 'Selecione um nó de destino',
  moveNode: 'Mover nó',
  discard: 'Descartar',
  networkTrees: 'Árvores de rede',
  networkTreeLog: 'Logs da árvore de rede',
  modified: 'Modificado',
  error: 'Erro',
  from: 'A partir de',
  to: 'Para',
  dateRange: 'Período',
  restore: 'Restaurar',
  actions: 'Ações',
  origin: 'Origem',
  version: 'Versão',
  reconfiguration: 'Reconfiguraçao',
  networkHistory: 'Histórico de Rede',
  moveNodeSameDestination: 'Selecione um destino de nó diferente',
  executionDate: 'Data de execução',
  changePassword: 'Alterar senha',
  confirmPassword: 'Confirme a Senha',
  passwordDontMatch: 'As senhas não correspondem',
  meterManagement: 'Gestão de Contadores',
  serialNumber: 'Número de série',
  readingTypes: 'Tipos de leitura',
  model: 'Modelo',
  brand: 'Marca',
  position: 'Posição',
  serviceType: 'Tipo de serviço',
  serviceId: 'Código do serviço',
  input: 'Entrada',
  output: 'Resultado',
  prepaid: 'Pré-pago',
  postpaid: 'Postpaid',
  active: 'Ativo',
  power: 'Poder',
  reactive: 'Reativo',
  customer: 'Cliente',
  customers: 'Clientes',
  areasDefinition: 'Definição de áreas',
  newArea: 'Nova área',
  editArea: 'Editar área',
  selectedNodes: 'Nós selecionados',
  selectNodesInfo: '-Selecione os nós que definem esta área na árvore.',
  periodsMaintenance: 'Manutenção de Períodos',
  newPeriod: 'Novo período',
  editPeriod: 'Editar período',
  processing: 'Em processamento',
  closed: 'Fechados',
  open: 'Abrir',
  initialDate: 'Data inicial',
  lastDayReadingsCapture: 'Captura de leituras do último dia',
  closureDate: 'Data de encerramento',
  meters: 'Contadores',
  metersHorizontal: 'Contadores horizontales',
  metersVertical: 'Contadores veticales',
  purchases: 'Recargas',
  newPurchase: 'Nova compra',
  editPurchase: 'Editar compra',
  totalAmount: 'Valor total',
  totalEnergy: 'Energia total',
  reference: 'Referência',
  billOriginalReference: 'Referência original da fatura',
  measurementLoading: 'Gestão de medição',
  measurementAnalysis: 'Análise de medição',
  measurementValidation: 'Validação de medição',
  individualValidation: 'Validação individual de medição',
  consumption: 'consumo',
  date: 'encontro',
  reading: 'leitura',
  period: 'Período',
  search: 'Procurar',
  readings: 'Leituras',
  newReading: 'Nova leitura',
  new: 'Novo',
  estimate: 'Estimativo',
  runValidation: 'Executar validação',
  editReading: 'Editar leitura',
  analysis: 'Análise',
  periodicity: 'Periodicidade',
  readingPeriod: 'Período de leitura',
  real: 'Real',
  estimated: 'Estimado',
  system_estimated: 'Estimativa do sistema',
  hourly: 'De hora em hora',
  daily: 'Diário',
  monthly: 'Por mês',
  consumptionType: 'Tipo de consumo',
  consumptionOrigin: 'Origem do consumo',
  readingOrigin: 'Origem da leitura',
  readingToBalance: 'Leitura para Balanço',
  consumptionToBalance: 'Consumo para Balanço',
  lastReport: 'Último relatório',
  validateEmptyReadings: 'Validar leituras vazias',
  validateReadings: 'Validar leituras',
  bulkValidations: 'Validação em massa',
  energyInput: 'Entrada de energia',
  energyType: 'Tipo de energia',
  balanceArea: 'Área de Balanço',
  element: 'Elemento',
  average: 'Média',
  monthValue1: 'Valor do mês 1',
  monthValue2: 'Valor do mês 2',
  monthValue3: 'Valor do mês 3',
  monthValue4: 'Valor do mês 4',
  monthValue5: 'Valor do mês 5',
  monthValue6: 'Valor do mês 6',
  monthValue7: 'Valor do mês 7',
  monthValue8: 'Valor do mês 8',
  monthValue9: 'Valor do mês 9',
  monthValue10: 'Valor do mês 10',
  monthValue11: 'Valor do mês 11',
  monthValue12: 'Valor do mês 12',
  monthValue1Percent: 'M1 %',
  monthValue2Percent: 'M2 %',
  monthValue3Percent: 'M3 %',
  monthValue4Percent: 'M4 %',
  monthValue5Percent: 'M5 %',
  monthValue6Percent: 'M6 %',
  monthValue7Percent: 'M7 %',
  monthValue8Percent: 'M8 %',
  monthValue9Percent: 'M9 %',
  monthValue10Percent: 'M10 %',
  monthValue11Percent: 'M11 %',
  monthValue12Percent: 'M12 %',
  currentBalances: 'Balanços actuais',
  includeLosses: 'Incluir perdas',
  balanceDashboard: 'Painel de Balanço',
  balanceDashboardHorizontal: 'Painel de Balanço horizontal',
  balanceDashboardVertical: 'Painel de Balanço vertical',
  lastMonthLosses: ' Perdas do mês anterior',
  losses: 'Perdas',
  lossesPercent: 'Perdas %',
  lastMonthLossesPercent: ' Perdas do mês anterior %',
  applyManeuvers: 'Aplicar manobras',
  maneuversApplied: 'Manobras aplicadas',
  nodeSaved: 'A operação foi bem sucedida',
  nodeCreated: 'Criado',
  nodeMoved: 'Mudou-se',
  nodeDeleted: 'Excluído',
  nodeBlocked: 'Bloqueado',
  colorsLegend: 'Legenda de cores',
  noMeterAssociated: 'Nenhum medidor associado',
  bulkValidationStarted:
    'O processo foi iniciado. Vai levar algum tempo para terminar',
  meter: 'Medidor',
  highEstimation: 'Alta estimativa',
  highDeviation: 'Alto desvio',
  lowEstimation: 'Estimativa baixa',
  potentialTurnover: 'Rotatividade potencial',
  readingBounds: 'Limites de leitura',
  consumptionBounds: 'Limites de consumo',
  noReading: 'Sem leitura',
  anomalies: 'Anomalias',
  noMeterSerialNumber: 'Este nó não tem um medidor associado',
  noMoreFiveComparison: 'Não selecione mais de 5 nós para comparar',
  noHistorical: 'Este nó não tem um histórico associado',
  bulkEstimation: 'Estimativa em massa',
  historical: 'Histórico',
  balanceLog: 'Registro',
  balanceLogTitle: 'Saldo de log',
  balanceLogTitleValidation: 'Validação de registro',
  readingsProcessed: 'Leituras processadas',
  meterReadingsToProcess: 'Leituras a processar',
  noReadingsValidationLog: 'Validar registro de leituras vazio',
  validateReadingsLog: 'Validar registro de leituras',
  bulkValidationLog: 'Registro de validação em massa',
  bulkEstimationLog: 'Registro de estimativa em massa',
  totalJobs: 'Total de trabalhos',
  executedJobs: 'Trabalhos executados',
  dailyBalance: 'Balanço diário',
  periodCalculation: 'Calcular período',
  dailyBalanceStarted: 'Saldo diário iniciado',
  periodCalculationStarted: 'Cálculo iniciado',
  ERROR_MESSAGE_REPORT_NOT_FOUND: 'O relatório não está disponível',
  apiError: 'Ops ocorreu um erro',
  ERROR_MESSAGE_REQUEST_INVALID_USER_PASSWORD_COMBINATION:
    'Usuário ou senha inválidos',
  ERROR_MESSAGE_THE_PROCESS_HAS_ALREADY_BEEN_STARTED:
    'O processo já foi iniciado',
  ERROR_MESSAGE_THE_PROCESS_DAILY_BALANCE_IS_RUNNING:
    'O processo Saldo Diário está em execução',
  ERROR_MESSAGE_METER_NOT_FOUND: 'Medidor não encontrado',
  ERROR_MESSAGE_ACTIVE_PERIOD_NOT_FOUND: 'Período ativo não encontrado',
  ERROR_MESSAGE_INVALID_DATES_TO_CREATE_PERIOD:
    'Datas inválidas para criar um período',
  ERROR_MESSAGE_CLOSURE_DATE_HAS_TO_BE_GREATER_THAN_INITIAL_DATE:
    'A data de encerramento deve ser maior que a data inicial',
  ERROR_MESSAGE_INVALID_LAST_DAY_READING_CAPTURE:
    'A captura de leitura do último dia deve ser maior que a data inicial e menor que a data de fechamento',
  ERROR_MESSAGE_INVALID_DATE: 'Data inválida',
  ERROR_MESSAGE_BALANCE_AREA_NOT_FOUND: 'Área não encontrada',
  ERROR_MESSAGE_INVALID_ORDER_BETWEEN_NODE_AND_PARENT:
    'Ordem inválida entre o nó e o pai',
  ERROR_MESSAGE_REPORT_TYPE_NOT_FOUND: 'Tipo de relatório não encontrado',
  ERROR_MESSAGE_INVALID_LAST_READING_DATE:
    'A data deve ser maior que a data da última leitura',
  order: 'ordem',
  process_tree: 'Árvore de processos',
  done_pending: 'Concluído pendente',
  ERROR_MESSAGE_PERIOD_NOT_FOUND: 'Período não encontrado',
  activePeriodsNotFound: 'Períodos ativos não encontrados',
  REAL: 'Real',
  lastReadingDate: 'Data da última leitura',
  dateGreaterThanLastReadingDate:
    'A data deve ser maior que a data da última leitura',
  dateGreaterThanFromDate: 'The end date most be greater than the initial date',
  initialDateLessOrEqualsThanLastReadingDateCapture:
    'A data inicial deve ser menor ou igual à última captura de data de leitura',
  initialDateLessOrEqualsThanClosureDate:
    'A data inicial deve ser menor ou igual à data de fechamento',
  lastDayReadingsCaptureGreatherOrEqualsThanInitialDate:
    'A data da última leitura deve ser maior ou igual à data inicial',
  lastDayReadingsCaptureLessOrEqualsThanClosureDate:
    'A data da última leitura deve ser menor ou igual à data de fechamento',
  initialDateGreatherOrEqualsThanInitialDate:
    'A data de encerramento deve ser maior ou igual à data inicial',
  initialDateGreatherOrEqualsThanLastReadingDateCapture:
    'A data de fechamento deve ser maior ou igual à última captura da data de leitura',
  readingImported: 'Leitura importada',
  consumptionImported: 'Consumo importado',
  ERROR_MESSAGE_ENERGY_PURCHASE_NOT_FOUND: 'Compra de energia não encontrada',
  ERROR_MESSAGE_THERE_IS_ALREADY_AN_ACTIVE_PERIOD: 'Já existe um período ativo',
  ERROR_MESSAGE_CAN_NOT_PERFORM_THIS_ACTION:
    'Não é possível realizar esta ação',
  ERROR_MESSAGE_NETWORK_TREE_IS_BLOCKED: 'A árvore de rede está bloqueada',
  monetary: 'Monetário',
  selectPeriod: 'Você deve selecionar um período primeiro',
  monetaryCalculation: 'Cáculo monetárion',
  nodeAlreadySelected: 'Este nó já está selecionado',
  ERROR_MESSAGE_NO_INPUT_TYPE_METER_IS_FOUND:
    'Nenhum medidor de tipo de entrada foi encontrado',
  selectNodesInfo2:
    '-Lembre-se de selecionar um medidor de tipo de entrada como o primeiro nó.',
  selectNodesInfo3:
    '-Ao selecionar um nó, o restante da árvore pertencente a este nó será selecionado.',
  ERROR_MESSAGE_INVALID_NODE_TO_FORM_PART_OF_AN_AREA:
    'Este nó não atende aos requisitos para fazer parte de uma área',
  unitCost: 'Custo unitário',
  voltage: 'Tensão',
  region: 'Região',
  district: 'Distrito',
  location: 'Localização',
  tariff: 'Tarifa',
  solved: 'Resolvido',
  ignored: 'Ignorado',
  totalConsumption: 'Consumo total',
  strategicPlan: 'Manutenção Plano Estratégico',
  initialLosses: 'Perdas iniciais',
  initialLossesPercent: 'Perdas iniciais (%)',
  lossesObjective: 'Objetivo de Perdas',
  lossesObjectivePercent: 'Objetivo de Perdas (%)',
  timePeriod: 'Período de tempo (meses)',
  interventionsPlanned: 'Intervenções planejadas',
  estimatedCost: 'Custo estimado',
  estimatedCostDollar: `Custo estimado (${Config.currency})`,
  createPlan: 'Criar plano',
  editPlan: 'Editar plano',
  createStategicPlan: 'Criar plano estratégico',
  area: 'Área',
  lessThan100: 'Este campo deve ser menor que 100',
  lessOrEqualsThan100: 'Este campo deve ser menor ou igual a 100',
  greatherThan0: 'Este campo deve ser maior que 0',
  lessThanValue: 'Este campo deve ser menor que ',
  lossesObjectiveLessThanInitialLosses:
    'As perdas objetivas devem ser menores que as perdas iniciais',
  ERROR_MESSAGE_LOV_NOT_FOUND: 'LOV não encontrado',
  strategicActivities: 'Atividades estratégicas',
  newActivity: 'Nova atividade',
  createActivity: 'Criar atividade',
  editActivity: 'Editar atividade',
  refresh: 'Actualizar',
  about: 'Sobre',
  apiVersion: 'Versão da API',
  mongoVersion: 'Versão Mongo',
  mysqlVersion: 'Mysql Versão',
  phpVersion: 'Php Versão',
  webVersion: 'Web Versão',
  largePower: 'Grande potência',
  result: 'Resultado',
  realCost: `Custo real (${Config.currency})`,
  comment: 'Comente',
  addActionActivitie: 'Adicionar ações/atividades',
  negative: 'Negativo',
  positive: 'Positivo',
  NEGATIVE: 'Negativo',
  POSITIVE: 'Positivo',
  OPEN: 'Abrir',
  CLOSED: 'Fechados',
  brandModels: 'Marcas/Modelos',
  brands: 'Marcas',
  createBrand: 'Criar marca',
  editBrand: 'Editar marca',
  createModel: 'Criar modelo',
  editModel: 'Editar modelo',
  models: 'Modelos',
  ERROR_MESSAGE_BRAND_NOT_FOUNT: 'Marca não encontrada',
  underConstruction: 'Esta área está em construção',
  lossReductionTarget: 'Meta de redução de perdas',
  lossReductionTargetPercent: 'Meta de redução de perdas (%)',
  lossReductionTargetLessThanInitialLosses:
    'A meta de redução de perdas deve ser menor que as perdas iniciais',
  realCostRequired: 'O custo real não deve ficar em branco',
  activityHistory: 'Histórico de atividades',
  strategicPlanDetail: 'Plano estratégico',
  detailPlan: 'Plano de detalhes',
  ROLE_MANAGER: 'Gerente',
  userDontHaveAcces: 'Este usuário não tem acesso a esta seção',
  networkElementDetail: 'Detalhe do elemento de rede',
  elementType: 'Tipo de elemento',
  initialFields: 'Campos iniciais',
  fieldsUpdated: 'Campos atualizados',
  ERROR_MESSAGE_STRATEGIC_PLAN_NOT_FOUND: 'Plano estratégico não encontrado',
  expectedRealCost: `Custo esperado (${Config.currency})`,
  expectedExecutionDate: 'Data de execução prevista',
  otherComment: 'Outro comentário',
  forecastMeasurementLoading: 'Gerenciamento de medição de previsão',
  forecastMeasurementAnalysis: 'Análise de medição de previsão',
  forecastMeasurementValidation: 'Validação de medição de previsão',
  consumptions: 'Consumos',
  consumptionsHorizontal: 'Consumos horizontal',
  consumptionsVertical: 'Consumos vertical',
  consumptionForecast: 'Previsão de consumo',
  editConsumption: 'Editar consumo',
  addConsumption: 'Adicionar consumo',
  readingForecast: 'Previsão de leitura',
  readingForecastToBalance: 'Leitura para balanço a previsão',
  consumptionForecastToBalance: 'Consumo para balanço a previsão',
  INSPECTIONS: 'Inspeções',
  REPLACEMENT: 'Substituição',
  CONTROL_METER: 'Medidor de controle',
  MAINTENANCE: 'Manutenção',
  includeForecast: 'Incluir previsão',
  consumptionToDay: 'Consumo por dia',
  consumptionForecastToDay: 'Previsão de consumo para o dia',
  generatePeriods: 'Gerar períodos',
  year: 'Ano',
  noDecimal: 'Este campo não deve ter casas decimais',
  greatherThan2000: 'Este campo deve ser maior que 2000',
  ERROR_MESSAGE_THIS_YEAR_ALREADY_EXISTS:
    'Os períodos deste ano já são gerados',
  startPeriod: 'Período inicial',
  onlyActivePlans: 'Apenas planos ativos',
  currentLoss: 'Perda atual',
  currentLossPercent: 'Perda atual(%)',
  lossReduction: 'Redução de perdas',
  lossReductionPercent: 'Redução de perdas(%)',
  profit: 'Lucro',
  profitPercent: 'Lucro(%)',
  benefit: 'Beneficiar',
  cost: 'Custo',
  monthlyLosses: 'Perdas mensais',
  consumptionOriginForecast: 'Previsão de origem de consumo',
  detailStrategicPlan: 'Detalhe do Plano Estratégico',
  totalLossReductionPercent: 'Redução de perdas totais(%)',
  totalBenefit: 'Benefício total',
  totalCost: 'Custo total',
  month: 'Mês',
  lossesStart: 'Perdas (início)',
  lossesEnd: 'Perdas (fim)',
  rank: 'Classificação',
  filter: 'Filtro',
  periodNotFound: 'Nenhum período foi encontrado',
  firstTrimester: '1 Trimestre',
  secondTrimester: '2 Trimestre',
  thirdTrimester: '3 Trimestre',
  fourTrimester: '4 Trimestre',
  firstSemester: '1 Semestre',
  secondSemester: '2 Semestre',
  annual: 'Anual',
  downloadTable: 'Baixar tabela',
  custom: 'Personalizado',
  downloadTemplate: 'Baixar modelo',
  uploadTemplate: 'Carregar modelo',
  templateUploaded: 'Modelo gerado',
  ERROR_MESSAGE_FILE_NOT_FOUND: 'Arquivo não encontrado',
  generateTemplate: 'Modelo Gerador',
  meterHistory: 'Histórico do medidor',
  replaceMeter: 'Substituir medidor',
  installationDate: 'Data de instalação',
  ERROR_MESSAGE_THIS_PLAN_IS_STARTED: 'Este plano já foi iniciado',
  ERROR_MESSAGE_YOU_MUST_SPECIFY_SERIAL_NUMBER_OR_NETWORK_UNIT:
    'Você deve especificar um número de série ou uma unidade de rede',
  ignoreAnomalies: 'Ignorar anomalias',
  estimateAnomalies: 'Estimar anomalias',
  ERROR_MESSAGE_ANOMALY_TYPE_NOT_FOUND: 'Tipo de anomalia não encontrado',
  measurementBulkValidation: 'Validação em massa de medição',
  resetOrigin: 'Redefinir origem',
  editConsumptionForMeter: 'Editar consumo para medidor',
  addConsumptionForMeter: 'Adicionar consumo por medidor',
  section: 'Seção',
  Energy: 'Energia',
  Water: 'Água',
  ERROR_MESSAGE_INVALID_MODULE:
    'Este usuário não tem acesso à seção selecionada',
  newParentWater: 'Nova estação de água',
  address: 'Endereço',
  manufacturer: 'Fabricante',
  volume: 'Volume',
  volumeRest: 'Volume restante',
  waterplants: 'Plantas aquáticas',
  neighbors: 'Vizinhos',
  hideDetails: 'Detalhes ocultos',
  showDetails: 'Mostrar detalhes',
  details: 'Detalhes',
  ERROR_MESSAGE_INVALID_NEIGHBOR: 'Vizinho inválido',
  ERROR_MESSAGE_NETWORK_UNIT_NOT_FOUND: 'Unidade de rede não encontrada',
  mostSelectLevel2: 'Você só pode selecionar nós com nível 1 e 2',
  totalWater: 'Água total',
  canOnlySelectBrotherOrChildren:
    'Você só pode selecionar um nó irmão ou filho',
  waterInput: 'Entrada de água',
  invalidData: 'Dados inconsistentes',
  ERROR_MESSAGE_INVALID_AREA: 'Área inválida',
  ERROR_MESSAGE_THIS_NODE_CANNOT_BE_REMOVED: 'Este nó não pode ser removido',
  ERROR_MESSAGE_INVALID_NETWORK_ELEMENT_TYPE:
    'Tipo de elemento de rede inválido',
  until: 'Até',
  ERROR_MESSAGE_INVALID_METER_READING: 'Leitura do medidor inválida',
  ERROR_MESSAGE_INVALID_METER: 'Medidor inválido',
  ERROR_MESSAGE_INVALID_ACTIVE_PERIOD_WITH_THIS_DATE:
    'Período ativo inválido com esta data',
  ERROR_MESSAGE_INVALID_FILE: 'Arquivo inválido',
  filterKeyword: 'Filtrar',
  onlyActivePeriod: 'Apenas períodos ativos são permitidos',
  balanceManagement: 'Gestão de balanço',
  balanceManagementHorizontal: 'Gestão de balanço horizontal',
  balanceManagementVertical: 'Gestão de balanço vertical',
  createBalance: 'Criar balance',
  editBalance: 'Editar balance',
  editBalanceHorizontal: 'Editar balance horizontal',
  editBalanceVertical: 'Editar balance vertical',
  createBalanceHorizontal: 'Crear balance horizontal ',
  createBalanceVertical: 'Crear balance vertical ',
  entries: 'Entradas',
  outs: 'Partidas',
  clientType: 'Tipo de cliente',
  generationType: 'Tipo de geração',
  transportationDivision: 'Divisão de transporte',
  voltageType: 'Tipo de tensão',
  activeFilters: 'Filtros ativos',
  ERROR_MESSAGE_NETWORK_NODE_NOT_FOUND: 'Nó de rede não encontrado',
  elementTypes: 'Tipos de elemento',
  clientTypes: 'Tipos de cliente',
  generationTypes: 'Tipos de geração',
  commercialOffice: 'ASC',
  activationDate: 'Data de ativação',
  deactivationDate: 'Data de desativação',
  getBalance: 'Obter balance',
  balance: 'Balance',
  inputs: 'Entradas',
  outputs: 'Salidas',
  total: 'Total',
  totalReading: 'Lectura total',
  inputTotalReading: 'Entrada de lectura total',
  inputTotalConsumption: 'Entrada de consumo total',
  outputTotalReading: 'Salida de lectura total',
  outputTotalConsumption: 'Salida de consumo total',
  balanceConsumption: 'Balanço de consumo',
  balanceReading: 'Leitura de Balanço',
  networkMap: 'Mapa de rede',
  networkUnitDeletedSuccessfully: 'Elemento de rede removido com sucesso',
  balanceType: 'Tipo de Balanço',
  borderType: 'Tipo de borda',
  billing: 'Facturação',
  massiveImport: 'Importação em massa',
  ERROR_MESSAGE_THERE_IS_AN_IMPORT_FILE_READING_THAT_IS_ALREADY_BEING_IMPORTED: 'É uma leitura de arquivo de importação que já está sendo importada',
  ltmBalance: "balanço LTM",
  regionImport: "Região de importação",
  regionExport: "Região de exportação",
  ascImport: "Asc importação",
  ascExport: "Asc exportação",
  ERROR_MESSAGE_THERE_ARE_NO_DOCUMENTS_IN_THE_FOLDER_TO_IMPORT_THE_MEASUREMENTS: 'Não há documentos na pasta para importar as medidas',
  ERROR_MESSAGE_THERE_ARE_TO_MUCH_DOCUMENTS_IN_IMPORT_FOLDER: 'Há muitos documentos na pasta de importação',
  importationLog: "Registros de importação",
  distributedGrossEnergy: "Energia Bruta Distribuida",
  availableGrossEnergy: "Energia Bruta Disponivel",
  transportedGrossEnergy: "Energia Bruta Transportada",
  billedEnergy: "Energia Facturada",
  energyDeliveredToDistribution: "Energia Entregue a Distribuicao",
  MWhLosses: "Perdas MWh"
}
