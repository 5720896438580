// This file is automatically generated by actions/build-index.js
// Please, do NOT update this file directly

// Addons
import locale from '../addons/locale'
import OdsCollapseTransition from '../addons/transitions/collapse-transition.js'

// ODS Components
import OdsAccordion from './accordion/Accordion'
import OdsAccordionItem from './accordion-item/AccordionItem'
import OdsActionsMenu from './actions-menu/ActionsMenu'
import OdsAlert from './alert/Alert'
import OdsAside from './aside/Aside'
import OdsAutocomplete from './autocomplete/Autocomplete'
import OdsAvatar from './avatar/Avatar'
import OdsBacktop from './backtop/Backtop'
import OdsBadge from './badge/Badge'
import OdsBreadcrumb from './breadcrumb/Breadcrumb'
import OdsBreadcrumbItem from './breadcrumb-item/BreadcrumbItem'
import OdsButton from './button/Button'
import OdsButtonGroup from './button-group/ButtonGroup'
import OdsCalendar from './calendar/Calendar'
import OdsCarousel from './carousel/Carousel'
import OdsCarouselItem from './carousel-item/CarouselItem'
import OdsCascader from './cascader/Cascader'
import OdsCascaderPanel from './cascader-panel/CascaderPanel'
import OdsChart from './chart/Chart'
import OdsCheckbox from './checkbox/Checkbox'
import OdsCheckboxButton from './checkbox-button/CheckboxButton'
import OdsCheckboxGroup from './checkbox-group/CheckboxGroup'
import OdsCol from './col/Col'
import OdsCollapse from './collapse/Collapse'
import OdsColorPicker from './color-picker/ColorPicker'
import OdsContainer from './container/Container'
import OdsDatavizBar from './dataviz-bar/DatavizBar'
import OdsDatavizGauge from './dataviz-gauge/DatavizGauge'
import OdsDatavizLine from './dataviz-line/DatavizLine'
import OdsDatavizPie from './dataviz-pie/DatavizPie'
import OdsDatePicker from './date-picker/DatePicker'
import OdsDialog from './dialog/Dialog'
import OdsDialogConfirmation from './dialog-confirmation/DialogConfirmation'
import OdsDivider from './divider/Divider'
import OdsDropdown from './dropdown/Dropdown'
import OdsDropdownItem from './dropdown-item/DropdownItem'
import OdsDropdownMenu from './dropdown-menu/DropdownMenu'
import OdsFooter from './footer/Footer'
import OdsForm from './form/Form'
import OdsFormItem from './form-item/FormItem'
import OdsHeader from './header/Header'
import OdsIcon from './icon/Icon'
import OdsImage from './image/Image'
import OdsInfiniteScroll from './infinite-scroll/InfiniteScroll'
import OdsInput from './input/Input'
import OdsInputNumber from './input-number/InputNumber'
import OdsInputSearch from './input-search/InputSearch'
import OdsKpi from './kpi/Kpi'
import OdsLoading from './loading/Loading'
import OdsLogo from './logo/Logo'
import OdsMain from './main/Main'
import OdsMainHeader from './main-header/MainHeader'
import OdsMainNavigation from './main-navigation/MainNavigation'
import OdsMenu from './menu/Menu'
import OdsMenuItem from './menu-item/MenuItem'
import OdsMenuItemGroup from './menu-item-group/MenuItemGroup'
import OdsMessage from './message/Message'
import OdsMessageBox from './message-box/MessageBox'
import OdsModule from './module/Module'
import OdsNavigation from './navigation/Navigation'
import OdsNotification from './notification/Notification'
import OdsNotificationLink from './notification-link/NotificationLink'
import OdsOption from './option/Option'
import OdsOptionGroup from './option-group/OptionGroup'
import OdsPagination from './pagination/Pagination'
import OdsPopover from './popover/Popover'
import OdsProgress from './progress/Progress'
import OdsRadio from './radio/Radio'
import OdsRadioButton from './radio-button/RadioButton'
import OdsRadioGroup from './radio-group/RadioGroup'
import OdsRate from './rate/Rate'
import OdsRow from './row/Row'
import OdsScrollbar from './scrollbar/Scrollbar'
import OdsSelect from './select/Select'
import OdsSlider from './slider/Slider'
import OdsSpinner from './spinner/Spinner'
import OdsStep from './step/Step'
import OdsSteps from './steps/Steps'
import OdsSubHeader from './sub-header/SubHeader'
import OdsSubmenu from './submenu/Submenu'
import OdsSwitch from './switch/Switch'
import OdsTabPane from './tab-pane/TabPane'
import OdsTable from './table/Table'
import OdsTableColumn from './table-column/TableColumn'
import OdsTabs from './tabs/Tabs'
import OdsTag from './tag/Tag'
import OdsThemeOnesait from './theme-onesait/ThemeOnesait'
import OdsTimePicker from './time-picker/TimePicker'
import OdsTimeSelect from './time-select/TimeSelect'
import OdsTimeline from './timeline/Timeline'
import OdsTimelineItem from './timeline-item/TimelineItem'
import OdsTooltip from './tooltip/Tooltip'
import OdsTransfer from './transfer/Transfer'
import OdsTree from './tree/Tree'
import OdsUpload from './upload/Upload'

// What should happen if the user installs the library as a plugin
function install (Vue, opts = {}) {
  Vue.component('OdsAccordion', OdsAccordion)
  Vue.component('OdsAccordionItem', OdsAccordionItem)
  Vue.component('OdsActionsMenu', OdsActionsMenu)
  Vue.component('OdsAlert', OdsAlert)
  Vue.component('OdsAside', OdsAside)
  Vue.component('OdsAutocomplete', OdsAutocomplete)
  Vue.component('OdsAvatar', OdsAvatar)
  Vue.component('OdsBacktop', OdsBacktop)
  Vue.component('OdsBadge', OdsBadge)
  Vue.component('OdsBreadcrumb', OdsBreadcrumb)
  Vue.component('OdsBreadcrumbItem', OdsBreadcrumbItem)
  Vue.component('OdsButton', OdsButton)
  Vue.component('OdsButtonGroup', OdsButtonGroup)
  Vue.component('OdsCalendar', OdsCalendar)
  Vue.component('OdsCarousel', OdsCarousel)
  Vue.component('OdsCarouselItem', OdsCarouselItem)
  Vue.component('OdsCascader', OdsCascader)
  Vue.component('OdsCascaderPanel', OdsCascaderPanel)
  Vue.component('OdsChart', OdsChart)
  Vue.component('OdsCheckbox', OdsCheckbox)
  Vue.component('OdsCheckboxButton', OdsCheckboxButton)
  Vue.component('OdsCheckboxGroup', OdsCheckboxGroup)
  Vue.component('OdsCol', OdsCol)
  Vue.component('OdsCollapse', OdsCollapse)
  Vue.component('OdsColorPicker', OdsColorPicker)
  Vue.component('OdsContainer', OdsContainer)
  Vue.component('OdsDatavizBar', OdsDatavizBar)
  Vue.component('OdsDatavizGauge', OdsDatavizGauge)
  Vue.component('OdsDatavizLine', OdsDatavizLine)
  Vue.component('OdsDatavizPie', OdsDatavizPie)
  Vue.component('OdsDatePicker', OdsDatePicker)
  Vue.component('OdsDialog', OdsDialog)
  Vue.component('OdsDialogConfirmation', OdsDialogConfirmation)
  Vue.component('OdsDivider', OdsDivider)
  Vue.component('OdsDropdown', OdsDropdown)
  Vue.component('OdsDropdownItem', OdsDropdownItem)
  Vue.component('OdsDropdownMenu', OdsDropdownMenu)
  Vue.component('OdsFooter', OdsFooter)
  Vue.component('OdsForm', OdsForm)
  Vue.component('OdsFormItem', OdsFormItem)
  Vue.component('OdsHeader', OdsHeader)
  Vue.component('OdsIcon', OdsIcon)
  Vue.component('OdsImage', OdsImage)
  Vue.component('OdsInfiniteScroll', OdsInfiniteScroll)
  Vue.component('OdsInput', OdsInput)
  Vue.component('OdsInputNumber', OdsInputNumber)
  Vue.component('OdsInputSearch', OdsInputSearch)
  Vue.component('OdsKpi', OdsKpi)
  Vue.component('OdsLoading', OdsLoading)
  Vue.component('OdsLogo', OdsLogo)
  Vue.component('OdsMain', OdsMain)
  Vue.component('OdsMainHeader', OdsMainHeader)
  Vue.component('OdsMainNavigation', OdsMainNavigation)
  Vue.component('OdsMenu', OdsMenu)
  Vue.component('OdsMenuItem', OdsMenuItem)
  Vue.component('OdsMenuItemGroup', OdsMenuItemGroup)
  Vue.component('OdsMessage', OdsMessage)
  Vue.component('OdsMessageBox', OdsMessageBox)
  Vue.component('OdsModule', OdsModule)
  Vue.component('OdsNavigation', OdsNavigation)
  Vue.component('OdsNotification', OdsNotification)
  Vue.component('OdsNotificationLink', OdsNotificationLink)
  Vue.component('OdsOption', OdsOption)
  Vue.component('OdsOptionGroup', OdsOptionGroup)
  Vue.component('OdsPagination', OdsPagination)
  Vue.component('OdsPopover', OdsPopover)
  Vue.component('OdsProgress', OdsProgress)
  Vue.component('OdsRadio', OdsRadio)
  Vue.component('OdsRadioButton', OdsRadioButton)
  Vue.component('OdsRadioGroup', OdsRadioGroup)
  Vue.component('OdsRate', OdsRate)
  Vue.component('OdsRow', OdsRow)
  Vue.component('OdsScrollbar', OdsScrollbar)
  Vue.component('OdsSelect', OdsSelect)
  Vue.component('OdsSlider', OdsSlider)
  Vue.component('OdsSpinner', OdsSpinner)
  Vue.component('OdsStep', OdsStep)
  Vue.component('OdsSteps', OdsSteps)
  Vue.component('OdsSubHeader', OdsSubHeader)
  Vue.component('OdsSubmenu', OdsSubmenu)
  Vue.component('OdsSwitch', OdsSwitch)
  Vue.component('OdsTabPane', OdsTabPane)
  Vue.component('OdsTable', OdsTable)
  Vue.component('OdsTableColumn', OdsTableColumn)
  Vue.component('OdsTabs', OdsTabs)
  Vue.component('OdsTag', OdsTag)
  Vue.component('OdsThemeOnesait', OdsThemeOnesait)
  Vue.component('OdsTimePicker', OdsTimePicker)
  Vue.component('OdsTimeSelect', OdsTimeSelect)
  Vue.component('OdsTimeline', OdsTimeline)
  Vue.component('OdsTimelineItem', OdsTimelineItem)
  Vue.component('OdsTooltip', OdsTooltip)
  Vue.component('OdsTransfer', OdsTransfer)
  Vue.component('OdsTree', OdsTree)
  Vue.component('OdsUpload', OdsUpload)
  Vue.component('OdsCollapseTransition', OdsCollapseTransition)

  locale.use(opts.locale)
  locale.i18n(opts.i18n)

  Vue.use(OdsLoading.directive)
  Vue.directive(OdsInfiniteScroll.name, OdsInfiniteScroll)

  Vue.prototype.$ONESAITDS = {
    size: opts.size || '',
    zIndex: opts.zIndex || 2000
  }

  Vue.prototype.$loading = OdsLoading.service
  Vue.prototype.$msgbox = OdsMessageBox
  Vue.prototype.$alert = OdsMessageBox.alert
  Vue.prototype.$confirm = OdsMessageBox.confirm
  Vue.prototype.$prompt = OdsMessageBox.prompt
  Vue.prototype.$notify = OdsNotification
  Vue.prototype.$message = OdsMessage
}

export default {
  version: '1.0.0',
  install,
  locale: locale.use,
  i18n: locale.i18n,
  OdsAccordion,
  OdsAccordionItem,
  OdsActionsMenu,
  OdsAlert,
  OdsAside,
  OdsAutocomplete,
  OdsAvatar,
  OdsBacktop,
  OdsBadge,
  OdsBreadcrumb,
  OdsBreadcrumbItem,
  OdsButton,
  OdsButtonGroup,
  OdsCalendar,
  OdsCarousel,
  OdsCarouselItem,
  OdsCascader,
  OdsCascaderPanel,
  OdsChart,
  OdsCheckbox,
  OdsCheckboxButton,
  OdsCheckboxGroup,
  OdsCol,
  OdsCollapse,
  OdsColorPicker,
  OdsContainer,
  OdsDatavizBar,
  OdsDatavizGauge,
  OdsDatavizLine,
  OdsDatavizPie,
  OdsDatePicker,
  OdsDialog,
  OdsDialogConfirmation,
  OdsDivider,
  OdsDropdown,
  OdsDropdownItem,
  OdsDropdownMenu,
  OdsFooter,
  OdsForm,
  OdsFormItem,
  OdsHeader,
  OdsIcon,
  OdsImage,
  OdsInfiniteScroll,
  OdsInput,
  OdsInputNumber,
  OdsInputSearch,
  OdsKpi,
  OdsLoading,
  OdsLogo,
  OdsMain,
  OdsMainHeader,
  OdsMainNavigation,
  OdsMenu,
  OdsMenuItem,
  OdsMenuItemGroup,
  OdsMessage,
  OdsMessageBox,
  OdsModule,
  OdsNavigation,
  OdsNotification,
  OdsNotificationLink,
  OdsOption,
  OdsOptionGroup,
  OdsPagination,
  OdsPopover,
  OdsProgress,
  OdsRadio,
  OdsRadioButton,
  OdsRadioGroup,
  OdsRate,
  OdsRow,
  OdsScrollbar,
  OdsSelect,
  OdsSlider,
  OdsSpinner,
  OdsStep,
  OdsSteps,
  OdsSubHeader,
  OdsSubmenu,
  OdsSwitch,
  OdsTabPane,
  OdsTable,
  OdsTableColumn,
  OdsTabs,
  OdsTag,
  OdsThemeOnesait,
  OdsTimePicker,
  OdsTimeSelect,
  OdsTimeline,
  OdsTimelineItem,
  OdsTooltip,
  OdsTransfer,
  OdsTree,
  OdsUpload,
  OdsCollapseTransition,
  OdsLoading
}
