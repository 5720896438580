const importCommon = {
  methods: {
    getStatusBadgeType (status) {
      switch (status) {
        case 'created':
          return 'secondary'
        case 'validate_data':
          return 'warning'
        case 'error':
          return 'danger'
        case 'restore':
          return 'info'
        case 'done':
          return 'success'
        default:
          return 'primary'
      }
    }
  }
}

export default importCommon
