<template>
  <section>
    <ods-module header="Profile">
      <ods-form
          class="login-form"
          label-position="top"
          :model="profileForm"
          :rules="validations"
          ref="profileForm"
          v-loading="loading"
      >
        <ods-row>
          <ods-col>
            <ods-form-item label="Name" prop="name">
              <ods-input v-model="profileForm.name"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col>
            <ods-form-item label="Email" prop="email">
              <ods-input v-model="profileForm.email" type="email"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row type="flex" justify="center">
          <ods-button type="primary" @click="submitForm()">Save</ods-button>
        </ods-row>
      </ods-form>
    </ods-module>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import UserService from '@/services/User'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'

export default {
  name: 'Profile',
  mounted: function () {
    this.getData()
  },
  data () {
    return {
      loading: false,
      profileForm: {},
      validations: {
        email: [errors.required, errors.email],
        name: [errors.required]
      }
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.data
    })
  },
  methods: {
    async getData () {
      this.loading = true

      const [error, response, data] = await handlePromise(UserService.getUser(this.userData.user.id))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.profileForm = {
        email: data.email,
        name: data.userProfile?.name
      }
    },
    async submitForm () {
      this.$refs['profileForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          const formattedData = {
            email: this.profileForm.email,
            name: this.profileForm.name
          }

          const [error, response] = await handlePromise(
            UserService.updateUser(this.userData.user.id, formattedData)
          )
          this.loading = false
          if (!response.ok) {
            return this.$store.commit('settings/toggleAlert', error)
          }
          return this.$router.push({ name: 'BalanceDashboard' })
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
