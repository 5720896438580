import api from '@/config/Api'
import { formatFormDataBody } from '@/utils/format'

const baseEndpointList = '/measurement/import-files-reading-forecast'
const baseEndpoint = '/measurement/import-file-reading-forecast'

const baseEndpointListWater = '/measurement/import-water-files-reading-forecast'
const baseEndpointWater = '/measurement/import-water-file-reading-forecast'

export default {
  importFile (data) {
    return api.post(baseEndpoint, formatFormDataBody(data))
  },

  getImportFile (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  getImports (params) {
    return api.get(baseEndpointList, params)
  },

  importFileWater (data) {
    return api.post(baseEndpointWater, formatFormDataBody(data))
  },

  getImportFileWater (id) {
    return api.get(`${baseEndpointWater}/${id}`)
  },

  getImportsWater (params) {
    return api.get(baseEndpointListWater, params)
  }
}
