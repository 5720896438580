<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
             {{valueToEdit === null ? $t('createModel') : $t('editModel')}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
        <ods-module v-loading="loading">
          <section class="modal-body">
              <slot name="body">
                  <ods-form
                      :model="form"
                      ref="form"
                      v-loading="formLoading"
                  >
                    <ods-row :gutter="20">
                      <ods-col :md="12">
                        <ods-form-item :label="$t('value')" prop="value"
                                      :rules="[errors.required]">
                          <ods-input v-model="form.value"></ods-input>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                        <ods-form-item :label="$t('code')" prop="code"
                                      :rules="[errors.required]">
                          <ods-input v-model="form.code"></ods-input>
                        </ods-form-item>
                      </ods-col>
                      <ods-col :md="12">
                          <ods-form-item :label="$t('description')" prop="description"
                                      :rules="[errors.required]">
                              <ods-input v-model="form.description" type="textarea"></ods-input>
                          </ods-form-item>
                      </ods-col>
                    </ods-row>
                  </ods-form>
              </slot>
          </section>
        </ods-module>
        <slot name="footer" class="modal-footer">
            <form-buttons
                @submit="submitForm"
                @cancel="$emit('close')"
            ></form-buttons>
        </slot>
        </div>
    </div>
  </transition>
</template>

<script>
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import BrandModelService from '@/services/BrandModel'
import handlePromise from '@/utils/promise'

export default {
  name: 'CreateModelModal',
  props: {
    valueToEdit: {
      type: String,
      default: null
    },
    brand: String
  },
  components: {
    FormButtons
  },
  mounted: function () {
    if (this.valueToEdit != null) { this.getValue() }
  },
  data () {
    return {
      formLoading: false,
      loading: false,
      form: {},
      errors
    }
  },
  methods: {
    async getValue () {
      this.formLoading = true

      const [error, response, data] = await handlePromise(BrandModelService.getModel(this.brand, this.valueToEdit))
      this.formLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        value: data.value,
        code: data.code,
        description: data.description
      }
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.formLoading = true
          const toSend = {
            value: this.form.value,
            code: this.form.code,
            description: this.form.description
          }
          const [error, response] = await handlePromise(
            this.valueToEdit
              ? BrandModelService.updateModel(this.brand, this.valueToEdit, toSend)
              : BrandModelService.saveModel(this.brand, toSend)
          )
          this.formLoading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.$emit('close')
        }
      })
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 60%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 40vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
