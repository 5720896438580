import api from '@/config/Api'

const baseEndpoint = '/planning/strategic-plan'
const baseEndpoint2 = '/planning/strategic-plan-analysis'

export default {
  getStrategicPlans (params) {
    return api.get(baseEndpoint, params)
  },

  getStrategicPlan (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  saveStrategicPlan (data) {
    return api.post(baseEndpoint, data)
  },

  updateStrategicPlan (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  deleteStrategicPlan (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  getActivityByPlan (id) {
    return api.get(`${baseEndpoint}/${id}/activity`)
  },

  getActivity (id, idActivity) {
    return api.get(`${baseEndpoint}/${id}/activity/${idActivity}`)
  },

  deleteActivity (id, idActivity) {
    return api.delete(`${baseEndpoint}/${id}/activity/${idActivity}`)
  },

  updateActivity (id, idActivity, data) {
    return api.put(`${baseEndpoint}/${id}/activity/${idActivity}`, data)
  },

  saveActivity (id, data) {
    return api.post(`${baseEndpoint}/${id}/activity`, data)
  },

  getInitialLosseByArea (id) {
    return api.get(`${baseEndpoint}/initialLoss/${id}`)
  },

  getHistoricActivity (id, idActivity) {
    return api.get(`${baseEndpoint}/${id}/activity/${idActivity}/history`)
  },

  getStrategicAnalisis (params) {
    return api.get(baseEndpoint2, params)
  },

  getStrategicActiveAnalisis (params) {
    return api.get(`${baseEndpoint2}-open`, params)
  },

  getStrategicAnalisisDetail (id, params) {
    return api.get(`${baseEndpoint2}/${id}`, params)
  }
}
