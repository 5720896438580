<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="7">
        <network-tree-mozambique
          @node-click="searchByNetworkUnit"
          :selectedNodes="selectedNodes"
          @toggle-loading="toggleLoading"
          ref="treeComp"
        ></network-tree-mozambique>
      </ods-col>
      <ods-col :md="17">
        <ods-module :header="$t('individualValidation')">
          <ods-form :model="search" ref="form">
            <ods-row :gutter="20" type="flex" align="center">
              <ods-col :md="10">
                <ods-form-item :label="$t('number')" prop="serialNumber">
                  <ods-input v-model="search.serialNumber" disabled></ods-input>
                </ods-form-item>
              </ods-col>
              <ods-col :md="10">
                <ods-form-item :label="$t('period')" prop="period">
                  <ods-select
                    v-model="search.period"
                    placeholder=""
                    remote
                    clearable
                  >
                    <ods-option
                      v-for="item in periods"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </ods-option>
                  </ods-select>
                </ods-form-item>
              </ods-col>

              <ods-col :md="4">
                <ods-button type="primary" @click="searchBySerialNumber">
                  {{ $t("search") }}
                </ods-button>
              </ods-col>
            </ods-row>
          </ods-form>
        </ods-module>

        <validation-readings
          v-if="searchEnabled"
          :meter="selectedMeter"
          :data="readings"
          :hasPermission="hasPermission"
          @reload-data="searchMeterReadings"
        />

        <ods-row
          :gutter="20"
          class="mb-1"
          v-if="hasPermission"
          style="margin-top: 15px"
        >
          <ods-col :md="12" :sm="12">
            <ods-module body-style="padding-top: 15px; padding-bottom:15px">
              <ods-row type="flex" align="center">
                <ods-col
                  :md="24"
                  class="text-right"
                  style="margin-bottom: 15px"
                >
                  <ods-button
                    type="transparent"
                    size="small"
                    @click="noReadingValidationsLastReport"
                    style="margin-left: -10px; display: inherit; float: left"
                  >
                    <ods-icon
                      name="download"
                      style="margin-left: -10px; display: inherit; float: left"
                      size="15"
                    />
                    {{ $t("lastReport") }} - {{ validateEmptyReading | date2 }}
                  </ods-button>
                  <ods-tooltip
                    v-if="validateEmptyReading"
                    class="item"
                    effect="dark"
                    :content="$t('balanceLogTitleValidation')"
                    placement="top"
                    style="float: right"
                  >
                    <ods-button
                      style="
                        margin-top: -3px;
                        display: inherit;
                        float: right;
                        margin-right: -10px;
                      "
                      type="secondary"
                      icon="ods-icon-iniciatives"
                      size="small"
                      @click="toggleLogDialog('no-validation')"
                      circle
                    />
                  </ods-tooltip>
                </ods-col>
              </ods-row>
              <ods-row class="text-right" style="margin-bottom: 15px">
                <ods-col
                  :md="24"
                  style="display: flex; justify-content: center"
                >
                  <ods-button
                    size="medium"
                    @click="noReadingValidations"
                    style="padding-top: 10px; padding-bottom: 10px"
                  >
                    {{ $t("validateEmptyReadings") }}
                  </ods-button>
                </ods-col>
              </ods-row>
            </ods-module>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-module body-style="padding-top: 15px; padding-bottom:15px">
              <ods-row type="flex" align="center">
                <ods-col :md="24" style="margin-bottom: 15px">
                  <ods-button
                    type="transparent"
                    size="small"
                    @click="bulkValidationsLastReport"
                    style="margin-left: -10px; display: inherit; float: left"
                  >
                    <ods-icon
                      name="download"
                      size="15"
                      style="margin-left: -10px; display: inherit; float: left"
                    />
                    {{ $t("lastReport") }} - {{ bulkValidation | date2 }}
                  </ods-button>
                  <ods-tooltip
                    v-if="bulkValidation"
                    class="item"
                    effect="dark"
                    :content="$t('balanceLogTitleValidation')"
                    placement="top"
                    style="float: right"
                  >
                    <ods-button
                      style="
                        margin-top: -3px;
                        display: inherit;
                        float: right;
                        margin-right: -10px;
                      "
                      type="secondary"
                      icon="ods-icon-iniciatives"
                      size="small"
                      @click="toggleLogDialog('massive-validation')"
                      circle
                    />
                  </ods-tooltip>
                </ods-col>
              </ods-row>
              <ods-row class="text-right" style="margin-bottom: 15px">
                <ods-col
                  :md="24"
                  style="display: flex; justify-content: center"
                >
                  <ods-button
                    size="medium"
                    type="warning"
                    style="padding-top: 10px; padding-bottom: 10px"
                    @click="bulkValidations"
                  >
                    {{ $t("bulkValidations") }}
                  </ods-button>
                </ods-col>
              </ods-row>
            </ods-module>
          </ods-col>
        </ods-row>
      </ods-col>
    </ods-row>

    <ModalLog
      v-if="dialogLogVisible"
      :logType="logType"
      @close="closeLogModal"
    />
  </section>
</template>

<script>
import MeterService from "@/services/Meter";
import MeterReadingService from "@/services/MeterReading";
import PeriodService from "@/services/Period";
import handlePromise from "@/utils/promise";
import NetworkTree from "@/custom-components/Network/Tree";
import NetworkTreeMozambique from "../../../../custom-components/Network/TreeMozambique.vue";
import ValidationReadings from "./Reading";
import ModalLog from "./ModalLog";
import errors from "@/config/InputErrors";
import { mapState } from "vuex";

export default {
  name: "MeasurementLoading",
  components: {
    NetworkTree,
    NetworkTreeMozambique,
    ValidationReadings,
    ModalLog,
  },
  computed: {
    ...mapState({
      userRole: (state) => state.userRoles.data,
    }),
  },
  mounted() {
    this.fetchPeriods();
    this.fetchLastReportDate();
    this.handlePermission();
  },
  data() {
    return {
      hasPermission: true,
      loading: false,
      searchEnabled: false,
      dialogLogVisible: false,
      logType: "",
      selectedMeter: {},
      search: {},
      periods: [],
      selectedNodes: [],
      lastReportDate: null,
      validateEmptyReading: null,
      bulkValidation: null,
      bulkEstimation: null,
      readings: [],
      errors,
    };
  },
  methods: {
    async fetchPeriods() {
      const [, response, responseData] = await handlePromise(
        PeriodService.getPeriods({ limit: 0 })
      );
      if (response.ok) this.periods = responseData.data;
    },
    async fetchLastReportDate() {
      const [, response, responseData] = await handlePromise(
        MeterReadingService.getLastReport()
      );
      if (response.ok) this.lastReportDate = responseData.data;
      this.validateEmptyReading = responseData[1].updateDate;
      this.bulkValidation = responseData[0].updateDate;
      this.bulkEstimation = responseData[2].updateDate;
    },
    async bulkValidations() {
      const [error, response] = await handlePromise(
        MeterReadingService.bulkValidation()
      );
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }

      return this.$store.commit(
        "settings/toggleSuccessAlert",
        this.$t("bulkValidationStarted")
      );
    },
    async bulkValidationsLastReport() {
      const [error, response, data] = await handlePromise(
        MeterReadingService.bulkValidationReport()
      );
      if (!response.ok) {
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      return window.open(data.url);
      // return this.$store.commit('settings/toggleSuccessAlert', this.$t('bulkValidationStarted'))
    },
    async bulkEstimations() {
      const [error, response] = await handlePromise(
        MeterReadingService.massiveEstimation()
      );
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));

      return this.$store.commit(
        "settings/toggleSuccessAlert",
        this.$t("bulkValidationStarted")
      );
    },
    async noReadingValidations() {
      const [error, response] = await handlePromise(
        MeterReadingService.noReadingValidation()
      );
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));

      return this.$store.commit(
        "settings/toggleSuccessAlert",
        this.$t("bulkValidationStarted")
      );
    },
    async noReadingValidationsLastReport() {
      const [error, response, data] = await handlePromise(
        MeterReadingService.noReadingValidationReport()
      );
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      return window.open(data.url);
      // return window.open(data.url),this.$store.commit('settings/toggleSuccessAlert', this.$t('bulkValidationStarted'))
    },
    async searchByNetworkUnit({ data }) {
      this.selectedNodes = [{ id: data.id }];
      this.toggleLoading();
      const [error, response, responseData] = await handlePromise(
        MeterService.getMeterBySearch({
          networkUnit: data.id,
        })
      );
      if (!response.ok) {
        this.toggleLoading();
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      }
      this.selectedMeter = responseData;
      this.search = {
        serialNumber: responseData.serialNumber,
      };
      this.$refs["form"].validateField("serialNumber");
      setTimeout(() => {
        this.searchMeterReadings();
      }, 100);
    },
    async searchBySerialNumber() {
      this.$refs["form"].validate(async (valid) => {
        this.searchEnabled = false;
        if (valid) {
          this.toggleLoading();
          const [error, response, responseData] = await handlePromise(
            MeterService.getMeterBySearch({
              serialNumber: this.search.serialNumber,
            })
          );
          if (!response.ok) {
            this.toggleLoading();
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          }
          this.selectedMeter = responseData;
          this.searchMeterReadings();
        }
      });
    },
    async searchMeterReadings() {
      this.$refs["form"].validate(async (valid) => {
        this.searchEnabled = false;
        if (!valid && this.loading) this.toggleLoading();

        if (valid) {
          if (!this.loading) this.toggleLoading();
          const params = { limit: 0 };
          if (this.search.period)
            params["filters[period]"] = this.search.period;

          const [error, response, data] = await handlePromise(
            MeterReadingService.getMeterReadings(this.selectedMeter.id, params)
          );
          if (this.loading) this.toggleLoading();
          if (!response.ok)
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          this.searchEnabled = true;
          this.readings = data;
        }
      });
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    toggleLogDialog(type) {
      this.logType = type;
      this.dialogLogVisible = !this.dialogLogVisible;
    },
    closeLogModal() {
      this.dialogLogVisible = false;
    },
    handlePermission() {
      let rolesArray = Object.values(this.userRole);
      rolesArray.map((role) => {
        if (role === "ROLE_OPERATOR") {
          this.hasPermission = false;
          rolesArray.map((role2) => {
            if (role2 === "ROLE_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_SUPER_ADMIN") {
              this.hasPermission = true;
            }
          });
          rolesArray.map((role2) => {
            if (role2 === "ROLE_MANAGER") {
              this.hasPermission = true;
            }
          });
        }
      });
    },
  },
};
</script>
