<template>
  <section v-loading="loading">
    <ods-row :gutter="20" type="flex">
      <ods-col :md="7">
        <network-tree
            @node-click="loadNodeData"
            @toggle-loading="toggleLoading"
            ref="treeComp"
        >
        </network-tree>
      </ods-col>

      <ods-col :md="11" v-if="nodeForm.id">
        <ods-module :header="$t('reconfiguration')">
          <ods-row type="flex" justify="end"
                   v-if="!nodeForm.disabledMove">
            <ods-button
                type="warning"
                size="small"
                @click="toggleMoveNodeDialog"
                v-if="nodeForm && nodeForm.extraData && !nodeForm.extraData.networkTree && hasPermission"
            >
              {{$t('move')}}
            </ods-button>
          </ods-row>
          <edit-node :model="nodeForm"></edit-node>
        </ods-module>
      </ods-col>

      <ods-col :md="6" v-if="nodeForm.id">
        <ods-module class="pt-1" v-if="map">
          <div class="google-map" ref="googleMap" style="height: 200px">
          </div>
        </ods-module>

        <ods-module class="pt-1" v-bind:class="{ 'mt-1': !!map }"
                    v-if="logs && logs.id">
          <network-logs
              showActions
              :data="logs"
              @toggle-loading="toggleLoading"
              @reload="reloadData"
          ></network-logs>
        </ods-module>
      </ods-col>

      <ods-col :md="17" v-if="!nodeForm.id" style="min-height: 100%;">
        <ods-module :header="$t('reconfiguration')" style="min-height: 100%">
          <ods-row type="flex" justify="center" style="margin-top: 2rem">
            <ods-icon name="info" size="100" class="custom-placeholder"/>
          </ods-row>
        </ods-module>
      </ods-col>
    </ods-row>

    <move-node
        v-if="nodeForm.id"
        :visible="moveNodeDialogVisible"
        :from="nodeForm.extraData"
        @cancel="toggleMoveNodeDialog"
        @reload="reloadNodeMoved"
    ></move-node>
  </section>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import { mapState } from 'vuex'
import get from 'lodash/get'
import NetworkNodeService from '@/services/NetworkNode'
import NetwortManeuverService from '@/services/NetwortManeuver'
import handlePromise from '@/utils/promise'
import EditNode from './EditNode'
import MoveNode from './MoveNode'
import NetworkTree from '@/custom-components/Network/Tree'
import NetworkLogs from '@/custom-components/Network/Logs'
import attributes from '@/config/data/Attributes'
import _ from 'lodash'

export default {
  name: 'ReconfigurationDashboard',
  components: {
    NetworkTree,
    NetworkLogs,
    EditNode,
    MoveNode
  },
  data () {
    return {
      hasPermission: true,
      loading: false,
      nodeForm: {},
      logs: null,
      moveNodeDialogVisible: false,
      google: null,
      map: null
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.data,
      userRole: state => state.userRoles.data
    })
  },
  async mounted () {
    this.google = await GoogleMapsApiLoader({
      apiKey: process.env.VUE_APP_MAP_API_KEY
    })
    this.handlePermission()
  },
  methods: {
    async loadNodeData ({ id }) {
      this.loading = true
      const [error, response, data] = await handlePromise(NetworkNodeService.getNode(id))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      await this.loadNodeManeuvers()

      this.loading = false
      if (data.networkUnit) {
        const attributeValues = attributes.map(attr => ({
          ...attr,
          value: data.networkUnit[attr.name]
        }))

        this.nodeForm = {
          id: data.networkUnit.id,
          name: data.networkUnit.name,
          technicalLosses: data.networkUnit.technicalLosses,
          originSystemId: data.networkUnit.originSystemId,
          networkElement: `${data.networkUnit.networkElement.name} ${data.networkUnit.networkElement.elementType}`,
          attributes: attributeValues,
          extraData: data,
          disabled: true,
          disabledAttributes: true,
          disabledMove: get(data, 'parentNetworkTree.blocked') &&
              (get(data, 'parentNetworkTree.blockedBy') !== get(this.userData, 'user.id') ||
                (data.status === 'created' || data.status === 'deleted' || data.status === 'modified'))
          /* disabled: get(data, 'parentNetworkTree.blocked') &&
                  (get(data, 'parentNetworkTree.blockedBy') !== get(this.userData, 'user.id') ||
                    (data.status === 'created' || data.status === 'deleted' || data.status === 'modified')),
              disabledAttributes: get(data, 'parentNetworkTree.blocked') &&
                  get(data, 'parentNetworkTree.blockedBy') !== get(this.userData, 'user.id') */
        }
      } else {
        this.nodeForm = {}
      }

      if (data.networkUnit.geometry) {
        this.map = {}
        setTimeout(() => {
          this.initializeMap(data.networkUnit.geometry)
        }, 100)
      }
    },
    async loadNodeManeuvers () {
      const [, response, data] = await handlePromise(NetwortManeuverService.getMyManeuvers())
      if (response.ok) {
        _.map(data.logNetworkChanges, change => {
          if (change.action === 'modified') {
            const secondMov = _.find(data.logNetworkChanges,
              item => item.id !== change.id && item.refModified === change.refModified && !change.mapped
            )
            if (!secondMov) return change
            if (change.origin) {
              change.destination = { ...secondMov.destination }
              change.destinationTree = { ...secondMov.networkTree }
            } else {
              change.origin = { ...secondMov.origin }
              change.originTree = { ...secondMov.networkTree }
            }
            secondMov.mapped = true
          }
        })

        data.logNetworkChanges = _.filter(data.logNetworkChanges, item => !item.mapped)
        this.logs = data
      }
    },
    initializeMap (marker) {
      const position = { lat: marker.coordinates[0], lng: marker.coordinates[1] }
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, { center: position, zoom: 8 })
      // eslint-disable-next-line no-new
      new this.google.maps.Marker({
        position,
        map: this.map
      })
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    toggleMoveNodeDialog () {
      this.moveNodeDialogVisible = !this.moveNodeDialogVisible
    },
    reloadData () {
      this.$refs.treeComp.$refs.tree.reload()
      if (this.nodeForm.id) this.loadNodeData({ id: this.nodeForm.extraData.id })
    },
    reloadNodeMoved () {
      this.toggleMoveNodeDialog()
      this.reloadData()
    },
    handlePermission () {
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  }
}
</script>
