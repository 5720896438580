import api from '@/config/Api'

const baseEndpoint = '/network/network-node'
const baseEndpoint2 = '/network/network-tree'

const baseEndpointWater = '/network/network-node-water'
const baseEndpoint2Water = '/network/network-water-tree'

export default {
  getNodeTree(id, params) {
    console.log(JSON.stringify(params) );
    return api.get(id ? `${baseEndpoint}/tree/${id}` : `${baseEndpoint}/tree`, params)
  },

  getNodeTreeWater(id, params) {
    return api.get(id ? `${baseEndpointWater}/tree/${id}` : `${baseEndpointWater}/tree`, params)
  },

  getNetworkByNode(node, until) {
    if (until) {
      return api.get(`${baseEndpoint}/subTree/${node}?until=${until}`)
    }
    return api.get(`${baseEndpoint}/subTree/${node}`)

  },

  getNetworkByNodeWater(node) {
    return api.get(`${baseEndpointWater}/subTree/${node}`)
  },

  getNode(id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  getNeighborsWater(level, tree) {
    return api.get(`${baseEndpointWater}/tree/${tree}/level/${level}`)
  },

  getNodeWater(id) {
    return api.get(`${baseEndpointWater}/${id}`)
  },

  getGrapgWater(id) {
    return api.get(`${baseEndpointWater}/tree/${id}`)
  },

  getClientsWater(id) {
    return api.get(`${baseEndpointWater}/costumers/${id}`)
  },

  saveNode(data) {
    return api.post(baseEndpoint, data)
  },

  saveNodeWater(data) {
    return api.post(baseEndpointWater, data)
  },

  saveBaseNode(data) {
    return api.post(baseEndpoint2, data)
  },
  saveBaseNodeWater(data) {
    return api.post(baseEndpoint2Water, data)
  },

  updateNode(id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  updateNodeWater(id, data) {
    return api.put(`${baseEndpointWater}/${id}`, data)
  },

  updateReconfiguration(id, data) {
    return api.put(`${baseEndpoint}/${id}/reconfiguration`, data)
  },

  updateReconfigurationWater(id, data) {
    return api.put(`${baseEndpointWater}/${id}/reconfiguration`, data)
  },

  deleteNode(id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  deleteNodeWater(id) {
    return api.delete(`${baseEndpointWater}/${id}`)
  }
}
