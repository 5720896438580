<template>
  <section v-loading="loading">
      <ods-module :header="$t('analysis')">
         <template slot="header">
            <list-header title="analysis">
              <template slot="right">
                <ods-checkbox
                    v-model="onlyActivePlans"
                    :label="$t('onlyActivePlans')"
                >
                </ods-checkbox>
              </template>
            </list-header>
          </template>
          <ods-table
              :data="plans"
              size="mini"
          >
            <ods-table-column :label="$t('name')"  width="135">
              <template slot-scope="scope">
                <ods-button type="text" class="p-0"
                  @click="toggleDetailDialog(scope.row.id,
                    scope.row.name,
                    scope.row.balanceArea.name,
                    scope.row.status,
                    scope.row.from,
                    scope.row.to,
                    scope.row.lossesInitial,
                    scope.row.currentLoss,
                    scope.row.lossReduction,
                    scope.row.cost,
                    scope.row.benefit,
                    scope.row.profit
                    )">
                  {{ scope.row.name }}
                </ods-button>
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('area')" >
              <template slot-scope="scope">
                {{ scope.row.balanceArea.name }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('from')" >
              <template slot-scope="scope">
                {{ scope.row.from }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('to')" >
              <template slot-scope="scope">
                {{ scope.row.to }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('initialLossesPercent')" >
              <template slot-scope="scope">
                {{ scope.row.lossesInitial | formatNumberPercentNoDecimal }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('currentLossPercent')" >
              <template slot-scope="scope">
                {{ scope.row.currentLoss | formatNumberPercentNoDecimal }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('lossReductionTargetPercent')" >
              <template slot-scope="scope">
                {{ scope.row.lossesObjective | formatNumberPercentNoDecimal }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('lossReductionPercent')" >
              <template slot-scope="scope">
                {{ scope.row.lossReduction | formatNumberPercentNoDecimal }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('cost')" >
              <template slot-scope="scope">
                {{ scope.row.cost | formatNumber }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('benefit')" >
              <template slot-scope="scope">
                {{ scope.row.benefit | formatNumber }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('profitPercent')" >
              <template slot-scope="scope">
                {{ scope.row.profit | formatNumberPercentNoDecimal }}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('status')" >
              <template slot-scope="scope">
                <ods-badge
                v-if="scope.row.status"
                :value="$t(scope.row.status).toUpperCase()"
                :type="getStatusBadgeType(scope.row.status)"
                />
              </template>
            </ods-table-column>
          </ods-table>
      </ods-module>

      <ods-module class="mt-1" :header="$t('monthlyLosses')">
              <div id="chart" style="height:400px;"></div>
      </ods-module>

      <DetailModal
      v-show="detailDialogVisible"
      v-if="detailDialogVisible"
      @close="closeDetailModal"
      :valueToDetail = valueToDetail
      :name = nameToDetail
      :area = areaToDetail
      :status  = statusToDetail
      :from = fromToDetail
      :to = toToDetail
      :iniLosses = iniLossesToDetail
      :currentLosses = currentLossesToDetail
      :totalLosses = totalLossesToDetail
      :cost = costToDetail
      :benefit = benefitToDetail
      :profit = profitToDetail
      />
  </section>
</template>
<script>
import StrategicService from '@/services/Strategic'
import ListHeader from '@/custom-components/ListHeader'
import handlePromise from '@/utils/promise'
import DetailModal from './DetailModal.vue'
import * as echarts from 'echarts'
import _ from 'lodash'
export default {
  name: 'Strategic-Analisis',
  components: {
    ListHeader,
    DetailModal
  },
  mounted () {
    this.fetchStrategicAnalysis()
  },
  data () {
    return {
      onlyActivePlans: true,
      detailDialogVisible: false,
      valueToDetail: null,
      nameToDetail: null,
      areaToDetail: null,
      statusToDetail: null,
      fromToDetail: null,
      toToDetail: null,
      iniLossesToDetail: null,
      currentLossesToDetail: null,
      totalLossesToDetail: null,
      costToDetail: null,
      benefitToDetail: null,
      profitToDetail: null,
      loading: false,
      plans: [],
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: [
          {
            boundaryGap: false,
            name: 'Months',
            type: 'category'
          }
        ],
        yAxis: [
          {
            name: 'Losses %',
            type: 'value'
          }
        ],
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
          filterMode: 'none'
        }, {
          start: 0,
          end: 100,
          filterMode: 'none'
        }]
      },
      chartData: {
        'xAxis': [],
        'lines': [ ]
      }
    }
  },
  methods: {
    async fetchStrategicAnalysis () {
      const params = {
        limit: 0
      }
      this.toggleLoading()
      if (!this.onlyActivePlans) {
        const [error, response, data] = await handlePromise(StrategicService.getStrategicAnalisis(params))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.plans = data.data
      } else {
        const [error, response, data] = await handlePromise(StrategicService.getStrategicActiveAnalisis(params))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.plans = data.data
      }
      const chartData = []
      _.chain(this.plans).groupBy('name').map((plans, name) => {
        chartData.push({
          name: name,
          type: 'line',
          smooth: true,
          data: _.map(this.getStrategicDetail(plans), p => [p.month, p.currentLoss])
        })
      }).value()
      // console.log(chartData, 'cd')
      setTimeout(() => {
        this.chart = echarts.init(document.getElementById('chart'))
        this.chartOptions = {
          ...this.chartOptions,
          series: chartData
        }
        this.chart.setOption(this.chartOptions, true)
      }, 100)
    },
    getStrategicDetail (data) {
      let aux = []
      for (let index = 0; index < data.length; index++) {
        // console.log(data[index].detail[0].data, 'index')
        aux = data[index].detail[0].data
      }
      // console.log(aux, 'aux')
      return aux
    },
    toggleDetailDialog (id, name, area, status, from, to, iniLosses, currentLosses, totalLosses, cost, benefit, profit) {
      this.valueToDetail = id
      this.nameToDetail = name
      this.areaToDetail = area
      this.statusToDetail = status
      this.fromToDetail = from
      this.toToDetail = to
      this.iniLossesToDetail = iniLosses
      this.currentLossesToDetail = currentLosses
      this.totalLossesToDetail = totalLosses
      this.costToDetail = cost
      this.benefitToDetail = benefit
      this.profitToDetail = profit
      this.detailDialogVisible = !this.detailDialogVisible
    },
    closeDetailModal () {
      this.detailDialogVisible = false
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    getStatusBadgeType (status) {
      switch (status) {
        case 'CLOSED':
          return 'secondary'
        case 'OPEN':
          return 'primary'
        default:
          return 'primary'
      }
    }
  },
  watch: {
    onlyActivePlans: function () {
      this.fetchStrategicAnalysis()
    }
  }
}
</script>
