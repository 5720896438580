<template>
  <section>
    <ods-module :header="$t('changePassword')">
      <ods-form
          :model="form"
          ref="form"
          v-loading="loading"
      >
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('password')" prop="password" :rules="[errors.required]">
              <ods-input v-model="form.password" type="password"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
                :label="$t('confirmPassword')"
                prop="passwordConfirmation"
                :rules="[errors.required, { validator: validatePassword, trigger: 'blur' }]">
              <ods-input v-model="form.passwordConfirmation" type="password"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <form-buttons
            @submit="submitForm"
            cancel-to="UserDashboard"
        ></form-buttons>
      </ods-form>
    </ods-module>
  </section>
</template>

<script>
import UserService from '@/services/User'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'

export default {
  name: 'ChangePassword',
  components: {
    FormButtons
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      roles: [
        { id: 'ROLE_OPERATOR', name: 'ROLE_OPERATOR' },
        { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
        { id: 'ROLE_SUPER_ADMIN', name: 'ROLE_SUPER_ADMIN' }
      ],
      errors,
      submitErrors: null
    }
  },
  methods: {
    async getData () {
      this.loading = true

      const [error, response, data] = await handlePromise(UserService.getUser(this.$route.params.id))
      this.loading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.form = {
        name: data.userProfile ? data.userProfile.name : '',
        email: data.email,
        roles: data.roles,
        externalAuthentication: data.externalAuthentication,
        enabled: data.enabled,
        modules:data.modules
      }
    },
    async submitForm () {
      this.submitErrors = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          const [error, response] = await handlePromise(
            UserService.updateUser(this.$route.params.id, { password: this.form.password })
          )
          this.loading = false
          if (!response.ok) {
            return this.$store.commit('settings/toggleAlert', error)
          }
          return this.$router.push({ name: 'UserDashboard' })
        }
      })
    },
    validatePassword (rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error('passwordDontMatch'))
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="scss">
</style>
