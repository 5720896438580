<template>
  <section>
    <ods-module class="mt-1" :header="$t('models')">
      <template slot="header">
        <list-header title="models">
          <template slot="right">
            <ods-button size="medium" @click="toggleDialog()">
              {{$t('createModel')}}
            </ods-button>
          </template>
        </list-header>
      </template>

      <async-table
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <ods-table-column prop="code" :label="$t('code')">
          <template slot-scope="scope">
            <ods-button type="text" class="p-0" @click="toggleDialog(scope.row.id)">
              {{scope.row.code}}
            </ods-button>
          </template>
        </ods-table-column>

        <ods-table-column prop="value" :label="$t('name')">
          <template slot-scope="scope">
              {{scope.row.value}}
          </template>
        </ods-table-column>

        <ods-table-column prop="description" :label="$t('description')">
          <template slot-scope="scope">
              {{scope.row.description | max20}}
          </template>
        </ods-table-column>

         <ods-table-column align="right">
          <template slot-scope="scope">
            <table-delete @click="toggleDeleteDialog(scope.row.id)"></table-delete>
          </template>
        </ods-table-column>

      </async-table>
    </ods-module>

    <EditModelModal
      v-show="dialogVisible"
      v-if="dialogVisible"
      @close="closeModal"
      :valueToEdit = valueToEdit
      :brand = brand
    />

    <dialog-confirmation
        :visible="deleteDialogVisible"
        :loading="deleteLoading"
        :brand=brand
        @confirm="deleteValue"
        @cancel="toggleDeleteDialog()"
    />
  </section>
</template>

<script>
import BrandModelService from '@/services/BrandModel'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import AsyncTable from '@/custom-components/AsyncTable'
import ListHeader from '@/custom-components/ListHeader'
import asyncTableCommon from '@/mixins/async-table'
import EditModelModal from './EditModelModal.vue'

export default {
  name: 'Model',
  mounted: function () {
    this.getData()
  },
  components: {
    TableDelete,
    DialogConfirmation,
    EditModelModal,
    AsyncTable,
    ListHeader
  },
  props: {
    brand: String
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      dialogVisible: false,
      deleteLoading: false,
      form: {},
      errors,
      editDialogVisible: false,
      valueToEdit: null,
      deleteDialogVisible: false,
      valueToDelete: null,
      promise: (params) => BrandModelService.getModelsByBrand(this.brand, params)
    }
  },
  methods: {
    toggleDialog (id) {
      this.valueToEdit = id
      this.dialogVisible = true
    },
    closeModal () {
      this.getData(this.tableParams)
      this.dialogVisible = false
    },
    toggleDeleteDialog (id) {
      this.valueToDelete = id
      this.deleteDialogVisible = !this.deleteDialogVisible
    },
    async deleteValue () {
      this.deleteLoading = true
      const [error, response] = await handlePromise(BrandModelService.deleteModel(this.brand, this.valueToDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDeleteDialog()
      return this.getData(this.tableParams)
    }
  }
}
</script>
